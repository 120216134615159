import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { RestService } from '../services/rest.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-shop-tax-management',
  templateUrl: './shop-tax-management.component.html',
  styleUrls: ['./shop-tax-management.component.css']
})
export class ShopTaxManagementComponent implements OnInit {
  ownerId: any;
  shopId: any;
  sheetData: any[];
  sheetName: string;
  columnNames: string[];
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  defaultGST = 0;
  defaultTaxType = "";

  constructor(private fireRest: FirebaseRestService, private route: ActivatedRoute, private rest: RestService, private dataService: DataService) {
    this.route.params.subscribe(params => {
      // console.log(params);
      this.ownerId = params.ownerId;
      this.shopId = params.shopId;
      this.fireRest.getShopProductsByOwnerId(this.ownerId).subscribe((data) => {

        this.sheetData = [];
        let columns: any = [];

        if (data.length > 0) {
          console.log(data.filter(ele => ele.data.hasVariants == 1));
          for (const hit of data) {
            // console.log(hit);
            let row: any = {};
            columns = [];


            row["Name"] = hit.data.Name;
            row["Ml"] = hit.data.menuLabel;
            // row["Discount"] = hit.data._clientView.find(ele => ele.l === "Discount amount") ? hit.data._clientView.find(ele => ele.l === "Discount amount").v : 0;
            let ec: any;
            if (hit.data.comissions && hit.data.comissions.comArr) {
              ec = hit.data.comissions.comArr.find(ele => ele.id == hit.id);
            }
            row["GST"] = hit.data._clientView.find(ele => ele.l === "GST") ? hit.data._clientView.find(ele => ele.l === "GST").v : 0;;
            row["TaxType"] = hit.data._clientView.find(ele => ele.l === "GST Type") ? hit.data._clientView.find(ele => ele.l === "GST Type").v : "CGST + SGST";;
            row["id"] = hit.id;
            row["productId"] = hit.id;

            this.sheetData.push(row)

          }


          this.columnNames = ["Ml", "Name", "TaxType", "GST"];


          // return;

          this.sheetName = data[0]["data"]["shopDetails"].shopname;

          this.dataSource = new MatTableDataSource(this.sheetData);
          this.dataSource.sort = this.sort;

        }

      })
    });
  }

  ngOnInit(): void {
  }



  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
  }

  // downloadFile() {
  //   const finalDataToSave = this.sheetData.map(ele => ({ Name: ele.Name, GST: ele.GST, Type: ele.TaxType, PC: ele.proposedComission, "Effective Amount": ele.TaxType == "p" ? ele.GST - (ele.GST / 100) * ele.proposedComission : ele.GST - ele.proposedComission }));
  //   this.dataService.downloadFile(finalDataToSave, this.sheetName, ["Name", "GST", "Type", "PC", "Effective Amount"]);
  // }

  submit() {
    // console.log(this.sheetData);
    const finalDataToSend = _.groupBy(this.sheetData.filter(ele => ele.changed == true).map(ele => ({ id: ele.id, productId: ele.productId, GST: ele.GST.length > 0 ? JSON.parse(ele.GST) : 0, taxType: ele.TaxType })), "productId");
    console.log(finalDataToSend);

    this.rest.updateShoptaxes(finalDataToSend).subscribe((data) => {
      console.log(data);
      // if (data.status === "success") {
      //   this.downloadFile()
      // } else if (data.status == "fail" && data.message == "Nothing to update") {
      //   this.downloadFile();
      // }

    })

  }

  setComission() {
    for (const d of this.sheetData) {
      // if (!d.changed && d.GST == 0) {
      d.GST = this.defaultGST;
      d.changed = true;
      // }
    }
  }

  setGSTType() {
    for (const d of this.sheetData) {
      // if (!d.changed && !d.TaxType) {
      d.TaxType = this.defaultTaxType;
      d.changed = true;
      // }
    }
  }

  format(comp) {
    return comp.replace(/,/g, '|')
  }


}
