<mat-form-field appearance="fill">
    <mat-label>Select POS</mat-label>
    <mat-select [(value)]="selectedPOS" (selectionChange)="showPOSForm($event)">
        <mat-option *ngFor="let pos of allPOSs" [value]="pos">{{pos.get('Name')}}</mat-option>
    </mat-select>




</mat-form-field>
<p *ngFor="let field of currentFormFields|keyvalue">
    <mat-form-field class="w-100 px-4" appearance="standard">
        <mat-label>{{field.key}}</mat-label>
        <input matInput [(ngModel)]="currentFormFieldValues[field.key]" type="{{field.value}}">
    </mat-form-field>
</p>

<button mat-raised-button (click)="selPOS()">Map menu</button>


<table>
    <tr *ngFor="let mc of mappedCategories">
        <td>{{mc.tag1}}-{{mc.tag2}}</td>
        <td *ngIf="mc.snby_cat&&mc.snby_cat.id">{{mc.snby_cat.name}}<button mat-mini-fab
                (click)="mc.snby_cat={}">x</button></td>
        <td *ngIf="!mc.snby_cat||!mc.snby_cat.id">

            <mat-toolbar color="white">
                <mat-toolbar-row>
                    <button mat-raised-button color="primary" #menuTrigger="matMenuTrigger"
                        [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="{name: 'axJnLMUyzYAdyrifhTvr'}">Select
                        Category</button>
                    <span class="fill-remaining-space"></span>


                </mat-toolbar-row>

            </mat-toolbar>
            <mat-menu #appMenu="matMenu">
                <ng-template matMenuContent let-name="name">
                    <button *ngFor="let n of catData | getChildern:name:'id'" mat-menu-item
                        [matMenuTriggerFor]="subMenu" [matMenuTriggerData]="{name2: n.id}"
                        (click)="showAddChildForm(n,mc);menuTrigger.closeMenu();">{{n.name}}</button>
                </ng-template>
            </mat-menu>

            <mat-menu #subMenu="matMenu">
                <ng-template matMenuContent let-name2="name2">
                    <button *ngFor="let n of catData | getChildern:name2:'id'" mat-menu-item
                        [matMenuTriggerFor]="subsubMenu" [matMenuTriggerData]="{name3: n.id}"
                        (click)="showAddChildForm(n,mc);menuTrigger.closeMenu();">{{n.name}}</button>
                </ng-template>
            </mat-menu>

            <mat-menu #subsubMenu="matMenu">
                <ng-template matMenuContent let-name3="name3">
                    <button *ngFor="let n of catData | getChildern:name3:'id'" mat-menu-item
                        [matMenuTriggerFor]="subsubsubMenu" [matMenuTriggerData]="{name4: n.id}"
                        (click)="showAddChildForm(n,mc);menuTrigger.closeMenu();">{{n.name}}</button>
                </ng-template>
            </mat-menu>
            <mat-menu #subsubsubMenu="matMenu">
                <ng-template matMenuContent let-name4="name4">
                    <button *ngFor="let n of catData | getChildern:name4:'id'" mat-menu-item
                        (click)="showAddChildForm(n,mc);menuTrigger.closeMenu();">{{n.name}}</button>
                </ng-template>
            </mat-menu>

        </td>
    </tr>
</table>


<button mat-raised-button (click)="translateItems()">Translate</button>