<mat-card *ngIf="showCF" class="m-2">
    <form [formGroup]="editShopCatForm" (ngSubmit)="update_shop_category()">

        <mat-form-field>
            <input formControlName="Name" autocapitalize="words" matInput placeholder="Category Name">
        </mat-form-field><br>
        <mat-form-field>
            <input formControlName="PluralName" autocapitalize="words" matInput placeholder="Plural Category Name">
        </mat-form-field>

        <br>
        <mat-checkbox formControlName="allowReturns">allowReturns</mat-checkbox>
        <br>
        <br>
        <mat-checkbox formControlName="madeForOrder">Made for Order</mat-checkbox>
        <br>
        <br>
        <mat-checkbox formControlName="needFSSAI">Need Food Registration?</mat-checkbox>
        <br>
        <mat-form-field color="accent">
            <mat-label>Delivery Type</mat-label>
            <mat-select formControlName="deliveryType">
                <mat-option color="accent" *ngFor="let i of deliveryType|keyvalue" [value]="i.value">{{i.key}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br>

        <mat-form-field color="accent">
            <mat-label>Payment Type</mat-label>
            <mat-select formControlName="paymentType">
                <mat-option color="accent" *ngFor="let i of paymentType|keyvalue" [value]="i.value">{{i.key}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field>
            <input type="number" formControlName="sortOrder" matInput placeholder="Sort Order">
        </mat-form-field>
        <br>


        <button mat-raised-button type="submit">Add/Update Category</button>
        <button mat-raised-button type="reset" (click)="resetForm()">Cancel</button>
        <!-- <button mat-raised-button [hidden]="!delHidden" color="warn" type="button"  (click)="delHidden=false">Delete Category</button>
    <button mat-raised-button [hidden]="delHidden" color="warn" type="button" (click)="delete_category()">Confirm</button>
    <button mat-raised-button [hidden]="delHidden" color="accent" type="button"  (click)="delHidden=true">Cancel</button> -->
    </form>
</mat-card>


<div class="carousel row mx-1 cat-bar-desktop">
    <div (click)="showCatForm()" class="slide m-1 p-0 col-auto">
        <mat-card class="cat-card">
            <!-- <img mat-card-image
          src="https://firebasestorage.googleapis.com/v0/b/shopnearby-301e7-product-image-bucket/o/category_images%2F{{n.id}}%2Ficon.png?alt=media"
          class="w-auto m-auto" alt="media"> -->
            <mat-card-content style="text-align: center;">
                Add
            </mat-card-content>

        </mat-card>
    </div>
    <div *ngFor="let n of shopCats" class="slide m-1 p-0 col-auto">
        <mat-card class="cat-card">
            <!-- <img mat-card-image
          src="https://firebasestorage.googleapis.com/v0/b/shopnearby-301e7-product-image-bucket/o/category_images%2F{{n.id}}%2Ficon.png?alt=media"
          class="w-auto m-auto" alt="media"> -->
            <mat-card-content style="text-align: center;">
                <div class="mat-caption w-auto mx-auto">{{n.get('Name')}}</div>
            </mat-card-content>
            <mat-card-actions>
                <button (click)="showCatForm(n)" mat-raised-button>Edit</button>
            </mat-card-actions>
        </mat-card>
    </div>

</div>