<mat-card class="container-fluid m-0" *ngIf="getObjlength(pData)>0">
    <div class="mat-caption" *ngIf="pData.lastModifiedBy">Last Modified By Role:
        {{pData.lastModifiedBy}}
    </div>

    <div class="mat-caption" *ngIf="pData.DEVerifiedByEmail">DE Last Modified by:
        {{pData.DEVerifiedByEmail}}
    </div>

    <div class="mat-caption" *ngIf="pData.DXVerifiedByEmail">Expert Last Modified by:
        {{pData.DXVerifiedByEmail}}
    </div>

    <div class="mat-caption" *ngIf="pData.lastPriceUpdate&&pData.lastPriceUpdate.updateBy">Price Last Updated by:
        <strong>{{pData.lastPriceUpdate.updateBy}}</strong>
    </div>

</mat-card>
<mat-card>DX Comment:{{comment}}</mat-card>
<mat-card>DE Comment:{{decomment}}</mat-card>


<mat-card class="example-card">
    <mat-form-field class="example-chip-list w-100">
        <mat-chip-list #chipList aria-label="Fruit selection">
            <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                (removed)="remove(tag)">
                {{tag}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="New tag..." #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                {{tag}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <mat-card-actions>
        <button color="primary" [hidden]="declined" (click)="submitResponse(true)" mat-raised-button>
            Approve
        </button>
        <button color="accent" [hidden]="declined" (click)="declined=true" mat-raised-button>
            Decline
        </button>
        <!--  -->



        <mat-form-field class="w-100" [hidden]="!declined">
            <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"
                [(ngModel)]="comment" matInput placeholder="Comment"></textarea>
        </mat-form-field>

        <button color="warn" type="button" [hidden]="!declined" (click)="declined=false" mat-raised-button>
            Cancel
        </button>
        <button color="warn" type="button" [hidden]="!declined" (click)="submitResponse(false)" mat-raised-button>
            Submit
        </button>

    </mat-card-actions>

    <p>{{catPath | uppercase}}</p>
    <ul>
        <li *ngFor="let c of captureThumbs; let i = index">
            <img src="{{ c }}" height="100" (click)="openPhoto(photoContent,c,i)" />
        </li>
    </ul>

    <mat-card-content>
        <table class="table table-hover">
            <tbody>
                <tr *ngFor="let p of formdetails; let i=index;">
                    <td>{{p.cl}}</td>
                    <td (click)="searchGoogle(p.v)">{{p.v}}</td>
                </tr>
                <tr>
                    <td colspan="2">

                        <div *ngIf="formdetails && formdetails.length>0">

                            <div *ngIf="addVrnts">
                                <div>Has Variants</div>
                                <mat-card *ngFor="let v of variants;let i=index">
                                    <mat-card-title>
                                        {{v.groupName}}
                                    </mat-card-title>
                                    <div>Group Name : {{v.groupName}}</div>
                                    <div *ngFor="let k of v.items;let j=index">
                                        <mat-card class="m-1">

                                            <div>Name: {{k.vrntname}}</div>
                                            <div>Price: {{k.price}}</div>


                                            <div *ngIf="k.hasAddons">
                                                <div>Has Addons</div>
                                                <mat-card class="m-1" *ngIf="k.addongroups.length>0">
                                                    <mat-card-title>
                                                        Addons
                                                    </mat-card-title>
                                                    <div *ngFor="let ag of k.addongroups;let l=index">

                                                        <div>Group Name: {{ag.groupName}}</div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div>Max Count: {{ag.maxSelection}}</div>
                                                            </div>
                                                            <div class="col">
                                                                <div>Min Count: {{ag.minSelection}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="example-list">
                                                            <mat-card class="m-1"
                                                                *ngFor="let a of ag.addons;let m=index">
                                                                <div>{{a.adnname}}, {{a.addedPrice}}</div>
                                                            </mat-card>
                                                        </div>
                                                    </div>

                                                </mat-card>

                                            </div>
                                        </mat-card>
                                    </div>


                                </mat-card>

                            </div>
                            <div *ngIf="!addVrnts">

                                <div *ngIf="addAdns">

                                    <div>Has Addons</div>
                                    <mat-card class="m-1" *ngIf="addons.length>0">
                                        <mat-card-title>
                                            Addons
                                        </mat-card-title>
                                        <div *ngFor="let ag of addons;let l=index">

                                            <div>Group Name: {{ag.groupName}}</div>
                                            <div class="row">
                                                <div class="col">
                                                    <div>Max Count: {{ag.maxSelection}}</div>
                                                </div>
                                                <div class="col">
                                                    <div>Min Count: {{ag.minSelection}}</div>
                                                </div>
                                            </div>
                                            <div class="example-list">
                                                <mat-card class="m-1" *ngFor="let a of ag.addons;let m=index">
                                                    <div>{{a.adnname}}, {{a.addedPrice}}</div>
                                                </mat-card>
                                            </div>
                                        </div>

                                    </mat-card>

                                </div>
                            </div>
                        </div>


                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="2" class="text-center"><strong>Shop Details</strong></td>

                </tr>
                <tr>
                    <td>Shop Name</td>
                    <td>{{shopDetails.shopname}}</td>
                </tr>
                <tr>
                    <td>Shop Phone</td>
                    <td><button color="accent" type="button" (click)="call('tel:'+shopDetails.phone)" mat-raised-button>
                            Call
                        </button></td>
                </tr>
                <tr>
                    <td>Shop Address</td>
                    <td>{{shopDetails.address}}</td>
                </tr>
            </tfoot>
        </table>
    </mat-card-content>



</mat-card>



<ng-template #photoContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Photo</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="{{ currentImage }}" width="100%" />
    </div>
    <!-- <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-outline-dark" (click)="modal.close(currentImageIndex)">Delete</button>
      </div> -->
</ng-template>