import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getChildern'
})
export class GetChildernPipe implements PipeTransform {

  transform(value: any, parent: string, type: string = 'name'): any {

    let newValue: any = [];
    if (type === 'name') {
      value = value.filter(item => (item.parent.indexOf(parent) !== -1) && (item.enabled.indexOf('1') !== -1));
    } else if (type === 'id') {
      value = value.filter(item => (item.parentId && (item.parentId.indexOf(parent) !== -1) && (item.enabled.indexOf('1') !== -1)));
    }


    // for(let v of value){
    //   newValue.push(v.name);
    // }

    return value;
  }

}
