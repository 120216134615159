import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { FirebaseRestService } from '../services/firebase-rest.service';

@Component({
  selector: 'app-add-inventory-field',
  templateUrl: './add-inventory-field.component.html',
  styleUrls: ['./add-inventory-field.component.css']
})
export class AddInventoryFieldComponent implements OnInit {

  addIALForm: UntypedFormGroup;
  isList = false;
  constructor(private formBuilder: UntypedFormBuilder, private fireRest: FirebaseRestService) {
    fireRest.getIal().subscribe((data) => {
      if (data.length > 0) {
        console.log(data);
      }
    });
    this.addIALForm = this.formBuilder.group({
      label: [''],
      clientLabel: [''],
      isActive: true,
      rfd: false,
      dm: [''],
      dataType: [''],
      refValues: ['']
    });

  }

  ngOnInit(): void {
  }

  setIsList(type) {
    if (type == 'List' || type == 'Multi') {
      this.isList = true;
    } else {
      this.isList = false;
    }
  }

  add_ial() {
    this.fireRest.addIAL(this.addIALForm.value);
  }

}
