<mat-toolbar color="white">
    <mat-toolbar-row>
        <button mat-raised-button color="primary" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="appMenu"
            [matMenuTriggerData]="{name: 'PhFTzbx3NzM0ySBopFJj'}">Select Category</button>
        <span class="fill-remaining-space"></span>


    </mat-toolbar-row>

</mat-toolbar>
<mat-menu #appMenu="matMenu">
    <ng-template matMenuContent let-name="name">
        <button *ngFor="let n of catData | getChildern:name:'id'" mat-menu-item [matMenuTriggerFor]="subMenu"
            [matMenuTriggerData]="{name2: n.id}"
            (click)="showAddChildForm(n);menuTrigger.closeMenu();">{{n.name}}</button>
    </ng-template>
</mat-menu>

<mat-menu #subMenu="matMenu">
    <ng-template matMenuContent let-name2="name2">
        <button *ngFor="let n of catData | getChildern:name2:'id'" mat-menu-item [matMenuTriggerFor]="subsubMenu"
            [matMenuTriggerData]="{name3: n.id}"
            (click)="showAddChildForm(n);menuTrigger.closeMenu();">{{n.name}}</button>
    </ng-template>
</mat-menu>

<mat-menu #subsubMenu="matMenu">
    <ng-template matMenuContent let-name3="name3">
        <button *ngFor="let n of catData | getChildern:name3:'id'" mat-menu-item [matMenuTriggerFor]="subsubsubMenu"
            [matMenuTriggerData]="{name4: n.id}"
            (click)="showAddChildForm(n);menuTrigger.closeMenu();">{{n.name}}</button>
    </ng-template>
</mat-menu>
<mat-menu #subsubsubMenu="matMenu">
    <ng-template matMenuContent let-name4="name4">
        <button *ngFor="let n of catData | getChildern:name4:'id'" mat-menu-item
            (click)="showAddChildForm(n);menuTrigger.closeMenu();">{{n.name}}</button>
    </ng-template>
</mat-menu>


<div *ngIf="currentCat.name">

    <img width="256" [src]="croppedImage" />

    <ngx-dropzone class="w-25" [multiple]="false" (change)="fileChangeEvent($event)">
        <ngx-dropzone-label>Drop the Icon</ngx-dropzone-label>
    </ngx-dropzone>

    <image-cropper style="width: 256px;" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
        [resizeToWidth]="512" [resizeToHeight]="512" [aspectRatio]="1/1" format="webp" [canvasRotation]="rotation/90"
        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"></image-cropper>

    <mat-slider *ngIf="showRotater" [max]="270" [min]="0" [step]="90" [thumbLabel]="1" [tickInterval]="90"
        [(ngModel)]="rotation" [vertical]="false">
    </mat-slider>

</div>

<form *ngIf="currentCat.name" [formGroup]="editCatForm" (ngSubmit)="update_category()">


    <img
        src="https://firebasestorage.googleapis.com/v0/b/shopnearby-301e7-product-image-bucket/o/category_images%2F{{currentCat.id}}%2Ficon.png?alt=media">
    <br><br>
    <mat-form-field>
        <mat-label>Parent</mat-label>
        <mat-select formControlName="parent" required>
            <mat-option *ngFor="let n of catData" [value]="n.id">
                {{n.name}}
            </mat-option>
        </mat-select>
    </mat-form-field><br><br>
    <mat-form-field>
        <input formControlName="name" autocapitalize="words" [value]="currentCat.name" matInput
            placeholder="Category Name">
    </mat-form-field>
    <!-- <ngx-dropzone-image-preview class="w-25" ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview>
    <ngx-dropzone class="w-50" [multiple]="false" (change)="onSelect($event)">
        <ngx-dropzone-label>Drop the Icon</ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
    </ngx-dropzone> -->





    <br>
    <mat-checkbox formControlName="isActive">Enabled</mat-checkbox>
    <br>

    <button mat-raised-button type="submit">Edit Category</button>
    <button mat-raised-button [hidden]="!delHidden" color="warn" type="button" (click)="delHidden=false">Delete
        Category</button>
    <button mat-raised-button [hidden]="delHidden" color="warn" type="button"
        (click)="delete_category()">Confirm</button>
    <button mat-raised-button [hidden]="delHidden" color="accent" type="button" (click)="delHidden=true">Cancel</button>
</form>