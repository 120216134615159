import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { FirebaseRestService } from '../services/firebase-rest.service';

@Component({
  selector: 'app-edit-inventory-field',
  templateUrl: './edit-inventory-field.component.html',
  styleUrls: ['./edit-inventory-field.component.css']
})
export class EditInventoryFieldComponent implements OnInit {

  editIALForm: UntypedFormGroup;
  isList = false;
  ialList: any = []
  currentIndex = 0;
  currentIal: any = {};
  ialSelected = false;
  constructor(private formBuilder: UntypedFormBuilder, private fireRest: FirebaseRestService) {
    fireRest.getIal().subscribe((data) => {
      if (data.length > 0) {
        // console.log(data);
        this.ialList = data;
      }
    });


  }

  ngOnInit(): void {

  }


  showEditForm(val) {
    this.currentIndex = val;

    this.editIALForm = this.formBuilder.group({
      label: [this.ialList[this.currentIndex].l],
      clientLabel: [this.ialList[this.currentIndex].cl],
      isActive: this.ialList[this.currentIndex].a,
      rfd: this.ialList[this.currentIndex].rfd,
      dm: [this.ialList[this.currentIndex].dm],
      dataType: [this.ialList[this.currentIndex].d],
      refValues: [this.ialList[this.currentIndex].ref_values ? this.ialList[this.currentIndex].ref_values.join(' | ') : ""]
    });
    this.setIsList(this.ialList[this.currentIndex].d);

    this.currentIal = this.ialList[this.currentIndex];
    this.ialSelected = true;
  }

  setIsList(type) {
    if (type == 'List' || type == 'Multi') {
      this.isList = true;
    } else {
      this.isList = false;
    }
  }

  edit_ial() {
    this.fireRest.editIAL(this.editIALForm.value, this.currentIndex, this.currentIal.ialId);
  }

}
