import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.css']
})
export class ProductViewComponent implements OnInit {

  @Input() productData: any;
  @Output() selection: any = new EventEmitter<any>(false);
  @Input() selected = false;
  isShopPage: boolean = true;
  cartQuantity: number = 0;
  originalCartQty: number = 0;
  cartButText: string;
  qtarr: number[];
  isAvailable = true;
  shopActive = true;
  userLoggedIn = false;
  price: any = {};
  description: any = {};
  sellingUnit: any = {};
  minCartQty = 1;
  maxCartQty = 50;
  discount: any = {};
  efd = false;
  currentShift = "";
  dayAvailability = {}
  // cartSub: Subscription;

  productNameExpanded = false;
  productDescExpanded = false;

  productHasCustomizations = false;
  productCustomizations;
  foodType: any;


  constructor() {


  }




  ngOnInit(): void {




    let maxsq = 50;
    let minsq = 1;
    // 

    const ipAvil = this.productData.ipAvil;
    this.price = this.productData.price;
    this.discount = this.productData.discount;
    // 

    this.description = this.productData.description;
    this.foodType = this.productData.foodType;
    this.sellingUnit = this.productData.sellingUnit;
    const maxSObj = this.productData.maxSObj;
    const minSObj = this.productData.minSObj;

    if (this.productData.metaData) {
      this.efd = this.productData.metaData.deliveryEligible;
    }

    if (ipAvil && ipAvil.v === "Out of Stock") {
      this.isAvailable = false;
    }

    if (maxSObj && maxSObj.v && maxSObj.v != null && maxSObj.v.length > 0) {
      maxsq = maxSObj.v;
      this.maxCartQty = maxsq;
    }
    if (minSObj && minSObj.v && minSObj.v != null && minSObj.v.length > 0) {
      minsq = minSObj.v
      this.minCartQty = minsq;
    }



    let diff = maxsq - minsq;

    // if (minsq && minsq > 0) {
    //   this.cartQuantity = minsq;
    // } else {
    //   this.cartQuantity = 0;
    // }


    if (diff > 0) {
      this.qtarr = Array.from(Array(diff + 1).keys()).map(x => x + minsq - 1);
    }



    // data._metaData.CategoryPath.map(function(elem){
    //       return elem.Name;
    //   }).join(" / ");

    let fdata = [];
    // let clientViewMeta = {}
    // if (this.productData._clientViewMeta) {
    //   clientViewMeta = this.productData._clientViewMeta
    // }
    // for (let d of this.productData._clientView) {
    //   // if (d.dm != "meta" && d.v && d.v != "" && d.v != 0)
    //   // fdata.push({ 'FieldLabel': d.cl, 'FieldValue': d.v, 'd': d.d ? d.d : 'PlainString', 'o': d.o })

    // }

  }

  private updateLocalCartCount() {
    if (localStorage.getItem('anonymousCartData')) {
      let acd = {};
      if (JSON.parse(localStorage.getItem('anonymousCartData'))) {
        acd = JSON.parse(localStorage.getItem('anonymousCartData'));
      }

      let acdc = {};
      if (JSON.parse(localStorage.getItem('anonymousCartDataCustomization'))) {
        acdc = JSON.parse(localStorage.getItem('anonymousCartDataCustomization'));
      }

      let cartCustomizations = 0;
      // 

      if (acdc) {
        let cCount = 0;
        this.productCustomizations = {};
        for (const c in acdc) {
          if (acdc[c].productId === this.productData.productId) {
            // 
            // 


            cCount = cCount + acd[c];
            this.productCustomizations[c] = acdc[c];
          }
        }

        cartCustomizations = cCount;//_.countBy(_.values(data.customization), (cust) => cust.productId === this.inventoryId).true;
      }
      // 

      if (acd[this.productData.productId]) {
        this.cartQuantity = acd[this.productData.productId];
        this.originalCartQty = acd[this.productData.productId];

        this.cartButText = "Update Cart";
      } else if (cartCustomizations > 0) {
        this.productHasCustomizations = true;
        this.cartQuantity = cartCustomizations;
        this.originalCartQty = cartCustomizations;
        this.cartButText = "Update Cart";
      } else {
        this.cartQuantity = 0;
        this.originalCartQty = 0;
      }

    }
  }

  ngOnDistroy(): void {
    // if (this.cartSub && !this.cartSub.closed) {
    //   this.cartSub.unsubscribe();
    //   

    // }
  }



  select() {
    this.selected = !this.selected;
    const selection: any = {};
    selection[this.productData.productId] = this.selected;
    this.selection.emit(selection);

  }



  view(id: string) {
    window.open('/#/product-view/' + id, '_blank')
    // this._bottomSheet.open(ProductViewMinComponent, { data: { "id": id } });
  }



}

