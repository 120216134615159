import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { RestService } from '../services/rest.service';
import * as _ from 'lodash';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductStatus } from '../_models/models';
import { AuthService } from '../auth/auth.service';
import { Role } from '../_models/role.enum';



@Component({
  selector: 'app-price-management',
  templateUrl: './price-management.component.html',
  styleUrls: ['./price-management.component.css']
})
export class PriceManagementComponent implements OnInit {
  ownerId: any;
  shopId: any;
  sheetData: any[];
  sheetName: string;
  columnNames: string[];
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  defaultComission = 0;
  userRole = "";
  hasUnApprovedItems = false;
  shopName = "";

  hasError = false;
  itemIdWithError = "";

  constructor(private authService: AuthService, private fireRest: FirebaseRestService, private route: ActivatedRoute, private rest: RestService, private dataService: DataService) {

    authService.loginStatus.subscribe((data: any) => {
      if (data) {
        if (this.authService.userRole === Role.Admin) {
          this.userRole = "admin";
        }
      }

    });



    this.route.params.subscribe(params => {
      // console.log(params);
      this.ownerId = params.ownerId;
      this.shopId = params.shopId;
      this.fireRest.getShopProductsByOwnerId(this.ownerId).subscribe((data) => {

        this.sheetData = [];
        let columns: any = [];

        if (data.length > 0) {
          // console.log(data.filter(ele => ele.data.hasVariants == 1));

          this.shopName = data[0].data.shopDetails.shopname;
          for (const hit of data) {
            // console.log(hit);
            let row: any = {};
            columns = [];




            if (hit.data.hasVariants && hit.data.hasVariants == 1) {

              for (const vrnt of hit.data.variations) {
                row = {};
                if (!hit.data._clientView) {
                  this.hasError = true;
                  this.itemIdWithError = hit.id;
                }

                row["Name"] = hit.data.Name + " - [" + vrnt.name + "]";
                row["Ml"] = hit.data.menuLabel;
                row["Availability"] = hit.data._clientView && hit.data._clientView.find(ele => ele.l === "Is Available?") ? hit.data._clientView.find(ele => ele.l === "Is Available?").v : "Error " + hit.id;
                row["Price"] = vrnt.price;
                row["Discount"] = vrnt.discount ? vrnt.discount : 0;
                let ec: any;
                let ac: any;
                if (hit.data.comissions && hit.data.comissions.comArr) {
                  ec = hit.data.comissions.comArr.find(ele => ele.id == hit.id + "_" + vrnt.snbyId);
                }
                if (hit.data.comissions && hit.data.comissions.approvedComArr) {
                  ac = hit.data.comissions.approvedComArr.find(ele => ele.id == hit.id + "_" + vrnt.snbyId);
                }
                row["proposedComission"] = ec && ec.pc ? ec.pc : 0;
                row["approvedComission"] = ac && ac.ac ? ac.ac : 0;
                row["cType"] = "p";
                row["status"] = this.dataService.getStatusText(hit.data.active)
                row["id"] = hit.id + "_" + vrnt.snbyId;
                row["productId"] = hit.id;
                row["hit"] = hit.data

                if (hit.data.active != ProductStatus.Trash && hit.data.active != ProductStatus.SNBDEDeclined && JSON.parse(row["proposedComission"]) != 0 && JSON.parse(row["proposedComission"]) != JSON.parse(row["approvedComission"])) {
                  this.hasUnApprovedItems = true;
                }

                // row["effectiveComission"] = row["cType"] == "p" ? (row["Price"] / 100) * row["proposedComission"] : row["proposedComission"];

                if (hit.data.active != ProductStatus.Trash && hit.data.active != ProductStatus.SNBDEDeclined) {
                  this.sheetData.push(row)
                }
              }
            } else {
              row["Name"] = hit.data.Name;
              row["Availability"] = hit.data._clientView.find(ele => ele.l === "Is Available?") ? hit.data._clientView.find(ele => ele.l === "Is Available?").v : "";
              row["Ml"] = hit.data.menuLabel;
              row["Price"] = hit.data._clientView.find(ele => ele.l === "Price (MRP)") ? hit.data._clientView.find(ele => ele.l === "Price (MRP)").v : 0;
              row["Discount"] = hit.data._clientView.find(ele => ele.l === "Discount amount") ? hit.data._clientView.find(ele => ele.l === "Discount amount").v : 0;
              let ec: any;
              let ac: any;
              if (hit.data.comissions && hit.data.comissions.comArr) {
                ec = hit.data.comissions.comArr.find(ele => ele.id == hit.id);
              }
              if (hit.data.comissions && hit.data.comissions.approvedComArr) {
                ac = hit.data.comissions.approvedComArr.find(ele => ele.id == hit.id);
              }
              row["proposedComission"] = ec && ec.pc ? ec.pc : 0;
              row["approvedComission"] = ac && ac.ac ? ac.ac : 0;
              row["cType"] = "p";
              row["status"] = this.dataService.getStatusText(hit.data.active)
              row["id"] = hit.id;
              row["productId"] = hit.id;
              row["hit"] = hit.data
              if (hit.data.active != ProductStatus.Trash && hit.data.active != ProductStatus.SNBDEDeclined && JSON.parse(row["proposedComission"]) != 0 && JSON.parse(row["proposedComission"]) != JSON.parse(row["approvedComission"])) {
                this.hasUnApprovedItems = true;
              }
              // row["effectiveComission"] = row["cType"] == "p" ? (row["Price"] / 100) * row["proposedComission"] : row["proposedComission"];
              if (hit.data.active != ProductStatus.Trash && hit.data.active != ProductStatus.SNBDEDeclined) {
                this.sheetData.push(row)
              }
            }
          }
          // console.log(sheetData);
          // console.log(columnNames);

          this.columnNames = ["Ml", "Name", "Availability", "Price", "Discount", "priceAfterDiscount", "cType", "proposedComission", "effectiveComission", "approvedComission", "status"];


          // return;

          this.sheetName = data[0]["data"]["shopDetails"].shopname;

          this.dataSource = new MatTableDataSource(this.sheetData);
          this.dataSource.sort = this.sort;

        }

      })
    });
  }

  ngOnInit(): void {
  }



  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
  }

  downloadFile() {
    const finalDataToSave = this.sheetData.map(ele => ({ Name: ele.Name, Price: ele.Price, Discount: ele.Discount, PAD: ele.Price - ele.Discount, Type: ele.cType, PC: ele.proposedComission, "Effective Amount": ele.cType == "p" ? (ele.Price - ele.Discount) - ((ele.Price - ele.Discount) / 100) * ele.proposedComission : (ele.Price - ele.Discount) - ele.proposedComission }));
    this.dataService.downloadFile(finalDataToSave, this.sheetName, ["Name", "Price", "Discount", "PAD", "Type", "PC", "Effective Amount"]);
  }

  submit() {
    // console.log(this.sheetData);
    const finalDataToSend = _.groupBy(this.sheetData.filter(ele => ele.changed == true).map(ele => ({ id: ele.id, productId: ele.productId, ac: ele.approvedComission, pc: ele.cType == "p" ? ele.proposedComission : ((ele.proposedComission * 100) / (ele.Price - ele.Discount)) })), "productId");
    // console.log(finalDataToSend);

    const _finalPricesToSend = _.groupBy(this.sheetData.filter(ele => ele.changed == true), "productId");
    const finalPricesToSend: any = {};

    for (const p in _finalPricesToSend) {
      // console.log(_finalPricesToSend[p]);
      if (!finalPricesToSend[p]) {
        finalPricesToSend[p] = {};
      }

      if (_finalPricesToSend[p].length > 1) {
        //has variants
        const variantsArr = _finalPricesToSend[p][0].hit.variations;
        // console.log(variantsArr);
        let leastPrice = _finalPricesToSend[p][0].Price;
        let leastDiscount = _finalPricesToSend[p][0].Discount ? _finalPricesToSend[p][0].Discount : 0;
        // console.log(variantsArr);

        for (const vrnt of variantsArr) {
          const currentVariant = _finalPricesToSend[p].find(vp => vp.id === [p, vrnt.snbyId].join('_'));
          if (currentVariant) {
            vrnt.price = JSON.parse(currentVariant.Price)
            console.log(currentVariant);

            vrnt.discount = JSON.parse(currentVariant.Discount != "" ? currentVariant.Discount : "0")
            if (leastPrice > JSON.parse(currentVariant.Price)) {
              leastPrice = JSON.parse(currentVariant.Price);
              leastDiscount = JSON.parse(currentVariant.Discount != "" ? currentVariant.Discount : "0");
            }
          }
        }
        finalPricesToSend[p].price = leastPrice;
        finalPricesToSend[p].discount = leastDiscount;
        finalPricesToSend[p].variations = variantsArr;

      } else {
        //has no variants
        finalPricesToSend[p].price = _finalPricesToSend[p][0].Price
        finalPricesToSend[p].discount = _finalPricesToSend[p][0].Discount
      }

    }

    // console.log(finalPricesToSend);
    // return;


    this.rest.updateShopComissions(finalDataToSend, finalPricesToSend).subscribe((data) => {
      // console.log(data);
      if (data.status === "success") {
        this.downloadFile()
      } else if (data.status == "fail" && data.message == "Nothing to update") {
        this.downloadFile();
      }

    })

  }

  approveComissions(awr?: boolean) {
    console.log(this.sheetData);

    const allowWalletRewards = awr && awr === true ? true : false;

    const finalDataToSend = _.groupBy(this.sheetData.map(ele => ({ id: ele.id, productId: ele.productId, ac: ele.cType == "p" ? ele.proposedComission : ((ele.proposedComission * 100) / (ele.Price - ele.Discount)) })), "productId");
    console.log(finalDataToSend);



    this.rest.approveShopComissions(finalDataToSend, this.ownerId, this.shopId, allowWalletRewards).subscribe((data) => {
      // console.log(data);
      if (data.status === "success") {
        this.hasUnApprovedItems = false;
      }

    })
  }

  setComission() {
    for (const d of this.sheetData) {
      if (!d.changed && d.proposedComission == 0) {
        d.proposedComission = this.defaultComission;
      }
    }
  }

  setChanged(ele) {
    console.log(ele);

    if (ele.priductId !== ele.id) {
      const variants = this.sheetData.filter(e => e.productId === ele.productId);
      for (const v of variants) {
        v.changed = true;
      }
    } else {
      ele.changed = true;
    }

  }

  format(comp) {
    return comp.replace(/,/g, '|')
  }


}
