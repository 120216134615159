<button *ngIf="userLoggedIn" mat-button class="w-100 text-left d-block" (click)="goto_dashboard()">Dashboard</button>
<button *ngIf="userLoggedIn && (isAdmin||isDE||isDX)" mat-button class="w-100 text-left d-block"
    (click)="ref_products()">Reference
    Products</button>
<button *ngIf="userLoggedIn && (isAdmin||isDM||isCS)" mat-button class="w-100  text-left d-block"
    (click)="manage_order()">Manage
    Orders</button>
<button *ngIf="userLoggedIn && (isAdmin)" mat-button class="w-100  text-left d-block"
    (click)="reports()">Reports</button>

<button *ngIf="userLoggedIn && (isAdmin||isDX)" mat-button class="w-100  text-left d-block"
    (click)="manage_search()">Manage
    Search</button>

<button *ngIf="userLoggedIn && (isAdmin||isDM||isCS)" mat-button class="w-100  text-left d-block"
    (click)="cart_data()">Cart
    Data</button>
<button *ngIf="userLoggedIn && (isAdmin||isDE||isDX)" mat-button class="w-100  text-left d-block"
    (click)="manage_shop()">Manage
    Shops</button>
<button *ngIf="userLoggedIn && (isAdmin)" mat-button class="w-100  text-left d-block" (click)="manage_users()">Manage
    Users</button>
<button *ngIf="userLoggedIn && isAdmin" mat-button class="w-100  text-left d-block" (click)="add_category()">Add
    Category</button>
<button *ngIf="userLoggedIn && isAdmin" mat-button class="w-100  text-left d-block" (click)="edit_category()">Edit
    Category</button>
<button *ngIf="userLoggedIn && isAdmin" mat-button class="w-100  text-left d-block" (click)="add_inv_field()">Add
    Inventory
    Field</button>
<button *ngIf="userLoggedIn && isAdmin" mat-button class="w-100  text-left d-block" (click)="edit_inv_field()">Edit
    Inventory
    Field</button>
<button *ngIf="userLoggedIn && isAdmin" mat-button class="w-100  text-left d-block"
    (click)="create_form()">Create/modify
    Form</button>
<button *ngIf="!userLoggedIn" mat-button class="w-100  text-left d-block" (click)="login()">Login</button>
<button *ngIf="userLoggedIn" mat-button class="w-100  text-left d-block" (click)="logout()">Logout</button>