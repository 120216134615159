import { Component, Inject, OnInit } from '@angular/core';
import { RestService } from '../services/rest.service';
import { OrderStatus } from '../_models/order-status.enum';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { Router } from '@angular/router';
import * as plusCode from 'open-location-code-typescript'
import { Subscription } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.css']
})
export class OrderManagementComponent implements OnInit {

  panelOpenState = false;

  userOrderData: any;

  userOrderInventory: any = {};
  map;
  riderMarkers: any = {};
  orderOTPs: any = {}
  allSub: any = [];
  shopOrderCustomizations: any = {};

  allRiders: any = {}
  allRCount = 0;
  activeRiders: any = {}
  activeRCount = 0;
  serviceSubArr: any = {};

  hasPendingOrders: boolean = false;
  hasProcessingOrders: boolean = false;
  hasReadyOrders: boolean = false;
  hasCompletedOrders: boolean = false;
  hasCancelledOrders: boolean = false;
  constructor(private rest: RestService, private fireRest: FirebaseRestService, private router: Router, private clipboard: Clipboard, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.showRiders();
    const getRidersSub = this.fireRest.getAllRiders();
    this.allSub.push(getRidersSub)
    getRidersSub.subscribe((rd: any) => {

      if (rd.length > 0) {
        this.allRiders = {};
        rd.forEach(r => {
          this.allRiders[r.id] = r;
          if (r.active) {
            this.activeRiders[r.id] = r;
          }
          this.allRiders[r.id] = r;
        });
      }
      this.allRCount = Object.keys(this.allRiders).length;
      this.activeRCount = Object.keys(this.activeRiders).length;
      console.log(this.allRiders);
      console.log(this.activeRiders);

      this.getOrderData();
    })

  }





  private getOrderData() {
    this.fireRest.getShopOrderData().subscribe((orderData) => {
      // console.log(orderData);
      if (orderData.orders && orderData.orders.length > 0) {
        const data: any[] = orderData.orders.reverse();

        if (data.length > 0) {
          this.userOrderData = data;
          this.hasPendingOrders = false;
          this.hasProcessingOrders = false;
          this.hasReadyOrders = false;
          this.hasCompletedOrders = false;
          this.hasCancelledOrders = false;


          for (let d of this.userOrderData) {

            this.shopOrderCustomizations[d.propertyId] = {};

            d.shops.forEach(oshop => {

              this.shopOrderCustomizations[d.propertyId][oshop.sp] = {};
              this.shopOrderCustomizations[d.propertyId][oshop.sp].customizations = oshop.c;

            });


            if (d['ds'] && Object.keys(d['ds']).length > 0) {
              d.signedForDelivery = true;
            } else {
              d.signedForDelivery = false;
            }





            let cumulatedStatus = 1;
            let hcount = 0;
            let pcount = 0;
            let rcount = 0;
            let comcount = 0;
            let cancount = 0;
            // const time = new Date(d.lastUpdated._seconds * 1000);
            // time.setSeconds(d.lastUpdated._seconds)
            // d.time = d.lastUpdated;
            for (let s in d.status) {

              if (!d.statusText) {
                d.statusText = {};
              }
              d.statusText[s] = this.getStatusText(d.status[s]);


              switch (d.status[s]) {
                case 0: {
                  hcount += 1;
                  break;
                }
                case 1:
                case 2:
                case 21:
                case 22:
                case 23: {
                  pcount += 1;
                  break;
                }
                case 3:
                case 26: {
                  rcount += 1;
                  break;
                }
                case 4: {
                  comcount += 1;
                  break;
                }
                case 5: {
                  cancount += 1;
                  break;
                }
              }
            }
            // //console.log("p:" + pcount + "r:" + rcount + "com:" + comcount + "can:" + cancount)
            if (hcount > 0) {
              cumulatedStatus = 0;
              this.hasPendingOrders = true;
            } else if (pcount > 0) {
              cumulatedStatus = 1;
              this.hasProcessingOrders = true;
            } else if (rcount > 0) {
              cumulatedStatus = 3;
              this.hasReadyOrders = true;
            } else if (comcount > 0) {
              cumulatedStatus = 4;
              this.hasCompletedOrders = true;
            } else if (cancount > 0) {
              cumulatedStatus = 5;
              this.hasCancelledOrders = true;
            }

            d.finalStatus = cumulatedStatus;

          }
          // //console.log(this.userOrderData);
        }
      }
    });
  }

  getStatusText(status) {
    let res = ""
    switch (status) {
      case OrderStatus.Active:
        res = "Order Placed";
        break;
      case OrderStatus.Cancelled:
        res = "Cancelled";
        break;
      case OrderStatus.Completed:
        res = "Completed";
        break;
      case OrderStatus.PaidReadyForPickup:
        res = "Ready For Pickup";
        break;
      case OrderStatus.PickedForDelivery:
        res = "Out For Delivery";
        break;
      case OrderStatus.Processing:
        res = "Processing";
        break;
      case OrderStatus.Ready:
        res = "Ready For Pickup";
        break;
      case OrderStatus.ShopPackaged:
        res = "Shipped";
        break;
      case OrderStatus.ShopViewed:
        res = "Shop Viewed";
        break;
      case OrderStatus.YetToConfirm:
        res = "Pending Payment";
        break;
    }
    return res;
  }


  getStatusColor(status) {
    // //console.log(status)
    let res = ""
    switch (status) {
      case OrderStatus.Active:
        res = "warn";
        break;
      case OrderStatus.Cancelled:
        res = "accent";
        break;
      case OrderStatus.Completed:
        res = "accent";
        break;
      case OrderStatus.PaidReadyForPickup:
        res = "warn";
        break;
      case OrderStatus.PickedForDelivery:
        res = "primary";
        break;
      case OrderStatus.Processing:
        res = "warn";
        break;
      case OrderStatus.Ready:
        res = "accent";
        break;
      case OrderStatus.ShopPackaged:
        res = "warn";
        break;
      case OrderStatus.ShopViewed:
        res = "warn";
        break;
      case OrderStatus.YetToConfirm:
        res = "accent";
        break;
    }
    return res;
  }


  panalOpened(data) {
    // //console.log(data);
    if (!(data.propertyId in this.userOrderInventory)) {



      // let inventoryIds = [];
      // this.userOrderInventory[data.propertyId].qty = {}
      // for (let s of data.shops) {

      //   for (let i in s.i) {
      //     inventoryIds.push(i);
      //     this.userOrderInventory[data.propertyId].qty[i] = s.i[i];
      //   }
      // }
      // //console.log(inventoryIds);

      this.fireRest.getMultipleProductsForOrders(data.shops).subscribe((idata) => {
        this.userOrderInventory[data.propertyId] = {};

        // //console.log(idata);
        let invData = idata;

        invData = this.applyCustomization(invData, data.propertyId);
        this.userOrderInventory[data.propertyId].invData = this.getImages(invData);
      });

      // this.rest.getMultipleProducts(inventoryIds.map(function (k) { return k; }).join(",")).subscribe((iData) => {
      //   this.userOrderInventory[data.propertyId].invData = iData.records;
      // });
    }
    //console.log(this.userOrderInventory);
  }

  isCustomized(orderId: string, id: string, shopId: string) {
    // console.log(id);
    // console.log(this.shopOrderCustomizations[orderId].customizations);
    if (this.shopOrderCustomizations[orderId] && this.shopOrderCustomizations[orderId][shopId] && this.shopOrderCustomizations[orderId][shopId].customizations) {

      if (this.shopOrderCustomizations[orderId][shopId].customizations.hasOwnProperty(id)) {


        return true;
      } else return false;
    } else return false;
  }

  applyCustomization(records: any[], orderId: string) {

    for (let shop in records) {
      for (let r of records[shop].items) {
        r.customized = this.isCustomized(orderId, r.productId, shop);
        // console.log(r.customized);

        if (r.customized) {
          let customPrice = r.Price;
          let addonNameArr = [];
          if (this.shopOrderCustomizations[orderId][shop].customizations[r.productId].variation && Object.keys(this.shopOrderCustomizations[orderId][shop].customizations[r.productId].variation).length > 0) {
            r.variationName = this.shopOrderCustomizations[orderId][shop].customizations[r.productId].variation.name
            customPrice = JSON.parse(this.shopOrderCustomizations[orderId][shop].customizations[r.productId].variation.price);
          }
          this.shopOrderCustomizations[orderId][shop].customizations[r.productId].addon.forEach(adon => {
            customPrice = customPrice + JSON.parse(adon.addonitem_price);
            addonNameArr.push(adon.addonitem_name)
          });

          r.customPrice = customPrice;
          r.addonNames = addonNameArr.join(", ")
        }
      }
    }

    // console.log(records);

    return records;

  }


  getImages(records: any[]) {

    for (let shop in records) {
      for (let r of records[shop].items) {
        if (r.imgCount > 0) {
          this.fireRest.getProductFirstImageStatic(r.productId).subscribe((data: any) => {
            r.image = data != "" ? data : "assets/no-image.png";
          });
        } else {
          r.image = "assets/no-image.png";
        }
      }
    }
    return records;

  }



  requestOTP(o, s) {
    //console.log(o);//console.log(s);
    // this.rest.requestOTP(o, s).subscribe((data) => {
    //   if (data.status === "success") {
    //     this.orderOTPs[o] = {};
    //     this.orderOTPs[o][s] = data.otp;
    //   }
    // })
  }

  getDirections(destLocation: string) {

    let mapsLink = `https://www.google.com/maps/dir/?api=1&destination=${destLocation}&travelmode=driving`;//&waypoints=-------%7C--------%7C-------%7C------`;
    window.open(mapsLink, '_blank');

  }

  findDP(orderId) {

    this.router.navigate(['find-dp/' + orderId]);

  }

  goToDPStats(dpid) {
    this.router.navigate(['dp-stats/' + dpid]);
  }

  getSuffix(v) {
    return JSON.stringify(v).indexOf('.') === -1 ? ".00" : "";
  }



  showRiders() {
    // console.log(riderData.docs);
    this.map = new google.maps.Map(
      document.getElementById("omgmap") as HTMLElement,
      {
        zoom: 10,
        center: this.getlatLngForPC("7M72VC00+"),
        draggable: true,
        streetViewControl: false,
        mapTypeControl: false,
        scrollwheel: false
      }
    );

    const riderSub = this.fireRest.getRiders();
    this.allSub.push(riderSub);
    riderSub.subscribe((riderData) => {

      riderData.forEach(rider => {
        // pcaArr.push(rider)
        this.addMarkerToMap(this.getlatLngForPC(rider['pc10']), this.map, "d", rider);
      });
    })



  }

  getNeighbours(code, factor = 1.5) {

    const area = plusCode.default.decode(code);
    const originalLat = area.latitudeCenter;
    const originalLng = area.longitudeCenter;
    const height = area.latitudeHi - area.latitudeLo;
    const width = area.longitudeHi - area.longitudeLo;
    const neighbours: any = [];
    // The < check multiples height by 1.5 to avoid Javascript exactness issues with
    // floating point numbers.
    for (let lat = originalLat - height; lat < originalLat + height * factor; lat += height) {
      for (let lng = originalLng - width; lng < originalLng + width * factor; lng += width) {
        const code = plusCode.default.encode(lat, lng, area.codeLength)
        neighbours.push(code);
      }
    }
    return neighbours;
  }

  getlatLngForPC(code) {
    const area = plusCode.default.decode(code);
    return { lat: area.latitudeCenter, lng: area.longitudeCenter }

  }

  addMarkerToMap(position, map, label, entityData) {

    if (!this.riderMarkers[entityData['id']]) {
      this.riderMarkers[entityData['id']] = new google.maps.Marker({
        position: position,
        map: map,
        clickable: true,
        icon: {
          url: "assets/" + label + ".png"
        },
      });
    } else {
      this.riderMarkers[entityData['id']].setPosition(position)
    }

    // console.log(this.riderMarkers);


  }

  ngOnDestroy() {
    //console.log('view distroyed')
    this.allSub.forEach((sub: Subscription) => {
      if (sub && !sub.closed) {
        // sub.unsubscribe();
      }
    })
  }


  getPaymentStatus(orderId) {
    // this.rest.getOrderPaymentStatus(orderId).subscribe((data) => {
    //   console.log(data);
    //   if (data.status === "success") {
    //     const payStatus = data.resp.status;
    //     const link = data.resp.link.paymentLink;
    //     if (payStatus.orderStatus === "ACTIVE" && payStatus.txStatus === "FAILED") {
    //       window.open(link, '_blank');
    //     } else {
    //       this._snackBar.open("Order Expired! Please place a new one.", "Okay", {
    //         duration: 10000,
    //       });
    //     }
    //   }
    // })
  }


  getToken(ownerId) {
    this.rest.getUserToken(ownerId).subscribe((data) => {
      // console.log(data);
      if (data.status === "success") {
        this.clipboard.copy("https://shopnearby.me/#/token-login/" + data.role + "/" + data.token)
      }
    })
  }


  call(toNum) {
    // window.open("tel:" + toNum, '_self');
    if (confirm("Are you sure you want to call?")) {
      this.rest.callFromExotel(toNum, "04048211111").subscribe((data) => {
        // console.log(data);

        if (data.status == "fail") {
          console.log("fail");

        }

      })
    }

  }


  openRADialog(lat1, lng1, srid): void {
    const dialogRef = this.dialog.open(RiderAssignment, {
      width: '250px',
      data: { srid: srid, ars: Object.values(this.activeRiders).map((ele1: any) => ({ n: ele1.name, id: ele1.id, sd: this.getDistanceRounded(lat1, lng1, ele1.location.coords.latitude, ele1.location.coords.longitude) })).filter((ele: any) => ele.sd < 12) }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      if (result) {

        console.log(result);



        this.rest.assignRiderNew(result.rider, result.data.srid, true).subscribe((data) => {
          console.log(data);

          if (data.status === "success") {

            // this._snackBar.open("Updated!", "Okay", { duration: 1000 })
          }
        })

      }

    });
  }



  getDistanceRounded(lat1, lon1, lat2, lon2) {
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p) / 2 +
      c(lat1 * p) * c(lat2 * p) *
      (1 - c((lon2 - lon1) * p)) / 2;

    return this.precise_round((12742 * Math.asin(Math.sqrt(a))) * 1.25, 1); // 2 * R; R = 6371 km
  }

  precise_round(num, dec) {
    return parseFloat(num.toFixed(dec))
  }

}


@Component({
  selector: 'rider-assignment',
  templateUrl: 'rider-assignment.html',
})
export class RiderAssignment {

  selectedDP = "";
  constructor(
    public dialogRef: MatDialogRef<RiderAssignment>, @Inject(MAT_DIALOG_DATA) public data: any) {

    // console.log(data);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}