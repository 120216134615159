import { Component, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { DataService } from '../services/data.service';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { RestService } from '../services/rest.service';
import { ProductStatus, RefProductStatus } from '../_models/models';
import { Role } from '../_models/role.enum';


export interface CatFilter {
  name: string;
  id?: string;
  count: number;
  checked: boolean;
  color: ThemePalette;
  subcats?: CatFilter[];
}

export interface StatusFilter {
  name: string;
  status?: number;
  count: number;
  checked: boolean;
  color: ThemePalette;
  substatus?: StatusFilter[];
}

export interface ICFilter {
  name: string;
  iCount?: number;
  count: number;
  checked: boolean;
  color: ThemePalette;
  subcounts?: ICFilter[];
}


@Component({
  selector: 'app-ref-product-dashboard',
  templateUrl: './ref-product-dashboard.component.html',
  styleUrls: ['./ref-product-dashboard.component.css']
})
export class RefProductDashboardComponent implements OnInit {
  loggedIn = true;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  datarole;
  expertrole;
  productListHome: any = [];
  catFilter: CatFilter = {
    name: 'All Categories',
    count: 0,
    checked: true,
    color: 'primary',
    subcats: []
  };


  statusFilter: StatusFilter = {
    name: 'All Statuses',
    count: 0,
    checked: true,
    color: 'primary',
    substatus: []
  };
  icFilter: ICFilter = {
    name: 'All Image Counts',
    count: 0,
    checked: true,
    color: 'primary',
    subcounts: []
  };

  pageNumber = 1;
  loadedPageNumber = 1;
  totalCount = 0;


  allChecked: boolean = true;
  allStatusChecked: boolean = true;
  allICChecked: boolean = true;
  step = 0;

  constructor(private authService: AuthService, public router: Router, private rest: RestService, public dataService: DataService, private fireRest: FirebaseRestService) {

  }

  ngOnInit(): void {
    this.authService.loginStatus.subscribe((data) => {
      this.datarole = (this.authService.userRole == Role.Admin || this.authService.userRole == Role.DataEntry);
      this.expertrole = (this.authService.userRole == Role.Admin || this.authService.userRole == Role.DataExpert);
      // console.log(this.datarole);
      if (data) {
        this.loggedIn = true;
        this.getProducts();

      } else {
        this.loggedIn = false;
      }
    })
  }







  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  updateallChecked(type?: string) {
    if (type === "status") {
      this.allStatusChecked = this.statusFilter.substatus != null && this.statusFilter.substatus.every(t => t.checked);
    } else if (type === "ic") {
      this.allICChecked = this.icFilter.subcounts != null && this.icFilter.subcounts.every(t => t.checked);
    } else {
      this.allChecked = this.catFilter.subcats != null && this.catFilter.subcats.every(t => t.checked);
    }

    // this.applyFilters();

  }

  getProductStatusColor(status): string {
    switch (status) {
      case ProductStatus.Active: {
        return '';
      }
      case ProductStatus.PendingSNBVerification: {
        return 'bg-info';
      }
      case ProductStatus.SNBDEBeingVerified, ProductStatus.SNBDXBeingVerified, ProductStatus.PendingSNBExpertVerification, ProductStatus.SNBExpertDeclined: {
        return 'bg-primary';
      }
    }

  }

  applyFilters(clear?: boolean, nextPage?: boolean) {
    const cFilter: any = [];
    const sFilter: any = [];
    const icFilter: any = [];

    if (!clear) {
      for (const cf in this.catFilter.subcats) {
        if (this.catFilter.subcats[cf].checked)
          cFilter.push('categoryId:' + this.catFilter.subcats[cf].id);
      }
      for (const sf in this.statusFilter.substatus) {
        if (this.statusFilter.substatus[sf].checked)
          sFilter.push('status:' + this.statusFilter.substatus[sf].status);
      }
      for (const icf in this.icFilter.subcounts) {
        if (this.icFilter.subcounts[icf].checked)
          icFilter.push('imgCount:' + this.icFilter.subcounts[icf].iCount);
      }
    }

    if (nextPage) {
      this.pageNumber = this.pageNumber + 1
    } else {
      this.pageNumber = 1;
    }
    const currPage = this.pageNumber;


    this.rest.getRefProductList(cFilter.length > 0 ? cFilter : null, sFilter.length > 0 ? sFilter : null, icFilter.length > 0 ? icFilter : null, currPage).subscribe((productData) => {
      // console.log(productData);
      if (productData.status === "success") {
        if (nextPage) {
          for (const hit of productData.data.hits) {
            this.dataService.refProductListResults.hits.push(hit);
          }
          this.dataService.refProductListResults.page = productData.data.page;
          this.setFilters(this.dataService.refProductListResults);
        } else {
          this.setFilters(productData.data);
        }
      }
    });
  }

  someComplete(type?: string): boolean {
    if (type === "status") {
      if (this.statusFilter.substatus == null) {
        return false;
      }
      return this.statusFilter.substatus.filter(t => t.checked).length > 0 && !this.allStatusChecked;
    } else if (type === "ic") {
      if (this.icFilter.subcounts == null) {
        return false;
      }
      return this.icFilter.subcounts.filter(t => t.checked).length > 0 && !this.allICChecked;
    } else {
      if (this.catFilter.subcats == null) {
        return false;
      }
      return this.catFilter.subcats.filter(t => t.checked).length > 0 && !this.allChecked;
    }

  }

  setAll(completed: boolean, type?: string) {
    if (type === "status") {
      this.allStatusChecked = completed;
      if (this.statusFilter.substatus == null) {
        return;
      }
      this.statusFilter.substatus.forEach(t => t.checked = completed);
    } else if (type === "ic") {
      this.allICChecked = completed;
      if (this.icFilter.subcounts == null) {
        return;
      }
      this.icFilter.subcounts.forEach(t => t.checked = completed);
    } else {
      this.allChecked = completed;
      if (this.catFilter.subcats == null) {
        return;
      }
      this.catFilter.subcats.forEach(t => t.checked = completed);
    }

  }

  getProducts() {
    // this.dataService.productListHome = [];
    if (Object.keys(this.dataService.refProductListResults).length == 0) {
      this.rest.getRefProductList().subscribe((productData) => {
        // console.log(productData);
        if (productData.status === 'success') {
          this.setFilters(productData.data);
        }
      })
    } else {
      this.setFilters(this.dataService.refProductListResults);
    }
  }

  private setFilters(productData: any) {
    this.dataService.refProductListResults = productData;
    const catFacets = this.dataService.refProductListResults.facets.categoryId;
    const statusFacets = this.dataService.refProductListResults.facets.status;
    const icFacets = this.dataService.refProductListResults.facets.imgCount;
    this.totalCount = this.dataService.refProductListResults.nbHits;
    this.loadedPageNumber = this.dataService.refProductListResults.page + 1;
    this.catFilter.subcats = [];
    this.statusFilter.substatus = [];
    this.icFilter.subcounts = [];
    this.productListHome = this.dataService.refProductListResults.hits;


    if (catFacets && Object.keys(catFacets).length > 0) {
      this.catFilter.count = this.totalCount;

      this.fireRest.getAllCategories().subscribe((catData) => {
        if (catData.length > 0) {
          for (const cf in catFacets) {
            const catName = catData.find(ele => ele.id == cf).name;
            this.catFilter.subcats.push({ name: catName, id: cf, count: catFacets[cf], checked: true, color: "primary" });
          }
        }
      });
    }




    if (icFacets && Object.keys(icFacets).length > 0) {
      this.icFilter.count = this.totalCount;
      for (const icf in icFacets) {
        let countText = "NA";
        switch (parseInt(icf)) {
          case 0: {
            countText = "No Image";
            break;
          }
          case 1: {
            countText = "1 Image";
            break;
          }
          case 2: {
            countText = "2 Images";
            break;
          }
          case 3: {
            countText = "3 Images";
            break;
          }
          case 4: {
            countText = "4 Images";
            break;
          }
          case 5: {
            countText = "5 Images";
            break;
          }
        }
        this.icFilter.subcounts.push({ name: countText, iCount: parseInt(icf), count: icFacets[icf], checked: true, color: "primary" });
      }
    }

    if (statusFacets && Object.keys(statusFacets).length > 0) {
      this.statusFilter.count = this.totalCount;
      for (const sf in statusFacets) {
        let statusText = this.dataService.getStatusText(sf)
        this.statusFilter.substatus.push({ name: statusText, status: parseInt(sf), count: statusFacets[sf], checked: true, color: "primary" });
      }
    }
  }


  getStatusText(s) {
    return this.dataService.getStatusText(s)
  }

  handleChange(e) {
    console.log(e);
    if (e) {
      // this.accordion.closeAll();
      this.nextStep()
    }
  }


}
