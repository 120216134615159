<div>
    <mat-form-field appearance="standard">
        <mat-label>Select Key</mat-label>
        <mat-select [(ngModel)]="selectedKey" (selectionChange)="resetKeys()" required>
            <mat-option [value]="item" *ngFor="let item of retrievedData">{{item.k}} - {{item.r}}</mat-option>
            <mat-option [value]="{ k: '', t: '', l: 1, c: '', sl: [] }">New</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div style="border: 2px solid gray; width: fit-content;" class="p-4">
    <div class="mat-title" *ngIf="!selectedKey.k">
        Add Key
    </div>
    <div class="mat-title" *ngIf="selectedKey.k">
        {{selectedKey.k}}
    </div>
    <div>
        <mat-form-field appearance="standard">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="selectedKey.k" placeholder="Key">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="standard">
            <mat-label>Search Term</mat-label>
            <input matInput [(ngModel)]="selectedKey.st" placeholder="Search Term">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="standard">
            <mat-label>Type</mat-label>
            <mat-select required [(ngModel)]="selectedKey.t">
                <mat-option value="Dish">Dish</mat-option>
                <mat-option value="Cuisine">Cuisine</mat-option>
            </mat-select>
        </mat-form-field>

    </div>
    <div>
        <mat-form-field appearance="standard">
            <mat-label>Level</mat-label>
            <mat-select required [(ngModel)]="selectedKey.l">
                <mat-option value="0">Level 0</mat-option>
                <mat-option value="1">Level 1</mat-option>
                <mat-option value="2">Level 2</mat-option>
            </mat-select>
        </mat-form-field>

    </div>
    <div>
        <mat-form-field appearance="standard">
            <mat-label>Rank</mat-label>
            <input matInput [(ngModel)]="selectedKey.r" placeholder="Sort Rank" type="number">
        </mat-form-field>
    </div>
    <div>
        <mat-slide-toggle [(ngModel)]="selectedKey.e">Enabled</mat-slide-toggle>

    </div>
    <div>
        <mat-form-field appearance="standard">
            <mat-label>Food Type Classification</mat-label>
            <mat-select required [(ngModel)]="selectedKey.c">
                <mat-option value="Mixed">Mixed</mat-option>
                <mat-option value="Veg">Veg</mat-option>
                <mat-option value="Non Veg">Non Veg</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <img width="256" [src]="croppedImage" />

    <ngx-dropzone class="w-100" [multiple]="false" (change)="fileChangeEvent($event)">
        <ngx-dropzone-label>Drop the Icon</ngx-dropzone-label>
    </ngx-dropzone>

    <image-cropper style="width: 256px;" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
        [resizeToWidth]="133" [resizeToHeight]="133" [aspectRatio]="1/1" format="webp" [canvasRotation]="rotation/90"
        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"></image-cropper>

    <mat-slider *ngIf="showRotater" [max]="270" [min]="0" [step]="90" [thumbLabel]="1" [tickInterval]="90"
        [(ngModel)]="rotation" [vertical]="false">
    </mat-slider>

</div>



<div *ngIf="selectedKey.l=='1'">
    <div class="search-list-container">
        <h2>Sub List</h2>

        <div cdkDropList #sl="cdkDropList" [cdkDropListData]="selectedKey.sl" [cdkDropListConnectedTo]="[al]"
            class="search-list" (cdkDropListDropped)="drop($event)">
            <div class="search-list-box" *ngFor="let item of selectedKey.sl" cdkDrag>{{item.k}}</div>
        </div>
    </div>

    <div class="search-list-container">
        <h2>All Keys</h2>

        <div cdkDropList #al="cdkDropList" [cdkDropListData]="a" [cdkDropListConnectedTo]="[sl]" class="search-list"
            (cdkDropListDropped)="drop($event)">
            <div class="search-list-box" *ngFor="let item of a" cdkDrag>{{item.k}}</div>
        </div>
    </div>
</div>

<div>
    <button mat-raised-button (click)="submitSK()"> Save </button>
</div>