<form [formGroup]="editProductForm" class="needs-validation">

    <div *ngIf="l1categories && l1categories.length>0" class="no-form-group">
        <mat-form-field>
            <mat-label for="sel1">Select One:</mat-label>
            <mat-select id="sel1" (selectionChange)="getCategoriesByParent($event.value,'l2')" [(value)]="l1selected">
                <!-- <mat-option value="0">Choose</mat-option>  -->
                <mat-option *ngFor="let p of l1categories; let i=index;" value="{{p.id}}">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="l2categories && l2categories.length>0" class="no-form-group">
        <mat-form-field>
            <mat-label for="sel2">Select One:</mat-label>
            <mat-select id="sel2" (selectionChange)="getCategoriesByParent($event.value,'l3')" [(value)]="l2selected">
                <!-- <mat-option value="0">Choose</mat-option>  -->
                <mat-option *ngFor="let p of l2categories; let i=index;" value="{{p.id}}">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="l3categories && l3categories.length>0" class="no-form-group">
        <mat-form-field>
            <mat-label for="sel3">Select One:</mat-label>
            <mat-select id="sel3" (selectionChange)="getCategoriesByParent($event.value,'l4')" [(value)]="l3selected">
                <!-- <mat-option value="0">Choose</mat-option>     -->
                <mat-option *ngFor="let p of l3categories; let i=index;" value="{{p.id}}">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <ngx-dropzone class="w-100" id="{{inventoryId}}" [multiple]="false" (addedfiles)="addedFiles($event)"
        (change)="onSelect($event)">
        <ngx-dropzone-label>Drop the image</ngx-dropzone-label>
    </ngx-dropzone>
    <ul class="row imglist m-0" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
        <li cdkDrag *ngFor="let c of captures; let i = index" class="imgli col-auto w-auto">
            <img src="{{ c }}" (click)="openPhoto(photoContent,c,i)" />
            <img *cdkDragPreview width="200" src="{{ c }}" />
        </li>
    </ul>

    <button mat-raised-button class="m-2" color="primary" type="button" (click)="getRelated()">Get Related
        Images</button>

    <div *ngIf="getRelatedCalled">
        <div *ngIf="getObjLength(origImages)>0">
            <ul *ngFor="let c of origImages|keyvalue; let i = index">
                <li *ngFor="let c2 of c.value; let i2 = index">
                    <div *ngIf="c2">
                        <img src="{{ c2 }}" height="100" (click)="openPhoto(photoContent,c2,i2)" />
                        <button mat-raised-button class="m-2" type="button" (click)="addFromRelated(c2)">+</button>
                    </div>
                </li>
            </ul>
        </div>
        <div *ngIf="getObjLength(origImages)==0">
            <p>loading/no images found</p>
        </div>
    </div>
    <ng-template #photoContent let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Photo</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <img src="{{ currentImage }}" width="100%" />
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-outline-dark"
                (click)="modal.close(currentImageIndex)">Delete</button>
        </div>
    </ng-template>

    <div *ngFor="let p of formdetails; let i=index;">

        <div class="no-form-group" *ngIf="p.d=='PlainString'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <input [formControlName]="p.docId" type="text" matInput class="mat-input-element"
                    id="{{p.docId}}{{inventoryId}}" [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
                <button *ngIf="p.v&&p.v.length>3" type="button" mat-icon-button matSuffix (click)="searchGoogle(p.v)">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='LargeText'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5" [formControlName]="p.docId" matInput class="mat-input-element"
                    id="{{p.docId}}{{inventoryId}}"
                    [required]="p.required==1||(deliveryEligible&&p.rfd==true)"></textarea>
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='URL'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <input [formControlName]="p.docId" type="url" matInput class="mat-input-element"
                    id="{{p.docId}}{{inventoryId}}" [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='Email'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <input [formControlName]="p.docId" type="email" matInput class="mat-input-element"
                    id="{{p.docId}}{{inventoryId}}" [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='Date'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <input [formControlName]="p.docId" type="date" matInput class="mat-input-element"
                    id="{{p.docId}}{{inventoryId}}" [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='Number'||p.d=='Fraction'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <input [formControlName]="p.docId" type="number" matInput class="mat-input-element"
                    id="{{p.docId}}{{inventoryId}}" [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='Currency'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <input [formControlName]="p.docId" type="number" matInput class="mat-input-element"
                    id="{{p.docId}}{{inventoryId}}" [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
                <span matPrefix>&#8377;&nbsp;</span>
                <span matSuffix>.00</span>
            </mat-form-field>
        </div>


        <div class="no-form-group" *ngIf="p.d=='List'">
            <mat-form-field>
                <mat-label>{{p.l}}</mat-label>
                <mat-select [formControlName]="p.docId" id="{{p.docId}}{{inventoryId}}"
                    [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
                    <mat-option *ngFor="let v of p.ref_values; let i=index;" value="{{v}}">{{v}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='Multi'">
            <mat-form-field>
                <mat-label>{{p.l}}</mat-label>
                <mat-select [formControlName]="p.docId" id="{{p.docId}}{{inventoryId}}"
                    [required]="p.required==1||(deliveryEligible&&p.rfd==true)" multiple>
                    <mat-option *ngFor="let v of p.ref_values; let i=index;" value="{{v}}">{{v}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>


    </div>




</form>

<div class="my-2">


    <mat-form-field class="example-chip-list">
        <mat-chip-list #chipList aria-label="Fruit selection">
            <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                (removed)="remove(tag)">
                {{tag}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="New tag..." #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                {{tag}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>


    <mat-checkbox *ngIf="formdetails && formdetails.length>0" [(ngModel)]="deliveryEligible">Eligible for
        Delivery{{deliveryEligible}}
    </mat-checkbox>
    <mat-form-field *ngIf="formdetails && formdetails.length>0 && deliveryEligible">
        <mat-label>Price Changes</mat-label>
        <mat-select [(ngModel)]="priceChangesEvery">
            <mat-option value="0">Never</mat-option>
            <mat-option value="1">Every Day</mat-option>
            <mat-option value="2">Every Two Days</mat-option>
            <mat-option value="7">Every Week</mat-option>
            <mat-option value="30">Every Month</mat-option>
            <mat-option value="365">Every Year</mat-option>
        </mat-select>
    </mat-form-field>
</div>



<button mat-raised-button class="m-2" color="primary" *ngIf="formdetails && formdetails.length>0" type="button"
    (click)="onSubmit(editProductForm.value,101)">Submit</button>
<button mat-raised-button class="m-2" color="accent" *ngIf="formdetails && formdetails.length>0" type="button"
    (click)="onSubmit(editProductForm.value,102)">Save as Draft</button>
<button mat-raised-button class="m-2" color="warn" *ngIf="formdetails && formdetails.length>0" type="button"
    (click)="onSubmit(editProductForm.value,99)">Delete</button>