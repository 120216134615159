import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { RestService } from '../services/rest.service';



export interface MenuLabel {
  name: string;
  rank?: number;
}



@Component({
  selector: 'app-edit-shop-menu',
  templateUrl: './edit-shop-menu.component.html',
  styleUrls: ['./edit-shop-menu.component.css']
})
export class EditShopMenuComponent implements OnInit {
  ownerId = "";
  shopId = "";
  shopData: any = {};


  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  menuListProducts: any = {};


  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  menuLabels: MenuLabel[] = [
    { name: 'Others', rank: 1 }
  ];

  constructor(private fireRest: FirebaseRestService, private route: ActivatedRoute, private router: Router, private rest: RestService) {
    this.route.params.subscribe(params => {
      // console.log(params);
      this.ownerId = params.ownerId;
      this.shopId = params.shopId;
      this.getShopDetails(this.shopId);

      const cFilter: any = [];
      const sFilter: any = [];
      // this.catId = cId;


      this.fireRest.getShopMenuByOwnerId(this.ownerId).subscribe((mData) => {
        console.log(mData.data());
        if (mData.exists) {
          this.menuLabels = mData.get('menu');

          for (const mlbl of this.menuLabels) {
            this.menuListProducts[mlbl.name] = [];
          }


        }

        if (this.shopId && this.shopId.length > 0) {
          sFilter.push('shopId:' + this.shopId);
        }




        this.rest.getSearchResultsByRegion({}, "", cFilter, sFilter).subscribe((productData) => {


          if (!this.menuListProducts["Others"] || this.menuListProducts["Others"].length == 0) {
            this.menuListProducts["Others"] = [];
          }

          if (productData.data && productData.data.hits) {
            console.log(productData.data);

            for (const p of productData.data.hits) {
              // console.log(p.menuLabel);

              if (p.menuLabel) {
                if (!this.menuListProducts[p.menuLabel] || this.menuListProducts[p.menuLabel].length == 0) {
                  this.menuListProducts[p.menuLabel] = [];
                }
                this.menuListProducts[p.menuLabel].push(p);
              } else {
                this.menuListProducts["Others"].push(p);
              }
            }

            console.log(this.menuListProducts);




          }



        });

      })






    })
  }

  ngOnInit(): void {
  }


  private getShopDetails(shopId: string) {
    this.fireRest.getShopByShopId(shopId).subscribe((data) => {
      if (!data.empty) {

        this.shopData = data.docs[0].data()

        // console.log(this.shopData);

      }

    })
  }




  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.menuLabels.push({ name: value.trim() });
      this.menuListProducts[value.trim()] = [];
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(ml: MenuLabel): void {
    const index = this.menuLabels.indexOf(ml);

    if (index >= 0) {
      this.menuLabels.splice(index, 1);

      for (const rp of this.menuListProducts[ml.name]) {
        this.menuListProducts["Others"].push(rp);
      }

      delete this.menuListProducts[ml.name];


    }
  }


  drop(event: CdkDragDrop<string[]>) {

    // console.log(event);


    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  drop2(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.menuLabels, event.previousIndex, event.currentIndex);
  }


  submitMenu() {
    // console.log(this.menuListProducts);
    // console.log(this.menuLabels);
    // return;
    const finalMenuProducts = [];
    const finalMenuLabels = [];
    let mrank = 1;
    let prank = 1;
    for (const ml of this.menuLabels) {
      finalMenuLabels.push({
        name: ml.name,
        rank: mrank
      })
      mrank++;
      for (const p of this.menuListProducts[ml.name]) {
        finalMenuProducts.push({
          id: p.productId,
          menuLabel: ml.name,
          spRank: prank
        })
        prank++;
      }
    }

    // console.log(finalMenuProducts);
    // console.log(finalMenuLabels);

    this.rest.updateShopMenu({ labels: finalMenuLabels, prodcuts: finalMenuProducts, ownerId: this.ownerId }).subscribe((data) => {
      // console.log(data);

    })



  }

}
