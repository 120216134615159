




<mat-sidenav-container class="example-container h-100">
    <mat-sidenav #sidenav mode="over" class="">
        <app-sidenav></app-sidenav>        
    </mat-sidenav>
  
    <mat-sidenav-content>
        <app-top-bar></app-top-bar>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>


  <!--  [(opened)]="opened" (opened)="events.push('open!')"
                 (closed)="events.push('close!')" -->