import { Component, OnInit, NgZone } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl } from '@angular/forms';
import {
  Router
} from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import * as firebase from 'firebase/compat/app';
import 'firebase/auth'
import { map } from 'rxjs/operators';
import { Role } from '../_models/role.enum';
import { DataService } from '../services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';









@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  loginFormUsingEmail: UntypedFormGroup;

  message: string;
  useEmail = true;
  shopUrlsToExclude = ['/', '/login']

  constructor(public authService: AuthService, private formBuilder: UntypedFormBuilder, public router: Router, public dialog: MatDialog, public dataService: DataService, public _snackBar: MatSnackBar, private ngZone: NgZone) {

    authService.loginStatus.subscribe((data: boolean) => {

      if (data) {
        this.ngZone.run(() => {
          // //console.log('surr url: '+sessionStorage.getItem('current_url'));
          // //console.log(new Date());
          if (authService.isLoggedIn && sessionStorage.getItem('current_url') && sessionStorage.getItem('current_url') != '' && this.shopUrlsToExclude.indexOf(sessionStorage.getItem('current_url')) === -1) {
            this.router.navigate([sessionStorage.getItem('current_url')]);
          } else if (authService.isLoggedIn) {
            if (this.authService.redirectUrl) {
              this.router.navigate([this.authService.redirectUrl]);
            } else {
              this.router.navigate(['/']);
            }

          }
        });

      }


    });

  }

  setMessage() {
    //this.message = 'Logged ' + (this.authService.isLoggedIn ? 'in' : 'out');
  }




  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: ['']
    });

    this.loginFormUsingEmail = this.formBuilder.group({
      email: [''],
      password: ['']
    });
  }

  switchLogin() {
    this.useEmail = !this.useEmail;
  }

  get f() { return this.loginForm.controls; }


  onSubmit(loginData) {
    this.dataService.inProgress = true;
    this.message = 'Trying to log in ...';

    let body = this.loginForm.value;
    this.authService.cloudLogin(body).subscribe((data) => {

      if (data.token) {


        firebase.default.auth().signInWithCustomToken(data.token).then((authData) => {
          this.setPropsAndRedirect(data, authData);
        });

      } else {
        this.dataService.inProgress = false;
        if (!data.login) {
          this._snackBar.open("Incorrect Username/Password, Please try again.", "Okay", {
            duration: 3000,
          });
        }
        return false;
      }
    }, error => {
      // console.log('oops', error)
    });



  }

  onSubmitWithEmail(loginData) {
    this.dataService.inProgress = true;
    this.message = 'Trying to log in ...';

    let body = loginData;


    firebase.default.auth().signInWithEmailAndPassword(body.email, body.password).then((result) => {
      // this.setPropsAndRedirect(data, authData);
      return result.user.getIdToken();
    }).then((idToken) => {
      if (idToken) {
        let body: any = {};
        body.idToken = idToken;
        this.authService.setClaims(body).subscribe((res) => {
          // console.log(res);
          if (res && res.claimsSet === "success") {
            firebase.default.auth().currentUser.getIdToken(true);
            this.router.navigate(['/']);
          }
        });

      }
    }).catch((err) => {
      if (err.message)
        alert(err.message);


    });;



  }

  private setPropsAndRedirect(data: any, authData: firebase.default.auth.UserCredential) {
    // console.log(data);
    // // console.log('gor from server '+data.role);
    if (data.role == 'shop') {
      this.authService.setUserRole(Role.Shop);
    }

    this.authService.currentUser = authData.user;
    this.dataService.LoggedInUserPhone = data.phone;
    this.dataService.LoggedInUserUID = authData.user.uid;
    this.authService.setLoggedIn(true);
    if (this.authService.userRole == Role.Shop) {
      this.authService.redirectUrl = '/';
    }


    if (this.authService.currentUser != null) {
      // // console.log('user role '+this.authService.userRole);
      // // console.log('set redir url '+this.authService.redirectUrl);
      let redirect = this.authService.redirectUrl ? this.router.parseUrl(this.authService.redirectUrl) : '/';
      // // console.log('final redir url '+redirect);
      this.router.navigateByUrl(redirect);
      this.loginForm.reset();
      this.dataService.inProgress = false;
    }
  }


}