import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';


@Component({
  selector: 'app-help-topic',
  templateUrl: './help-topic.component.html',
  styleUrls: ['./help-topic.component.css']
})
export class HelpTopicComponent implements OnInit {

  public Editor = ClassicEditor;

  constructor() { }

  ngOnInit(): void {

  }

  public onChange({ editor }: ChangeEvent) {
    const data = editor.getData();

    console.log(data);
  }

}
