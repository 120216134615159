import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { DeliveryTypes, PaymentTypes } from '../_models/models';

@Component({
  selector: 'app-update-shop-category',
  templateUrl: './update-shop-category.component.html',
  styleUrls: ['./update-shop-category.component.css']
})
export class UpdateShopCategoryComponent implements OnInit {

  shopCats: any = [];

  editShopCatForm: UntypedFormGroup;
  currentShopCat: any = {};

  deliveryType: any = {
    "Immediate Delivery": DeliveryTypes.ImmediateDelivery,
    "Delayed Delivery": DeliveryTypes.DelayedDelivery
  }

  paymentType: any = {
    "On Pickup": PaymentTypes.onPickup,
    "On Delivery": PaymentTypes.onDelivery,
    "WeekEnd": PaymentTypes.weekEnd
  }
  showCF: boolean = false;
  currentShopCatId: any = "";


  constructor(private fireRest: FirebaseRestService, private formBuilder: UntypedFormBuilder) {
    this.getShopCats();


  }

  private getShopCats() {
    this.fireRest.getShopCategories().subscribe((data) => {
      this.shopCats = data.docs;
      console.log(data.docs);

    });
  }

  ngOnInit(): void {
  }


  showCatForm(cat?) {
    // console.log(cat); return;
    //[this.toTitleCase(cat.name)],

    this.showCF = true;

    if (cat && Object.keys(cat.data()).length > 0) {
      this.editShopCatForm = this.formBuilder.group({
        Name: [this.toTitleCase(cat.data().Name)],
        PluralName: [this.toTitleCase(cat.data().PluralName)],
        allowReturns: cat.data().allowReturns,
        madeForOrder: cat.data().madeForOrder,
        deliveryType: cat.data().deliveryType,
        paymentType: cat.data().paymentType,
        sortOrder: cat.data().sortOrder,
        needFSSAI: cat.data().needFSSAI
      });
      // this.currentShopCat = cat.data();
      this.currentShopCatId = cat.id;
    } else {
      // this.currentShopCat = {};
      this.currentShopCatId = "";
      this.editShopCatForm = this.formBuilder.group({
        Name: '',
        PluralName: '',
        allowReturns: false,
        madeForOrder: false,
        deliveryType: DeliveryTypes.ImmediateDelivery,
        paymentType: PaymentTypes.onPickup,
        sortOrder: 0,
        needFSSAI: false
      });
    }

  }



  toTitleCase(str) {
    return str.split(/\s+/).map(s => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()).join(" ");
  }

  update_shop_category() {

    this.fireRest.updateShopCat(this.editShopCatForm.value, this.currentShopCatId).then((data: any) => {
      console.log(data);
      this.showCF = false;
      this.editShopCatForm.reset();
      this.currentShopCatId = "";
      this.getShopCats();
    }).catch((err) => {
      console.log(err);

    })


  }

  resetForm() {
    this.showCF = false;
    this.editShopCatForm.reset();
    this.currentShopCatId = "";
  }

}
