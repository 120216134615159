<mat-card>
    <div>
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Title</mat-label>
            <input matInput placeholder="Enter Title" [(ngModel)]="title">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Body</mat-label>
            <textarea matInput placeholder="Enter Body" [(ngModel)]="message"></textarea>
        </mat-form-field>
    </div>

    <section class="example-section">
        <mat-checkbox [(ngModel)]="pointed">
            Is Pointed
        </mat-checkbox>
    </section>

    <div *ngIf="pointed">
        <section class="example-section">
            <mat-checkbox [(ngModel)]="locationSpecific">
                Location Specific
            </mat-checkbox>
        </section>
        <div *ngIf="locationSpecific">

            <div>
                <mat-radio-group aria-label="Select a Region" (change)="setCity($event)">
                    <mat-radio-button class="ml-2" *ngFor="let c of city_list" [checked]="regionSelected.city==c.city"
                        [value]="c">
                        {{c.city}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <section class="example-section">
                <mat-checkbox [(ngModel)]="shopSpecific">
                    Shop Specific
                </mat-checkbox>
            </section>
            <div *ngIf="shopSpecific">
                <section class="example-section">
                    <mat-checkbox [(ngModel)]="productSpecific">
                        Product Specific
                    </mat-checkbox>
                </section>
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Shops</mat-label>
                        <mat-select [(ngModel)]="selectedShops" multiple>
                            <div *ngFor="let n of shopsData">
                                <mat-option
                                    *ngIf="!(regionSelected.city!=(n.address_city=='Chirala'||n.address_city=='Bapatla'||n.address_city=='Guntur'?n.address_city:'Others'))"
                                    [value]="n">{{n.shopname}} <span style="font-size: 10px;">--
                                        {{n.address_short}}</span>
                                </mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>

                </div>
                <mat-card *ngIf="selectedShops.length>0">
                    <div *ngIf="productSpecific">
                        <mat-tab-group>
                            <mat-tab *ngFor="let shop of selectedShops" label="{{shop.shopname}}">
                                <ng-template matTabContent>
                                    <app-shop-products [shopId]="shop.id"
                                        [sProducts]="selectedProductsByShop[shop.id]?selectedProductsByShop[shop.id]:[]"
                                        (selectionChanges)="handleSelectionChanges($event)"></app-shop-products>
                                </ng-template>
                            </mat-tab>

                        </mat-tab-group>
                    </div>
                    <div *ngIf="!productSpecific">
                        <div style="display: flex;flex-wrap: wrap;" class="m-1">
                            <mat-card class="mx-2 my-1" style="width: fit-content;" *ngFor="let shop of selectedShops">
                                {{shop.shopname}}
                            </mat-card>
                        </div>
                    </div>
                </mat-card>


            </div>
        </div>
        <div *ngIf="!productSpecific">
            <section class="example-section">
                <mat-checkbox [(ngModel)]="searchPointed">
                    Point to Search
                </mat-checkbox>
            </section>

            <div *ngIf="searchPointed">
                <div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Search Term</mat-label>
                        <input matInput placeholder="Search Term" [(ngModel)]="searchTerm">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div><button mat-raised-button color="primary" (click)="sendNotification()">Send</button></div>

</mat-card>