import { Component, OnInit } from '@angular/core';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { RestService } from '../services/rest.service';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-cart-data',
  templateUrl: './cart-data.component.html',
  styleUrls: ['./cart-data.component.css']
})


export class CartDataComponent implements OnInit {
  cartData: any = [];
  constructor(private fireRest: FirebaseRestService, private rest: RestService, private clipboard: Clipboard) {
    this.fireRest.getCartData().subscribe((data) => {
      if (data.length > 0) {
        this.cartData = [];
        data.forEach((ele: any) => {
          // console.log(ele);

          if (ele['userDetails'] && ele['cartCount'] && ele['cartCount'] > 0)
            this.cartData.push(ele);
        })
        console.log(this.cartData);


      }

    })
  }

  ngOnInit(): void {
  }

  getToken(ownerId) {
    this.rest.getUserToken(ownerId).subscribe((data) => {
      // console.log(data);
      if (data.status === "success") {
        this.clipboard.copy("https://shopnearby.me/#/token-login/" + data.role + "/" + data.token)
      }
    })
  }


  call(toNum) {
    // window.open("tel:" + toNum, '_self');
    if (confirm("Are you sure you want to call?")) {
      this.rest.callFromExotel(toNum, "04048211111").subscribe((data) => {
        // console.log(data);

        if (data.status == "fail") {
          console.log("fail");

        }

      })
    }

  }
}
