import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseRestService } from 'src/app/services/firebase-rest.service';
import { RestService } from 'src/app/services/rest.service';
import { ProductStatus } from 'src/app/_models/models';

@Component({
  selector: 'app-integrate',
  templateUrl: './integrate.component.html',
  styleUrls: ['./integrate.component.css']
})
export class IntegrateComponent implements OnInit {


  currentFormFields = {};
  currentFormFieldValues: any = {};

  posMapData: any = {};


  // app_key = "f14qd3se9a6juzbmoit85c0nrvhykgwp";
  // app_secret = "0ecb9930ec89b68dbc923d3ecedc43f37901cf61";
  // access_token = "04fc7877ce7e5f771328b2a1434cb040ad1b2c0f";
  // restID = "k13cv5ho";
  // menu_url = "https://qle1yy2ydc.execute-api.ap-southeast-1.amazonaws.com/V1/mapped_restaurant_menus";
  // order_save_url = "https://qle1yy2ydc.execute-api.ap-southeast-1.amazonaws.com/V1/mapped_restaurant_menus";
  // order_status_url = "https://qle1yy2ydc.execute-api.ap-southeast-1.amazonaws.com/V1/mapped_restaurant_menus";

  posData: any = {};
  addOnGroups: any = [];
  addOnItems: any = [];
  variations: any = [];
  items: any = [];
  snbyProducts: any = [];

  dryRun = false;

  allPOSs = [];
  selectedPOS: any = {};

  shopId = "";
  shopOwnerId = "";


  mappedCategories = []
  catData = []
  constructor(private rest: RestService, private fireRest: FirebaseRestService, private route: ActivatedRoute, private titlecasePipe: TitleCasePipe) {


    fireRest.getAllPOSs().subscribe((posData) => {
      // console.log(posData);

      if (!posData.empty) {
        this.allPOSs = posData.docs;
      }
    });

    fireRest.getAllCategories().subscribe((cd: any) => {
      // // console.log(cd);
      if (cd.length > 0) {
        this.catData = cd;
      }

      // // console.log(cd);
    });

    this.route.params.subscribe(params => {
      // console.log(params);
      this.shopId = params.shopId;
      this.shopOwnerId = params.ownerId;

    })



  }

  ngOnInit(): void {
  }

  showPOSForm(e) {
    console.log(e);

    this.fireRest.getPOSMap(this.shopId + "-" + this.selectedPOS.id).subscribe((data) => {
      if (data.exists) {
        this.posMapData = data.data();
        this.currentFormFieldValues = data.get('config');


      }
    })

    let posConfig = e.value.data();

    this.currentFormFields = posConfig.requiredFields;

  }


  selPOS() {
    // console.log(this.currentFormFieldValues); return;

    if (this.selectedPOS && this.selectedPOS.id != "" && this.shopId != "" && this.shopOwnerId != "") {
      this.rest.getPOSMenu(this.currentFormFieldValues.app_key, this.currentFormFieldValues.app_secret, this.currentFormFieldValues.access_token, this.currentFormFieldValues.menu_url, this.currentFormFieldValues.restID).subscribe((data) => {
        console.log(data);

        if (data && data.success == "1") {
          this.posData = data;
          let parentCategories = this.posData.parentcategories.filter((ele) => ele.status == "1");
          // console.log(parentCategories);

          let categories = this.posData.categories.filter((ele) => ele.active == "1");
          // console.log(categories);



          categories.forEach(cat => {

            let snbyCat = {};
            if (this.posMapData && this.posMapData.cat_map) {
              const _snbyCatMap = this.posMapData.cat_map.find(ele => ele.pos_cat_id === cat.categoryid);
              if (_snbyCatMap) {
                snbyCat = _snbyCatMap.snby_cat;
              }

            }
            let tag1 = ""
            if (parentCategories.find((ele) => ele.id == cat.parent_category_id)) {
              tag1 = parentCategories.find((ele) => ele.id == cat.parent_category_id).name;
            }
            let tag2 = cat.categoryname;
            if (tag1 === "Swiggy Pop") {
              tag1 = "Meal For One";
            }
            if (tag2 === "Swiggy Pop") {
              tag2 = "Meal For One";
            }
            this.mappedCategories.push({
              tag1: tag1,
              tag2: tag2,
              pos_cat_id: cat.categoryid,
              snby_cat: snbyCat
            })

          });



          // console.log(this.mappedCategories);







        }




      })
    }
  }

  translateItems() {
    if (this.mappedCategories.filter((ele) => !ele.snby_cat).length == 0) {
      // console.log(this.mappedCategories);//save this===================

      this.fireRest.updateShopPOSMapp({
        config: this.currentFormFieldValues,
        cat_map: this.mappedCategories
      }, this.shopId + "-" + this.selectedPOS.id).subscribe((data) => {
        console.log(data);

      })

      // return;

      this.addOnGroups = this.posData.addongroups.filter((ele) => ele.active == "1");
      // console.log(addOnGroups);
      this.addOnGroups.forEach(addOnGrp => {

        addOnGrp.shopId = this.shopId;
        addOnGrp.ownerId = this.shopOwnerId;
        addOnGrp.pos = this.selectedPOS.id;
        addOnGrp.snbyId = this.shopId + "-" + this.selectedPOS.id + "-" + addOnGrp.addongroupid;
        addOnGrp.addongroupitems.forEach(item => {
          const attr = this.posData.attributes.find((a) => a.attributeid == item.attributes);
          item.foodType = this.titlecasePipe.transform(attr.attribute.replace("-", " ")).replace(" ", "-");
          item.snbyId = this.shopId + "-" + this.selectedPOS.id + "-" + item.addonitemid;
          item.ownerId = this.shopOwnerId;
          this.addOnItems.push(item);
        });

      });

      console.log(this.addOnGroups); //save this========================
      console.log(this.addOnItems);
      if (!this.dryRun) {
        this.fireRest.addPOSAddonGroups(this.addOnGroups).subscribe((data) => {
          console.log(data);

        })
        this.fireRest.addPOSAddonItems(this.addOnItems).subscribe((data) => {
          console.log(data);

        })
      }


      this.variations = this.posData.variations.filter((ele) => ele.status == "1");
      // console.log(variations);
      this.variations.forEach(variant => {

        variant.shopId = this.shopId;
        variant.ownerId = this.shopOwnerId;
        variant.pos = this.selectedPOS.id;
        variant.snbyId = this.shopId + "-" + this.selectedPOS.id + "-" + variant.variationid;;
      });
      console.log(this.variations); //save this==========================
      if (!this.dryRun) {
        this.fireRest.addPOSVariations(this.variations).subscribe((data) => {
          console.log(data);

        })
      }

      this.items = this.posData.items.filter((ele) => ele.active == "1" && (JSON.parse(ele.price) > 0 || ele.variation.length > 0));

      let products: any = [];
      let productsSubObj = {}

      this.items.forEach(item => {

        let itemAddons = item.addon;

        itemAddons.forEach(ia => {
          let addonGroup = this.addOnGroups.find(ele => ele.addongroupid == ia.addon_group_id)
          console.log(addonGroup);

          if (addonGroup) {
            ia.snbyId = addonGroup.snbyId;

          }
        });

        let itemVariants = item.variation;

        itemVariants.forEach(iv => {
          let varient = this.variations.find(ele => ele.variationid == iv.variationid)
          if (varient) {
            iv.snbyId = varient.snbyId;

          }

          let vAddons = iv.addon;

          vAddons.forEach(va => {
            let vAddonGroup = this.addOnGroups.find(ele => ele.addongroupid == va.addon_group_id)
            if (vAddonGroup) {
              va.snbyId = vAddonGroup.snbyId;

            }
          });


        });

        let catmap = this.mappedCategories.find((ele) => ele.pos_cat_id == item.item_categoryid)
        const itemattr = this.posData.attributes.find((a) => a.attributeid == item.item_attributeid);
        let snbyProduct = {
          Tags: [catmap.tag1, catmap.tag2],
          active: ProductStatus.Active,
          categoryId: catmap && catmap.snby_cat ? catmap.snby_cat['id'] : "",
          imgCount: 0,
          ownerId: this.shopOwnerId,
          type: "product",
          addonBasedOnVarients: JSON.parse(item.itemaddonbasedon),//
          allowAddons: JSON.parse(item.itemallowaddon),
          hasVariants: JSON.parse(item.itemallowvariation),
          posProductId: item.itemid,
          snbyId: this.shopId + this.selectedPOS.id + item.itemid,
          posItem: 1,
          posData: item,
          addons: itemAddons,
          variations: itemVariants,
          metaData: {
            deliveryEligible: true,
            priceChangesEvery: 0,
          },
          details: {
            "8yIYfCWxJ3eg6L8BRreW": item.itemname,
            "G1v1dCSZLe7G338AIzBK": "Available",
            "3TMy0q0Xo5FPTzPw7ryK": "99633",
            "704lQOZcuhjMA6DlOGeq": "",
            "LCcTnuJznJxAgqQBaVPw": this.titlecasePipe.transform(itemattr.attribute.replace("-", " ")).replace(" ", "-"),
            "HZLJsHvs5o7j38BehhKz": item.minimumpreparationtime.length > 0 ? JSON.parse(item.minimumpreparationtime) : 2,
            "70t907pEiRyB1BEftiRb": this.getTaxDetails(item.item_tax, this.posData.taxes).type,
            "UqXfb4OkXThtfpSB3EKE": this.getTaxDetails(item.item_tax, this.posData.taxes).amt,
            "XbI8HhCyun94vpZHoaS5": 0,
            "ar8pJs5dEGYZA5wyVJyT": item.itemdescription,
            "k4E8qyED6qPzhdsGUpoG": 1,
            "k5E5opuxTKbFHcSWO9Au": "1N",
            "ljCIb1lXOZ0vCVqK0dBf": "",
            "u22UClLx9FPBZMo1G2O7": this.getPrice(item.price, itemVariants, this.getTaxDetails(item.item_tax, this.posData.taxes).amt),
            "z35YxpWMYu0g4p4ogMLh": 0
          }
        };
        snbyProduct['Tags'] = []
        if (catmap.tag1 != "") {
          snbyProduct['Tags'].push(catmap.tag1)
        }
        if (catmap.tag2 != "" && catmap.tag1 != catmap.tag2) {
          snbyProduct['Tags'].push(catmap.tag2)
        }
        if (products.length < 300) {
          products.push(snbyProduct);

        } else {
          return;
        }

      });



      console.log(products);//save this==================
      if (!this.dryRun) {
        this.fireRest.addPOSProducts(products).subscribe((data) => {
          console.log(data);

        })
      }

    }
  }

  showAddChildForm(cat, mc) {
    // console.log(cat);
    // console.log(this.catData.find((ele) => ele.parentId == cat.id));
    console.log(this.selectedPOS.id);

    if (this.catData.find((ele) => ele.parentId == cat.id) && Object.keys(this.catData.find((ele) => ele.parentId == cat.id)).length > 0) {

    } else {
      mc.snby_cat = cat;
    }
    console.log(this.mappedCategories);

    // this.currentCat = cat;
  }

  getPrice(price, variants, tax) {
    console.log(variants);

    if (JSON.parse(price) == 0 && Object.keys(variants).length > 0) {
      let price = JSON.parse(variants.sort(function (a, b) { JSON.parse(a.variationrank) - JSON.parse(b.variationrank) })[0].price)
      return Math.round((price / (100 - tax)) * 100);
    } else {
      return Math.round((JSON.parse(price) / (100 - tax)) * 100);
    }
  }

  getTaxDetails(key, ref) {
    let keys = key.split(',');

    let res = { type: key, amt: -1 }

    let typeArr = [];
    let amtArr = [];

    keys.forEach(k => {
      let t = ref.find((ele) =>
        JSON.parse(ele.taxid) == k
      )
      // console.log(t);

      if (t) {
        typeArr.push(t.taxname.split(' ')[0])
        amtArr.push(JSON.parse(t.tax))
      }
    });

    res.type = typeArr.join(' + ');
    res.amt = amtArr.reduce((sum, current) => sum + current, 0)
    // console.log(res);

    return res;

  }

}
