<div id="omgmap" class="w-100" style="height: 50vh;"></div>
<mat-card style="text-align: center;">
    There are <strong>{{activeRCount}}</strong> riders active out of {{allRCount}}


</mat-card>
<mat-accordion class="mat-accordion mt-4">
    <mat-expansion-panel [expanded]="first" *ngFor="let order of this.userOrderData; let first=first"
        (opened)="panalOpened(order)">
        <mat-expansion-panel-header class="{{getStatusColor(order.finalStatus)}}">
            <mat-panel-title>
                <span *ngIf="!order.signedForDelivery && ![0,5].includes(order.finalStatus) ">
                    <button mat-raised-button color="primary" (click)="findDP(order.propertyId)">Find DP</button>
                </span>
                <span>{{order.orderRef?order.orderRef:''}}-{{order.finalStatus}}</span>
            </mat-panel-title>
            <mat-panel-description>
                <span>{{order.lastUpdated.toDate()| date:'medium'}}-{{getStatusText(order.finalStatus)}}</span>

            </mat-panel-description>

        </mat-expansion-panel-header>

        <!-- <h3 class="mat-subheading-2">Order Id: {{order.propertyId}}</h3> -->
        <div *ngIf="order.userAddress">
            <div class="mat-body-2"><span>{{order.userAddress['fullname']}}</span>
            </div>
            <div class="mat-body-1" *ngIf="order.userAddress['phone']!=null">
                {{ order.userAddress['phone']}}</div>
            <div>
                <span class="mat-body-1"
                    *ngIf="order.userAddress['addrline1']!=null">{{order.userAddress['addrline1']}}</span>
                <span class="mat-body-1" *ngIf="order.userAddress['addrline2']!=null">,
                    {{order.userAddress['addrline2']}}</span>
                <span class="mat-body-1" *ngIf="order.userAddress['landmark']!=null">,
                    {{order.userAddress['landmark']}}</span>
            </div>
            <div>
                <span class="mat-body-1" *ngIf="order.userAddress['city']!=null">{{order.userAddress['city']}}</span>
                <span class="mat-body-1" *ngIf="order.userAddress['state']!=null">,
                    {{order.userAddress['state']}}</span>
                <span class="mat-body-1" *ngIf="order.userAddress['pincode']!=null">,
                    {{order.userAddress['pincode']}}</span>
            </div>
            <div>
                <button *ngIf="!order.signedForDelivery&&!order.pendingPayment&&!order.fullyCancelled" mat-raised-button
                    color="primary" (click)="findDP(order.propertyId)">Find DP</button>
                <button mat-raised-button class="ml-4" color="accent" type="button"
                    (click)="getDirections(order.userAddress['location'].Latitude+','+order.userAddress['location'].Longitude)">
                    <mat-icon>directions</mat-icon>
                    <span>Get Directions</span>
                </button>

                <button mat-raised-button class="ml-4" color="accent" type="button"
                    (click)="call(order.userAddress['phone'])">
                    <span>Call User</span>
                </button>

                <button mat-raised-button class="ml-4" color="accent" type="button" (click)="getToken(order.UserUID)">
                    <span>Get Login Link</span>
                </button>
            </div>
        </div>

        <div
            *ngIf="userOrderInventory&&userOrderInventory[order.propertyId]&&userOrderInventory[order.propertyId].invData">


            <mat-list *ngFor="let c of userOrderInventory[order.propertyId].invData | keyvalue">

                <div mat-header>
                    <div>Shop: {{c.value.shopDetails.shopname}}, {{c.value.shopDetails.address_short}}</div>
                    <div>
                        <strong>{{getStatusText(order.status[c.key])}} {{order.status[c.key]}}</strong>
                    </div>
                    <div>
                        <button mat-raised-button class="mr-4" color="accent" type="button"
                            (click)="call(c.value.shopDetails.phone)">
                            Call Shop ({{c.value.shopDetails.phone}})
                        </button>
                    </div>
                </div>
                <div mat-subheader *ngIf="[0,1,3,4].includes(order.status[c.key])">

                    <span class="fill-remaining-space"></span>

                    <span *ngIf="order.status[c.key]==0">Order Status: Pending Payment</span>
                    <span *ngIf="order.status[c.key]==1">Order Status: Processing</span>
                    <span *ngIf="order.status[c.key]==3">Order Status: Ready</span>
                    <span *ngIf="order.status[c.key]==4">Order Status: Completed</span>

                </div>

                <mat-card
                    *ngIf="[2,21,22,23,4].includes(order.status[c.key])&&!order.ds && order.assignments&&allRiders[order.assignments[c.key].assigned]">
                    <div><span>Assigned: </span><span
                            style="font-weight: 500;">{{allRiders[order.assignments[c.key].assigned].name}}</span></div>
                    <div><span>Time: </span><span
                            style="font-weight: 500;">{{order.assignments[c.key].assignedTime.toDate()|
                            date:'medium'}}</span></div>

                    <div><button mat-raised-button class="mr-4" color="accent" type="button"
                            (click)="call(allRiders[order.assignments[c.key].assigned].phone)">
                            Call DP ({{allRiders[order.assignments[c.key].assigned].phone}})
                        </button><button mat-raised-button color="primary"
                            (click)="openRADialog(c.value.shopDetails.location.Latitude,c.value.shopDetails.location.Longitude, order.propertyId+'_'+c.key)">Re-assign?</button>
                    </div>
                </mat-card>
                <mat-card
                    *ngIf="[2,21,22,23,4].includes(order.status[c.key]) &&order.ds &&allRiders[order.ds[c.key].provider]">
                    <div><span>Delivery By: </span><span style="font-weight: 500;"
                            (click)="goToDPStats(order.ds[c.key].provider)">{{allRiders[order.ds[c.key].provider].name}}</span>
                    </div>
                    <div><span>Checked In: </span><span
                            style="font-weight: 500;">{{order.ds[c.key].checkedIn==2?'Yes':'No'}}</span></div>
                    <div><span>Picked Up: </span><span
                            style="font-weight: 500;">{{order.ds[c.key].pickedUp==2?'Yes':'No'}}</span></div>
                    <div><button mat-raised-button class="mr-4" color="accent" type="button"
                            (click)="call(allRiders[order.ds[c.key].provider].phone)">
                            Call DP ({{allRiders[order.ds[c.key].provider].phone}})
                        </button></div>

                </mat-card>


                <mat-list-item *ngFor="let i of c.value.items " class="mb-4">
                    <img matListAvatar src="{{i.image}}" alt="no-image" width="100" />
                    <div mat-line>{{i.Name}}</div>
                    <div mat-line>
                        <div class="d-flex justify-content-start text-wrap mat-caption"
                            *ngIf="i.customized && i.variationName && i.variationName.length>0">
                            {{i.variationName}}</div>
                        <div class="d-flex justify-content-start text-wrap mat-caption"
                            *ngIf="i.customized &&i.addonNames&& i.addonNames.length>0">
                            Addons:
                            {{i.addonNames}}</div>
                        <div class="d-flex justify-content-end">Qty: {{i.quantity}}</div>
                        <div class="d-inline-flex justify-content-end  w-100" *ngIf="i.Price&&!i.customized">
                            <span>
                                <span class="mat-caption">
                                    <span>Price: {{i.quantity}}
                                        X
                                        (&#8377;&nbsp;{{i.Price}}{{getSuffix(i.Price)}}</span><span
                                        *ngIf="i.Discount&&i.Discount>0">&nbsp;-&nbsp;&#8377;&nbsp;{{i.Discount}}{{getSuffix(i.Discount)}}
                                        discount</span><span>) =</span>
                                </span>
                                <span>
                                    &#8377;&nbsp;{{i.quantity*(i.Price-i.Discount)}}{{getSuffix(i.quantity*(i.Price-i.Discount))}}</span>
                            </span>
                        </div>
                        <div class="d-inline-flex justify-content-end  w-100"
                            *ngIf="i.Price&&i.customized&&i.customPrice">
                            <span>
                                <span class="mat-caption">
                                    <span>Price: {{i.quantity}}
                                        X
                                        (&#8377;&nbsp;{{i.customPrice}}{{getSuffix(i.customPrice)}}</span><span
                                        *ngIf="i.Discount&&i.Discount>0">&nbsp;-&nbsp;&#8377;&nbsp;{{i.Discount}}{{getSuffix(i.Discount)}}
                                        discount</span><span>) =</span>
                                </span>
                                <span>
                                    &#8377;&nbsp;{{i.quantity*(i.customPrice-i.Discount)}}{{getSuffix(i.quantity*(i.customPrice-i.Discount))}}</span>
                            </span>
                        </div>
                    </div>
                    <div mat-line>
                        <!-- <span>Qty: {{i.quantity}}</span> -->
                    </div>
                </mat-list-item>

            </mat-list>
        </div>
    </mat-expansion-panel>

</mat-accordion>