import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { RestService } from '../services/rest.service';
import { starter_cities_with_others } from '../_models/cities-states';
import * as _ from 'lodash';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.css']
})
export class SendNotificationComponent implements OnInit {

  readonly locStgName = 'location'


  title = "";
  message = "";
  userUID: string = "";
  pointed = false;
  locationSpecific = false;
  shopSpecific = false;
  productSpecific = false;
  searchPointed = false;
  searchTerm = "";

  city_list: any[] = starter_cities_with_others;
  regionSelected: any = {};
  shopsData: any;
  selectedShops: any[] = [];
  selectedProducts: string[] = [];
  selectedProductsByShop: any = {};



  constructor(private rest: RestService, private route: ActivatedRoute, private fireRest: FirebaseRestService) {
    if (localStorage.getItem('selected' + this.locStgName)) {
      this.regionSelected = JSON.parse(localStorage.getItem('selected' + this.locStgName));
    }
    route.params.subscribe((params) => {
      if (params.userId) {
        this.userUID = params.userId;
      }
    })

    this.fireRest.getActiveShops().subscribe((data) => {
      this.shopsData = _.orderBy(data.map(ele => ele.data()).filter(s => s.shopActive), ['shopCategory', 'shopname'], ['desc', 'asc'])
      console.log(this.shopsData);

      // const gshops = this.shopsData.filter(ele => (ele.address_city == "Guntur"));
      // // gshops.forEach(element => console.log(element));
      // console.log(gshops);

    })

  }

  ngOnInit(): void {
  }

  sendNotification() {
    const payload: any = {};
    payload["p"] = this.pointed;
    payload["ls"] = this.locationSpecific;
    payload["ss"] = this.shopSpecific;
    payload["ps"] = this.productSpecific;
    payload["sp"] = this.searchPointed;
    if (this.pointed) {
      if (this.locationSpecific) {
        payload["rsel"] = this.regionSelected;
        if (this.shopSpecific) {
          payload["ssel"] = this.selectedShops.map(s => s.id);
          if (this.productSpecific) {
            payload["psel"] = this.selectedProducts;
          }
        }
      }
      if (!this.productSpecific && this.searchPointed) {
        payload["sk"] = this.searchTerm;
      }
    }


    // console.log(payload);

    // return;
    if (this.userUID != "") {
      this.rest.sendNotification([this.userUID], this.title, this.message, btoa(JSON.stringify(payload))).subscribe((data) => {
        console.log(data);

      })
    }
  }


  handleSelectionChanges(e) {
    this.selectedProductsByShop[e.sId] = e.l;
    this.selectedProducts = [];
    Object.values(this.selectedProductsByShop).forEach((_l: any[]) => {
      this.selectedProducts = this.selectedProducts.concat(_l);
    })


  }


  setCity(e) {
    console.log(e);
    this.regionSelected = e.value;
    if (this.regionSelected.city != "Others")
      localStorage.setItem('selected' + this.locStgName, JSON.stringify(this.regionSelected));
  }

}
