<!-- <div id="smgmap" class="w-100" style="height: 50vh;"></div> -->

<mat-radio-group aria-label="Select a Region" (change)="setCity($event)">
    <mat-radio-button *ngFor="let c of city_list" [checked]="regionSelected.city==c.city" [value]="c">
        {{c.city}}
    </mat-radio-button>
</mat-radio-group>
<div class="carousel row mx-1 cat-bar-desktop">
    <div *ngFor="let n of shopsData" class="slide m-1 p-0 col-auto">
        <mat-card class="shopCard"
            [hidden]="regionSelected.city!=(n.address_city=='Chirala'||n.address_city=='Bapatla'||n.address_city=='Guntur'?n.address_city:'Others')">

            <img mat-card-image *ngIf="n.hasLogo"
                src="https://firebasestorage.googleapis.com/v0/b/shopnearby-301e7-product-image-bucket/o/shop_images%2F{{n.id}}%2Flogo.webp?alt=media"
                class="p-auto" alt="media">
            <mat-card-content style="text-align: center;">
                <div class="mat-caption w-auto mx-auto">{{n.shopname}}</div>
                <div class="mat-caption w-auto mx-auto">{{n.address_short}}</div>
                <div class="mat-caption w-auto mx-auto" *ngIf="n.shopActive">Active</div>
                <div class="mat-caption w-auto mx-auto" *ngIf="n.comingSoon==false">Launched</div>
                <div class="mat-caption w-auto mx-auto" *ngIf="n.reg_referral">Added by:
                    {{n.reg_referral}}</div>
                <div class="mat-caption w-auto mx-auto" *ngIf="n.createdTime">Created on:
                    {{n.createdTime.toDate()|
                    date:'medium'}}</div>
            </mat-card-content>
            <mat-card-actions>

                <div class="btn-group w-100">
                    <button type="button" class="btn btn-default dropdown-toggle w-100" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        Action
                    </button>
                    <div class="dropdown-menu">
                        <button class="dropdown-item" (click)="printShopQr(n.id)">QR</button>
                        <button class="dropdown-item" (click)="editShop(n.ownerId,n.id)">Edit</button>
                        <button class="dropdown-item" (click)="editShopMenu(n.ownerId,n.id)">Edit Menu</button>
                        <button class="dropdown-item" (click)="managePrices(n.ownerId,n.id)">Manage Prices</button>
                        <button class="dropdown-item" (click)="manageTaxes(n.ownerId,n.id)">Manage Taxes</button>
                        <button class="dropdown-item" (click)="linkPos(n.ownerId,n.id)">POS</button>
                        <button class="dropdown-item" (click)="downloadMenu(n.ownerId,n.id)">Download Full
                            List</button>
                        <button class="dropdown-item" (click)="downloadMenu(n.ownerId,n.id,true)">Download
                            Price List</button>
                        <button class="dropdown-item" (click)="refresh(n.ownerId,n.id)">Refresh</button>
                        <button class="dropdown-item" (click)="getToken(n.ownerId)">Get Login Link</button>
                    </div>
                </div>



                <!-- <button (click)="printShopQr(n.id)" mat-raised-button>QR</button>
                <button (click)="editShop(n.ownerId,n.id)" mat-raised-button>Edit</button>
                <button (click)="linkPos(n.ownerId,n.id)" mat-raised-button>POS</button>
                <button (click)="refresh(n.ownerId,n.id)" mat-raised-button>Refresh</button> -->
            </mat-card-actions>
        </mat-card>
    </div>

</div>