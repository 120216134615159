import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { TitleCasePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { GetChildernPipe } from '../pipes/get-childern.pipe';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { RestService } from '../services/rest.service';
import { RefProductStatus } from '../_models/models';

@Component({
  selector: 'app-ref-product-edit',
  templateUrl: './ref-product-edit.component.html',
  styleUrls: ['./ref-product-edit.component.css']
})
export class RefProductEditComponent implements OnInit {

  @Input() refProductId = "";
  @Output() changed: any = new EventEmitter<any>(false);


  editProductForm: UntypedFormGroup;
  l1categories: any = [];
  l2categories: any = [];
  l3categories: any = [];

  l1selected: any = [];
  l2selected: any = [];
  l3selected: any = [];
  formdetails: any = [];
  breadCrumb = "";
  parentId = 0;
  formParentName = "";
  inventoryId = "";
  closeResult = '';
  currentImage = '';
  currentImageIndex = -1;
  deliveryEligible = true;
  catPathFull: any = [];
  catPathNames: any = [];
  priceChangesEvery = "0";
  allCategories: any = [];
  getRelatedCalled = false;
  productDetails: any = {};

  // codeReader=new BrowserMultiFormatReader();

  public captures: Array<any>;
  public capturesForUpload: Array<any>;
  categoryId: any = "";
  // shopDetails: any = {};
  dxComment = "";
  comment = "";
  origImages: any = {};



  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new UntypedFormControl();
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private rest: RestService, private route: ActivatedRoute, private router: Router, private formBuilder: UntypedFormBuilder, private modalService: NgbModal, public _snackBar: MatSnackBar, public fireRest: FirebaseRestService, private getChildern: GetChildernPipe, private titlecasePipe: TitleCasePipe) {
    // this.route.params.subscribe(params => {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));

    // });
  }

  ngOnInit() {
    if (this.refProductId && this.refProductId.length > 0) {
      this.inventoryId = this.refProductId;
      this.fetch(this.inventoryId);
    }
    // this.getRefProductCloud();
  }


  private fetch(invId: string) {
    this.captures = [];
    this.capturesForUpload = [];
    this.l1categories = [];
    this.l2categories = [];
    this.l3categories = [];

    this.l1selected = [];
    this.l2selected = [];
    this.l3selected = [];
    this.formdetails = [];
    this.breadCrumb = "";
    this.parentId = 0;
    this.formParentName = "";
    this.inventoryId = (invId && invId.length > 0) ? invId : "";
    this.closeResult = '';
    this.currentImage = '';
    this.currentImageIndex = -1;
    this.deliveryEligible = true;
    this.catPathFull = [];
    this.catPathNames = [];
    this.priceChangesEvery = "0";
    this.categoryId = "";
    // this.shopDetails = {};
    this.dxComment = "";
    this.comment = "";
    this.getRefProduct(invId);
    this.editProductForm = this.formBuilder.group({});
  }

  // public capture(webcamImage: WebcamImage) {
  //   if (this.captures.length < 5) {
  //     this.captures.push(webcamImage.imageAsDataUrl);
  //     this.capturesForUpload.push(webcamImage.imageAsBase64);
  //     // // console.log(this.canvas.nativeElement.toDataURL("image/png").replace(/^data:image.+;base64,/, ''));
  //   } else {
  //     alert('only 5 photos allowed');
  //   }
  // }

  openPhoto(content, capture, index) {
    // this.codeReader.decodeFromImageUrl(capture).then((data)=>{
    //   // console.log(data);
    // }).catch((err)=>{
    //   // console.log(err);
    // });
    this.currentImage = capture;
    this.currentImageIndex = index;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.deleteCapture(result);
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  deleteCapture(id) {
    this.captures.splice(id, 1);
    this.capturesForUpload.splice(id, 1);
  }


  onSelect(e) {
    // console.log(e.addedFiles);
    const reader = new FileReader();
    reader.onload = (event) => {
      // console.log(event);
      this.captures.push(event.target.result);
    }
    reader.readAsDataURL(e.addedFiles[0]);
  }

  addFromRelated(imgData) {
    this.captures.push(imgData);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.captures, event.previousIndex, event.currentIndex);
  }


  addedFiles(e) {
    // console.log(e);
  }

  getRelated() {
    this.getRelatedCalled = true;
    this.fireRest.getRefKids(this.inventoryId).subscribe((data) => {
      if (data && data.docs && data.docs.length > 0) {

        data.docs.forEach((doc) => {
          this.fireRest.getProductImagesAsBaseBlob(doc.id).subscribe((imgList: any[]) => {
            let iList = [];
            imgList.forEach((img) => {
              var reader = new FileReader();
              reader.readAsDataURL(img);
              reader.onloadend = (event) => {
                // // console.log(event);
                var base64data = reader.result;
                iList.push(base64data);
              }
            })

            this.origImages[doc.id] = iList;
          });
        });
      }


    })

    if (this.inventoryId.indexOf("reference") === 0) {
      this.fireRest.getProductImagesAsBaseBlob(this.inventoryId.split("_")[1]).subscribe((imgList: any[]) => {
        let iList = [];
        imgList.forEach((img) => {
          var reader = new FileReader();
          reader.readAsDataURL(img);
          reader.onloadend = (event) => {
            // // console.log(event);
            var base64data = reader.result;
            iList.push(base64data);
          }

        })

        this.origImages[this.inventoryId.split("_")[1]] = iList;
        console.log(this.origImages);

      });
    }

  }



  getRefProduct(invId: string) {
    this.fireRest.getAllCategories().subscribe((allCat) => {
      if (allCat.length > 0) {
        // // console.log(allCat);
        this.allCategories = allCat;
        this.fireRest.getRefProduct((invId && invId.length > 0) ? invId : null).subscribe((product: any) => {
          if (Object.keys(product).length > 0) {

            this.productDetails = product;
            this.categoryId = this.productDetails.get('categoryId');
            if (this.productDetails.get('metaData')) {
              this.deliveryEligible = this.productDetails.get('metaData').deliveryEligible ? this.productDetails.get('metaData').deliveryEligible : true;
              this.priceChangesEvery = this.productDetails.get('metaData').priceChangesEvery ? this.productDetails.get('metaData').priceChangesEvery : "0";
            }

            let cpf = this.productDetails.get('catPathFull').reverse();
            // this.shopDetails = this.productDetails.get('shopDetails');
            this.dxComment = this.productDetails.get('DXDeclineComment') ? this.productDetails.get('DXDeclineComment') : "";
            this.comment = this.productDetails.get('DEComment') ? this.productDetails.get('DEComment') : "";

            this.tags = this.productDetails.get('Tags') ? this.productDetails.get('Tags') : [];
            // // console.log(cpf);
            for (let i = 0; i < cpf.length; i++) {
              // // console.log(cpf[i]);
              if (i == 1) {
                this.getCategoriesByParent(cpf[i].parentId, "l1", cpf[i].id);
              }
              if (i == 2) {
                this.getCategoriesByParent(cpf[i].parentId, "l2", cpf[i].id);
              }
              if (i == 3) {
                this.getCategoriesByParent(cpf[i].parentId, "l3", cpf[i].id);
              }
              if (i == cpf.length - 1) {
                this.getFormDetailsByCategory(cpf[i].id, this.productDetails.get('details'));
              }
            }

            this.inventoryId = this.productDetails.id;

            this.fireRest.getRefProductImagesAsBaseBlob(this.productDetails.id).subscribe((imgList: any[]) => {
              // this.captures=imgList;
              // console.log(imgList);
              let iList = [];
              imgList.forEach((img) => {
                var reader = new FileReader();
                reader.readAsDataURL(img);
                reader.onloadend = (event) => {
                  // // console.log(event);
                  var base64data = reader.result;
                  iList.push(base64data);
                }

              })
              this.captures = iList;

            });

            // console.log(this.inventoryId);
            // console.log(this.inventoryId.split('_')[1]);








            // this.getFormDetailsByCategory(this.productDetails.get('categoryId'), this.productDetails.get('details'));

          }
        });
      }
    }
    );
  }

  getCategoriesByParent(parent, level: String, selected?: string) {
    this.formdetails = [];
    if (parent != "") {
      let data = this.getChildern.transform(this.allCategories, parent, 'id');
      if (level == "l1") {
        this.l1categories = data;
        if (selected) {
          this.l1selected = selected;
        }
        this.l2categories = [];
        this.l3categories = [];
      } else if (level == "l2") {
        this.l2categories = data;
        if (selected) {
          this.l2selected = selected;
        }
        this.l3categories = [];
      } else if (level == "l3") {
        if (data && data.length > 0) {
          this.l3categories = data;
          if (selected) {
            this.l3selected = selected;
          }
        } else {
          this.getFormDetailsByCategory(parent, this.productDetails.get('details'));
        }
      } else if (level == "l4") {
        this.getFormDetailsByCategory(parent, this.productDetails.get('details'));
      }
    }
  }


  getFormDetailsByCategory(id, productDetails) {
    //*_*_*_*need to change this to getbycatid
    // // console.log(id);
    // // console.log(productDetails);
    this.fireRest.getFormByCategoryId(id).subscribe((fieldData) => {
      // // console.log(fieldData);
      if (Object.keys(fieldData).length > 0) {

        this.categoryId = id;

        // fieldData = fieldData.filter(item => (exclude.indexOf(item.docId) === -1));

        this.fireRest.getIal().subscribe((allIALS) => {
          if (allIALS.length > 0) {
            for (let f in fieldData) {
              // f.docId
              // // console.log(fieldData[f]);
              // // console.log(allIALS);
              const curIAL = allIALS.find(ele => ele.ialId == fieldData[f].docId);
              // // console.log(curIAL);
              fieldData[f].cl = curIAL.cl;
              fieldData[f].l = curIAL.l;
              fieldData[f].d = curIAL.d;
              fieldData[f].v = productDetails[f];
              if (curIAL.dm) {
                fieldData[f].dm = curIAL.dm;
              }
              fieldData[f].rfd = curIAL.rfd ? true : false;

              if (curIAL.ref_values) {
                fieldData[f].ref_values = curIAL.ref_values;
              }

              this.editProductForm.addControl(f, new UntypedFormControl(productDetails[f]));
              this.formdetails.push(fieldData[f])

            }
            this.formdetails = this.formdetails.sort((a, b) => a.order - b.order);
          }

        });
      }
    });
    // this.formParentName = name;
  }


  searchGoogle(keyword) {
    if (keyword.length > 3)
      window.open('https://www.google.com/search?tbm=isch&q=' + keyword, '_blank');
    // this.iframeUrl = 'https://www.google.com/search?q=' + keyword;
  }

  call(link) {
    window.open(link, '_self');
  }

  onSubmit(formData, status: RefProductStatus) {

    this.editProductForm.updateValueAndValidity();
    if (status === RefProductStatus.Trash || status === RefProductStatus.Draft || this.editProductForm.valid) {
      const metaData: any = {};
      metaData['deliveryEligible'] = this.deliveryEligible;
      metaData['priceChangesEvery'] = this.priceChangesEvery;
      this.rest.updateRefProduct(formData, this.categoryId, metaData, this.captures.length, this.inventoryId, status, this.tags).subscribe((data) => {
        if (data.status == "success") {
          console.log(data);
          this.fireRest.uploadRefProductImages(this.inventoryId, this.captures).subscribe((upload) => {
            // // console.log(upload);
            if (upload.status == "done") {

              this.changed.emit(true);

              // if (goNext) {
              //   this.fetch();
              // } else {
              //   this.router.navigate(['/']);
              // }
              // window.location.reload();
              // this.router.navigate(['/shop/product-details/' + data.resp.productId]);
            }
          })


        }
      })
    } else {
      this._snackBar.open("Please Fille the required fields", "Okay", {
        duration: 2000,
      });


    }


  }

  getObjLength(obj: any) {
    let valLen = 0;
    for (const o in obj) {
      valLen = valLen + obj[o].length;
    }
    return valLen;
  }



  //code for autofill chips

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      this.tags.push(this.titlecasePipe.transform(value.trim()));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

}
