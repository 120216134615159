import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseRestService } from '../services/firebase-rest.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-dp-stats',
  templateUrl: './dp-stats.component.html',
  styleUrls: ['./dp-stats.component.css']
})
export class DpStatsComponent implements OnInit {
  grouped: any = {};
  constructor(private fireRest: FirebaseRestService, private route: ActivatedRoute) {
    route.params.subscribe((params) => {
      console.log(params);

      fireRest.getCompletedRequestsForRider(params.dpId).subscribe((data) => {
        // console.log(data);
        if (!data.empty) {
          const srs = data.docs.map(ele => ele.data());

          srs.reverse().forEach((sr: any) => {
            const dt = sr.createdTime.toDate();
            const dtStr = dt.getDate() + "-" + (dt.getMonth() + 1) + "-" + dt.getFullYear()
            if (!this.grouped[dtStr]) {
              this.grouped[dtStr] = {}
            }
            if (!this.grouped[dtStr][sr.orderId]) {
              this.grouped[dtStr][sr.orderId] = [];
            }
            this.grouped[dtStr][sr.orderId].push(sr);
          })
          console.log(this.grouped);

        }

      })
    })


  }

  ngOnInit(): void {
  }

}
