<form [formGroup]="addIALForm" (ngSubmit)="add_ial()">


    <mat-form-field>
        <input formControlName="label" matInput placeholder="Shop Label">
    </mat-form-field>
    <br>
    <mat-form-field>
        <input formControlName="clientLabel" matInput placeholder="Client Label">
    </mat-form-field>
    <br>
    <mat-form-field>
        <mat-select formControlName="dataType" (selectionChange)="setIsList($event.value)">
            <mat-option value="PlainString">PlainString</mat-option>
            <mat-option value="LargeText">LargeText</mat-option>
            <mat-option value="EncryptedString">EncryptedString</mat-option>
            <mat-option value="Email">Email</mat-option>
            <mat-option value="Number">Number</mat-option>
            <mat-option value="Currency">Currency</mat-option>
            <mat-option value="Fraction">Fraction</mat-option>
            <mat-option value="Picture">Picture</mat-option>
            <mat-option value="Video">Video</mat-option>
            <mat-option value="HTML">HTML</mat-option>
            <mat-option value="URL">URL</mat-option>
            <mat-option value="Date">Date</mat-option>
            <mat-option value="List">List</mat-option>
            <mat-option value="Multi">Multi</mat-option>
        </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field>
        <mat-select formControlName="dm">
            <mat-option value="detail">detail</mat-option>
            <mat-option value="meta-detail">meta-detail</mat-option>
            <mat-option value="large-detail">large-detail</mat-option>
            <mat-option value="masked">masked</mat-option>
            <mat-option value="meta">meta</mat-option>
            
        </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field [hidden]="!isList">
        <textarea formControlName="refValues" matInput placeholder="Reference Values"></textarea>
    </mat-form-field>
    <br>
    <mat-checkbox formControlName="isActive">Enabled</mat-checkbox>
    <br>
    <mat-checkbox formControlName="rfd">Required for Delivery</mat-checkbox>
    <br>
    <button mat-raised-button type="submit">Add Field</button>
</form>