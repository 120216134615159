export enum OrderStatus {
    YetToConfirm = 0,
    Processing = 1,
    Active = 2,
    Ready = 3,
    Completed = 4,
    Cancelled = 5,
    ShopViewed = 21,
    ShopPackaged = 22,
    PickedForDelivery =23,
    PaidReadyForPickup =26,

}
