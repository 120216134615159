<p *ngIf="dxComment.length>0" class="m-4">Data Expert Comment: <strong class="text-danger">{{dxComment}}</strong></p>

<p *ngIf="lastPriceUpdate&&lastPriceUpdate.updateBy">
    Price last Updated by <strong>{{lastPriceUpdate.updateBy}}</strong>
</p>


<mat-form-field class="w-100">
    <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"
        [(ngModel)]="comment" matInput placeholder="Comment"></textarea>
</mat-form-field>


<form [formGroup]="editProductForm" class="needs-validation">

    <div *ngIf="l1categories && l1categories.length>0" class="no-form-group">
        <mat-form-field>
            <mat-label for="sel1">Select One:</mat-label>
            <mat-select id="sel1" (selectionChange)="getCategoriesByParent($event.value,'l2')" [(value)]="l1selected">
                <!-- <mat-option value="0">Choose</mat-option>  -->
                <mat-option *ngFor="let p of l1categories; let i=index;" value="{{p.id}}">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="l2categories && l2categories.length>0" class="no-form-group">
        <mat-form-field>
            <mat-label for="sel2">Select One:</mat-label>
            <mat-select id="sel2" (selectionChange)="getCategoriesByParent($event.value,'l3')" [(value)]="l2selected">
                <!-- <mat-option value="0">Choose</mat-option>  -->
                <mat-option *ngFor="let p of l2categories; let i=index;" value="{{p.id}}">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="l3categories && l3categories.length>0" class="no-form-group">
        <mat-form-field>
            <mat-label for="sel3">Select One:</mat-label>
            <mat-select id="sel3" (selectionChange)="getCategoriesByParent($event.value,'l4')" [(value)]="l3selected">
                <!-- <mat-option value="0">Choose</mat-option>     -->
                <mat-option *ngFor="let p of l3categories; let i=index;" value="{{p.id}}">{{p.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <ngx-dropzone class="w-100" [multiple]="false" (addedfiles)="addedFiles($event)" (change)="onSelect($event)">
        <ngx-dropzone-label>Drop the image</ngx-dropzone-label>
    </ngx-dropzone>
    <ul class="row imglist m-0" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
        <li cdkDrag *ngFor="let c of captures; let i = index" class="imgli col-auto w-auto">
            <img src="{{ c }}" (click)="openPhoto(photoContent,c,i)" />
            <img *cdkDragPreview width="200" src="{{ c }}" />
        </li>
    </ul>
    <ng-template #photoContent let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Photo</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <img src="{{ currentImage }}" width="100%" />
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-outline-dark"
                (click)="modal.close(currentImageIndex)">Delete</button>
        </div>
    </ng-template>

    <div id="dynaform" *ngFor="let p of formdetails; let i=index;">

        <div class="no-form-group" *ngIf="p.d=='PlainString'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <input [formControlName]="p.docId" type="text" matInput class="mat-input-element" id="{{p.docId}}"
                    [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
                <button *ngIf="p.v&&p.v.length>3" type="button" mat-icon-button matSuffix (click)="searchGoogle(p.v)">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='LargeText'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <textarea cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5" [formControlName]="p.docId" matInput class="mat-input-element"
                    id="{{p.docId}}" [required]="p.required==1||(deliveryEligible&&p.rfd==true)"></textarea>
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='URL'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <input [formControlName]="p.docId" type="url" matInput class="mat-input-element" id="{{p.docId}}"
                    [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='Email'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <input [formControlName]="p.docId" type="email" matInput class="mat-input-element" id="{{p.docId}}"
                    [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='Date'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <input [formControlName]="p.docId" type="date" matInput class="mat-input-element" id="{{p.docId}}"
                    [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='Number'||p.d=='Fraction'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <input [formControlName]="p.docId" type="number" matInput class="mat-input-element" id="{{p.docId}}"
                    [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='Currency'">
            <mat-form-field appearance="standard">
                <mat-label>{{p.l}}</mat-label>
                <input [formControlName]="p.docId" type="number" matInput class="mat-input-element" id="{{p.docId}}"
                    [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
                <span matPrefix>&#8377;&nbsp;</span>
                <span matSuffix>.00</span>
            </mat-form-field>
        </div>


        <div class="no-form-group" *ngIf="p.d=='List'">
            <mat-form-field>
                <mat-label>{{p.l}}</mat-label>
                <mat-select [formControlName]="p.docId" id="{{p.docId}}"
                    [required]="p.required==1||(deliveryEligible&&p.rfd==true)">
                    <mat-option *ngFor="let v of p.ref_values; let i=index;" value="{{v}}">{{v}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="no-form-group" *ngIf="p.d=='Multi'">
            <mat-form-field>
                <mat-label>{{p.l}}</mat-label>
                <mat-select [formControlName]="p.docId" id="{{p.docId}}"
                    [required]="p.required==1||(deliveryEligible&&p.rfd==true)" multiple>
                    <mat-option *ngFor="let v of p.ref_values; let i=index;" value="{{v}}">{{v}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>


    </div>




</form>

<div class="my-2">

    <mat-form-field class="example-chip-list">
        <mat-chip-list #chipList aria-label="Fruit selection">
            <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                (removed)="remove(tag)">
                {{tag}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="New tag..." #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                {{tag}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>


    <div *ngIf="formdetails && formdetails.length>0">
        <mat-slide-toggle [(ngModel)]="addVrnts">Has Variants</mat-slide-toggle>
        <div *ngIf="addVrnts">
            <mat-card *ngFor="let v of variants;let i=index">
                <mat-card-title>
                    {{v.groupName}}
                </mat-card-title>
                <button mat-button color="accent" (click)="removeGroup(i)">
                    X
                </button>
                <mat-form-field appearance="outline">
                    <mat-label>Group Name</mat-label>
                    <input matInput [(ngModel)]="v.groupName">
                </mat-form-field>

                <div *ngFor="let k of v.items;let j=index">
                    <mat-card class="m-1">
                        <button mat-button color="accent" (click)="removeItem(i,j)">
                            X
                        </button>
                        <mat-form-field appearance="outline">
                            <mat-label>Name</mat-label>
                            <input matInput [(ngModel)]="k.vrntname">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Price</mat-label>
                            <input matInput type="number" [(ngModel)]="k.price">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Discount</mat-label>
                            <input matInput type="number" [(ngModel)]="k.discount">
                        </mat-form-field>
                        <mat-slide-toggle [(ngModel)]="k.hasAddons">Has Addons</mat-slide-toggle>

                        <div *ngIf="k.hasAddons">
                            <mat-card class="m-1" *ngIf="k.addongroups.length>0">
                                <mat-card-title>
                                    Addons
                                </mat-card-title>
                                <div *ngFor="let ag of k.addongroups;let l=index">

                                    <mat-form-field appearance="outline">
                                        <mat-label>Group Name</mat-label>
                                        <input matInput [(ngModel)]="ag.groupName">
                                    </mat-form-field>
                                    <div class="row">
                                        <div class="col">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Max Count</mat-label>
                                                <input matInput type="number" [(ngModel)]="ag.maxSelection">
                                            </mat-form-field>
                                        </div>
                                        <div class="col">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Min Count</mat-label>
                                                <input matInput type="number" [(ngModel)]="ag.minSelection">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div cdkDropList class="example-list" (cdkDropListDropped)="vadndrop($event,i,j,l)">
                                        <mat-card cdkDrag class="m-1" *ngFor="let a of ag.addons;let m=index">
                                            <div>{{a.adnname}}, {{a.addedPrice}}</div>
                                        </mat-card>
                                    </div>
                                    <button *ngIf="!ag.vshowAdnForm" mat-button color="accent"
                                        (click)="ag.vshowAdnForm=true">+ New Addon
                                        Item</button>
                                    <div *ngIf="ag.vshowAdnForm" class="mt-2">

                                        <div style="max-height: 100px;overflow-y: scroll;overflow-x: hidden;">
                                            <mat-chip-list class="example-chip">
                                                <mat-chip (click)="addAddonToVariant(i,j,l,ra)"
                                                    *ngFor="let ra of filteredremoteAdonItems">
                                                    {{ra.adnname+' - &#8377;'+ra.addedPrice}}
                                                </mat-chip>
                                            </mat-chip-list>
                                        </div>

                                        <mat-form-field appearance="outline">
                                            <mat-label>Name</mat-label>
                                            <input matInput (keyup)="filterRemoteAddons($event)"
                                                [(ngModel)]="currentAddonDetails.adnname">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Added Price</mat-label>
                                            <input matInput type="number" [(ngModel)]="currentAddonDetails.addedPrice">
                                        </mat-form-field>
                                        <mat-form-field *ngIf="isFoodItem&&foodTypeField&&foodTypeField.ref_values"
                                            appearance="outline">
                                            <mat-label>Food Type</mat-label>
                                            <mat-select [(ngModel)]="currentAddonDetails.foodType">
                                                <mat-option *ngFor="let food of foodTypeField.ref_values"
                                                    [value]="food">
                                                    {{food}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <button mat-raised-button color="accent"
                                            (click)="addAddonToVariant(i,j,l,currentAddonDetails);ag.vshowAdnForm=false">Add</button>
                                    </div>
                                    <!-- <button mat-raised-button color="accent" (click)="addAddonToVariant(i,j,l)">+ Addon Item</button> -->
                                </div>

                            </mat-card>
                            <button mat-raised-button color="accent" class="mt-2"
                                (click)="addAddonGroupToVariant(i,j)">+ Addon
                                Group</button>
                        </div>
                    </mat-card>
                </div>
                <button mat-raised-button color="accent" (click)="addVariant(i)">+ Variant</button>

            </mat-card>
            <button mat-raised-button color="accent" class="mt-2" *ngIf="variants.length==0" (click)="addVariant()">+
                Variants
                Group</button>
        </div>
        <div *ngIf="!addVrnts">
            <mat-slide-toggle [(ngModel)]="addAdns">Has Addons</mat-slide-toggle>
            <div *ngIf="addAdns">
                <mat-card class="m-1" *ngIf="addons.length>0">
                    <mat-card-title>
                        Addons
                    </mat-card-title>
                    <div *ngFor="let ag of addons;let l=index">

                        <mat-form-field appearance="outline">
                            <mat-label>Group Name</mat-label>
                            <input matInput [(ngModel)]="ag.groupName">
                        </mat-form-field>
                        <div class="row">
                            <div class="col">
                                <mat-form-field appearance="outline">
                                    <mat-label>Max Count</mat-label>
                                    <input matInput type="number" [(ngModel)]="ag.maxSelection">
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="outline">
                                    <mat-label>Min Count</mat-label>
                                    <input matInput type="number" [(ngModel)]="ag.minSelection">
                                </mat-form-field>
                            </div>
                        </div>
                        <div cdkDropList class="example-list" (cdkDropListDropped)="adndrop($event,l)">
                            <mat-card cdkDrag class="m-1" *ngFor="let a of ag.addons;let m=index">
                                <div>{{a.adnname}}, {{a.addedPrice}}</div>
                            </mat-card>
                        </div>
                        <button *ngIf="!showAdnForm" mat-button color="accent" (click)="showAdnForm=true">+ New Addon
                            Item</button>
                        <div *ngIf="showAdnForm" class="mt-2">

                            <div style="max-height: 100px;overflow-y: scroll;overflow-x: hidden;">
                                <mat-chip-list class="example-chip">
                                    <mat-chip (click)="addAddon(l,ra)" *ngFor="let ra of filteredremoteAdonItems">
                                        {{ra.adnname+' - &#8377;'+ra.addedPrice}}
                                    </mat-chip>
                                </mat-chip-list>
                            </div>

                            <mat-form-field appearance="outline">
                                <mat-label>Name</mat-label>
                                <input matInput (keyup)="filterRemoteAddons($event)"
                                    [(ngModel)]="currentAddonDetails.adnname">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Added Price</mat-label>
                                <input matInput type="number" [(ngModel)]="currentAddonDetails.addedPrice">
                            </mat-form-field>
                            <mat-form-field *ngIf="isFoodItem&&foodTypeField&&foodTypeField.ref_values"
                                appearance="outline">
                                <mat-label>Food Type</mat-label>
                                <mat-select [(ngModel)]="currentAddonDetails.foodType">
                                    <mat-option *ngFor="let food of foodTypeField.ref_values" [value]="food">
                                        {{food}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-raised-button color="accent"
                                (click)="addAddon(l,currentAddonDetails)">Add</button>
                        </div>
                    </div>

                </mat-card>
                <button mat-raised-button color="accent" class="mt-2" (click)="addAddonGroup()">+ Addon Group</button>
            </div>
        </div>
    </div>


    <mat-checkbox *ngIf="formdetails && formdetails.length>0" [(ngModel)]="deliveryEligible">Eligible for Delivery
    </mat-checkbox>
    <mat-form-field *ngIf="formdetails && formdetails.length>0 && deliveryEligible">
        <mat-label>Price Changes</mat-label>
        <mat-select [(ngModel)]="priceChangesEvery">
            <mat-option value="0">Never</mat-option>
            <mat-option value="1">Every Day</mat-option>
            <mat-option value="2">Every Two Days</mat-option>
            <mat-option value="7">Every Week</mat-option>
            <mat-option value="30">Every Month</mat-option>
            <mat-option value="365">Every Year</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<table>
    <tbody>
        <tr>
            <td colspan="2" class="text-center"><strong>Shop Details</strong></td>

        </tr>
        <tr>
            <td>Shop Name</td>
            <td>{{shopDetails.shopname}}</td>
        </tr>
        <tr>
            <td>Shop Phone</td>
            <td><button color="accent" type="button" (click)="call('tel:'+shopDetails.phone)" mat-raised-button>
                    Call
                </button></td>
        </tr>
        <tr>
            <td>Shop Address</td>
            <td>{{shopDetails.address}}</td>
        </tr>
    </tbody>
</table>

<button mat-raised-button class="m-2" color="primary" *ngIf="formdetails && formdetails.length>0" type="button"
    (click)="onSubmit(editProductForm.value,false)">Submit</button>


<button mat-raised-button class="m-2" color="primary" *ngIf="formdetails && formdetails.length>0&&!embeeded"
    type="button" (click)="onSubmit(editProductForm.value)">Submit & Go To Next Item</button>

<button mat-raised-button class="m-2" color="warn" *ngIf="formdetails && formdetails.length>0&&!showDeclineConfirm"
    type="button" (click)="showDeclineConfirm=true;">Decline</button>

<div *ngIf="showDeclineConfirm">
    <button mat-raised-button class="m-2" color="warn" *ngIf="formdetails && formdetails.length>0" type="button"
        (click)="onSubmit(editProductForm.value,false,true)">Proceed with Decline</button>
    <button mat-raised-button class="m-2" color="warn" *ngIf="formdetails && formdetails.length>0" type="button"
        (click)="deleteProduct(inventoryId,false)">Delete</button>
    <button mat-raised-button class="m-2" color="primary" *ngIf="formdetails && formdetails.length>0" type="button"
        (click)="showDeclineConfirm=false">Go Back</button>
</div>

<button mat-raised-button class="m-2" color="warn" *ngIf="formdetails && formdetails.length>0&&!embeeded" type="button"
    (click)="onSubmit(editProductForm.value,true,true)">Decline & Go To Next Item</button>