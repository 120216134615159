<mat-card class="m-2" *ngFor="let c of cartData">
    <mat-card-header>
        <mat-card-title>{{c.userDetails.name}}</mat-card-title>
        <mat-card-subtitle>
            <div>Cart Updated on: {{c.lastUpdated.toDate() | date:'medium'}}</div>
            <div>User Since: {{c.userDetails.userSince.toDate()| date:'medium'}}</div>
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>

        <mat-card class="p-1 m-1" *ngFor="let i of c.Meta|keyvalue">
            <mat-card-header>
                <div class="mat-body-1" style="font-weight: bold;"><span>{{c.Inventory[i.key]}} X
                        {{i.value.Name}}</span> <span
                        *ngIf="c.customization&&c.customization[i.key]&&c.customization[i.key].variation">
                        ({{c.customization[i.key].variation.name}})
                    </span> </div>

            </mat-card-header>
        </mat-card>

    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="call(c.userDetails.phone)">Call user
            ({{c.userDetails.phone}})</button>
        <button mat-raised-button color="primary" (click)="getToken(c.userId)">Get Login Link</button>

    </mat-card-actions>
</mat-card>