import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import 'firebase/firestore';
import * as firebase from 'firebase/compat/app';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import 'firebase/storage';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseRestService {

  allIALs: any = [];
  allCategories = [];
  allShops = [];
  shopProducts: any = {};

  constructor(private firestore: AngularFirestore, private storage: AngularFireStorage, private http: HttpClient, private authService: AuthService, private rest: RestService) { }

  getAllCategories(): Observable<any> {
    let allCatSubject = new BehaviorSubject<any>(this.allCategories);
    if (this.allCategories.length == 0) {
      this.firestore.collection('all_categories').doc('all').get().subscribe((cdata: any) => {
        this.allCategories = cdata.data().categories;
        allCatSubject.next(this.allCategories);
      });
    }
    return allCatSubject;
  }


  getAllSearchKeys(): Observable<any> {
    let allSKSubject = new BehaviorSubject<any>([]);

    this.firestore.collection('searchKeys').valueChanges({ idField: 'id' }).subscribe((skdata: any) => {
      allSKSubject.next(skdata);
    });

    return allSKSubject;
  }


  updateShopPOSMapp(mappingData, mapId): Observable<boolean> {
    let res: any = false;
    let resultSubject = new BehaviorSubject<any>(res);



    this.firestore.collection("posShopCatMapping").doc(mapId).set(mappingData, { merge: true }).then((data) => {
      resultSubject.next(true);
    })

    return resultSubject;


  }


  getPOSMap(mapId): Observable<any> {
    return this.firestore.collection("posShopCatMapping").doc(mapId).get();

  }


  refreshShopProducts(ownerId, shopId): Observable<boolean> {
    let res: any = false;
    let resultSubject = new BehaviorSubject<any>(res);

    this.firestore.collection("userShops").doc(ownerId).collection('shops').doc(shopId).set({ propigate: true }, { merge: true }).then((data) => {
      resultSubject.next(true);
    })

    return resultSubject;


  }


  addPOSAddonGroups(addonGroupData): Observable<boolean> {
    let res: any = false;
    let resultSubject = new BehaviorSubject<any>(res);
    let promiseArr: any = []

    addonGroupData.forEach(addon => {
      promiseArr.push(this.firestore.collection("productAddonGroups").doc(addon.snbyId).set(addon))
    });

    Promise.all(promiseArr).then((data) => {
      resultSubject.next(true);
    })

    return resultSubject;


  }

  addPOSAddonItems(addonitemData): Observable<boolean> {
    let res: any = false;
    let resultSubject = new BehaviorSubject<any>(res);
    let promiseArr: any = []

    addonitemData.forEach(addonItem => {
      promiseArr.push(this.firestore.collection("productAddonItems").doc(addonItem.snbyId).set(addonItem))
    });

    Promise.all(promiseArr).then((data) => {
      resultSubject.next(true);
    })

    return resultSubject;


  }

  addPOSVariations(variationData): Observable<boolean> {
    let res: any = false;
    let resultSubject = new BehaviorSubject<any>(res);

    let promiseArr: any = []

    variationData.forEach(variation => {
      promiseArr.push(this.firestore.collection("productVariations").doc(variation.snbyId).set(variation))
    });

    Promise.all(promiseArr).then((data) => {
      resultSubject.next(true);
    })
    return resultSubject;


  }

  addPOSProducts(productData): Observable<boolean> {
    let res: any = false;
    let resultSubject = new BehaviorSubject<any>(res);
    let promiseArr: any = []

    productData.forEach(product => {
      promiseArr.push(this.firestore.collection("products").doc(product.snbyId).set(product))
    });

    Promise.all(promiseArr).then((data) => {
      resultSubject.next(true);
    })

    return resultSubject;


  }


  getAddonGroupsById(addonGroupIds): Observable<any> {
    let subscriptionArr: any = {}
    let addonObj: any = {};
    let addonArrSubject = new BehaviorSubject<any>(addonObj);

    //// console.log(id);
    for (const id in addonGroupIds) {
      subscriptionArr[id] = this.firestore.collection('productAddonGroups').doc(id).get();
    }
    forkJoin(subscriptionArr).subscribe((addonData: any) => {
      // console.log(catData)
      // console.log(addonData);
      const returnData = {};
      for (const adonid in addonData) {
        if (addonData[adonid].exists)
          returnData[adonid] = addonData[adonid].data()
      }

      addonArrSubject.next(returnData);


    })



    return addonArrSubject;
  }

  getProductAddonItems() {
    let resultData = { success: false };
    let resultsub = new BehaviorSubject<any>(resultData)
    if (this.authService.currentUser != null) {
      this.firestore.collection('productAddonItems', ref => (ref.where("ownerId", "==", this.authService.currentUser.uid))).get().subscribe((adndata) => {
        resultsub.next({ data: adndata, success: true });
      });
    }
    return resultsub
  }




  addCategory(data): Observable<boolean> {
    let res: any = false;
    let resultSubject = new BehaviorSubject<any>(res);
    const forSingle = { n: data.name, p: data.parentName, pid: data.parentId, a: data.enabled };

    // console.log(forSingle);

    this.firestore.collection('categories').add(forSingle).then((doc: DocumentReference) => {
      let catID = doc.id
      const forMulti = { id: catID, name: data.name, parent: data.parentName, parentId: data.parentId, enabled: data.enabled ? "1" : "0" };
      // console.log(forMulti);
      const allCat = this.firestore.collection('all_categories').doc('all');
      allCat.update({ categories: firebase.default.firestore.FieldValue.arrayUnion(forMulti) }).then((data) => {
        resultSubject.next(true);
      });
    })

    return resultSubject;


  }



  editCategory(data, catId, index, img): Observable<any> {

    let res: any = [];
    // let file:File;

    let resultSubject = new BehaviorSubject<any>(res);

    const forSingle = { n: data.name, p: data.parentName, pid: data.parentId, a: data.enabled };

    // // console.log(forSingle);

    this.firestore.collection('categories').doc(catId).set(forSingle, { merge: true }).then((doc) => {
      // let catID=doc.id
    })
    // console.log(forSingle);
    const forMulti = { id: catId, name: data.name, parent: data.parentName, parentId: data.parentId, enabled: data.enabled ? "1" : "0" };
    // console.log(forMulti);
    const allCat = this.firestore.collection('all_categories').doc('all');

    if (img && img.length > 0) {
      this.uploadCatImageFile(img, "category_images/" + catId + "/").subscribe((data: any) => {
        if (data == 100) {
          // console.log("upload done");
        }
      });
    }


    allCat.get().subscribe((cats: any) => {
      let ac = cats.data().categories;
      ac[index] = forMulti;
      // // console.log(ac);
      allCat.update({ categories: ac });
      resultSubject.next(ac);
    })





    return resultSubject;


  }


  editShopDetails(shopId, ownerId, shopData, logo, hasLogo): Observable<any> {

    let res: any = { 'status': 'pending' };
    let resultSubject = new BehaviorSubject<any>(res);

    // let file:File;

    let dataStored = false;
    let imageUploaded = false;

    let skipUpload = true;


    if (logo && logo.length > 0) {
      skipUpload = false;
    } else {
      imageUploaded = true;
    }


    const shopRef = this.firestore.collection('userShops').doc(ownerId).collection('shops').doc(shopId);

    shopRef.set({
      shopCategory: shopData.shopCategory,
      shopname: shopData.shopname,
      address_short: shopData.address_short,
      address_line1: shopData.address_line1,
      address_line2: shopData.address_line2,
      landmark: shopData.landmark,
      address_city: shopData.address_city,
      address_state: shopData.address_state,
      address_pincode: shopData.address_pincode,
      hasLogo: hasLogo,
      propigate: true
    }, { merge: true }).then((data) => {
      // console.log(data);

      dataStored = true;
      if (dataStored && imageUploaded) {
        resultSubject.next({ 'status': 'done' })
      }
    })

    // const shopUserRef = this.firestore.collection('users').doc(ownerId);

    // shopUserRef.set({
    //   propigate: true
    // }, { merge: true }).then((data) => {
    //   dataStored = true;
    //   if (dataStored && imageUploaded) {
    //     resultSubject.next({ 'status': 'done' })
    //   }
    // })

    if (!skipUpload) {
      this.uploadShopImageFile(logo, "shop_images/" + shopId + "/logo").subscribe((data: any) => {
        if (data == 100) {
          console.log("upload done");
          imageUploaded = true;
          if (dataStored && imageUploaded) {
            resultSubject.next({ 'status': 'done' })
          }
        }
      });
    }

    return resultSubject;


  }

  getShopOrderData() {
    let resultObj = {};
    let sodSubject = new BehaviorSubject<any>(resultObj);
    let d = new Date(); // Today!
    d.setDate(d.getDate() - 25);
    this.firestore.collection('userOrders', ref => ref.where("lastUpdated", ">=", d)).valueChanges({ idField: 'propertyId' }).subscribe((data) => {
      resultObj['orders'] = data;
      sodSubject.next(resultObj);
    });
    return sodSubject;
  }


  getOrderDataBetween(from: Date, to: Date) {
    let resultObj = {};
    let sodSubject = new BehaviorSubject<any>(resultObj);
    let d = new Date(); // Today!
    d.setDate(d.getDate() - 25);
    this.firestore.collection('userOrders', ref => ref.where("lastUpdated", ">=", from).where("lastUpdated", "<", to).where("fullyCancelled", "==", false).where("pendingPayment", "==", false)).valueChanges({ idField: 'propertyId' }).subscribe((data) => {
      resultObj['orders'] = data;
      sodSubject.next(resultObj);
    });
    return sodSubject;
  }




  getOrderById(orderId) {

    return this.firestore.collection('userOrders').doc(orderId).get();

  }

  getRiders(scope?, pcaa?: string[]) {

    switch (scope) {
      case 6:
        return this.firestore.collection('deliveryPersonStatus', ref => ref.where('pc6', 'in', pcaa).where('active', '==', true)).valueChanges({ idField: 'id' });
        break;
      case 8:
        return this.firestore.collection('deliveryPersonStatus', ref => ref.where('pc8', 'in', pcaa).where('active', '==', true)).valueChanges({ idField: 'id' });
        break;
      case 10:
        return this.firestore.collection('deliveryPersonStatus', ref => ref.where('pc10', 'in', pcaa).where('active', '==', true)).valueChanges({ idField: 'id' });
        break
      default:
        return this.firestore.collection('deliveryPersonStatus', ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
        break
    }


  }

  getActiveRiders() {
    return this.firestore.collection('deliveryPersonStatus', ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
  }


  getAllRiders() {
    return this.firestore.collection('deliveryPersonStatus').valueChanges({ idField: 'id' });
  }

  getCompletedRequestsForRider(dpId: string) {
    return this.firestore.collection("serviceRequests", ref => ref.where("provider", "==", dpId).where("status", "in", [4]).orderBy("lastModified", "desc")).get();
  }

  getSNBDEUnverifiedProduct(invId?: string): Observable<any> {
    const resProduct = new BehaviorSubject<any>({});
    if (invId && invId.length > 0) {
      this.firestore.collection('products').doc(invId).get().subscribe((uvProducts) => {
        // console.log(uvProducts);
        if (uvProducts.exists) {
          // this.rest.lockDEVerification(uvProducts.id).subscribe((result) => {
          // if (result.status === "success") {
          resProduct.next(uvProducts);
          // }
          // });

        }
      })
    } else {
      this.firestore.collection('products', ref => (ref.where('active', '==', 10).where('DEVerifiedBy', '==', this.authService.currentUser.uid).limit(1))).get().subscribe((bvProducts) => {
        if (!bvProducts.empty) {
          resProduct.next(bvProducts.docs[0]);
        } else {
          this.firestore.collection('products', ref => (ref.where('active', 'in', [19, 2]).limit(1))).get().subscribe((uvProducts) => {
            if (!uvProducts.empty) {
              // this.rest.lockDEVerification(uvProducts.docs[0].id).subscribe((result) => {
              // if (result.status === "success") {
              resProduct.next(uvProducts.docs[0]);
              // }
              // });

            }
          })
        }
      })
    }
    return resProduct;
  }


  getRefProduct(invId: string): Observable<any> {
    const resProduct = new BehaviorSubject<any>({});

    if (invId && invId.length > 0) {
      this.firestore.collection('referenceProducts').doc(invId).get().subscribe((refProduct) => {
        if (refProduct.exists) {
          // this.rest.lockDEVerification(refProduct.id).subscribe((result) => {
          // if (result.status === "success") {
          resProduct.next(refProduct);
          // }
          // });

        }
      })
    }
    return resProduct;
  }



  getSNBEXPERTUnverifiedProduct(invId?: string): Observable<any> {
    const resProduct = new BehaviorSubject<any>({});
    if (invId && invId.length > 0) {
      this.firestore.collection('products').doc(invId).get().subscribe((uvProducts) => {
        if (uvProducts.exists) {//&& (!uvProducts.get('DXVerifiedBy') || uvProducts.get('DXVerifiedBy') === this.authService.currentUser.uid)
          // this.rest.lockDXVerification(uvProducts.id).subscribe((result) => {
          // if (result.status === "success") {
          resProduct.next(uvProducts);
          // }
          // });

        }
      })
    } else {
      this.firestore.collection('products', ref => (ref.where('active', '==', 12).where('DXVerifiedBy', '==', this.authService.currentUser.uid).limit(1))).get().subscribe((bvProducts) => {
        if (!bvProducts.empty) {
          resProduct.next(bvProducts.docs[0]);
        } else {
          this.firestore.collection('products', ref => (ref.where('active', '==', 11).limit(1))).get().subscribe((uvProducts) => {
            if (!uvProducts.empty) {
              // this.rest.lockDXVerification(uvProducts.docs[0].id).subscribe((result) => {
              // if (result.status === "success") {
              resProduct.next(uvProducts.docs[0]);
              // }
              // });

            }
          })
        }
      })
    }

    return resProduct;
  }

  getAllShops(): Observable<any> {

    // return this.firestore.collectionGroup('shops', ref => ref.where('status', '==', 2).where('role', '==', "shop")).get();

    let allShopsSubject = new BehaviorSubject<any>(this.allShops);
    if (this.allShops.length == 0) {
      this.firestore.collectionGroup('shops', ref => ref.where('status', '==', 2).where('role', '==', "shop")).get().subscribe((shopData: any) => {
        this.allShops = shopData.docs;
        allShopsSubject.next(this.allShops);
      });
    }
    return allShopsSubject;


  }

  getActiveShops(): Observable<any> {

    // return this.firestore.collectionGroup('shops', ref => ref.where('status', '==', 2).where('role', '==', "shop")).get();

    let allShopsSubject = new BehaviorSubject<any>(this.allShops);
    if (this.allShops.length == 0) {
      this.firestore.collectionGroup('shops', ref => ref.where('status', '==', 2).where("shopActive", "==", true).where('role', '==', "shop")).get().subscribe((shopData: any) => {
        this.allShops = shopData.docs;
        allShopsSubject.next(this.allShops);
      });
    }
    return allShopsSubject;


  }

  getShopByShopId(shopId): Observable<any> {
    return this.firestore.collectionGroup('shops', ref => ref.where('id', '==', shopId).where('status', '==', 2)).get();
  }

  getShopMenuByOwnerId(ownerId): Observable<any> {
    return this.firestore.collection('shopMenu').doc(ownerId).get();
  }

  getShopProductsByOwnerId(ownerId): Observable<any> {
    const pSub = new BehaviorSubject<any>([]);
    if (this.shopProducts && this.shopProducts[ownerId]) {
      pSub.next(this.shopProducts[ownerId]);
    } else {
      this.firestore.collection('products', ref => ref.where("ownerId", "==", ownerId)).get().subscribe((data) => {
        if (!data.empty) {
          this.shopProducts[ownerId] = data.docs.map(ele => ({ "data": ele.data(), "id": ele.id }));
          pSub.next(this.shopProducts[ownerId]);

        }
      })
    }
    return pSub;
  }

  getProduct(id): Observable<any> {
    return this.firestore.collection('products').doc(id).get();
  }

  getAllPOSs(): Observable<any> {
    return this.firestore.collection('pos').get();
  }

  getFirebaseUniqueId(collectionName: string) {
    return firebase.default.firestore().collection(collectionName).doc().id;
  }

  getCartData() {
    let d = new Date(); // Today!
    d.setDate(d.getDate() - 7);
    return this.firestore.collection("cartAddlInfo", ref => ref.where("lastUpdated", ">=", d).orderBy("lastUpdated", "desc")).valueChanges({ idField: 'userId' });
  }

  getMultipleProductsForOrders(shopOrders): Observable<any> {
    let productList = {};
    let products = new BehaviorSubject<any>(productList);
    let totalProductCount = 0;
    let productCount = 0;


    for (let shop of shopOrders) {
      for (let v in shop.i) {
        totalProductCount = totalProductCount + 1;
      }
    }

    for (let shop of shopOrders) {
      for (let v in shop.i) {

        let key = v;
        if (v.indexOf("_") != -1) {
          key = v.split("_")[0]
        }
        this.getProduct(key).subscribe((d: any) => {
          // let p=d.data();
          ////console.log(d.data());
          let res = {};




          const details: any = {};
          for (const detail of d.get('_clientView')) {
            details[detail.cl] = detail.v;
          }
          res['details'] = details;
          res['quantity'] = shop.i[v];
          res['productId'] = v;
          res['Price'] = d.get('details')['u22UClLx9FPBZMo1G2O7'];
          res['Discount'] = d.get('details')['z35YxpWMYu0g4p4ogMLh'] ? d.get('details')['z35YxpWMYu0g4p4ogMLh'] : 0;
          res['Name'] = d.get('Name');
          res['imgCount'] = d.get('imgCount');
          res['active'] = d.get('active');
          if (!productList[d.get('shopId')]) {
            productList[d.get('shopId')] = {};
          }
          if (!productList[d.get('shopId')]['items']) {
            productList[d.get('shopId')]['items'] = [];
          }
          productList[d.get('shopId')]['items'].push(res);
          productList[d.get('shopId')]['shopDetails'] = d.data().shopDetails;
          productCount = productCount + 1;
          if (productCount == totalProductCount) {
            products.next(productList);
          }


        });
      }


    }
    return products;
  }

  getProductFirstImageStatic(id) {
    let imgListArrSubject = new BehaviorSubject<string>("");
    let v = this.storage.storage.ref();
    v.child("product_images/" + id).listAll().then((data: any) => {
      let totalImages = data.items.length;
      if (totalImages > 0) {
        this.storage.ref(data.items[0].fullPath).getDownloadURL().subscribe((idata: any) => {
          imgListArrSubject.next(idata);
        });
      }

    });
    return imgListArrSubject
  }

  getProductImagesAsBaseBlob(id) {
    let imgList = [];
    let imgListArrSubject = new BehaviorSubject<any>(imgList);
    let v = this.storage.storage.ref();
    v.child("product_images/" + id).listAll().then((data: any) => {
      const imgs = data.items.filter((ele) => (!ele.name.includes('_')))

      let totalImages = imgs.length;
      // console.log(data.items);

      imgs.forEach((itemRef) => {
        this.storage.ref(itemRef.fullPath).getDownloadURL().subscribe((idata: any) => {
          this.http.get(idata, { responseType: 'blob' }).subscribe((imgData) => {
            imgList[parseInt(idata.split(".jpg")[0].split('%2F')[2])] = imgData;
            if (Object.keys(imgList).length == totalImages)
              imgListArrSubject.next(imgList);
          });
        });
      });

    });

    return imgListArrSubject
  }

  getRefProductImagesAsBaseBlob(id) {
    let imgList = [];
    let imgListArrSubject = new BehaviorSubject<any>(imgList);
    let v = this.storage.storage.ref();
    v.child("reference_product_images/" + id).listAll().then((data: any) => {
      let totalImages = data.items.length;
      data.items.forEach((itemRef) => {
        this.storage.ref(itemRef.fullPath).getDownloadURL().subscribe((idata: any) => {
          this.http.get(idata, { responseType: 'blob' }).subscribe((imgData) => {
            imgList[parseInt(idata.split(".jpg")[0].split('%2F')[2])] = imgData;
            if (Object.keys(imgList).length == totalImages)
              imgListArrSubject.next(imgList);
          });
        });
      });

    });

    return imgListArrSubject
  }


  uploadFile(imageBlob, name) {
    // // console.log(name);
    const file = imageBlob;
    const filePath = name;
    const ref = this.storage.ref(filePath + 'icon.png');
    const task = this.storage.upload(filePath + 'icon.png', file);
    // let uploadPercent = task.percentageChanges();
    let downloadURL = new Observable<any>();
    task.snapshotChanges().pipe(
      finalize(() => downloadURL = ref.getDownloadURL())
    )
      .subscribe((data) => {
        // console.log(data);
      });

    return task.percentageChanges();


  }

  uploadCatImageFile(imageBlob, name) {
    const file: string = imageBlob;
    const filePath = name;
    const ref = this.storage.ref(filePath + 'icon.webp');
    const task = ref.putString(file, 'data_url');
    // let uploadPercent = task.percentageChanges();
    let downloadURL = new Observable<any>();
    task.snapshotChanges().pipe(
      finalize(() => downloadURL = ref.getDownloadURL())
    )
      .subscribe((data) => {
        // console.log(data);
      });

    return task.percentageChanges();


  }

  uploadProductImageFile(imageBlob, name) {
    const file: string = imageBlob;
    const filePath = name;
    const ref = this.storage.ref(filePath + '.jpg');
    const task = ref.putString(file, 'data_url');
    // let uploadPercent = task.percentageChanges();
    let downloadURL = new Observable<any>();
    task.snapshotChanges().pipe(
      finalize(() => downloadURL = ref.getDownloadURL())
    )
      .subscribe((data) => {
        // console.log(data);
      });

    return task.percentageChanges();


  }

  uploadShopImageFile(imageBlob, name) {
    const file: string = imageBlob;
    const filePath = name;
    const ref = this.storage.ref(filePath + '.webp');
    const task = ref.putString(file, 'data_url');
    // let uploadPercent = task.percentageChanges();
    let downloadURL = new Observable<any>();
    task.snapshotChanges().pipe(
      finalize(() => downloadURL = ref.getDownloadURL())
    )
      .subscribe((data) => {
        // console.log(data);
      });

    return task.percentageChanges();


  }


  deleteCategory(catId, index): Observable<any> {

    let res: any = [];
    let resultSubject = new BehaviorSubject<any>(res);


    this.firestore.collection('categories').doc(catId).delete().then((doc) => {
    })

    const allCat = this.firestore.collection('all_categories').doc('all');

    allCat.get().subscribe((cats: any) => {
      let ac = cats.data().categories;
      if (index > -1) {
        ac.splice(index, 1);
      }
      allCat.update({ categories: ac });
      resultSubject.next(ac);
    })


    return resultSubject;


  }

  getIal() {
    let resSub = new BehaviorSubject<any>([]);
    if (this.allIALs.length == 0) {
      this.firestore.collection('inv_attr_labels').doc('all').get().subscribe((data) => {
        this.allIALs = data.get('ials');
        resSub.next(this.allIALs);
      })
    } else {
      resSub.next(this.allIALs);
    }
    return resSub
  }

  getShopCategories() {
    return this.firestore.collection('shopCategories').get();
  }

  updateShopCat(shopCatData, shopCatId) {
    return new Promise((resolve, reject) => {
      if (shopCatId != "")
        this.firestore.collection('shopCategories').doc(shopCatId).set(shopCatData, { merge: true }).then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);

        })
      else
        this.firestore.collection('shopCategories').add(shopCatData).then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);

        })
    })

  }

  addIAL(data) {

    const forSingle = {
      a: data.isActive,
      l: data.label,
      cl: data.clientLabel,
      dm: data.dm,
      rfd: data.rfd,
      d: data.dataType
    };

    if (data.dataType == 'List' || data.dataType == 'Multi') {
      forSingle['ref_values'] = data.refValues.toString().split('|').map(Function.prototype.call, String.prototype.trim);
    }

    // // console.log(forSingle);

    const ialRef = this.firestore.collection('ial');
    // const ialId = ialRef.id

    ialRef.add(forSingle).then((res: DocumentReference) => {

      const allIalRef = this.firestore.collection('inv_attr_labels').doc('all').ref;
      const forMulti = {
        a: data.isActive,
        l: data.label,
        cl: data.clientLabel,
        dm: data.dm,
        rfd: data.rfd,
        d: data.dataType,
        ialId: res.id
      };

      if (data.dataType == 'List' || data.dataType == 'Multi') {
        forMulti['ref_values'] = data.refValues.toString().split('|').map(Function.prototype.call, String.prototype.trim);
      }

      this.getIal().subscribe((ialList) => {
        if (ialList.length > 0) {
          ialList.push(forMulti);
          allIalRef.set(({ ials: ialList }), { merge: true })
        }
      })
    })
  }


  editIAL(data, index, ialId) {

    // console.log(data);
    // console.log(index);
    console.log(ialId);
    // return;
    const forSingle = {
      a: data.isActive,
      l: data.label,
      cl: data.clientLabel,
      dm: data.dm,
      rfd: data.rfd,
      d: data.dataType
    };

    if (data.dataType == 'List' || data.dataType == 'Multi') {
      forSingle['ref_values'] = data.refValues.toString().split('|').map(Function.prototype.call, String.prototype.trim);
    }

    console.log(forSingle);

    const ialRef = this.firestore.collection('ial');
    // const ialId = ialRef.id

    ialRef.doc(ialId).set(forSingle).then((res) => {


    })
    const allIalRef = this.firestore.collection('inv_attr_labels').doc('all').ref;
    const forMulti = {
      a: data.isActive,
      l: data.label,
      cl: data.clientLabel,
      dm: data.dm,
      rfd: data.rfd,
      d: data.dataType,
      ialId: ialId
    };

    if (data.dataType == 'List' || data.dataType == 'Multi') {
      forMulti['ref_values'] = data.refValues.toString().split('|').map(Function.prototype.call, String.prototype.trim);
    }

    this.getIal().subscribe((ialList) => {
      if (ialList.length > 0) {
        ialList[index] = forMulti;
        allIalRef.set(({ ials: ialList }), { merge: true })
      }
    })
  }

  getCategoryPathById(id: string): Observable<any> {
    //// console.log(id);
    const allCat = this.firestore.collection('all_categories').doc('all');
    let cpath: any = [];
    let cpathSubject = new BehaviorSubject<any>(cpath);
    if (this.allCategories.length == 0) {
      allCat.get().subscribe((cats: any) => {
        let ac = cats.data().categories;
        let currentItem: any = {}
        let cat = id;
        let i = 0;
        while (i < 7 && cat !== 'PhFTzbx3NzM0ySBopFJj') {
          i = i + 1;
          currentItem = ac.find(element => element.id === cat);
          //// console.log(cat);
          cpath.push(currentItem);
          //// console.log(cpath);
          //// console.log(currentItem);
          cat = currentItem.parentId;
        }
        cpathSubject.next(cpath);
      })
    } else {
      let ac = this.allCategories;
      let currentItem: any = {}
      let cat = id;
      let i = 0;
      while (i < 7 && cat !== 'PhFTzbx3NzM0ySBopFJj') {
        i = i + 1;
        currentItem = ac.find(element => element.id === cat);
        cpath.push(currentItem);
        //// console.log(currentItem);
        cat = currentItem.parentId;
      }
      cpathSubject.next(cpath);
    }

    return cpathSubject;

  }

  getCategoryById(id) {
    return this.firestore.collection('categories').doc(id).get();
  }


  getFormByCategoryId(id): Observable<any> {
    let subscriptionArr: any = {}
    let formFieldArr: any = {};
    let formFieldsSubject = new BehaviorSubject<any>(formFieldArr);

    //// console.log(id);
    this.getCategoryPathById(id).subscribe((cpath) => {
      cpath.forEach((cat) => {
        subscriptionArr[cat.id] = this.firestore.collection('categories').doc(cat.id).get();
      })
      forkJoin(subscriptionArr).subscribe((catData: any) => {
        // console.log(catData)

        for (let c in catData) {
          if (catData[c].data().form && catData[c].data().form.length > 0) {
            catData[c].data().form.forEach((form) => {
              form['catId'] = c;
              formFieldArr[form.docId] = form;
            })
          }
        }

        formFieldsSubject.next(formFieldArr);


      })

    })

    return formFieldsSubject;
  }


  uploadProductImages(productId: string, imageData: any): Observable<any> {
    let res = { 'status': 'pending' };
    let uploadStatus = new BehaviorSubject<any>(res);
    let dc = 0;
    let tc = imageData.length;
    let iuDone = false;
    this.deleteAllProductImages(productId).subscribe((status: boolean) => {
      if (status) {

        if (tc == 0) {
          iuDone = true;

          res['status'] = 'done';
          uploadStatus.next(res);

        }


        if (imageData[0]) {
          this.uploadProductImageFile(imageData[0], "product_images/" + productId + '/' + 0).subscribe((data: any) => {
            if (data == 100) {
              dc = dc + 1;
            }
            if ((tc - dc) == 0) {
              iuDone = true;

              res['status'] = 'done';
              uploadStatus.next(res);

            }
          });
        }
        if (imageData[1]) {
          this.uploadProductImageFile(imageData[1], "product_images/" + productId + '/' + 1).subscribe((data: any) => {
            if (data == 100) {
              dc = dc + 1;
            }
            if ((tc - dc) == 0) {
              iuDone = true;

              res['status'] = 'done';
              uploadStatus.next(res);

            }
          });
        }
        if (imageData[2]) {
          this.uploadProductImageFile(imageData[2], "product_images/" + productId + '/' + 2).subscribe((data: any) => {
            if (data == 100) {
              dc = dc + 1;
            }
            if ((tc - dc) == 0) {
              iuDone = true;

              res['status'] = 'done';
              uploadStatus.next(res);

            }
          });
        }
        if (imageData[3]) {
          this.uploadProductImageFile(imageData[3], "product_images/" + productId + '/' + 3).subscribe((data: any) => {
            if (data == 100) {
              dc = dc + 1;
            }
            if ((tc - dc) == 0) {
              iuDone = true;

              res['status'] = 'done';
              uploadStatus.next(res);

            }
          });
        }
        if (imageData[4]) {
          this.uploadProductImageFile(imageData[4], "product_images/" + productId + '/' + 4).subscribe((data: any) => {
            if (data == 100) {
              dc = dc + 1;
            }
            if ((tc - dc) == 0) {
              iuDone = true;
              res['status'] = 'done';
              uploadStatus.next(res);

            }
          });
        }
      }
    });
    return uploadStatus;
  }

  uploadRefProductImages(productId: string, imageData: any): Observable<any> {
    let res = { 'status': 'pending' };
    let uploadStatus = new BehaviorSubject<any>(res);
    let dc = 0;
    let tc = imageData.length;
    let iuDone = false;
    this.deleteAllRefProductImages(productId).subscribe((status: boolean) => {
      if (status) {

        if (tc == 0) {
          iuDone = true;

          res['status'] = 'done';
          uploadStatus.next(res);

        }


        if (imageData[0]) {
          this.uploadProductImageFile(imageData[0], "reference_product_images/" + productId + '/' + 0).subscribe((data: any) => {
            if (data == 100) {
              dc = dc + 1;
            }
            if ((tc - dc) == 0) {
              iuDone = true;

              res['status'] = 'done';
              uploadStatus.next(res);

            }
          });
        }
        if (imageData[1]) {
          this.uploadProductImageFile(imageData[1], "reference_product_images/" + productId + '/' + 1).subscribe((data: any) => {
            if (data == 100) {
              dc = dc + 1;
            }
            if ((tc - dc) == 0) {
              iuDone = true;

              res['status'] = 'done';
              uploadStatus.next(res);

            }
          });
        }
        if (imageData[2]) {
          this.uploadProductImageFile(imageData[2], "reference_product_images/" + productId + '/' + 2).subscribe((data: any) => {
            if (data == 100) {
              dc = dc + 1;
            }
            if ((tc - dc) == 0) {
              iuDone = true;

              res['status'] = 'done';
              uploadStatus.next(res);

            }
          });
        }
        if (imageData[3]) {
          this.uploadProductImageFile(imageData[3], "reference_product_images/" + productId + '/' + 3).subscribe((data: any) => {
            if (data == 100) {
              dc = dc + 1;
            }
            if ((tc - dc) == 0) {
              iuDone = true;

              res['status'] = 'done';
              uploadStatus.next(res);

            }
          });
        }
        if (imageData[4]) {
          this.uploadProductImageFile(imageData[4], "reference_product_images/" + productId + '/' + 4).subscribe((data: any) => {
            if (data == 100) {
              dc = dc + 1;
            }
            if ((tc - dc) == 0) {
              iuDone = true;
              res['status'] = 'done';
              uploadStatus.next(res);

            }
          });
        }
      }
    });
    return uploadStatus;
  }

  deleteAllProductImages(id) {
    let delimgCount = 0;
    let imgListArrSubject = new BehaviorSubject<boolean>(false);
    let v = this.storage.storage.ref();
    let mdone = false;
    let tdone = false;
    v.child("product_images/" + id).listAll().then((data: any) => {
      let totalImages = data.items.length;
      if (totalImages > 0) {
        data.items.forEach((itemRef) => {
          this.storage.ref(itemRef.fullPath).delete().subscribe((idata: any) => {
            delimgCount = delimgCount + 1
            if (delimgCount == totalImages) {
              // mdone = true;
              // if (tdone) {
              imgListArrSubject.next(true);
              // }
            }
          });
        });
      } else {
        // mdone = true;
        // if (tdone) {
        imgListArrSubject.next(true);
        // }
      }
    });

    // v.child("product_images/" + id + "/t").listAll().then((data: any) => {
    //   let totalImages = data.items.length;
    //   if (totalImages > 0) {
    //     data.items.forEach((itemRef) => {
    //       this.storage.ref(itemRef.fullPath).delete().subscribe((idata: any) => {
    //         delimgCount = delimgCount + 1
    //         if (delimgCount == totalImages) {
    //           tdone = true;
    //           if (mdone) {
    //             imgListArrSubject.next(true);
    //           }
    //         }
    //       });
    //     });
    //   } else {
    //     tdone = true;
    //     if (mdone) {
    //       imgListArrSubject.next(true);
    //     }
    //   }
    // });
    return imgListArrSubject
  }

  deleteAllRefProductImages(id) {
    let delimgCount = 0;
    let imgListArrSubject = new BehaviorSubject<boolean>(false);
    let v = this.storage.storage.ref();
    v.child("reference_product_images/" + id).listAll().then((data: any) => {
      let totalImages = data.items.length;
      if (totalImages > 0) {
        data.items.forEach((itemRef) => {
          this.storage.ref(itemRef.fullPath).delete().subscribe((idata: any) => {
            delimgCount = delimgCount + 1
            if (delimgCount == totalImages)
              imgListArrSubject.next(true);
          });
        });
      } else {
        imgListArrSubject.next(true);
      }
    });
    return imgListArrSubject
  }


  getProductImagesStatic(id) {
    let imgList = [];
    let imgListArrSubject = new BehaviorSubject<string[]>(imgList);
    let v = this.storage.storage.ref();
    //// console.log(id);
    v.child("product_images/" + id).listAll().then((data: any) => {
      let totalImages = data.items.length;
      data.items.forEach((itemRef) => {
        this.storage.ref(itemRef.fullPath).getDownloadURL().subscribe((idata: any) => {
          imgList[parseInt(idata.split(".jpg")[0].split('%2F')[2])] = idata;
          if (imgList.length == totalImages)
            imgListArrSubject.next(imgList);
        });
      });

    });
    return imgListArrSubject
  }

  getBlobFromStatic(imgStatic: string) {
    const imgBlobSub = new BehaviorSubject<any>(null);
    this.http.get(imgStatic, { responseType: 'blob' }).subscribe((imgData) => {
      imgBlobSub.next(imgData);
    });

    return imgBlobSub;
  }

  getProductImageThumbsStatic(id) {
    let imgList = [];
    let imgListArrSubject = new BehaviorSubject<string[]>(imgList);
    let v = this.storage.storage.ref();
    v.child("product_images/" + id + "/t").listAll().then((data: any) => {
      let totalImages = data.items.length;
      data.items.forEach((itemRef) => {
        this.storage.ref(itemRef.fullPath).getDownloadURL().subscribe((idata: any) => {
          if (idata.indexOf('_100x100') > -1)
            imgList.push(idata);
          if (imgList.length == totalImages)
            imgListArrSubject.next(imgList);
        });
      });

    });
    return imgListArrSubject
  }

  getRefKids(refId: string) {
    const resProduct = new BehaviorSubject<any>({});

    if (refId && refId.length > 0) {
      this.firestore.collection('products', ref => (ref.where("metaData.reference", "==", refId))).get().subscribe((products) => {
        if (!products.empty) {
          // this.rest.lockDEVerification(refProduct.id).subscribe((result) => {
          // if (result.status === "success") {
          resProduct.next(products);
          // }
          // });

        }
      })
    }
    return resProduct;
  }
}
