<mat-card class="w-100" style="height: fit-content;" [style.background-color]="selected?'#00afad':'#ffffff'">

    <mat-card-content>
        <div *ngIf="foodType&&foodType.v==='Veg'" class="veg"></div>
        <div *ngIf="foodType&&foodType.v==='Non-Veg'" class="non_veg"></div>
        <div *ngIf="foodType&&foodType.v==='Egg'" class="egg"></div>
        <!-- <div class="row">
            <div class="col  p-0">
                
            </div>
        </div> -->
        <div class="row">

            <div class="col px-1" style=" min-height: 100px;max-height: 130px;">
                <div>
                    <span (click)="view(productData.productId)"
                        [ngClass]="productNameExpanded?'productName mat-title mx-1 mt-1 mb-0 w-100 five-line-text':'productName mat-title mx-1 mt-1 mb-0 w-100 two-line-text'">
                        {{productData.Name}}
                    </span>
                    <h6 *ngIf="price&&price.v&&productData.hasVariants==0"
                        class=" mx-0 mt-1 mb-0 w-100 font-weight-bold one-line-text py-1 px-0">

                        <span class="price-tag py-1 px-2 shadow-sm ">
                            <span *ngIf="discount&&discount.v&&discount.v>0">
                                <span class="mat-caption"
                                    *ngIf="price.v"><del>&#8377;&nbsp;{{price.v}}</del>&nbsp;</span>
                                <span class="" *ngIf="price.v">&#8377;&nbsp;{{price.v-discount.v}}</span>
                            </span>
                            <span *ngIf="!discount||!discount.v||discount.v==0">
                                <span class="" *ngIf="price.v">&#8377;&nbsp;{{price.v}}</span>
                            </span>
                            <span *ngIf="sellingUnit&&sellingUnit.v&&sellingUnit.v.length>0" class="">
                                &nbsp;/&nbsp;{{sellingUnit.v}}</span>
                        </span>&nbsp;
                    </h6>

                    <h6 *ngIf="productData.hasVariants==1"
                        class=" mx-0 mt-1 mb-0 w-100 font-weight-bold one-line-text py-1 px-0">
                        <span class="price-tag py-1 px-2 shadow-sm ">

                            <span *ngIf="productData.priceRange">
                                <span class="">&#8377;&nbsp;{{productData.priceRange.min}} -
                                    &#8377;&nbsp;{{productData.priceRange.max}}</span>
                            </span>

                        </span>&nbsp;
                    </h6>
                </div>
                <p
                    [ngClass]="productDescExpanded?'mat-caption mx-1 mb-1 w-100 two-line-text productDesc':'mat-caption mx-1 mb-1 w-100 one-line-text productDesc'">
                    {{description.v}}&nbsp;</p>
                <p *ngIf="!isShopPage" class="mat-caption font-weight-bold mx-1 mb-1 w-100 one-line-text">
                    &nbsp;{{productData.shopName|titlecase}}&nbsp;
                </p>
            </div>
            <!-- <div class="col px-1" style=" min-width: 100px; max-width: 100px;max-height: 100px;padding-top:5px">
                <div *ngIf="productData.imgCount>0">
                    <img class="card-img-top" (click)="view(productData.productId)" style="background-image: url('{{productData.image}}');background-repeat: no-repeat;
                background-position: center;background-size: contain;" src="assets/empty-img.png" alt="Card image cap">
                </div>
                <div *ngIf="productData.imgCount==0" class="" style="position: absolute;right: 2.25px;top: 35px;">
                    <div *ngIf="isAvailable&&productData.inTime">
                        <div *ngIf="efd">
                            <div>

                                <button *ngIf="cartQuantity<1" type="button" color="primary"
                                    style="min-width: 96px; line-height: 26px;" mat-raised-button
                                    (click)="incrementCart()" class="mx-auto">
                                    <span class="color:white">&nbsp;Add</span>
                                </button>
                                <div *ngIf="cartQuantity>0" class="btn-group  mx-auto" style="background-color: #ffffff;
                                    border-radius: 4px;" role="group" aria-label="Button group with label">
                                    <button type="button"
                                        style="background-color: #ed1953; min-width: 30px; line-height: 14px; padding: 0;"
                                        mat-button (click)="decrementCart()" class="btn m-0">
                                        -
                                    </button>
                                    <div class="btn-group" role="group"
                                        style="min-width: 36px;border-top: 1px solid #ed1953;border-bottom: 1px solid #ed1953;">
                                        <div class="mat-subheading-1 m-auto font-weight-bold">{{cartQuantity}}</div>

                                    </div>
                                    <button type="button"
                                        style="background-color: #ed1953; min-width: 30px; line-height: 14px; padding: 0;"
                                        mat-button (click)="incrementCart()" class="btn m-0">
                                        +
                                    </button>


                                </div>
                                <span *ngIf="productData.allowAddons == 1 || productData.hasVariants == 1"
                                    class="text-below-button2 mat-caption">customizable</span>
                            </div>
                        </div>
                        <div *ngIf="!efd" style="background-color: #ffffff;
                        border-radius: 4px;">
                            <button type="button" color="primary"
                                style="min-width: 96px; line-height: 26px; overflow: hidden;" mat-raised-button
                                [disabled]="true" class="mx-auto">
                                <span class="color:white">Not Deliverable</span>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!isAvailable&&productData.inTime" style="background-color: #ffffff;
                    border-radius: 4px;">
                        <button type="button" color="primary"
                            style="min-width: 96px; line-height: 26px; overflow: hidden;" mat-raised-button
                            [disabled]="true" class="mx-auto">
                            <span class="color:white">No Stock</span>
                        </button>
                    </div>
                    <div *ngIf="!productData.inTime" style="background-color: #ffffff;
                    border-radius: 4px;">

                        <button type="button" color="primary" style="min-width: 96px; line-height: 26px;"
                            mat-raised-button [disabled]="true" class="mx-auto">
                            +<span class="color:white">&nbsp;NA</span>
                        </button>
                    </div>
                </div>
            </div> -->
        </div>



        <div class=" text-right " style="position: absolute;
            right: 3.25px;
            bottom: 16px;">
            <div *ngIf="isAvailable&&productData.inTime">
                <div *ngIf="efd">
                    <div>
                        <span *ngIf="productData.allowAddons == 1 || productData.hasVariants == 1"
                            class="text-below-button mat-caption">customizable</span>
                        <button type="button" color="primary" style="min-width: 96px; line-height: 26px;"
                            mat-raised-button (click)="select()" class="mx-auto">
                            <span class="color:white">&nbsp;Select</span>
                        </button>

                    </div>
                </div>
                <div *ngIf="!efd" style="background-color: #ffffff;
                border-radius: 4px;">
                    <button type="button" style="min-width: 96px; line-height: 26px; overflow: hidden;"
                        mat-raised-button [disabled]="true" class="mx-auto">
                        <span>Not Deliverable</span>
                    </button>
                </div>
            </div>
            <div *ngIf="!isAvailable&&productData.inTime" style="background-color: #ffffff;
            border-radius: 4px;">
                <button type="button" style="min-width: 96px; line-height: 26px; overflow: hidden;" mat-raised-button
                    [disabled]="true" class="mx-auto">
                    <span>No Stock</span>
                </button>
            </div>
            <div *ngIf="!productData.inTime" style="background-color: #ffffff;
            border-radius: 4px;">
                <span *ngIf="productData.allowAddons == 1 || productData.hasVariants == 1"
                    class="text-below-button mat-caption">customizable</span>
                <button type="button" style="min-width: 96px; line-height: 26px;" mat-raised-button (click)="select()"
                    class="mx-auto">
                    <span>&nbsp;NA</span>
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
<!-- </div> -->