<div class="row">
<div class="card col-12 mt-4 mx-auto">
<form  *ngIf="!useEmail" [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)" class="needs-validation card-body">
    <div class="form-group">
      <mat-form-field appearance="standard">
      <mat-label>Username</mat-label>
      <input matInput type="text" class="mat-input-element" autocapitalize="off" id="email" placeholder="Enter username" name="email" formControlName="username" required>
      <div class="valid-feedback">Valid.</div>
      <div class="invalid-feedback">Please fill out this field.</div>
    </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field appearance="standard">
      <mat-label>Password</mat-label>
      <input matInput type="password" class="mat-input-element" id="pwd" placeholder="Enter password" name="pswd" formControlName="password" required>
      <div class="valid-feedback">Valid.</div>
      <div class="invalid-feedback">Please fill out this field.</div>
    </mat-form-field>
    </div>
      

  <mat-toolbar color="white">
    <button  mat-raised-button color="primary" type="submit" class="w-100">Login <i class="fas fa-1x fa-sign-in-alt"></i></button>
  </mat-toolbar>
  
  </form>

  <form *ngIf="useEmail" [formGroup]="loginFormUsingEmail" (ngSubmit)="onSubmitWithEmail(loginFormUsingEmail.value)" class="needs-validation card-body">
    <div class="form-group">
      <mat-form-field appearance="standard">
      <mat-label>Email</mat-label>
      <input matInput type="text" class="mat-input-element" autocapitalize="off" id="email" placeholder="Enter Email" name="email" formControlName="email" required>
      <div class="valid-feedback">Valid.</div>
      <div class="invalid-feedback">Please fill out this field.</div>
    </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field appearance="standard">
      <mat-label>Password</mat-label>
      <input matInput type="password" class="mat-input-element" id="pwd" placeholder="Enter password" name="pswd" formControlName="password" required>
      <div class="valid-feedback">Valid.</div>
      <div class="invalid-feedback">Please fill out this field.</div>
    </mat-form-field>
    </div>
      

  <mat-toolbar color="white">
    <button  mat-raised-button color="primary" type="submit" class="w-100">Login <i class="fas fa-1x fa-sign-in-alt"></i></button>
  </mat-toolbar>
  
  </form>

  <mat-toolbar  *ngIf="!useEmail" color="white">
    <button  mat-raised-button color="primary" type="button" (click)="switchLogin()" class="w-100">Login with Email<i class="fas fa-1x fa-sign-in-alt"></i></button>
  </mat-toolbar>
</div>
</div>