import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/compat/storage';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateFormComponent } from './create-form/create-form.component';
import { GetChildernPipe } from './pipes/get-childern.pipe';
import { LocationStrategy, HashLocationStrategy, TitleCasePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';
import { AddCategoryComponent } from './add-category/add-category.component';
import { AddInventoryFieldComponent } from './add-inventory-field/add-inventory-field.component';
import { ModifyCategoriesComponent } from './modify-categories/modify-categories.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { SidenavService } from './services/sidenav.service';
import { SidenavComponent } from './sidenav/sidenav.component'
import { FirebaseRestService } from './services/firebase-rest.service';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from './auth/auth.service';
import { EditInventoryFieldComponent } from './edit-inventory-field/edit-inventory-field.component';
import { ExpertVerifyProductsComponent } from './products/expert-verify-products/expert-verify-products.component';
import { DeVerifyProductsComponent } from './products/de-verify-products/de-verify-products.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { WebcamModule } from 'ngx-webcam';
import { RestService } from './services/rest.service';
import { OrderManagementComponent, RiderAssignment } from './order-management/order-management.component';
import { HelpTopicComponent } from './help-topic/help-topic.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxPrintModule } from 'ngx-print';
import { QRCodeModule } from 'angularx-qrcode';
import { ImageCropperModule } from 'ngx-image-cropper';


import { ShopManagementComponent } from './shop-management/shop-management.component';
import { PrintQrComponent } from './print-qr/print-qr.component';
import { RefProductDashboardComponent } from './ref-product-dashboard/ref-product-dashboard.component';
import { RefProductEditComponent } from './ref-product-edit/ref-product-edit.component';
import { UpdateShopCategoryComponent } from './update-shop-category/update-shop-category.component';
import { EditShopDetailsComponent } from './edit-shop-details/edit-shop-details.component';
import { AddComponent } from './pos/add/add.component';
import { IntegrateComponent } from './pos/integrate/integrate.component';
import { DpFinderComponent } from './dp-finder/dp-finder.component';
import { EditShopMenuComponent } from './edit-shop-menu/edit-shop-menu.component';
import { PriceManagementComponent } from './price-management/price-management.component';
import { ShopTaxManagementComponent } from './shop-tax-management/shop-tax-management.component';
import { CartDataComponent } from './cart-data/cart-data.component';
import { SearchManagementComponent } from './search-management/search-management.component';
import { DpStatsComponent } from './dp-stats/dp-stats.component';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { ShopProductsComponent } from './shop-products/shop-products.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { ReportsComponent } from './reports/reports.component';




const fireConfig = {
  apiKey: "AIzaSyDhvyEAvrBaa0gr9ArRYAhL7kNMYO_OX90",
  authDomain: "shopnearby-301e7.firebaseapp.com",
  databaseURL: "https://shopnearby-301e7.firebaseio.com",
  storageBucket: "shopnearby-301e7.appspot.com",
  messagingSenderId: "620586929007",
  projectId: "shopnearby-301e7",
  appId: "1:620586929007:web:f75e2e0f47ac0ed2ccf69a"
};


@NgModule({
  declarations: [
    AppComponent,
    CreateFormComponent,
    GetChildernPipe,
    AddCategoryComponent,
    AddInventoryFieldComponent,
    ModifyCategoriesComponent,
    DashboardComponent,
    TopBarComponent,
    SidenavComponent,
    EditCategoryComponent,
    LoginComponent,
    EditInventoryFieldComponent,
    ExpertVerifyProductsComponent,
    DeVerifyProductsComponent,
    OrderManagementComponent,
    RiderAssignment,
    HelpTopicComponent,
    ShopManagementComponent,
    PrintQrComponent,
    RefProductDashboardComponent,
    RefProductEditComponent,
    UpdateShopCategoryComponent,
    EditShopDetailsComponent,
    AddComponent,
    IntegrateComponent,
    DpFinderComponent,
    EditShopMenuComponent,
    PriceManagementComponent,
    ShopTaxManagementComponent,
    CartDataComponent,
    SearchManagementComponent,
    DpStatsComponent,
    SendNotificationComponent,
    ShopProductsComponent,
    ProductViewComponent,
    UserManagementComponent,
    ReportsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(fireConfig),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDropzoneModule,
    NgbModule,
    // WebcamModule,
    NgxPrintModule,
    QRCodeModule,
    CKEditorModule,
    ImageCropperModule,
    HammerModule
  ],
  providers: [AuthService, { provide: LocationStrategy, useClass: HashLocationStrategy }, GetChildernPipe, SidenavService, FirebaseRestService, RestService, TitleCasePipe, { provide: BUCKET, useValue: 'shopnearby-301e7-product-image-bucket' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
