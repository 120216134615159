import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modify-categories',
  templateUrl: './modify-categories.component.html',
  styleUrls: ['./modify-categories.component.css']
})
export class ModifyCategoriesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
