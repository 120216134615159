<!-- <button mat-raised-button class="ml-4" color="primary" (click)="doAction()" type="button">Do Action</button> -->

<mat-toolbar color="white">
  <mat-toolbar-row>
    <button mat-raised-button color="primary" #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="{name: 'PhFTzbx3NzM0ySBopFJj'}">Select Category</button>
    <!-- <span *ngIf="catSelected">{{currentCategory.p}}/{{currentCategory.n}}</span> -->
    <span class="fill-remaining-space"></span>
    <button [hidden]="!catSelected" mat-raised-button class="ml-4" color="primary" (click)="submitForm()"
      type="button">Submit</button>

  </mat-toolbar-row>

</mat-toolbar>

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent let-name="name">
      <button *ngFor="let n of catData | getChildern:name:'id'" mat-menu-item [matMenuTriggerFor]="subMenu"
          [matMenuTriggerData]="{name2: n.id}"
          (click)="getForm(n.id);menuTrigger.closeMenu();">{{n.name}}</button>
  </ng-template>
</mat-menu>

<mat-menu #subMenu="matMenu">
  <ng-template matMenuContent let-name2="name2">
      <button *ngFor="let n of catData | getChildern:name2:'id'" mat-menu-item [matMenuTriggerFor]="subsubMenu"
          [matMenuTriggerData]="{name3: n.id}"
          (click)="getForm(n.id);menuTrigger.closeMenu();">{{n.name}}</button>
  </ng-template>
</mat-menu>

<mat-menu #subsubMenu="matMenu">
  <ng-template matMenuContent let-name3="name3">
      <button *ngFor="let n of catData | getChildern:name3:'id'" mat-menu-item [matMenuTriggerFor]="subsubsubMenu"
      [matMenuTriggerData]="{name4: n.id}"
          (click)="getForm(n.id);menuTrigger.closeMenu();">{{n.name}}</button>
  </ng-template>
</mat-menu>
<mat-menu #subsubsubMenu="matMenu">
  <ng-template matMenuContent let-name4="name4">
      <button *ngFor="let n of catData | getChildern:name4:'id'" mat-menu-item
          (click)="getForm(n.id);menuTrigger.closeMenu();">{{n.name}}</button>
  </ng-template>
</mat-menu>
<mat-card *ngFor="let i of ial">
  <mat-checkbox [disabled]="i.disabled" [(ngModel)]="i.checked">{{i.l}}</mat-checkbox>
  <mat-checkbox [disabled]="i.disabled" *ngIf="i.checked" [(ngModel)]="i.required" class="ml-4">Required</mat-checkbox>
  <mat-form-field appearance="standard" *ngIf="i.checked">
    <mat-label>Sort Order</mat-label>
    <!-- <input type="number" matInput> -->
    <input matInput [disabled]="i.disabled" [(ngModel)]="i.order" type="number" class="ml-4 mat-input-element"
      id="{{i.l}}order">
  </mat-form-field>
</mat-card>