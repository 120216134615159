export const Data = [
    {
        "a": true,
        "n": "Root",
        "p": "-"
    },
    {
        "a": true,
        "n": "Products",
        "p": "Root"
    },
    {
        "a": true,
        "n": "Services",
        "p": "Root"
    },
    {
        "a": true,
        "n": "ELECTRONICS",
        "p": "Products"
    },
    {
        "a": true,
        "n": "LIFESTYLE",
        "p": "Products"
    },
    {
        "a": true,
        "n": "HOME AND LIVING",
        "p": "Products"
    },
    {
        "a": true,
        "n": "KITCHEN APPLIANCES",
        "p": "Products"
    },
    {
        "a": true,
        "n": "BIKE AND CAR ACCESSORIES",
        "p": "Products"
    },
    {
        "a": true,
        "n": "BOOKS AND ENTERTAINMENT",
        "p": "Products"
    },
    {
        "a": true,
        "n": "Home Furnishing",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Home Decor",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Kitchen & Dining",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Home Cleaning & Organisation",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Lighting",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Tools & Hardware",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Home & Kitchen Appliances",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Bed",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Curtains & Accessories",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Bath",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Towels",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Laundry Baskets",
        "p": "Laundry"
    },
    {
        "a": true,
        "n": "Bath Robes",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Mats",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Bath Linen Sets",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Table Covers",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Coasters",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Table Placemats",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Aprons",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Table Runners",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Table Linen Sets",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Kitchen Linen Sets",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Floor Coverings",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Diwan Sets",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Photo Frames",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Sofa Covers",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Wall Decorations",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Mattress Projectors",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Cushion & Pillows",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Cushion & Pillows Covers",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Clocks & Wall Decor",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Show Pieces & Handicrafts",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Bean Bag Covers",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Windchimes",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Umbrellas",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Planters",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Bird Feeders",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Water Canes",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Garden Stakes",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Decorative Bottles",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Lamps",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Lanterns",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Tube Lights",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Series Lights",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Floor Lamp",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Ceiling Lamps",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Water Fountains",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Photo Albums",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Bongs",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Decorative Platters",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Artificial Flowers",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Flower Vase",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Artificial Plants",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Candles & Candle Holders",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Home Fragrances",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Magazine Holders",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Handles & Knobs",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Book Ends ",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Utility Boxes",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Greeting Cards",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Christmas Decorations",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Rakhi",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Envelopes",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Dandia Sticks",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Rangoli Powder",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Festive Gift Boxes",
        "p": "Home Furnishing"
    },
    {
        "a": true,
        "n": "Containers & Bottles",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Cookware",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Kitchen Tools",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Bar & Glassware",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Containers & Jars",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Lunch Boxes",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Bottles & Sippers",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Racks & Shelves",
        "p": "Bathroom Accessories"
    },
    {
        "a": true,
        "n": "Vegetable Bags & Baskets",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Kitchen Racks & Trolleys",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Multipurpose Storage Cabinets",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Pots & Pans",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Steamers & Idli Makers",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Cookware Sets",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Casserole & Sets",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Lids",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Choppers & Peelers",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Kitchen Knives",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Kitchen Implements",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Cutting Boards",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Colander & Sieves",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Spatulas & Ladles",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Kitchen Tool Sets",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Tongs & Whisks",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Weights & Measures",
        "p": "Kitchen Tools"
    },
    {
        "a": true,
        "n": "Gas Cylinder Trolleys",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Gas Lighters",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Dinner Sets",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Cups & Saucers",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Bathroom Accessories",
        "p": "HOUSEKEEPING & LAUNDRY"
    },
    {
        "a": true,
        "n": "Home Cleaning ",
        "p": "HOUSEKEEPING & LAUNDRY"
    },
    {
        "a": true,
        "n": "Laundry",
        "p": "HOUSEKEEPING & LAUNDRY"
    },
    {
        "a": true,
        "n": "Stools & Ladders",
        "p": "HOUSEKEEPING & LAUNDRY"
    },
    {
        "a": true,
        "n": "Tableware",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Cuttery",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Serving Trays & Sets",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Jugs & Pitchers",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Jug, Glass & Tray Sets",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Glasses & Tumblers",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Casserole & Sets",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Flasks",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Bakeware Moulds",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Bowls",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Baking Dishes & Pans",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Baking Cutters",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Weights & Measures",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Pastry Brushes",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Roasters",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Gas Stoves",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Barbecue, Grills & Skewers",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Plates",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Aluminium Foils & Shrink Wraps",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Food Containers",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Storage Pouches",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "Forks & Spoons",
        "p": "Kitchen & Dining"
    },
    {
        "a": true,
        "n": "ToothBrush Holders",
        "p": "Bathroom Accessories"
    },
    {
        "a": true,
        "n": "Bathroom Mirrors",
        "p": "Bathroom Accessories"
    },
    {
        "a": true,
        "n": "Buckets",
        "p": "Bathroom Accessories"
    },
    {
        "a": true,
        "n": "Bathroom Set",
        "p": "Bathroom Accessories"
    },
    {
        "a": true,
        "n": "Soap Dishes",
        "p": "Bathroom Accessories"
    },
    {
        "a": true,
        "n": "Wall & Door Hooks",
        "p": "Bathroom Accessories"
    },
    {
        "a": true,
        "n": "Towel Rods",
        "p": "Bathroom Accessories"
    },
    {
        "a": true,
        "n": "Mats",
        "p": "Bathroom Accessories"
    },
    {
        "a": true,
        "n": "Bath Tumblers",
        "p": "Bathroom Accessories"
    },
    {
        "a": true,
        "n": "Weighing Scales",
        "p": "Bathroom Accessories"
    },
    {
        "a": true,
        "n": "Toilet Paper Holders",
        "p": "Bathroom Accessories"
    },
    {
        "a": true,
        "n": "Dustbins",
        "p": "Home Cleaning "
    },
    {
        "a": true,
        "n": "Mop Set",
        "p": "Home Cleaning "
    },
    {
        "a": true,
        "n": "Brooms & Brushes",
        "p": "Home Cleaning "
    },
    {
        "a": true,
        "n": "Cleaning Cloths",
        "p": "Home Cleaning "
    },
    {
        "a": true,
        "n": "Cleaning Gloves",
        "p": "Home Cleaning "
    },
    {
        "a": true,
        "n": "Cleaning Dusters",
        "p": "Home Cleaning "
    },
    {
        "a": true,
        "n": "Scrub Pads",
        "p": "Home Cleaning "
    },
    {
        "a": true,
        "n": "Cleaning Wipes",
        "p": "Home Cleaning "
    },
    {
        "a": true,
        "n": "Cloth Hangers",
        "p": "Laundry"
    },
    {
        "a": true,
        "n": "Cloth Dryer Stands",
        "p": "Laundry"
    },
    {
        "a": true,
        "n": "Ironing Boards",
        "p": "Laundry"
    },
    {
        "a": true,
        "n": "Lint Rollers",
        "p": "Laundry"
    },
    {
        "a": true,
        "n": "Cloth Clips",
        "p": "Laundry"
    },
    {
        "a": true,
        "n": "Step Ladders",
        "p": "Stools & Ladders"
    },
    {
        "a": true,
        "n": "Decorative Lighting",
        "p": "Lighting"
    },
    {
        "a": true,
        "n": "Table Lamps",
        "p": "Lighting"
    },
    {
        "a": true,
        "n": "Wall Mounted Emergency Lights",
        "p": "Lighting"
    },
    {
        "a": true,
        "n": "Hand Tools",
        "p": "Tools & Hardware"
    },
    {
        "a": true,
        "n": "Screw Drivers & Allen Keys",
        "p": "Hard Tools"
    },
    {
        "a": true,
        "n": "Locks",
        "p": "Hard Tools"
    },
    {
        "a": true,
        "n": "Power & Hand Tool Kits",
        "p": "Hard Tools"
    },
    {
        "a": true,
        "n": "Wrenches & Sets",
        "p": "Hard Tools"
    },
    {
        "a": true,
        "n": "Hammers",
        "p": "Hard Tools"
    },
    {
        "a": true,
        "n": "Cutters & Snips",
        "p": "Hard Tools"
    },
    {
        "a": true,
        "n": "Socket Sets",
        "p": "Hard Tools"
    },
    {
        "a": true,
        "n": "Clamps ",
        "p": "Hard Tools"
    },
    {
        "a": true,
        "n": "Chisels & Sets",
        "p": "Hard Tools"
    },
    {
        "a": true,
        "n": "Axes",
        "p": "Hard Tools"
    },
    {
        "a": true,
        "n": "Handsaws & Sets",
        "p": "Hard Tools"
    },
    {
        "a": true,
        "n": "Crimpers",
        "p": "Hard Tools"
    },
    {
        "a": true,
        "n": "Drills",
        "p": "Power Tools"
    },
    {
        "a": true,
        "n": "Blowers",
        "p": "Power Tools"
    },
    {
        "a": true,
        "n": "Soldering Irons",
        "p": "Power Tools"
    },
    {
        "a": true,
        "n": "Sanders & Polishers",
        "p": "Power Tools"
    },
    {
        "a": true,
        "n": "Screw Guns",
        "p": "Power Tools"
    },
    {
        "a": true,
        "n": "Power Planes",
        "p": "Power Tools"
    },
    {
        "a": true,
        "n": "Nailers & Staplers",
        "p": "Power Tools"
    },
    {
        "a": true,
        "n": "Plant Container & Sets",
        "p": "Gardening Tools"
    },
    {
        "a": true,
        "n": "Garden Sprayers",
        "p": "Gardening Tools"
    },
    {
        "a": true,
        "n": "Garden Tool Sets",
        "p": "Gardening Tools"
    },
    {
        "a": true,
        "n": "Lawn Movers",
        "p": "Gardening Tools"
    },
    {
        "a": true,
        "n": "Scoops & Trowels",
        "p": "Gardening Tools"
    },
    {
        "a": true,
        "n": "Grass Trimmers",
        "p": "Gardening Tools"
    },
    {
        "a": true,
        "n": "Chain Saws",
        "p": "Gardening Tools"
    },
    {
        "a": true,
        "n": "Plant Seeds",
        "p": "Gardening Tools"
    },
    {
        "a": true,
        "n": "Paint Sprayers",
        "p": "Paint Equipments & Supplies"
    },
    {
        "a": true,
        "n": "Paint Rollers",
        "p": "Paint Equipments & Supplies"
    },
    {
        "a": true,
        "n": "Wall Paint Brushes",
        "p": "Paint Equipments & Supplies"
    },
    {
        "a": true,
        "n": "Spray Paints",
        "p": "Paint Equipments & Supplies"
    },
    {
        "a": true,
        "n": "Drill & Rotary Bit Sets",
        "p": "Tool Accessories"
    },
    {
        "a": true,
        "n": "Masks & Respirators",
        "p": "Tool Accessories"
    },
    {
        "a": true,
        "n": "Screw Driver Bit Sets",
        "p": "Tool Accessories"
    },
    {
        "a": true,
        "n": "Ear Muffs",
        "p": "Tool Accessories"
    },
    {
        "a": true,
        "n": "Tool Boxes & Trays",
        "p": "Tool Accessories"
    },
    {
        "a": true,
        "n": "Tool Bags & Holsters",
        "p": "Tool Accessories"
    },
    {
        "a": true,
        "n": "Level Measuring Instruments",
        "p": "Measuring & Layout Tools"
    },
    {
        "a": true,
        "n": "Calipers",
        "p": "Measuring & Layout Tools"
    },
    {
        "a": true,
        "n": "Hanging Organizers",
        "p": "Home Organizers & Storage"
    },
    {
        "a": true,
        "n": "Airconditioners",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Fans",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Refrigirators",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Water Purifiers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "WashingMachines",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Irons",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Aircoolers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Vaccum Cleaners",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Geyser \/ Water Heater",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Inverters \/ Stabilizers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Voltage Stabilizers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Immersion Rods",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Landline Phones",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Room Heaters",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Air Purifiers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Dimmers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Dryer",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Sensor Security Systems",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Mixer \/ Juicer \/ Grinders",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Sandwich & Roti Makers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Induction Cooktops",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Microwave Ovens",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Electric Cookers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Electric Jug (Heater) \/ Travel Kettles",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Hand Blenders",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Pop-Up Toasters",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Coffee Makers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Food Processors",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Food Makers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Popcorn Makers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Electric Deep Fryers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Egg Cookers",
        "p": "KITCHEN APPLIANCES"
    },
    {
        "a": true,
        "n": "Donut Makers",
        "p": "Home & Kitchen Appliances"
    },
    {
        "a": true,
        "n": "Clothing",
        "p": "Men"
    },
    {
        "a": true,
        "n": "Footware",
        "p": "Men"
    },
    {
        "a": true,
        "n": "Watches",
        "p": "Men"
    },
    {
        "a": true,
        "n": "Bags",
        "p": "Men"
    },
    {
        "a": true,
        "n": "Wallets",
        "p": "Men"
    },
    {
        "a": true,
        "n": "Belts",
        "p": "Men"
    },
    {
        "a": true,
        "n": "Sunglasses",
        "p": "Men"
    },
    {
        "a": true,
        "n": "Jewellery",
        "p": "Women"
    },
    {
        "a": true,
        "n": "Handbags",
        "p": "Women"
    },
    {
        "a": true,
        "n": "Makeup",
        "p": "Women"
    },
    {
        "a": true,
        "n": "Toys",
        "p": "Kids"
    },
    {
        "a": true,
        "n": "Diapers",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Living",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Tableware & Cutlery",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Flasks & Casseroles",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Stoves & Hobs",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Home Utilities",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Outdoor Cooking",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Consumables & Disposables",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Hard Tools",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Power Tools",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Gardening Tools",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Paint Equipments & Supplies",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Tool Accessories",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Measuring & Layout Tools",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Home Organizers & Storage",
        "p": "HOME AND LIVING"
    },
    {
        "a": true,
        "n": "Men",
        "p": "LIFESTYLE"
    },
    {
        "a": true,
        "n": "Women",
        "p": "LIFESTYLE"
    },
    {
        "a": true,
        "n": "Kids",
        "p": "LIFESTYLE"
    },
    {
        "a": true,
        "n": "Baby",
        "p": "LIFESTYLE"
    },
    {
        "a": true,
        "n": "Clothing",
        "p": "Women"
    },
    {
        "a": true,
        "n": "Footware",
        "p": "Women"
    },
    {
        "a": true,
        "n": "Watches",
        "p": "Women"
    },
    {
        "a": true,
        "n": "Jewellery",
        "p": "Women"
    },
    {
        "a": true,
        "n": "Wallets & Cluctches",
        "p": "Women"
    },
    {
        "a": true,
        "n": "Handbags",
        "p": "Women"
    },
    {
        "a": true,
        "n": "Belts",
        "p": "Women"
    },
    {
        "a": true,
        "n": "Makeup",
        "p": "Women"
    },
    {
        "a": true,
        "n": "Sunglasses",
        "p": "Women"
    },
    {
        "a": true,
        "n": "Clothing",
        "p": "Kids"
    },
    {
        "a": true,
        "n": "Footware",
        "p": "Kids"
    },
    {
        "a": true,
        "n": "Watches",
        "p": "Kids"
    },
    {
        "a": true,
        "n": "Sunglasses",
        "p": "Kids"
    },
    {
        "a": true,
        "n": "Infant Wear (Boys) ",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Infant Wear (Girls)",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Potty Seats",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Diaper Bags",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Wipes",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Nappies & Cloth Diapers",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Changing Tables",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Mats",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Potty Boxes",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Lotions & Creams",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Lotions & Creams",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Stollers & Prams",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Walkers",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Carriers & Carry Cots",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Cribs & Cardles",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Bouncers , Rockers & Swings",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Car Seats",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Play Gyms & Crib Toys",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Chairs",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Cots & Bassinets",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Mosquito Nets",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Blankets",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Sleeping Bags",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Mattresses",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Bedsheets",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Pillows",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Beds",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Infiatable Products",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Table & Chair Combos",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Folding Almirah & Organisers",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Wall Decorations",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Cribs & Cardles",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Albums",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Bedding Sets",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Tables",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Nursery Decorations",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Table Clocks",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Care Combos",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Photo Frames",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Birthday Combos",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Stickers",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Gift Wrappers",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Precious Articles",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Candles",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Decorations",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Hats",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Napkins",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Bath Towels",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Bath Tubs",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Shampoos",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Soaps",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Body Wash",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Bath Robes",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Powder Puffs",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Bath Essential Oils",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Shower Caps",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Bath Sponges",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Soap Cases",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Talcum Powder",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Insect Repellents",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Proofing",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Cleaners",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Monitors",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Oral Care",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Thermometer",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Teethers & Soothers",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Knee Pads",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Massage Oils",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Nail Cutters",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Cotton Buds",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Scissors",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Hair Oils",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Hair Brush & Comb",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Crib Toys & Play Gyms",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Stacking Toys",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Musical Toys",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Early Development Toys",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Baby Rettles",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Push & Pull Along",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Bath Toys",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Rideons",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Deodrants",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "Perfumes",
        "p": "Baby"
    },
    {
        "a": true,
        "n": "BOOKS",
        "p": "BOOKS AND ENTERTAINMENT"
    },
    {
        "a": true,
        "n": "Academic & Professional",
        "p": "BOOKS"
    },
    {
        "a": true,
        "n": "Literature & Fiction",
        "p": "BOOKS"
    },
    {
        "a": true,
        "n": "Children & Teens",
        "p": "BOOKS"
    },
    {
        "a": true,
        "n": "Comic & Graphic Novels",
        "p": "BOOKS"
    },
    {
        "a": true,
        "n": "Business & Management",
        "p": "BOOKS"
    },
    {
        "a": true,
        "n": "Biographies & Autobiographies",
        "p": "BOOKS"
    },
    {
        "a": true,
        "n": "History & Politics",
        "p": "BOOKS"
    },
    {
        "a": true,
        "n": "HOUSEKEEPING & LAUNDRY",
        "p": "Products"
    },
    {
        "a": true,
        "n": "Mobiles",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Tablets",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Laptops",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Wearable Smart Devices",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Computer Peripherals",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Mobile Accessories",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Headphones & Headsets",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Tablet Accessories",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Network Components",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Televisions",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Large Appliances",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Small Appliances",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Audio & Video",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Cameras",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Camera Accessories",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Gaming",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Personal Care Appliances",
        "p": "ELECTRONICS"
    },
    {
        "a": true,
        "n": "Car Electronics & Accessories",
        "p": "BIKE AND CAR ACCESSORIES"
    },
    {
        "a": true,
        "n": "Car Essentials",
        "p": "BIKE AND CAR ACCESSORIES"
    },
    {
        "a": true,
        "n": "Helmets & Riding Gear",
        "p": "BIKE AND CAR ACCESSORIES"
    },
    {
        "a": true,
        "n": "Bike & Care Breakdown Equipment",
        "p": "BIKE AND CAR ACCESSORIES"
    },
    {
        "a": true,
        "n": "Tyres",
        "p": "BIKE AND CAR ACCESSORIES"
    },
    {
        "a": true,
        "n": "Bike & Car Care",
        "p": "BIKE AND CAR ACCESSORIES"
    },
    {
        "a": true,
        "n": "Lubricants & Oils",
        "p": "BIKE AND CAR ACCESSORIES"
    },
    {
        "a": true,
        "n": "Spares & Performance Parts",
        "p": "BIKE AND CAR ACCESSORIES"
    },
    {
        "a": true,
        "n": "BIke & Car Lighting",
        "p": "BIKE AND CAR ACCESSORIES"
    },
    {
        "a": true,
        "n": "Bike & Car Styling",
        "p": "BIKE AND CAR ACCESSORIES"
    },
    {
        "a": true,
        "n": "Top BIke & Car Brands",
        "p": "BIKE AND CAR ACCESSORIES"
    },
    {
        "a": true,
        "n": "Plumbiing",
        "p": "Household"
    },
    {
        "a": true,
        "n": "Household",
        "p": "Services"
    },
    {
        "a": true,
        "n": "Catering",
        "p": "Services"
    },
    {
        "a": true,
        "n": "Real Estate",
        "p": "Services"
    },
    {
        "a": true,
        "n": "Plots",
        "p": "Real Estate"
    },
    {
        "a": true,
        "n": "Groceries",
        "p": "Products"
    },
    {
        "a": true,
        "n": "Fruits and Vegetables",
        "p": "Groceries"
    },
    {
        "a": true,
        "n": "Vegetables",
        "p": "Groceries"
    },
    {
        "a": true,
        "n": "Onion",
        "p": "Vegetables"
    },
    {
        "a": true,
        "n": "Tomato",
        "p": "Vegetables"
    },
    {
        "a": true,
        "n": "Fruits",
        "p": "Groceries"
    },
    {
        "a": true,
        "n": "Dairy Products",
        "p": "Groceries"
    },
    {
        "a": true,
        "n": "Beauty & Hygiene",
        "p": "Groceries"
    },
    {
        "a": true,
        "n": "Cleaning & Household",
        "p": "Groceries"
    },
    {
        "a": true,
        "n": "Baby Care",
        "p": "Groceries"
    },
    {
        "a": true,
        "n": "Eggs , Meat & Fish",
        "p": "Groceries"
    },
    {
        "a": true,
        "n": "Oil , Sugar & Masalas",
        "p": "Groceries"
    },
    {
        "a": true,
        "n": "Medicines",
        "p": "Groceries"
    },
    {
        "a": true,
        "n": "Rice , Atta  & Dals",
        "p": "Groceries"
    },
    {
        "a": true,
        "n": "Quantity",
        "p": "Groceries"
    }
]