<mat-form-field class="example-form-field" appearance="fill">
    <mat-label>Period</mat-label>
    <mat-date-range-input [formGroup]="campaignOne" [rangePicker]="campaignOnePicker">
        <input matStartDate placeholder="Start date" formControlName="start">
        <input matEndDate placeholder="End date" formControlName="end">
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
</mat-form-field>

<button mat-raised-button color="primary" (click)="getOrderData()">Get GST Report</button>


<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="Created Date">
        <th mat-header-cell *matHeaderCellDef> Created Date </th>
        <td mat-cell *matCellDef="let element"> {{element.createdDate.toDate()|
            date:'medium'}} </td>
    </ng-container>

    <ng-container matColumnDef="Order Ref">
        <th mat-header-cell *matHeaderCellDef> Order Ref </th>
        <td mat-cell *matCellDef="let element"> {{element.orderRef}} </td>
    </ng-container>

    <ng-container matColumnDef="Total Amount">
        <th mat-header-cell *matHeaderCellDef> Total Amount </th>
        <td mat-cell *matCellDef="let element"> {{element.totalAmount}} </td>
    </ng-container>

    <ng-container matColumnDef="Discount">
        <th mat-header-cell *matHeaderCellDef> Discount </th>
        <td mat-cell *matCellDef="let element"> {{element.discount}} </td>
    </ng-container>

    <ng-container matColumnDef="Total GST">
        <th mat-header-cell *matHeaderCellDef> Total GST </th>
        <td mat-cell *matCellDef="let element"> {{element.totalTax}} </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>