import { Component, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import 'firebase/firestore';
import { Data } from './data'
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './services/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'shopnearby-api';
  idata: any[] = Data;
  @ViewChild('sidenav') public sidenav: MatSidenav;

  constructor(public firestore: AngularFirestore, public router: Router, public sidenavService: SidenavService) {

    // let cdata=firestore.collection('categories').valueChanges({ idField: 'docId' });


    // // cdata.ref.where('p','==','Produts');

    // cdata.subscribe((d:any)=>{
    //   // console.log(d);
    //   // // console.log(d.data.filter(item=>(item.p.indexOf('Products')!==-1)&&(item.a)));
    // })




  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }






  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  doAction() {
    let cat = [];
    // // console.log(this.idata.length);

    let x = 0;

    for (let i of this.idata) {
      i.a = true;
      cat.push(i);

    }
    // // console.log(cat);

    // this.insertData('all_categories',cat);

  }


  insertData(collection, data) {
    this.firestore.collection(collection).doc('all').set({ data }, { merge: true }).then(function (docRef) {
      // // console.log("Document written with ID: ", docRef);

    })
  }


}
