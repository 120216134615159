import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { RestService } from '../services/rest.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-shop-products',
  templateUrl: './shop-products.component.html',
  styleUrls: ['./shop-products.component.css']
})
export class ShopProductsComponent implements OnInit {
  @Input() shopId = "";
  @Output() selectionChanges: any = new EventEmitter<any>(false);
  productList: any = {};
  menuList: any = [];
  productListFiltered: any = [];
  totalCount = 0;
  plflenght = 0;

  scrollPos: number;
  topPos: number;
  topAdjustment: number = 0;

  @Input() sProducts: string[] = [];

  selections: any = {};
  constructor(private rest: RestService) {



  }

  ngOnInit(): void {

    this.rest.getShopProducts(this.shopId, "").subscribe((data) => {
      console.log(data);
      if (data.status == "success") {
        this.setFilters(data.optData);

        this.sProducts.forEach((_sp) => {
          this.selections[_sp] = true;
        })

      }

    })

  }

  handleSelection(e) {

    this.selections[Object.keys(e)[0]] = Object.values(e)[0];

    let fl = [];
    for (let sel in this.selections) {
      if (this.selections[sel]) {
        fl.push(sel);
      }
    }

    this.selectionChanges.emit({ l: fl, sId: this.shopId });
  }



  private setFilters(productData: any) {

    this.productList = productData;

    this.menuList = this.productList.ml
    this.productListFiltered = this.productList.l;
  }

}
