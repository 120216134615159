export enum ProductStatus {
    Active = 1,
    PendingSNBVerification = 2,
    SNBDEDeclined = 3,
    Draft = 4,
    Hold = 5,
    AddedFromList = 9,
    SNBDEBeingVerified = 10,
    PendingSNBExpertVerification = 11,
    SNBDXBeingVerified = 12,
    WaitForLaunch = 13,
    SNBExpertDeclined = 19,
    Trash = -1,
}

export enum ReferenceTypes {
    self = 1,
    search = 2,
    suggetions = 3
}

export enum PromoCodeEntities {
    Delivery = 21,
    Service = 22,
    Product = 23,
    DeliveryServiceCombo = 24,
    DeliveryServiceProductCombo = 25
}

export enum RefProductStatus {
    ActiveDraft = 100,
    Available = 101,
    Draft = 102,
    Trash = 99,
}

export enum DeliveryTypes {
    ImmediateDelivery = 1,
    DelayedDelivery = 2,
    ScheduledDelivery = 3
}

export enum PaymentTypes {
    onPickup = 1,
    onDelivery = 2,
    weekEnd = 3
}


export const daysOfWeek = [{ "checked": true, "value": 0, "name": "Sun", "twoShifts": false, "multipleShifts": false, "start": 9, "end": 21 }
    , { "checked": true, "value": 1, "name": "Mon", "twoShifts": false, "multipleShifts": false, "start": 9, "end": 21 }
    , { "checked": true, "value": 2, "name": "Tue", "twoShifts": false, "multipleShifts": false, "start": 9, "end": 21 }
    , { "checked": true, "value": 3, "name": "Wed", "twoShifts": false, "multipleShifts": false, "start": 9, "end": 21 }
    , { "checked": true, "value": 4, "name": "Thu", "twoShifts": false, "multipleShifts": false, "start": 9, "end": 21 }
    , { "checked": true, "value": 5, "name": "Fri", "twoShifts": false, "multipleShifts": false, "start": 9, "end": 21 }
    , { "checked": true, "value": 6, "name": "Sat", "twoShifts": false, "multipleShifts": false, "start": 9, "end": 21 }];


export const hourOfDay = [{ "value": 3, "name": "3 AM" }, { "value": 3.5, "name": "3:30 AM" }, { "value": 4, "name": "4 AM" }, { "value": 4.5, "name": "4:30 AM" }, { "value": 5, "name": "5 AM" }, { "value": 5.5, "name": "5:30 AM" }, { "value": 6, "name": "6 AM" }, { "value": 6.5, "name": "6:30 AM" }, { "value": 7, "name": "7 AM" }, { "value": 7.5, "name": "7:30 AM" }, { "value": 8, "name": "8 AM" }, { "value": 8.5, "name": "8:30 AM" }, { "value": 9, "name": "9 AM" }, { "value": 9.5, "name": "9:30 AM" }, { "value": 10, "name": "10 AM" }, { "value": 10.5, "name": "10:30 AM" }, { "value": 11, "name": "11 AM" }, { "value": 11.5, "name": "11:30 AM" }, { "value": 12, "name": "12 PM" }, { "value": 12.5, "name": "12:30 PM" }, { "value": 13, "name": "1 PM" }, { "value": 13.5, "name": "1:30 PM" }, { "value": 14, "name": "2 PM" }, { "value": 14.5, "name": "2:30 PM" }, { "value": 15, "name": "3 PM" }, { "value": 15.5, "name": "3:30 PM" }, { "value": 16, "name": "4 PM" }, { "value": 16.5, "name": "4:30 PM" }, { "value": 17, "name": "5 PM" }, { "value": 17.5, "name": "5:30 PM" }, { "value": 18, "name": "6 PM" }, { "value": 18.5, "name": "6:30 PM" }, { "value": 19, "name": "7 PM" }, { "value": 19.5, "name": "7:30 PM" }, { "value": 20, "name": " 8 PM" }, { "value": 20.5, "name": "8:30 PM" }, { "value": 21, "name": "9 PM" }, { "value": 21.5, "name": "9:30 PM" }, { "value": 22, "name": "10 PM" }, { "value": 22.5, "name": "10:30 PM" }, { "value": 23, "name": "11 PM" }, { "value": 23.5, "name": "11:30 PM" }, { "value": 24, "name": "12 AM" }];

export const hourOfMorning = [{ "value": 7, "name": "7 AM" }, { "value": 7.5, "name": "7:30 AM" }, { "value": 8, "name": "8 AM" }, { "value": 8.5, "name": "8:30 AM" }, { "value": 9, "name": "9 AM" }, { "value": 9.5, "name": "9:30 AM" }, { "value": 10, "name": "10 AM" }, { "value": 10.5, "name": "10:30 AM" }, { "value": 11, "name": "11 AM" }];
export const hourOfAfternoon = [{ "value": 11, "name": "11 AM" }, { "value": 11.5, "name": "11:30 AM" }, { "value": 12, "name": "12 PM" }, { "value": 12.5, "name": "12:30 PM" }, { "value": 13, "name": "1 PM" }, { "value": 13.5, "name": "1:30 PM" }, { "value": 14, "name": "2 PM" }, { "value": 14.5, "name": "2:30 PM" }, { "value": 15, "name": "3 PM" }];
export const hourOfEvening = [{ "value": 16, "name": "4 PM" }, { "value": 16.5, "name": "4:30 PM" }, { "value": 17, "name": "5 PM" }, { "value": 17.5, "name": "5:30 PM" }, { "value": 18, "name": "6 PM" }, { "value": 18.5, "name": "6:30 PM" }, { "value": 19, "name": "7 PM" }, { "value": 19.5, "name": "7:30 PM" }, { "value": 20, "name": " 8 PM" }];
export const hourOfNight = [{ "value": 20, "name": " 8 PM" }, { "value": 20.5, "name": "8:30 PM" }, { "value": 21, "name": "9 PM" }, { "value": 21.5, "name": "9:30 PM" }, { "value": 22, "name": "10 PM" }, { "value": 22.5, "name": "10:30 PM" }, { "value": 23, "name": "11 PM" }, { "value": 23.5, "name": "11:30 PM" }, { "value": 24, "name": "12 AM" }];