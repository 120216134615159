import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateFormComponent } from './create-form/create-form.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddCategoryComponent } from './add-category/add-category.component';
import { AddInventoryFieldComponent } from './add-inventory-field/add-inventory-field.component';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { LoginComponent } from './login/login.component';
import { EditInventoryFieldComponent } from './edit-inventory-field/edit-inventory-field.component';
import { AuthGuard } from './auth/auth.guard';
import { Role } from './_models/role.enum';
import { ExpertVerifyProductsComponent } from './products/expert-verify-products/expert-verify-products.component';
import { DeVerifyProductsComponent } from './products/de-verify-products/de-verify-products.component';
import { OrderManagementComponent } from './order-management/order-management.component';
import { HelpTopicComponent } from './help-topic/help-topic.component';
import { ShopManagementComponent } from './shop-management/shop-management.component';
import { PrintQrComponent } from './print-qr/print-qr.component';
import { RefProductDashboardComponent } from './ref-product-dashboard/ref-product-dashboard.component';
import { UpdateShopCategoryComponent } from './update-shop-category/update-shop-category.component';
import { EditShopDetailsComponent } from './edit-shop-details/edit-shop-details.component';
import { IntegrateComponent } from './pos/integrate/integrate.component';
import { DpFinderComponent } from './dp-finder/dp-finder.component';
import { EditShopMenuComponent } from './edit-shop-menu/edit-shop-menu.component';
import { PriceManagementComponent } from './price-management/price-management.component';
import { ShopTaxManagementComponent } from './shop-tax-management/shop-tax-management.component';
import { CartDataComponent } from './cart-data/cart-data.component';
import { SearchManagementComponent } from './search-management/search-management.component';
import { DpStatsComponent } from './dp-stats/dp-stats.component';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { ReportsComponent } from './reports/reports.component';


const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'create-form',
    component: CreateFormComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'add-category',
    component: AddCategoryComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'edit-category',
    component: EditCategoryComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'add-inventory-field',
    component: AddInventoryFieldComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'edit-inventory-field',
    component: EditInventoryFieldComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'expert-verify',
    component: ExpertVerifyProductsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DataExpert] }
  },
  {
    path: 'de-verify/:productId',
    component: DeVerifyProductsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DataEntry] }
  },
  {
    path: 'expert-verify/:productId',
    component: ExpertVerifyProductsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DataExpert] }
  },
  {
    path: 'de-verify',
    component: DeVerifyProductsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DataEntry] }
  },
  {
    path: 'ref-products',
    component: RefProductDashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DataEntry] }
  },
  {
    path: 'manage-orders',
    component: OrderManagementComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DeliveryManager, Role.CustomerSupport] }
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'manage-search',
    component: SearchManagementComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DataExpert] }
  },
  {
    path: 'manage-users',
    component: UserManagementComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'cart-data',
    component: CartDataComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DeliveryManager, Role.CustomerSupport] }
  },
  {
    path: 'find-dp/:orderId',
    component: DpFinderComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DeliveryManager] }
  },
  {
    path: 'dp-stats/:dpId',
    component: DpStatsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DeliveryManager] }
  },

  {
    path: 'send-notification/:userId',
    component: SendNotificationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DeliveryManager, Role.CustomerSupport] }
  },
  {
    path: 'send-notification',
    component: SendNotificationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DeliveryManager, Role.CustomerSupport] }
  },
  {
    path: 'manage-shops',
    component: ShopManagementComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DataEntry, Role.DataExpert, Role.DeliveryManager] }
  },
  {
    path: 'shop-category',
    component: UpdateShopCategoryComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'edit-shop/:ownerId/:shopId',
    component: EditShopDetailsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DataEntry, Role.DataExpert, Role.DeliveryManager] }
  },
  {
    path: 'edit-shop-menu/:ownerId/:shopId',
    component: EditShopMenuComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DataEntry, Role.DataExpert, Role.DeliveryManager] }
  },
  {
    path: 'price-management/:ownerId/:shopId',
    component: PriceManagementComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DataExpert] }
  },
  {
    path: 'tax-management/:ownerId/:shopId',
    component: ShopTaxManagementComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.DataExpert] }
  },
  {
    path: 'link-pos/:ownerId/:shopId',
    component: IntegrateComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] }
  },
  {
    path: 'print-qr/:shopId',
    component: PrintQrComponent,
  },
  {
    path: 'help-topics',
    component: HelpTopicComponent,
    // canActivate: [AuthGuard],
    // data: { roles: [Role.Admin] }
  },
  {
    path: 'login',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
