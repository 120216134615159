import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FirebaseRestService } from '../services/firebase-rest.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  campaignOne: UntypedFormGroup;
  dataSource: any;
  displayedColumns: string[] = ['Created Date', 'Order Ref', "Total Amount", "Discount", "Total GST"];


  constructor(private fireRest: FirebaseRestService) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.campaignOne = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(year, month, 13)),
      end: new UntypedFormControl(new Date(year, month, 16)),
    });

  }

  ngOnInit(): void {
  }


  getOrderData() {




    this.fireRest.getOrderDataBetween(this.campaignOne.value.start, this.campaignOne.value.end).subscribe((orderData) => {
      console.log(orderData);
      if (orderData && orderData.orders && orderData.orders.length > 0) {
        this.dataSource = orderData.orders.map(ele => ({ createdDate: ele.created, orderRef: ele.orderRef, totalTax: ele.totalTax, totalAmount: _.sumBy(ele.taxData, 'netPrice'), discount: _.sumBy(ele.taxData, 'deduct') }));
      }
    });
  }

}
