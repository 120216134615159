export const states = ["Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
]


export const cities = [
    { city: "Achhnera", state: "Uttar Pradesh" },
    { city: "Adalaj", state: "Gujarat" },
    { city: "Adoor", state: "Kerala" },
    { city: "Adyar", state: "Karnataka" },
    { city: "Adra", state: "West Bengal" },
    { city: "Afzalpur", state: "Karnataka" },
    { city: "Akot", state: "Maharashtra" },
    { city: "Alipurduar", state: "West Bengal" },
    { city: "Alirajpur", state: "Madhya Pradesh" },
    { city: "Almora", state: "Uttarakhand" },
    { city: "Aluva", state: "Kerala" },
    { city: "Amalapuram", state: "Andhra Pradesh" },
    { city: "Amalner", state: "Maharashtra" },
    { city: "Amaravathi", state: "Andhra Pradesh" },
    { city: "Ambejogai", state: "Maharashtra" },
    { city: "Anakapalle", state: "Andhra Pradesh" },
    { city: "Angul", state: "Odisha" },
    { city: "Anjar", state: "Gujarat" },
    { city: "Anjangaon", state: "Maharashtra" },
    { city: "Ankleshwar", state: "Gujarat" },
    { city: "Arakkonam", state: "Tamil Nadu" },
    { city: "Araria", state: "Bihar" },
    { city: "Arambagh", state: "West Bengal" },
    { city: "Arsikere", state: "Karnataka" },
    { city: "Aruppukkottai", state: "Tamil Nadu" },
    { city: "Arvi", state: "Maharashtra" },
    { city: "Arwal", state: "Bihar" },
    { city: "Asarganj", state: "Bihar" },
    { city: "Ashok Nagar", state: "Madhya Pradesh" },
    { city: "Athni", state: "Karnataka" },
    { city: "Attili", state: "Andhra Pradesh" },
    { city: "Attingal", state: "Kerala" },
    { city: "Aurangabad", state: "Maharashtra" },
    { city: "Badrinath", state: "Uttarakhand" },
    { city: "Bagepalli", state: "Karnataka" },
    { city: "Bageshwar", state: "Uttarakhand" },
    { city: "Bajpur", state: "Uttarakhand" },
    { city: "Balaghat", state: "Madhya Pradesh" },
    { city: "Balangir", state: "Odisha" },
    { city: "Banaganipalli", state: "Andhra Pradesh" },
    { city: "Bapatla", state: "Andhra Pradesh" },
    { city: "Barauli", state: "Bihar" },
    { city: "Barbil", state: "Odisha" },
    { city: "Bargarh", state: "Odisha" },
    { city: "Barbigha", state: "Bihar" },
    { city: "Barkot", state: "Uttarakhand" },
    { city: "Barpeta", state: "Assam" },
    { city: "Bellampalle", state: "Telangana" },
    { city: "Belonia", state: "Tripura" },
    { city: "Bhabua", state: "Bihar" },
    { city: "Bhadrachalam", state: "Telangana" },
    { city: "Bhainsa", state: "Telangana" },
    { city: "Bhaiseena", state: "Rajasthan" },
    { city: "Bhatapara", state: "Chhattisgarh" },
    { city: "Bhawanipatna", state: "Odisha" },
    { city: "Bheemunipatnam", state: "Andhra Pradesh" },
    { city: "Bhimtal", state: "Uttarakhand" },
    { city: "Bhongir", state: "Telangana" },
    { city: "Bhowali", state: "Uttarakhand" },
    { city: "Bhusawar", state: "Rajasthan" },
    { city: "Bobbili", state: "Andhra Pradesh" },
    { city: "Bodhan", state: "Telangana" },
    { city: "Bongaigaon City", state: "Assam" },
    { city: "Byasanagar", state: "Odisha" },
    { city: "Bagha Kusmar", state: "Bihar" },
    { city: "Chaibasa", state: "Jharkhand" },
    { city: "Charkhi Dadri", state: "Haryana" },
    { city: "Chatra", state: "Jharkhand" },
    { city: "Chalakudy", state: "Kerala" },
    { city: "Challakere", state: "Karnataka" },
    { city: "Chamba", state: "Uttarakhand" },
    { city: "Chamoli Gopeshwar", state: "Uttarakhand" },
    { city: "Champawat", state: "Uttarakhand" },
    { city: "Changanassery", state: "Kerala" },
    { city: "Chengannur", state: "Kerala" },
    { city: "Cooch Behar", state: "West Bengal" },
    { city: "Cherthala", state: "Kerala" },
    { city: "Chhapra", state: "Gujarat" },
    { city: "Chidambaram", state: "Tamil nadu" },
    { city: "Chennai", state: "Tamil nadu" },
    { city: "Chikkaballapur", state: "Karnataka" },
    { city: "Chintamani", state: "Karnataka" },
    { city: "Chirala", state: "Andhra Pradesh" },
    { city: "Chirkunda", state: "Jharkhand" },
    { city: "Chirmiri", state: "Chhattisgarh" },
    { city: "Chandpara", state: "West Bengal" },
    { city: "Chittur-Thathamangalam", state: "Kerala" },
    { city: "Dalli-Rajhara", state: "Chhattisgarh" },
    { city: "Medininagar (Daltonganj)", state: "Jharkhand" },
    { city: "Degana", state: "Rajasthan" },
    { city: "Devprayag", state: "Uttarakhand" },
    { city: "Dhamtari", state: "Chhattisgarh" },
    { city: "Dharchula", state: "Uttarakhand" },
    { city: "Dharmanagar", state: "Tripura" },
    { city: "Dhenkanal", state: "Odisha" },
    { city: "Dhone", state: "Andhrapradesh" },
    { city: "Dhoraji", state: "Gujarat" },
    { city: "Dhubri", state: "Assam" },
    { city: "Dhuri", state: "Punjab" },
    { city: "Didihat", state: "Uttarakhand" },
    { city: "Dineshpur", state: "Uttarakhand" },
    { city: "Diphu", state: "Assam" },
    { city: "Dogadda", state: "Uttarakhand" },
    { city: "Doiwala", state: "Uttarakhand" },
    { city: "Dumka", state: "Jharkhand" },
    { city: "Dumraon", state: "Bihar" },
    { city: "Dwarahat", state: "Uttarakhand" },
    { city: "Ellenabad", state: "Haryana" },
    { city: "Faridkot", state: "Punjab" },
    { city: "Farooqnagar", state: "Telangana" },
    { city: "Fatehabad", state: "Haryana" },
    { city: "Fazilka", state: "Punjab" },
    { city: "Forbesganj", state: "Bihar" },
    { city: "Firozpur Cantt.", state: "Punjab" },
    { city: "Faizabad", state: "Uttar Pradesh" },
    { city: "Gadarpur", state: "Uttarakhand" },
    { city: "Gadwal", state: "Telangana" },
    { city: "Gangarampur", state: "West Bengal" },
    { city: "Gangotri", state: "Uttarakhand" },
    { city: "Gavaravaram", state: "Andhra Pradesh" },
    { city: "Goalpara", state: "Assam" },
    { city: "Gobichettipalayam", state: "Tamil Nadu" },
    { city: "Gobindgarh", state: "Punjab" },
    { city: "Gochar", state: "Uttarakhand" },
    { city: "Gohana", state: "Haryana" },
    { city: "Golaghat", state: "Assam" },
    { city: "Gokak", state: "Karnataka" },
    { city: "Gooty", state: "Andhra Pradesh" },
    { city: "Gopalganj", state: "Bihar" },
    { city: "Gowribidanur", state: "Karnataka" },
    { city: "Gudur", state: "Andhra Pradesh" },
    { city: "Gunupur", state: "Odisha" },
    { city: "Gumia", state: "Jharkhand" },
    { city: "Gurdaspur", state: "Punjab" },
    { city: "Guruvayoor", state: "Kerala" },
    { city: "Hansi", state: "Haryana" },
    { city: "Herbertpur", state: "Uttarakhand" },
    { city: "Hisar", state: "Haryana" },
    { city: "Itarsi", state: "Madhya Pradesh" },
    { city: "Indore", state: "Madhya Pradesh" },
    { city: "Inkollu", state: "Andhra pradesh" },
    { city: "Jaggaiahpet", state: "Andhra Pradesh" },
    { city: "Jagraon", state: "Punjab" },
    { city: "Jagtial", state: "Telangana" },
    { city: "Jalandhar Cantt.", state: "Punjab" },
    { city: "Jammalamadugu", state: "Andhra Pradesh" },
    { city: "Jamui", state: "Bihar" },
    { city: "Jangaon", state: "Telangana" },
    { city: "Jaspur", state: "Uttarakhand" },
    { city: "Jatani", state: "Odisha" },
    { city: "Jaynagar Majilpur", state: "West Bengal" },
    { city: "Jhabrera", state: "Uttarakhand" },
    { city: "Jhargram", state: "West Bengal" },
    { city: "Jharsuguda", state: "Odisha" },
    { city: "Jhumri Tilaiya", state: "Jharkhand" },
    { city: "Jorhat", state: "Assam" },
    { city: "Joshimath", state: "Uttarakhand" },
    { city: "Kadi", state: "Gujarat" },
    { city: "Kadiri", state: "Andhra Pradesh" },
    { city: "Kagaznagar", state: "Telangana" },
    { city: "Kailasahar", state: "Tripura" },
    { city: "Kaladhungi", state: "Uttarakhand" },
    { city: "Kallakurichi", state: "Tamil Nadu" },
    { city: "Kalimpong", state: "West Bengal" },
    { city: "Kalpi", state: "Uttar Pradesh" },
    { city: "Kamareddy", state: "Telangana" },
    { city: "Kanigiri", state: "Andhra Pradesh" },
    { city: "Kandukur", state: "Andhra Pradesh" },
    { city: "Kanhangad", state: "Kerala" },
    { city: "Kannur", state: "Kerala" },
    { city: "Kapadvanj", state: "Gujarat" },
    { city: "Karaikal", state: "Puducherry" },
    { city: "Karimganj", state: "Assam" },
    { city: "Karjat", state: "Maharashtra" },
    { city: "Karnaprayag", state: "Uttarakhand" },
    { city: "Karunagappally", state: "Kerala" },
    { city: "Karur", state: "Tamil Nadu" },
    { city: "Kasaragod", state: "Kerala" },
    { city: "Kathua", state: "Jammu and Kashmir" },
    { city: "Kavali", state: "Andhra Pradesh" },
    { city: "Kayamkulam", state: "Kerala" },
    { city: "Kedarnath", state: "Uttarakhand" },
    { city: "Kela Khera", state: "Uttarakhand" },
    { city: "Kendrapara", state: "Odisha" },
    { city: "Kendujhar", state: "Odisha" },
    { city: "Keshod", state: "Gujarat" },
    { city: "Khambhat", state: "Gujarat" },
    { city: "Khanda", state: "Haryana" },
    { city: "Kharar", state: "Punjab" },
    { city: "Khatima", state: "Uttarakhand" },
    { city: "Khowai", state: "Tripura" },
    { city: "Kichha", state: "Uttarakhand" },
    { city: "Kirtinagar", state: "Uttarakhand" },
    { city: "Kodungallur", state: "Kerala" },
    { city: "Kohima", state: "Nagaland" },
    { city: "Koratla", state: "Telangana" },
    { city: "Kot Kapura", state: "Punjab" },
    { city: "Kotdwar", state: "Uttarakhand" },
    { city: "Kothagudem", state: "Telangana" },
    { city: "Kottayam", state: "Kerala" },
    { city: "Kovvur", state: "Andhra Pradesh" },
    { city: "Kunnamkulam", state: "Kerala" },
    { city: "Kyathampalle", state: "Telangana" },
    { city: "Kumarganj", state: "Uttar Pradesh" },
    { city: "Lachhmangarh", state: "Rajasthan" },
    { city: "Ladnu", state: "Rajasthan" },
    { city: "Ladwa", state: "Haryana" },
    { city: "Lahar", state: "Madhya Pradesh" },
    { city: "Laharpur", state: "Uttar Pradesh" },
    { city: "Lakheri", state: "Rajasthan" },
    { city: "Lakhisarai", state: "Bihar" },
    { city: "Laksar", state: "Uttarakhand" },
    { city: "Lakshmeshwar", state: "Karnataka" },
    { city: "Lal Gopalganj Nindaura", state: "Uttar Pradesh" },
    { city: "Lalganj", state: "Bihar" },
    { city: "Lalgudi", state: "Tamil Nadu" },
    { city: "Lalganj", state: "Uttar Pradesh" },
    { city: "Lalkuan", state: "Uttarakhand" },
    { city: "Lalsot", state: "Rajasthan" },
    { city: "Landhaura", state: "Uttarakhand" },
    { city: "Lanka", state: "Assam" },
    { city: "Lar", state: "Uttar Pradesh" },
    { city: "Lathi", state: "Gujarat" },
    { city: "Lilong", state: "Manipur" },
    { city: "Limbdi", state: "Gujarat" },
    { city: "Lingsugur", state: "Karnataka" },
    { city: "Loha", state: "Maharashtra" },
    { city: "Lohaghat", state: "Uttarakhand" },
    { city: "Lohardaga", state: "Jharkhand" },
    { city: "Lonar", state: "Maharashtra" },
    { city: "Lonavla", state: "Maharashtra" },
    { city: "Longowal", state: "Punjab" },
    { city: "Losal", state: "Rajasthan" },
    { city: "Lumding", state: "Assam" },
    { city: "Lunawada", state: "Gujarat" },
    { city: "Lunglei", state: "Mizoram" },
    { city: "Macherla", state: "Andhra Pradesh" },
    { city: "Maddur", state: "Karnataka" },
    { city: "Madhepura", state: "Bihar" },
    { city: "Madhubani", state: "Bihar" },
    { city: "Madhugiri", state: "Karnataka" },
    { city: "Madhupur", state: "Jharkhand" },
    { city: "Madikeri", state: "Karnataka" },
    { city: "Magadi", state: "Karnataka" },
    { city: "Mahad", state: "Maharashtra" },
    { city: "Mahalingapura", state: "Karnataka" },
    { city: "Maharajganj", state: "Bihar" },
    { city: "Maharajpur", state: "Madhya Pradesh" },
    { city: "Mahasamund", state: "Chhattisgarh" },
    { city: "Mahe", state: "Puducherry" },
    { city: "Manendragarh", state: "Chhattisgarh" },
    { city: "Mahendragarh", state: "Haryana" },
    { city: "Mahnar Bazar", state: "Bihar" },
    { city: "Mahua Dabra Haripura", state: "Uttarakhand" },
    { city: "Mahua Kheraganj", state: "Uttarakhand" },
    { city: "Maihar", state: "Madhya Pradesh" },
    { city: "Mainaguri", state: "West Bengal" },
    { city: "Makhdumpur", state: "Bihar" },
    { city: "Makrana", state: "Rajasthan" },
    { city: "Malda", state: "West Bengal" },
    { city: "Malaj Khand", state: "Madhya Pradesh" },
    { city: "Malavalli", state: "Karnataka" },
    { city: "Malkangiri", state: "Odisha" },
    { city: "Malkapur", state: "Maharashtra" },
    { city: "Malout", state: "Punjab" },
    { city: "Malpura", state: "Rajasthan" },
    { city: "Malur", state: "Karnataka" },
    { city: "Manachanallur", state: "Tamil Nadu" },
    { city: "Manasa", state: "Madhya Pradesh" },
    { city: "Manavadar", state: "Gujarat" },
    { city: "Manawar", state: "Madhya Pradesh" },
    { city: "Mandalgarh", state: "Rajasthan" },
    { city: "Mandamarri", state: "Telangana" },
    { city: "Mandapeta", state: "Andhra Pradesh" },
    { city: "Mandawa", state: "Rajasthan" },
    { city: "Mandi Dabwali", state: "Haryana" },
    { city: "Mandideep", state: "Madhya Pradesh" },
    { city: "Mandla", state: "Madhya Pradesh" },
    { city: "Mandvi", state: "Gujarat" },
    { city: "Maner Sharif", state: "Bihar" },
    { city: "Mangaldoi", state: "Assam" },
    { city: "Mangalvedhe", state: "Maharashtra" },
    { city: "Manglaur", state: "Uttarakhand" },
    { city: "Mangrol", state: "Gujarat" },
    { city: "Mangrol", state: "Rajasthan" },
    { city: "Mangrulpir", state: "Maharashtra" },
    { city: "Manihari", state: "Bihar" },
    { city: "Manjlegaon", state: "Maharashtra" },
    { city: "Mankachar", state: "Assam" },
    { city: "Manmad", state: "Maharashtra" },
    { city: "Mansa", state: "Punjab" },
    { city: "Mansa", state: "Gujarat" },
    { city: "Manuguru", state: "Telangana" },
    { city: "Manvi", state: "Karnataka" },
    { city: "Manwath", state: "Maharashtra" },
    { city: "Mapusa", state: "Goa" },
    { city: "Margao", state: "Goa" },
    { city: "Margherita", state: "Assam" },
    { city: "Marhaura", state: "Bihar" },
    { city: "Mariani", state: "Assam" },
    { city: "Marigaon", state: "Assam" },
    { city: "Markapur", state: "Andhra Pradesh" },
    { city: "Masaurhi", state: "Bihar" },
    { city: "Mathabhanga", state: "West Bengal" },
    { city: "Mattannur", state: "Kerala" },
    { city: "Mauganj", state: "Madhya Pradesh" },
    { city: "Mavelikkara", state: "Kerala" },
    { city: "Mavoor", state: "Kerala" },
    { city: "Mayang Imphal", state: "Manipur" },
    { city: "Medak", state: "Telangana" },
    { city: "Medinipur", state: "West Bengal" },
    { city: "Mehkar", state: "Maharashtra" },
    { city: "Mahemdabad", state: "Gujarat" },
    { city: "Memari", state: "West Bengal" },
    { city: "Merta City", state: "Rajasthan" },
    { city: "Mhaswad", state: "Maharashtra" },
    { city: "Mhow Cantonment", state: "Madhya Pradesh" },
    { city: "Mhowgaon", state: "Madhya Pradesh" },
    { city: "Mihijam", state: "Jharkhand" },
    { city: "Mirganj", state: "Bihar" },
    { city: "Miryalaguda", state: "Telangana" },
    { city: "Modasa", state: "Gujarat" },
    { city: "Mokameh", state: "Bihar" },
    { city: "Mokokchung", state: "Nagaland" },
    { city: "Monoharpur", state: "West Bengal" },
    { city: "Morinda, India", state: "Punjab" },
    { city: "Morshi", state: "Maharashtra" },
    { city: "Motipur", state: "Bihar" },
    { city: "Mount Abu", state: "Rajasthan" },
    { city: "Mudalagi", state: "Karnataka" },
    { city: "Mudabidri", state: "Karnataka" },
    { city: "Muddebihal", state: "Karnataka" },
    { city: "Mudhol", state: "Karnataka" },
    { city: "Mukerian", state: "Punjab" },
    { city: "Mukhed", state: "Maharashtra" },
    { city: "Muktsar", state: "Punjab" },
    { city: "Mul", state: "Maharashtra" },
    { city: "Mulbagal", state: "Karnataka" },
    { city: "Multai", state: "Madhya Pradesh" },
    { city: "Mundi", state: "Madhya Pradesh" },
    { city: "Mundargi", state: "Karnataka" },
    { city: "Mungeli", state: "Chhattisgarh" },
    { city: "Muni Ki Reti", state: "Uttarakhand" },
    { city: "Murliganj", state: "Bihar" },
    { city: "Murshidabad", state: "West Bengal" },
    { city: "Murtijapur", state: "Maharashtra" },
    { city: "Musabani", state: "Jharkhand" },
    { city: "Mussoorie", state: "Uttarakhand" },
    { city: "Muvattupuzha", state: "Kerala" },
    { city: "Nabarangapur", state: "Odisha" },
    { city: "Nabha", state: "Punjab" },
    { city: "Nadbai", state: "Rajasthan" },
    { city: "Nagar", state: "Rajasthan" },
    { city: "Nagari", state: "Andhra Pradesh" },
    { city: "Nagarkurnool", state: "Telangana" },
    { city: "Nagina", state: "Uttar Pradesh" },
    { city: "Nagla", state: "Uttarakhand" },
    { city: "Nahan", state: "Himachal Pradesh" },
    { city: "Naharlagun", state: "Arunachal Pradesh" },
    { city: "Naidupet", state: "Andhra Pradesh" },
    { city: "Naila Janjgir", state: "Chhattisgarh" },
    { city: "Nainital", state: "Uttarakhand" },
    { city: "Nainpur", state: "Madhya Pradesh" },
    { city: "Najibabad", state: "Uttar Pradesh" },
    { city: "Nakodar", state: "Punjab" },
    { city: "Nakur", state: "Uttar Pradesh" },
    { city: "Nalbari", state: "Assam" },
    { city: "Namagiripettai", state: "Tamil Nadu" },
    { city: "Namakkal", state: "Tamil Nadu" },
    { city: "Nandaprayag", state: "Uttarakhand" },
    { city: "Nandgaon", state: "Maharashtra" },
    { city: "Nandivaram-Guduvancheri", state: "Tamil Nadu" },
    { city: "Nandura", state: "Maharashtra" },
    { city: "Nangal", state: "Punjab" },
    { city: "Nanjangud", state: "Karnataka" },
    { city: "Nanjikottai", state: "Tamil Nadu" },
    { city: "Nanpara", state: "Uttar Pradesh" },
    { city: "Narasapuram", state: "Andhra Pradesh" },
    { city: "Naraura", state: "Uttar Pradesh" },
    { city: "Narayanpet", state: "Telangana" },
    { city: "Narendranagar", state: "Uttarakhand" },
    { city: "Nargund", state: "Karnataka" },
    { city: "Narkatiaganj", state: "Bihar" },
    { city: "Narkhed", state: "Maharashtra" },
    { city: "Narnaul", state: "Haryana" },
    { city: "Narsinghgarh", state: "Madhya Pradesh" },
    { city: "Narsinghgarh", state: "Madhya Pradesh" },
    { city: "Narsipatnam", state: "Andhra Pradesh" },
    { city: "Narwana", state: "Haryana" },
    { city: "Nasirabad", state: "Rajasthan" },
    { city: "Natham", state: "Tamil Nadu" },
    { city: "Nathdwara", state: "Rajasthan" },
    { city: "Naugachhia", state: "Bihar" },
    { city: "Naugawan Sadat", state: "Uttar Pradesh" },
    { city: "Naura", state: "Punjab" },
    { city: "Nautanwa", state: "Uttar Pradesh" },
    { city: "Navalgund", state: "Karnataka" },
    { city: "Nawabganj", state: "Uttar Pradesh" },
    { city: "Nawada", state: "Bihar" },
    { city: "Nawanshahr", state: "Punjab" },
    { city: "Nawapur", state: "Maharashtra" },
    { city: "Nedumangad", state: "Kerala" },
    { city: "Nedumbassery", state: "Kerala" },
    { city: "Neem-Ka-Thana", state: "Rajasthan" },
    { city: "Nehtaur", state: "Uttar Pradesh" },
    { city: "Nelamangala", state: "Karnataka" },
    { city: "Nellikuppam", state: "Tamil Nadu" },
    { city: "Nepanagar", state: "Madhya Pradesh" },
    { city: "Neyyattinkara", state: "Kerala" },
    { city: "Nidadavole", state: "Andhra Pradesh" },
    { city: "Nilanga", state: "Maharashtra" },
    { city: "Nilambur", state: "Kerala" },
    { city: "Nimbahera", state: "Rajasthan" },
    { city: "Nirmal", state: "Telangana" },
    { city: "Niwari", state: "Madhya Pradesh" },
    { city: "Niwai", state: "Rajasthan" },
    { city: "Nohar", state: "Rajasthan" },
    { city: "Nokha", state: "Bihar" },
    { city: "Nokha", state: "Rajasthan" },
    { city: "Nongstoin", state: "Meghalaya" },
    { city: "Noorpur", state: "Uttar Pradesh" },
    { city: "North Lakhimpur", state: "Assam" },
    { city: "Nowgong", state: "Madhya Pradesh" },
    { city: "Nowrozabad (Khodargama)", state: "Madhya Pradesh" },
    { city: "Nuzvid", state: "Andhra Pradesh" },
    { city: "O' Valley", state: "Tamil Nadu" },
    { city: "Oddanchatram", state: "Tamil Nadu" },
    { city: "Obra", state: "Uttar Pradesh" },
    { city: "Ottappalam", state: "Kerala" },
    { city: "Ozar", state: "Maharashtra" },
    { city: "Owk", state: "Andhra Pradesh" },
    { city: "P.N.Patti", state: "Tamil Nadu" },
    { city: "Palampur", state: "Himachal Pradesh" },
    { city: "Pachora", state: "Maharashtra" },
    { city: "Pachore", state: "Madhya Pradesh" },
    { city: "Pacode", state: "Tamil Nadu" },
    { city: "Padmanabhapuram", state: "Tamil Nadu" },
    { city: "Padra", state: "Gujarat" },
    { city: "Padrauna", state: "Uttar Pradesh" },
    { city: "Paithan", state: "Maharashtra" },
    { city: "Pakaur", state: "Jharkhand" },
    { city: "Palai", state: "Kerala" },
    { city: "Palani", state: "Tamil Nadu" },
    { city: "Palasa Kasibugga", state: "Andhra Pradesh" },
    { city: "Palghar", state: "Maharashtra" },
    { city: "Pali", state: "Rajasthan" },
    { city: "Palia Kalan", state: "Uttar Pradesh" },
    { city: "Palitana", state: "Gujarat" },
    { city: "Palladam", state: "Tamil Nadu" },
    { city: "Pallapatti", state: "Tamil Nadu" },
    { city: "Pallikonda", state: "Tamil Nadu" },
    { city: "Palwancha", state: "Telangana" },
    { city: "Panagar", state: "Madhya Pradesh" },
    { city: "Panagudi", state: "Tamil Nadu" },
    { city: "Panaji*", state: "Goa" },
    { city: "Panamattom", state: "Kerala" },
    { city: "Panchla", state: "West Bengal" },
    { city: "Pandharkaoda", state: "Maharashtra" },
    { city: "Pandharpur", state: "Maharashtra" },
    { city: "Pandhurna", state: "Madhya Pradesh" },
    { city: "Pandua", state: "West Bengal" },
    { city: "Panna", state: "Madhya Pradesh" },
    { city: "Panniyannur", state: "Kerala" },
    { city: "Panruti", state: "Tamil Nadu" },
    { city: "Pappinisseri", state: "Kerala" },
    { city: "Paradip", state: "Odisha" },
    { city: "Paramakudi", state: "Tamil Nadu" },
    { city: "Parangipettai", state: "Tamil Nadu" },
    { city: "Parasi", state: "Uttar Pradesh" },
    { city: "Paravoor", state: "Kerala" },
    { city: "Pardi", state: "Gujarat" },
    { city: "Parlakhemundi", state: "Odisha" },
    { city: "Parli", state: "Maharashtra" },
    { city: "Partur", state: "Maharashtra" },
    { city: "Parvathipuram", state: "Andhra Pradesh" },
    { city: "Pasan", state: "Madhya Pradesh" },
    { city: "Paschim Punropara", state: "West Bengal" },
    { city: "Pasighat", state: "Arunachal Pradesh" },
    { city: "Pathanamthitta", state: "Kerala" },
    { city: "Pathardi", state: "Maharashtra" },
    { city: "Pathri", state: "Maharashtra" },
    { city: "Pattran", state: "Punjab" },
    { city: "Patratu", state: "Jharkhand" },
    { city: "Pattamundai", state: "Odisha" },
    { city: "Patti", state: "Punjab" },
    { city: "Pattukkottai", state: "Tamil Nadu" },
    { city: "Patur", state: "Maharashtra" },
    { city: "Pauni", state: "Maharashtra" },
    { city: "Pauri", state: "Uttarakhand" },
    { city: "Pavagada", state: "Karnataka" },
    { city: "Pedana", state: "Andhra Pradesh" },
    { city: "Peddapuram", state: "Andhra Pradesh" },
    { city: "Pehowa", state: "Haryana" },
    { city: "Pen", state: "Maharashtra" },
    { city: "Perambalur", state: "Tamil Nadu" },
    { city: "Peravurani", state: "Tamil Nadu" },
    { city: "Peringathur", state: "Kerala" },
    { city: "Perinthalmanna", state: "Kerala" },
    { city: "Periyakulam", state: "Tamil Nadu" },
    { city: "Periyasemur", state: "Tamil Nadu" },
    { city: "Pernampattu", state: "Tamil Nadu" },
    { city: "Perumbavoor", state: "Kerala" },
    { city: "Petlad", state: "Gujarat" },
    { city: "Phalodi", state: "Rajasthan" },
    { city: "Phaltan", state: "Maharashtra" },
    { city: "Phagwara", state: "Punjab" },
    { city: "Phillaur", state: "Punjab" },
    { city: "Phulabani", state: "Odisha" },
    { city: "Phulera", state: "Rajasthan" },
    { city: "Phulpur", state: "Uttar Pradesh" },
    { city: "Pihani", state: "Uttar Pradesh" },
    { city: "Piler", state: "Andra Pradesh" },
    { city: "Pilani", state: "Rajasthan" },
    { city: "Pilibanga", state: "Rajasthan" },
    { city: "Pilkhuwa", state: "Uttar Pradesh" },
    { city: "Pindwara", state: "Rajasthan" },
    { city: "Pipar City", state: "Rajasthan" },
    { city: "Piro", state: "Bihar" },
    { city: "Piriyapatna", state: "Karnataka" },
    { city: "Pithampur", state: "Madhya Pradesh" },
    { city: "Pithapuram", state: "Andhra Pradesh" },
    { city: "Pithoragarh", state: "Uttarakhand" },
    { city: "Polur", state: "Tamil Nadu" },
    { city: "Ponnani", state: "Kerala" },
    { city: "Ponneri", state: "Tamil Nadu" },
    { city: "Ponnur", state: "Andhra Pradesh" },
    { city: "Porsa", state: "Madhya Pradesh" },
    { city: "Port Blair*", state: "Andaman and Nicobar Islands" },
    { city: "Powayan", state: "Uttar Pradesh" },
    { city: "Prantij", state: "Rajasthan" },
    { city: "Pratapgarh", state: "Rajasthan" },
    { city: "Pratapgarh", state: "Tripura" },
    { city: "Prithvipur", state: "Madhya Pradesh" },
    { city: "Pudupattinam", state: "Tamil Nadu" },
    { city: "Pukhrayan", state: "Uttar Pradesh" },
    { city: "Pulgaon", state: "Maharashtra" },
    { city: "Puliyankudi", state: "Tamil Nadu" },
    { city: "Punalur", state: "Kerala" },
    { city: "Poonch", state: "Jammu and Kashmir" },
    { city: "Punjaipugalur", state: "Tamil Nadu" },
    { city: "Punganur", state: "Andhra Pradesh" },
    { city: "Puranpur", state: "Uttar Pradesh" },
    { city: "Purna", state: "Maharashtra" },
    { city: "Purquazi", state: "Uttar Pradesh" },
    { city: "Purwa", state: "Uttar Pradesh" },
    { city: "Pusad", state: "Maharashtra" },
    { city: "Puttur", state: "Karnataka" },
    { city: "Puthuppally", state: "Kerala" },
    { city: "Puttur", state: "Andhra Pradesh" },
    { city: "Qadian", state: "Punjab" },
    { city: "Koyilandy", state: "Kerala" },
    { city: "Rabkavi Banhatti", state: "Karnataka" },
    { city: "Radhanpur", state: "Gujarat" },
    { city: "Rafiganj", state: "Bihar" },
    { city: "Raghogarh-Vijaypur", state: "Madhya Pradesh" },
    { city: "Raghunathpur", state: "West Bengal" },
    { city: "Rahatgarh", state: "Madhya Pradesh" },
    { city: "Rahuri", state: "Maharashtra" },
    { city: "Raikot", state: "Punjab" },
    { city: "Rairangpur", state: "Odisha" },
    { city: "Raisen", state: "Madhya Pradesh" },
    { city: "Raisinghnagar", state: "Rajasthan" },
    { city: "Rajagangapur", state: "Odisha" },
    { city: "Rajakhera", state: "Rajasthan" },
    { city: "Rajaldesar", state: "Rajasthan" },
    { city: "Rajam", state: "Andhra Pradesh" },
    { city: "Rajauri", state: "Jammu and Kashmir" },
    { city: "Rajesultanpur", state: "Uttar Pradesh" },
    { city: "Rajgarh (Alwar)", state: "Rajasthan" },
    { city: "Rajgarh (Churu)", state: "Rajasthan" },
    { city: "Rajgarh", state: "Madhya Pradesh" },
    { city: "Rajgir", state: "Bihar" },
    { city: "Rajpipla", state: "Gujarat" },
    { city: "Rajpura", state: "Punjab" },
    { city: "Rajsamand", state: "Rajasthan" },
    { city: "Rajula", state: "Gujarat" },
    { city: "Rajura", state: "Maharashtra" },
    { city: "Ramachandrapuram", state: "Andhra Pradesh" },
    { city: "Ramnagar", state: "Uttarakhand" },
    { city: "Ramanagaram", state: "Karnataka" },
    { city: "Ramanathapuram", state: "Tamil Nadu" },
    { city: "Ramdurg", state: "Karnataka" },
    { city: "Rameshwaram", state: "Tamil Nadu" },
    { city: "Ramganj Mandi", state: "Rajasthan" },
    { city: "Ramngarh", state: "Rajasthan" },
    { city: "Ramnagar", state: "Bihar" },
    { city: "Ramnagar", state: "Uttarakhand" },
    { city: "Rampur Maniharan", state: "Uttar Pradesh" },
    { city: "Rampura Phul", state: "Punjab" },
    { city: "Rampurhat", state: "West Bengal" },
    { city: "Ramtek", state: "Maharashtra" },
    { city: "Ranavav", state: "Gujarat" },
    { city: "Rangiya", state: "Assam" },
    { city: "Rania", state: "Haryana" },
    { city: "Ranibennur", state: "Karnataka" },
    { city: "Rapar", state: "Gujarat" },
    { city: "Rasipuram", state: "Tamil Nadu" },
    { city: "Rasra", state: "Uttar Pradesh" },
    { city: "Ratangarh", state: "Rajasthan" },
    { city: "Rath", state: "Uttar Pradesh" },
    { city: "Ratia", state: "Haryana" },
    { city: "Ratnagiri", state: "Maharashtra" },
    { city: "Rau", state: "Madhya Pradesh" },
    { city: "Raver", state: "Maharashtra" },
    { city: "Rawatbhata", state: "Rajasthan" },
    { city: "Rawatsar", state: "Rajasthan" },
    { city: "Raxaul Bazar", state: "Bihar" },
    { city: "Rayachoti", state: "Andhra Pradesh" },
    { city: "Rayadurg", state: "Andhra Pradesh" },
    { city: "Rayagada", state: "Odisha" },
    { city: "Reengus", state: "Rajasthan" },
    { city: "Rehli", state: "Madhya Pradesh" },
    { city: "Renigunta", state: "Andhra Pradesh" },
    { city: "Renukoot", state: "Uttar Pradesh" },
    { city: "Reoti", state: "Uttar Pradesh" },
    { city: "Repalle", state: "Andhra Pradesh" },
    { city: "Revelganj", state: "Bihar" },
    { city: "Rishikesh", state: "Uttarakhand" },
    { city: "Risod", state: "Maharashtra" },
    { city: "Robertsganj", state: "Uttar Pradesh" },
    { city: "Ron", state: "Karnataka" },
    { city: "Rosera", state: "Bihar" },
    { city: "Rudauli", state: "Uttar Pradesh" },
    { city: "Rudraprayag", state: "Uttarakhand" },
    { city: "Rudrapur", state: "Uttar Pradesh" },
    { city: "Rupnagar", state: "Punjab" },
    { city: "Sabalgarh", state: "Madhya Pradesh" },
    { city: "Sadabad", state: "Uttar Pradesh" },
    { city: "Sadasivpet", state: "Telangana" },
    { city: "Sadri", state: "Rajasthan" },
    { city: "Sadulshahar", state: "Rajasthan" },
    { city: "Safidon", state: "Haryana" },
    { city: "Safipur", state: "Uttar Pradesh" },
    { city: "Sagara", state: "Karnataka" },
    { city: "Sagwara", state: "Rajasthan" },
    { city: "Sahaspur", state: "Uttar Pradesh" },
    { city: "Sahaswan", state: "Uttar Pradesh" },
    { city: "Sahawar", state: "Uttar Pradesh" },
    { city: "Sahibganj", state: "Jharkhand" },
    { city: "Sahjanwa", state: "Uttar Pradesh" },
    { city: "Saidpur", state: "Uttar Pradesh" },
    { city: "Saiha", state: "Mizoram" },
    { city: "Sailu", state: "Maharashtra" },
    { city: "Sainthia", state: "West Bengal" },
    { city: "Sakaleshapura", state: "Karnataka" },
    { city: "Sakti", state: "Chhattisgarh" },
    { city: "Salaya", state: "Gujarat" },
    { city: "Salur", state: "Andhra Pradesh" },
    { city: "Samalkha", state: "Haryana" },
    { city: "Samalkot", state: "Andhra Pradesh" },
    { city: "Samana", state: "Punjab" },
    { city: "Samastipur", state: "Bihar" },
    { city: "Sambhar", state: "Rajasthan" },
    { city: "Samdhan", state: "Uttar Pradesh" },
    { city: "Samthar", state: "Uttar Pradesh" },
    { city: "Sanand", state: "Gujarat" },
    { city: "Sanawad", state: "Madhya Pradesh" },
    { city: "Sanchore", state: "Rajasthan" },
    { city: "Sanivarapupeta", state: "Andhra Pradesh" },
    { city: "Sarsod", state: "Haryana" },
    { city: "Sindagi", state: "Karnataka" },
    { city: "Sandi", state: "Uttar Pradesh" },
    { city: "Sandila", state: "Uttar Pradesh" },
    { city: "Sanduru", state: "Karnataka" },
    { city: "Sangamner", state: "Maharashtra" },
    { city: "Sangareddy", state: "Telangana" },
    { city: "Sangaria", state: "Rajasthan" },
    { city: "Sangole", state: "Maharashtra" },
    { city: "Sangrur", state: "Punjab" },
    { city: "Sankarankovil", state: "Tamil Nadu" },
    { city: "Sankari", state: "Tamil Nadu" },
    { city: "Sankeshwara", state: "Karnataka" },
    { city: "Sarangpur", state: "Madhya Pradesh" },
    { city: "Sardarshahar", state: "Rajasthan" },
    { city: "Sardhana", state: "Uttar Pradesh" },
    { city: "Sarni", state: "Madhya Pradesh" },
    { city: "Sarsawa", state: "Uttar Pradesh" },
    { city: "Sasvad", state: "Maharashtra" },
    { city: "Satana", state: "Maharashtra" },
    { city: "Sathyamangalam", state: "Tamil Nadu" },
    { city: "Satrampadu", state: "Andhra Pradesh" },
    { city: "Sattenapalle", state: "Andhra Pradesh" },
    { city: "Sattur", state: "Tamil Nadu" },
    { city: "Saunda", state: "Jharkhand" },
    { city: "Saundatti-Yellamma", state: "Karnataka" },
    { city: "Sausar", state: "Madhya Pradesh" },
    { city: "Savarkundla", state: "Gujarat" },
    { city: "Savanur", state: "Karnataka" },
    { city: "Savner", state: "Maharashtra" },
    { city: "Sawantwadi", state: "Maharashtra" },
    { city: "Sedam", state: "Karnataka" },
    { city: "Sehore", state: "Madhya Pradesh" },
    { city: "Sendhwa", state: "Madhya Pradesh" },
    { city: "Seohara", state: "Uttar Pradesh" },
    { city: "Seoni", state: "Madhya Pradesh" },
    { city: "Seoni-Malwa", state: "Madhya Pradesh" },
    { city: "Shahabad", state: "Karnataka" },
    { city: "Shahabad, Hardoi", state: "Uttar Pradesh" },
    { city: "Shahabad, Rampur", state: "Uttar Pradesh" },
    { city: "Shahade", state: "Maharashtra" },
    { city: "Shahbad", state: "Haryana" },
    { city: "Shahdol", state: "Madhya Pradesh" },
    { city: "Shahganj", state: "Uttar Pradesh" },
    { city: "Shahpur", state: "Karnataka" },
    { city: "Shahpura", state: "Rajasthan" },
    { city: "Shahpura", state: "Rajasthan" },
    { city: "Shajapur", state: "Madhya Pradesh" },
    { city: "Shaktigarh", state: "Uttarakhand" },
    { city: "Shamgarh", state: "Madhya Pradesh" },
    { city: "Shamli", state: "Uttar Pradesh" },
    { city: "Shamsabad, Agra", state: "Uttar Pradesh" },
    { city: "Shamsabad, Farrukhabad", state: "Uttar Pradesh" },
    { city: "Shegaon", state: "Maharashtra" },
    { city: "Sheikhpura", state: "Bihar" },
    { city: "Shendurjana", state: "Maharashtra" },
    { city: "Shenkottai", state: "Tamil Nadu" },
    { city: "Sheoganj", state: "Rajasthan" },
    { city: "Sheohar", state: "Bihar" },
    { city: "Sheopur", state: "Madhya Pradesh" },
    { city: "Sherghati", state: "Bihar" },
    { city: "Sherkot", state: "Uttar Pradesh" },
    { city: "Shiggaon", state: "Karnataka" },
    { city: "Shikaripur", state: "Karnataka" },
    { city: "Shikarpur, Bulandshahr", state: "Uttar Pradesh" },
    { city: "Shikohabad", state: "Uttar Pradesh" },
    { city: "Shirdi", state: "Maharashtra" },
    { city: "Shirpur-Warwade", state: "Maharashtra" },
    { city: "Shirur", state: "Maharashtra" },
    { city: "Shishgarh", state: "Uttar Pradesh" },
    { city: "Sholavandan", state: "Tamil Nadu" },
    { city: "Sholingur", state: "Tamil Nadu" },
    { city: "Shoranur", state: "Kerala" },
    { city: "Surapura", state: "Karnataka" },
    { city: "Shrigonda", state: "Maharashtra" },
    { city: "Shrirampur", state: "Maharashtra" },
    { city: "Shrirangapattana", state: "Karnataka" },
    { city: "Shujalpur", state: "Madhya Pradesh" },
    { city: "Siana", state: "Uttar Pradesh" },
    { city: "Sibsagar", state: "Assam" },
    { city: "Siddipet", state: "Telangana" },
    { city: "Sidhi", state: "Madhya Pradesh" },
    { city: "Sidhpur", state: "Gujarat" },
    { city: "Sidlaghatta", state: "Karnataka" },
    { city: "Sihor", state: "Gujarat" },
    { city: "Sihora", state: "Madhya Pradesh" },
    { city: "Sikanderpur", state: "Uttar Pradesh" },
    { city: "Sikandra Rao", state: "Uttar Pradesh" },
    { city: "Sikandrabad", state: "Uttar Pradesh" },
    { city: "Silao", state: "Bihar" },
    { city: "Silapathar", state: "Assam" },
    { city: "Sillod", state: "Maharashtra" },
    { city: "Silvassa*", state: "Dadra and Nagar Haveli" },
    { city: "Simdega", state: "Jharkhand" },
    { city: "Sindhagi", state: "Karnataka" },
    { city: "Sindhnur", state: "Karnataka" },
    { city: "Sinnar", state: "Maharashtra" },
    { city: "Sira", state: "Karnataka" },
    { city: "Sircilla", state: "Telangana" },
    { city: "Sirhind Fatehgarh Sahib", state: "Punjab" },
    { city: "Sirkali", state: "Tamil Nadu" },
    { city: "Sirohi", state: "Rajasthan" },
    { city: "Sironj", state: "Madhya Pradesh" },
    { city: "Sirsaganj", state: "Uttar Pradesh" },
    { city: "Sirsi", state: "Karnataka" },
    { city: "Sirsi", state: "Uttar Pradesh" },
    { city: "Siruguppa", state: "Karnataka" },
    { city: "Sitamarhi", state: "Bihar" },
    { city: "Sitarganj", state: "Uttarakhand" },
    { city: "Sivaganga", state: "Tamil Nadu" },
    { city: "Sivagiri", state: "Tamil Nadu" },
    { city: "Sohagpur", state: "Madhya Pradesh" },
    { city: "Sohna", state: "Haryana" },
    { city: "Sojat", state: "Rajasthan" },
    { city: "Solan", state: "Himachal Pradesh" },
    { city: "Sonamukhi", state: "West Bengal" },
    { city: "Sonepur", state: "Bihar" },
    { city: "Songadh", state: "Gujarat" },
    { city: "Sopore", state: "Jammu and Kashmir" },
    { city: "Soro", state: "Odisha" },
    { city: "Soron", state: "Uttar Pradesh" },
    { city: "Soyagaon", state: "Maharashtra" },
    { city: "Sri Madhopur", state: "Rajasthan" },
    { city: "Srikalahasti", state: "Andhra Pradesh" },
    { city: "Srinagar, Uttarakhand", state: "Uttarakhand" },
    { city: "Srinivaspur", state: "Karnataka" },
    { city: "Srisailam (RFC) Township", state: "Andhra Pradesh" },
    { city: "Srirampore", state: "West Bengal" },
    { city: "Srivilliputhur", state: "Tamil Nadu" },
    { city: "Suar", state: "Uttar Pradesh" },
    { city: "Sugauli", state: "Bihar" },
    { city: "Sujangarh", state: "Rajasthan" },
    { city: "Sujanpur", state: "Punjab" },
    { city: "Sultanganj", state: "Bihar" },
    { city: "Sultanpur", state: "Uttarakhand" },
    { city: "Sullurpeta", state: "Andhra Pradesh" },
    { city: "Sumerpur", state: "Rajasthan" },
    { city: "Sumerpur", state: "Uttar Pradesh" },
    { city: "Sunabeda", state: "Odisha" },
    { city: "Sunam", state: "Punjab" },
    { city: "Sundargarh", state: "Odisha" },
    { city: "Sundarnagar", state: "Himachal Pradesh" },
    { city: "Supaul", state: "Bihar" },
    { city: "Surandai", state: "Tamil Nadu" },
    { city: "Suratgarh", state: "Rajasthan" },
    { city: "Suri", state: "West Bengal" },
    { city: "Suriyampalayam", state: "Tamil Nadu" },
    { city: "Talbehat", state: "Uttar Pradesh" },
    { city: "Takhatgarh", state: "Rajasthan" },
    { city: "Taki", state: "West Bengal" },
    { city: "Talaja", state: "Gujarat" },
    { city: "Talcher", state: "Odisha" },
    { city: "Talegaon Dabhade", state: "Maharashtra" },
    { city: "Taliparamba", state: "Kerala" },
    { city: "Talode", state: "Maharashtra" },
    { city: "Talwara", state: "Punjab" },
    { city: "Tamluk", state: "West Bengal" },
    { city: "Tanakpur", state: "Uttarakhand" },
    { city: "Tanda", state: "Uttar Pradesh" },
    { city: "Tandur", state: "Telangana" },
    { city: "Tanuku", state: "Andhra Pradesh" },
    { city: "Tarakeswar", state: "West Bengal" },
    { city: "Tarana", state: "Madhya Pradesh" },
    { city: "Taranagar", state: "Rajasthan" },
    { city: "Taraori", state: "Haryana" },
    { city: "Tarbha", state: "Odisha" },
    { city: "Tarikere", state: "Karnataka" },
    { city: "Tarn Taran", state: "Punjab" },
    { city: "Tasgaon", state: "Maharashtra" },
    { city: "Tehri", state: "Uttarakhand" },
    { city: "Tekkalakote", state: "Karnataka" },
    { city: "Tenkasi", state: "Tamil Nadu" },
    { city: "Tenu dam-cum-Kathhara", state: "Jharkhand" },
    { city: "Terdal", state: "Karnataka" },
    { city: "Tezpur", state: "Assam" },
    { city: "Thakurdwara", state: "Uttar Pradesh" },
    { city: "Thammampatti", state: "Tamil Nadu" },
    { city: "Thana Bhawan", state: "Uttar Pradesh" },
    { city: "Thangadh", state: "Gujarat" },
    { city: "Tharad", state: "Gujarat" },
    { city: "Tharamangalam", state: "Tamil Nadu" },
    { city: "Tharangambadi", state: "Tamil Nadu" },
    { city: "Theni Allinagaram", state: "Tamil Nadu" },
    { city: "Thirumangalam", state: "Tamil Nadu" },
    { city: "Thirupuvanam", state: "Tamil Nadu" },
    { city: "Thiruthuraipoondi", state: "Tamil Nadu" },
    { city: "Thiruvalla", state: "Kerala" },
    { city: "Thiruvallur", state: "Tamil Nadu" },
    { city: "Thiruvarur", state: "Tamil Nadu" },
    { city: "Thodupuzha", state: "Kerala" },
    { city: "Thoubal", state: "Manipur" },
    { city: "Thrippunithura", state: "Kerala" },
    { city: "Thrissur", state: "Kerala" },
    { city: "Thuraiyur", state: "Tamil Nadu" },
    { city: "Tikamgarh", state: "Madhya Pradesh" },
    { city: "Tilda Newra", state: "Chhattisgarh" },
    { city: "Tilhar", state: "Uttar Pradesh" },
    { city: "Talikota", state: "Karnataka" },
    { city: "Tindivanam", state: "Tamil Nadu" },
    { city: "Tinsukia", state: "Assam" },
    { city: "Tiptur", state: "Karnataka" },
    { city: "Tirora", state: "Maharashtra" },
    { city: "Tiruchendur", state: "Tamil Nadu" },
    { city: "Tiruchengode", state: "Tamil Nadu" },
    { city: "Tirukalukundram", state: "Tamil Nadu" },
    { city: "Tirukkoyilur", state: "Tamil Nadu" },
    { city: "Tirupathur", state: "Tamil Nadu" },
    { city: "Tirupathur", state: "Tamil Nadu" },
    { city: "Tiruppur", state: "Tamil Nadu" },
    { city: "Tirur", state: "Kerala" },
    { city: "Tiruttani", state: "Tamil Nadu" },
    { city: "Tiruvethipuram", state: "Tamil Nadu" },
    { city: "Tiruvuru", state: "Andhra Pradesh" },
    { city: "Tirwaganj", state: "Uttar Pradesh" },
    { city: "Titlagarh", state: "Odisha" },
    { city: "Tittakudi", state: "Tamil Nadu" },
    { city: "Todabhim", state: "Rajasthan" },
    { city: "Todaraisingh", state: "Rajasthan" },
    { city: "Tohana", state: "Haryana" },
    { city: "Tuensang", state: "Nagaland" },
    { city: "Tuljapur", state: "Maharashtra" },
    { city: "Tulsipur", state: "Uttar Pradesh" },
    { city: "Tumsar", state: "Maharashtra" },
    { city: "Tundla", state: "Uttar Pradesh" },
    { city: "Tuni", state: "Andhra Pradesh" },
    { city: "Tura", state: "Meghalaya" },
    { city: "Uchgaon", state: "Maharashtra" },
    { city: "Udaipur", state: "Tripura" },
    { city: "Udaipurwati", state: "Rajasthan" },
    { city: "Udhagamandalam", state: "Tamil Nadu" },
    { city: "Udhampur", state: "Jammu and Kashmir" },
    { city: "Udumalaipettai", state: "Tamil Nadu" },
    { city: "Ujhani", state: "Uttar Pradesh" },
    { city: "Umarga", state: "Maharashtra" },
    { city: "Umaria", state: "Madhya Pradesh" },
    { city: "Umarkhed", state: "Maharashtra" },
    { city: "Umbergaon", state: "Gujarat" },
    { city: "Umred", state: "Maharashtra" },
    { city: "Umreth", state: "Gujarat" },
    { city: "Una", state: "Gujarat" },
    { city: "Unjha", state: "Gujarat" },
    { city: "Unnamalaikadai", state: "Tamil Nadu" },
    { city: "Upleta", state: "Gujarat" },
    { city: "Uran", state: "Maharashtra" },
    { city: "Uran Islampur", state: "Maharashtra" },
    { city: "Uravakonda", state: "Andhra Pradesh" },
    { city: "Urmar Tanda", state: "Punjab" },
    { city: "Usilampatti", state: "Tamil Nadu" },
    { city: "Uthamapalayam", state: "Tamil Nadu" },
    { city: "Uthiramerur", state: "Tamil Nadu" },
    { city: "Utraula", state: "Uttar Pradesh" },
    { city: "Uttarkashi", state: "Uttarakhand" },
    { city: "Vadakkuvalliyur", state: "Tamil Nadu" },
    { city: "Vadalur", state: "Tamil Nadu" },
    { city: "Vadgaon Kasba", state: "Maharashtra" },
    { city: "vasudevanallur", state: "Tamil Nadu" },
    { city: "Vadnagar", state: "Gujarat" },
    { city: "Vaijapur", state: "Maharashtra" },
    { city: "Vaikom", state: "Kerala" },
    { city: "Valparai", state: "Tamil Nadu" },
    { city: "Vandavasi", state: "Tamil Nadu" },
    { city: "Vaniyambadi", state: "Tamil Nadu" },
    { city: "Vapi", state: "Gujarat" },
    { city: "Varandarappilly", state: "Kerala" },
    { city: "Varkala", state: "Kerala" },
    { city: "Vatakara", state: "Kerala" },
    { city: "Vedaranyam", state: "Tamil Nadu" },
    { city: "Vellakoil", state: "Tamil Nadu" },
    { city: "Venkatagiri", state: "Andhra Pradesh" },
    { city: "Vijainagar, Ajmer", state: "Rajasthan" },
    { city: "Vijapur", state: "Gujarat" },
    { city: "Vijaypur", state: "Madhya Pradesh" },
    { city: "Vijayapura", state: "Karnataka" },
    { city: "Vikarabad", state: "Telangana" },
    { city: "Vikasnagar", state: "Uttarakhand" },
    { city: "Vikramasingapuram", state: "Tamil Nadu" },
    { city: "Viluppuram", state: "Tamil Nadu" },
    { city: "Vinukonda", state: "Andhra Pradesh" },
    { city: "Viramgam", state: "Gujarat" },
    { city: "Virudhachalam", state: "Tamil Nadu" },
    { city: "Virudhunagar", state: "Tamil Nadu" },
    { city: "Visnagar", state: "Gujarat" },
    { city: "Viswanatham", state: "Tamil Nadu" },
    { city: "Vita", state: "Maharashtra" },
    { city: "Vrindavan", state: "Uttar Pradesh" },
    { city: "Vyara", state: "Gujarat" },
    { city: "Wadgaon Road", state: "Maharashtra" },
    { city: "Wadhwan", state: "Gujarat" },
    { city: "Wadi", state: "Karnataka" },
    { city: "Wai", state: "Maharashtra" },
    { city: "Wanaparthy", state: "Telangana" },
    { city: "Wani", state: "Maharashtra" },
    { city: "Wankaner", state: "Gujarat" },
    { city: "Wara Seoni", state: "Madhya Pradesh" },
    { city: "Warhapur", state: "Uttar Pradesh" },
    { city: "Warisaliganj", state: "Bihar" },
    { city: "Warora", state: "Maharashtra" },
    { city: "Warud", state: "Maharashtra" },
    { city: "Washim", state: "Maharashtra" },
    { city: "Wokha", state: "Nagaland" },
    { city: "Yadgir", state: "Karnataka" },
    { city: "Yanam", state: "Puducherry" },
    { city: "Yawal", state: "Maharashtra" },
    { city: "Yellandu", state: "Telangana" },
    { city: "Yemmiganur", state: "Andhra Pradesh" },
    { city: "Yerraguntla", state: "Andhra Pradesh" },
    { city: "Yevla", state: "Maharashtra" },
    { city: "Zaidpur", state: "Uttar Pradesh" },
    { city: "Zamania", state: "Uttar Pradesh" },
    { city: "Zira", state: "Punjab" },
    { city: "Zirakpur", state: "Punjab" },
    { city: "Zunheboto", state: "Nagaland" }
]

export const starter_states = ["Andhra Pradesh",
]


export const starter_cities = [
    { city: "Bapatla", state: "Andhra Pradesh", lat: 15.9039445, lng: 80.46713749999999 },
    { city: "Chirala", state: "Andhra Pradesh", lat: 15.8166616, lng: 80.35869 },
    { city: "Guntur", state: "Andhra Pradesh", lat: 16.3066525, lng: 80.4365402 },

]

export const starter_cities_with_others = [
    { city: "Bapatla", state: "Andhra Pradesh", lat: 15.9039445, lng: 80.46713749999999 },
    { city: "Chirala", state: "Andhra Pradesh", lat: 15.8166616, lng: 80.35869 },
    { city: "Guntur", state: "Andhra Pradesh", lat: 16.3066525, lng: 80.4365402 },
    { city: "Others", state: "Andhra Pradesh", lat: 16.3066525, lng: 80.4365402 },
]