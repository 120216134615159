import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { Observable, merge, BehaviorSubject } from 'rxjs';
import { OrderStatus } from '../_models/order-status.enum';
// import { AuthService } from '../auth/auth.service';
import * as plusCode from 'open-location-code-typescript'
import { ProductStatus, RefProductStatus } from '../_models/models';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  LoggedInUserData: any = {};
  LoggedInUserUID: string = "";
  pageState: any = {};
  inProgress: boolean = false;
  productIdBeingViewed = 0;
  currentUserPhone: String = "";
  LoggedInUserPhone: String = "";
  // cartData: Observable<any[]> = new Observable<any[]>();
  // userOrderData: Observable<any[]> = new Observable<any[]>();
  // shopOrderData: Observable<any[]> = new Observable<any[]>();
  // productListHome: any = [];
  productListResults: any = {};

  refProductListResults: any = {};
  lastUpdatedLocation: any = {};
  staticLocationSelected: any = {};
  currentViewShop: number = 0;
  allCategories: any;

  shopProducts: any = {};
  constructor(public firestore: AngularFirestore) { }

  getPosition(): Promise<any> {
    this.inProgress = true;
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        // // console.log(resp);  
        this.lastUpdatedLocation = { lng: resp.coords.longitude, lat: resp.coords.latitude, hasLocation: true };
        this.inProgress = false;
        resolve(this.lastUpdatedLocation);
      },
        err => {
          this.inProgress = false;
          reject(err);
        }, { maximumAge: 75000, enableHighAccuracy: true });
    });

  }



  // public getCartDetails(){
  //   if(Object.keys(this.LoggedInUserData).length>0){
  //     // console.log(this.LoggedInUserData);
  //     this.cartData=this.firestore.collection('cartItems', ref => ref.where('UserKey', '==', this.LoggedInUserData.req_phone)).valueChanges();
  //     // this.cartData.subscribe((data:any)=>{
  //     //   // console.log(data);
  //     //   dataService.cartData=data;
  //     // })
  //   }
  // }

  mergeCart(cartInventoryItems) {
    if (this.LoggedInUserPhone != "") {
      let uk = this.LoggedInUserPhone;
      let ct = 1;
      let data = {
        UserKey: uk,
        Inventory: cartInventoryItems,
        type: ct,
        lastUpdated: new Date()
      }
      if (!localStorage.getItem('cartDocId')) {
        this.firestore.collection('cartItems').add(data).then(function (docRef) {
          // // console.log("Document written with ID: ", docRef.id);
          localStorage.setItem('cartDocId', docRef.id);
        })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
      } else {

        let d = this.firestore.collection('cartItems').doc(localStorage.getItem('cartDocId'));
        d.set(data, { merge: true });
      }
      localStorage.removeItem('anonymousCartData');
    }
  }

  deleteFromRemoteCart(inventoryId) {
    if (this.LoggedInUserPhone != "") {
      let uk = this.LoggedInUserPhone;
      let ct = 1;

      if (localStorage.getItem('cartDocId')) {

        let d = this.firestore.collection('cartItems').doc(localStorage.getItem('cartDocId'));
        // // console.log(inventoryId);
        let u = d.set({
          Inventory: { [inventoryId]: firebase.default.firestore.FieldValue.delete() }
        }, { merge: true })
        // d.set(data, { merge: true });
      }
    }
  }

  deleteFromLocalCart(inventoryId) {
    if (localStorage.getItem('anonymousCartData')) {
      let acd = {};
      if (JSON.parse(localStorage.getItem('anonymousCartData'))) {
        acd = JSON.parse(localStorage.getItem('anonymousCartData'));
      }
      if (acd[inventoryId]) {
        delete acd[inventoryId]
      }
      // acd.push(cartInventoryItem);
      localStorage.setItem("anonymousCartData", JSON.stringify(acd));
    }
  }

  createOrder(shopOrders, allShops, status, payment, totalPrice, individualPrice): Observable<any> {
    let resultObj = { 'success': false };
    let result = new BehaviorSubject<any>(resultObj);
    if (this.LoggedInUserPhone != "") {
      let uk = this.LoggedInUserPhone;
      let data = {
        UserKey: uk,
        UserUID: this.LoggedInUserUID,
        shops: shopOrders,
        allShops: allShops,
        status: status,
        prices: individualPrice,
        finalPrice: totalPrice,
        lastUpdated: new Date(),
        created: new Date()
      }
      if (localStorage.getItem('cartDocId')) {
        let d = this.firestore.collection('cartItems').doc(localStorage.getItem('cartDocId'));
        this.firestore.collection('userOrders').add(data).then(function (docRef) {
          return docRef;
        }).then((doc) => {
          if (payment) {
            this.firestore.collection('orderPayments').add({
              created: new Date(),
              status: "pending",
              price: totalPrice,
              userUID: this.LoggedInUserUID,
              orderId: doc.id
            }).then((pt) => {
              resultObj.success = true;
              resultObj['transactionId'] = pt.id;
              d.delete();
              result.next(resultObj);
            })

          } else {
            resultObj.success = true;
            d.delete();
            result.next(resultObj);
          }
        })
      }
    }
    return result;
  }


  createOrderNew(order, inventoryToKeep): Observable<any> {

    let resultObj = { 'success': false };
    let ct = 1
    let result = new BehaviorSubject<any>(resultObj);
    if (this.LoggedInUserPhone != "") {
      let uk = this.LoggedInUserPhone;
      let data = {
        UserKey: uk,
        UserUID: this.LoggedInUserUID,
        userAddress: order.userAddress,
        shops: order.shopOrders,
        allShops: order.allShops,
        status: order.status,
        prices: order.individualPrice,
        finalPrice: order.totalPrice,
        itemsPrice: order.itemsPrice,
        gatewayCharges: order.gatewayCharges,
        serviceCharges: order.serviceCharges,
        deliveryCharges: order.deliveryCharges,
        paymentOpted: order.payment,
        transactionMode: order.transactionMode,
        deliverable: order.deliverable,
        lastUpdated: new Date(),
        created: new Date()
      }

      if (localStorage.getItem('cartDocId')) {
        let d = this.firestore.collection('cartItems').doc(localStorage.getItem('cartDocId'));
        this.firestore.collection('userOrders').add(data).then(function (docRef) {
          return docRef;
        }).then((doc) => {
          if (order.payment) {
            this.firestore.collection('orderPayments').add({
              created: new Date(),
              status: "pending",
              price: order.totalPrice,
              type: 'initial',
              userUID: this.LoggedInUserUID,
              orderId: doc.id
            }).then((pt) => {
              resultObj.success = true;
              resultObj['transactionId'] = pt.id;
              if (Object.keys(inventoryToKeep).length > 0) {
                d.set({
                  UserKey: uk,
                  Inventory: inventoryToKeep,
                  type: ct,
                  lastUpdated: new Date()
                }, { merge: false })
              } else {
                d.delete();
              }

              result.next(resultObj);
            })

          } else {
            resultObj.success = true;
            if (Object.keys(inventoryToKeep).length > 0) {
              d.set({
                UserKey: uk,
                Inventory: inventoryToKeep,
                type: ct,
                lastUpdated: new Date()
              }, { merge: false })
            } else {
              d.delete();
            }
            result.next(resultObj);
          }
        })
      }
    }
    return result;
  }

  updateOrderStatus(shopId, orderId, orderStatus: OrderStatus, estPrice?: number) {

    if (this.LoggedInUserPhone != "") {

      let lu: string = shopId;
      let status = {};
      let estimatedPrice = {};
      status[lu] = orderStatus;

      let estp = null;
      if (estPrice != null) {
        estp = estPrice;
        // // console.log('price not null')
      }
      estimatedPrice[lu] = estp;
      let sh: any = [];
      sh.push({ s: status, t: new Date() });

      let data = {
        status: status,
        estimatedPrice: estimatedPrice,
        lastUpdated: new Date(),
      }

      // // console.log(data);

      let d = this.firestore.collection('userOrders').doc(orderId);
      d.set(data, { merge: true });
      // d.collection('statusHistory').add({ s: status, t: new Date() }).then(function (x) {
      // });


    }

  }


  updateCart(cartInventoryItem): Observable<boolean> {

    const resSubject = new BehaviorSubject<boolean>(false);

    if (this.LoggedInUserPhone != "") {
      let uk = this.LoggedInUserPhone;
      let ct = 1;
      let data = {
        UserKey: uk,
        Inventory: cartInventoryItem,
        type: ct,
        lastUpdated: new Date()
      }
      if (!localStorage.getItem('cartDocId')) {
        this.firestore.collection('cartItems').add(data).then(function (docRef) {
          // // console.log("Document written with ID: ", docRef.id);
          localStorage.setItem('cartDocId', docRef.id);
          resSubject.next(true);
        }).catch(function (error) {
          console.error("Error adding document: ", error);
        });
      } else {

        let d = this.firestore.collection('cartItems').doc(localStorage.getItem('cartDocId'));
        // // console.log(d);
        d.set(data, { merge: true }).then((data) => {
          resSubject.next(true);
        }).catch(function (error) {
          console.error("Error updating document: ", error);
        });;
      }
    } else {
      // if (!localStorage.getItem('sessionId')) {
      //   let sessionId: any = this.makeid(15);
      //   localStorage.setItem("sessionId", sessionId);
      // }

      if (!localStorage.getItem('anonymousCartData')) {
        localStorage.setItem("anonymousCartData", JSON.stringify(cartInventoryItem));
      } else if (localStorage.getItem('anonymousCartData')) {
        let acd = {};
        if (JSON.parse(localStorage.getItem('anonymousCartData'))) {
          acd = JSON.parse(localStorage.getItem('anonymousCartData'));
        }
        acd[Object.keys(cartInventoryItem)[0]] = cartInventoryItem[Object.keys(cartInventoryItem)[0]];
        // acd.push(cartInventoryItem);
        localStorage.setItem("anonymousCartData", JSON.stringify(acd));
      }
      resSubject.next(true);

    }

    return resSubject;


  }


  // public static getAvailableVideoInputs(): Promise<MediaDeviceInfo[]> {
  //   if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
  //     return Promise.reject('enumerateDevices() not supported.');
  //   }

  //   return new Promise((resolve, reject) => {
  //     navigator.mediaDevices.enumerateDevices()
  //       .then((devices: MediaDeviceInfo[]) => {
  //         resolve(devices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput'));
  //       })
  //       .catch(err => {
  //         reject(err.message || err);
  //       });
  //   });
  // }

  makeid(length) {
    var result = '';
    var d = new Date();
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789' + d;
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  getPlusCodeArea(lat: number, lng: number) {
    return plusCode.default.encode(lat, lng, 6);
  }


  getNeighbours(code) {
    const area = plusCode.default.decode(code);
    const originalLat = area.latitudeCenter;
    const originalLng = area.longitudeCenter;
    const height = area.latitudeHi - area.latitudeLo;
    const width = area.longitudeHi - area.longitudeLo;
    const neighbours: any = [];
    // The < check multiples height by 1.5 to avoid Javascript exactness issues with
    // floating point numbers.
    for (let lat = originalLat - height; lat < originalLat + height * 1.5; lat += height) {
      for (let lng = originalLng - width; lng < originalLng + width * 1.5; lng += width) {
        const code = plusCode.default.encode(lat, lng, area.codeLength)
        neighbours.push(code);
      }
    }
    return neighbours;
  }


  getStatusText(status: any) {
    let statusText = "Pending";
    switch (parseInt(status)) {
      case ProductStatus.PendingSNBVerification: {
        statusText = "Pending Verification";
        break;
      }
      case ProductStatus.PendingSNBExpertVerification: {
        statusText = "Pending Expert Verification";
        break;
      }
      case ProductStatus.SNBDEBeingVerified: {
        statusText = "DataEntry Being Verified";
        break;
      }
      case ProductStatus.SNBDXBeingVerified: {
        statusText = "DataExpert Being Verified";
        break;
      }
      case ProductStatus.SNBExpertDeclined: {
        statusText = "DataExpert Declined";
        break;
      }
      case ProductStatus.Active: {
        statusText = "Active";
        break;
      }
      case ProductStatus.Hold: {
        statusText = "Shop On Hold";
        break;
      }
      case RefProductStatus.Available: {
        statusText = "Available";
        break;
      }
      case RefProductStatus.ActiveDraft: {
        statusText = "Active Draft";
        break;
      }
      case RefProductStatus.Draft: {
        statusText = "Draft";
        break;
      }
      case RefProductStatus.Trash: {
        statusText = "Trash";
        break;
      }
      case ProductStatus.Trash: {
        statusText = "Trash";
        break;
      }
      case ProductStatus.AddedFromList: {
        statusText = "Added From List";
        break;
      }
      case ProductStatus.WaitForLaunch: {
        statusText = "Ready for Launch";
        break;
      }
      case ProductStatus.SNBDEDeclined: {
        statusText = "Data Entry Declined";
        break;
      }
    }
    return statusText;
  }


  downloadFile(data, filename = 'data', columnNames) {
    let csvData = this.ConvertToCSV(data, columnNames);
    // console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No;';

    for (let index in headerList) {
      row += headerList[index] + ';';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ';' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

}
