export enum Role {
    User = 4,
    Shop = 5,
    Service = 6,
    AffliatedShop = 7,
    AffliatedService = 8,
    Admin = 101,
    DataEntry = 109,
    DataExpert = 110,
    DeliveryManager = 111,
    CustomerSupport = 120
}
