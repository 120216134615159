<mat-accordion>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Basic details
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
            <mat-form-field class="w-100 m-2" color="accent">
                <mat-label>Shop Category</mat-label>
                <mat-select [(ngModel)]="shopData.shopCategory">
                    <mat-option color="accent" *ngFor="let n of shopCats" [value]="n.id">{{n.get('Name')}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100 m-2" appearance="standard" required>
                <mat-label>Shop Name</mat-label>
                <input [(ngModel)]="shopData.shopname" matInput placeholder="Shop Name">
                <mat-hint>Enter Shop Name</mat-hint>
            </mat-form-field>


            <mat-form-field class="w-100 m-2" appearance="standard" required>
                <mat-label>Shop Address Short</mat-label>
                <input [(ngModel)]="shopData.address_short" matInput placeholder="Shop Address Short">
                <mat-hint>Enter Shop Address Short</mat-hint>
            </mat-form-field>

            <mat-form-field class="w-100 m-2" appearance="standard" required>
                <mat-label>Shop Address Line 1</mat-label>
                <input [(ngModel)]="shopData.address_line1" matInput placeholder="Shop Address Line 1">
                <mat-hint>Enter Shop Address Line 1</mat-hint>
            </mat-form-field>


            <mat-form-field class="w-100 m-2" appearance="standard" required>
                <mat-label>Shop Address Line 2</mat-label>
                <input [(ngModel)]="shopData.address_line2" matInput placeholder="Shop Address Line 2">
                <mat-hint>Enter Shop Address Line 2</mat-hint>
            </mat-form-field>

            <mat-form-field class="w-100 m-2" appearance="standard">
                <mat-label>Landmark</mat-label>
                <input [(ngModel)]="shopData.landmark" matInput placeholder="Landmark">
                <mat-hint>Enter Landmark</mat-hint>
            </mat-form-field>

            <mat-form-field class="w-100 m-2" appearance="standard" required>
                <mat-label>City</mat-label>
                <input [(ngModel)]="shopData.address_city" matInput placeholder="City">
                <mat-hint>Enter City</mat-hint>
            </mat-form-field>


            <mat-form-field class="w-100 m-2" appearance="standard" required>
                <mat-label>State</mat-label>
                <input [(ngModel)]="shopData.address_state" matInput placeholder="State">
                <mat-hint>Enter State</mat-hint>
            </mat-form-field>


            <mat-form-field class="w-100 m-2" appearance="standard" required>
                <mat-label>Pin Code</mat-label>
                <input [(ngModel)]="shopData.address_pincode" matInput placeholder="Pin Code">
                <mat-hint>Enter Pin Code</mat-hint>
            </mat-form-field>



            <img width="256" [src]="croppedImage" />

            <ngx-dropzone class="w-25" [multiple]="false" (change)="fileChangeEvent($event)">
                <ngx-dropzone-label>Drop the Icon</ngx-dropzone-label>
            </ngx-dropzone>

            <image-cropper style="width: 256px;" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                [resizeToWidth]="512" [resizeToHeight]="512" [aspectRatio]="1/1" format="webp"
                [canvasRotation]="rotation/90" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>

            <mat-slider *ngIf="showRotater" [max]="270" [min]="0" [step]="90" [thumbLabel]="1" [tickInterval]="90"
                [(ngModel)]="rotation" [vertical]="false">
            </mat-slider>


            <button type="button" (click)="saveShop()" mat-raised-button>Save Shop</button>
        </div>



    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Timings and Advanced
            </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-slide-toggle class="example-margin d-block mb-2" [color]="color" [(ngModel)]="comingSoon"
            (change)="shopActive=!comingSoon">
            <span *ngIf="comingSoon">Coming soon</span><span *ngIf="!comingSoon">Already Opened</span>
        </mat-slide-toggle>

        <mat-slide-toggle class="example-margin d-block mb-2" [color]="color" [(ngModel)]="shopActive">
            <span *ngIf="shopActive">Shop Open</span><span *ngIf="!shopActive">Shop Closed</span>
        </mat-slide-toggle>

        <div *ngIf="shopActive">
            <mat-card class="m-1 p-0">

                <mat-tab-group class="m-1">
                    <mat-tab *ngFor="let d of daysOfWeek" label="{{d.name}}">
                        <!-- <mat-checkbox class="mx-2" [(ngModel)]="d.checked">Open?
                    </mat-checkbox> -->
                        <mat-slide-toggle class="example-margin d-block mb-2" [color]="color" [(ngModel)]="d.checked">
                            <span *ngIf="d.checked">Open</span><span *ngIf="!d.checked">Closed</span>
                        </mat-slide-toggle>
                        <div *ngIf="d.checked">
                            <mat-slide-toggle class="example-margin d-block mb-2" color="accent"
                                [(ngModel)]="d['multipleShifts']">
                                <span>Multiple Shifts</span>
                            </mat-slide-toggle>





                            <div *ngIf="!d['multipleShifts']">
                                <mat-form-field appearance="standard" class="w-50">
                                    <mat-label>Opens at</mat-label>
                                    <mat-select [(ngModel)]="d['start']">
                                        <mat-option *ngFor="let h of hourOfDay" [disabled]="h.value>=d['end']"
                                            [value]="h.value">
                                            {{h.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="standard" class="w-50">
                                    <mat-label>Closes at</mat-label>
                                    <mat-select [(ngModel)]="d['end']">
                                        <mat-option *ngFor="let h of hourOfDay" [disabled]="h.value<=d['start']"
                                            [value]="h.value">
                                            {{h.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                            <div *ngIf="d['multipleShifts']">
                                <mat-slide-toggle class="example-margin d-block mb-2" color="accent"
                                    [(ngModel)]="d['morningShift']">
                                    <span>Morning Shift</span>
                                </mat-slide-toggle>
                                <div *ngIf="d['morningShift']">
                                    <mat-form-field appearance="standard" class="w-50">
                                        <mat-label>Opens at</mat-label>
                                        <mat-select [(ngModel)]="d['mstart']">
                                            <mat-option *ngFor="let h of hourOfMorning" [disabled]="h.value>=d['mend']"
                                                [value]="h.value">
                                                {{h.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="standard" class="w-50">
                                        <mat-label>Closes at</mat-label>
                                        <mat-select [(ngModel)]="d['mend']">
                                            <mat-option *ngFor="let h of hourOfMorning"
                                                [disabled]="h.value<=d['mstart']" [value]="h.value">
                                                {{h.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>



                                <mat-slide-toggle class="example-margin d-block mb-2" color="accent"
                                    [(ngModel)]="d['afternoonShift']">
                                    <span>Afternoon Shift</span>
                                </mat-slide-toggle>
                                <div *ngIf="d['afternoonShift']">
                                    <mat-form-field appearance="standard" class="w-50">
                                        <mat-label>Opens at</mat-label>
                                        <mat-select [(ngModel)]="d['astart']">
                                            <mat-option *ngFor="let h of hourOfAfternoon"
                                                [disabled]="h.value>=d['aend']" [value]="h.value">
                                                {{h.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="standard" class="w-50">
                                        <mat-label>Closes at</mat-label>
                                        <mat-select [(ngModel)]="d['aend']">
                                            <mat-option *ngFor="let h of hourOfAfternoon"
                                                [disabled]="h.value<=d['astart']" [value]="h.value">
                                                {{h.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>



                                <mat-slide-toggle class="example-margin d-block mb-2" color="accent"
                                    [(ngModel)]="d['eveningShift']">
                                    <span>Evening Shift</span>
                                </mat-slide-toggle>
                                <div *ngIf="d['eveningShift']">
                                    <mat-form-field appearance="standard" class="w-50">
                                        <mat-label>Opens at</mat-label>
                                        <mat-select [(ngModel)]="d['estart']">
                                            <mat-option *ngFor="let h of hourOfEvening" [disabled]="h.value>=d['eend']"
                                                [value]="h.value">
                                                {{h.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="standard" class="w-50">
                                        <mat-label>Closes at</mat-label>
                                        <mat-select [(ngModel)]="d['eend']">
                                            <mat-option *ngFor="let h of hourOfEvening"
                                                [disabled]="h.value<=d['estart']" [value]="h.value">
                                                {{h.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>



                                <mat-slide-toggle class="example-margin d-block mb-2" color="accent"
                                    [(ngModel)]="d['nightShift']">
                                    <span>Night Shift</span>
                                </mat-slide-toggle>
                                <div *ngIf="d['nightShift']">
                                    <mat-form-field appearance="standard" class="w-50">
                                        <mat-label>Opens at</mat-label>
                                        <mat-select [(ngModel)]="d['nstart']">
                                            <mat-option *ngFor="let h of hourOfNight" [disabled]="h.value>=d['nend']"
                                                [value]="h.value">
                                                {{h.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="standard" class="w-50">
                                        <mat-label>Closes at</mat-label>
                                        <mat-select [(ngModel)]="d['nend']">
                                            <mat-option *ngFor="let h of hourOfNight" [disabled]="h.value<=d['nstart']"
                                                [value]="h.value">
                                                {{h.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>
                        </div>
                    </mat-tab>

                </mat-tab-group>
            </mat-card>



        </div>

        <mat-slide-toggle class="example-margin d-block mb-2" [color]="color" [(ngModel)]="noOTPRequired">
            Skip Order OTP
        </mat-slide-toggle>

        <mat-slide-toggle class="example-margin d-block mb-2" [color]="color" [(ngModel)]="hidePhone">
            Hide Phone Number
        </mat-slide-toggle>
        <mat-divider></mat-divider>

        <label id="example-radio-group-label">Eligible For Delivery?</label>
        <mat-radio-group class="delivery-radio-group" [(ngModel)]="eligibleForDelivery">
            <mat-radio-button class="example-radio-button" [value]="1">
                All
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="2">
                Some (Individual Preference)
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="3">
                None
            </mat-radio-button>
        </mat-radio-group>

        <mat-divider></mat-divider>

        <label id="example-radio-group-label">Payout Options</label>
        <mat-radio-group class="delivery-radio-group" [(ngModel)]="payoutType">
            <mat-radio-button class="example-radio-button" [value]="1">
                Instant
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="2">
                Scheduled
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="3">
                On Demand
            </mat-radio-button>
        </mat-radio-group>

        <button mat-raised-button *ngIf="updateTimeButton" (click)="updateTimeButton=false" color="primary">Update
            availability</button>

        <div *ngIf="!updateTimeButton">Are you sure you want to change timings?
        </div>
        <button mat-raised-button class="mr-2" *ngIf="!updateTimeButton"
            (click)="updateShopTimings();updateTimeButton=true" color="primary">Yes</button>
        <button mat-raised-button class="" *ngIf="!updateTimeButton" (click)="updateTimeButton=true"
            color="accent">No</button>



    </mat-expansion-panel>

</mat-accordion>