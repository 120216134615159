import { Component, OnInit } from '@angular/core';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  catData: any = [];
  currentCat: any = {};
  addCatForm: UntypedFormGroup;

  constructor(private fireRest: FirebaseRestService, private formBuilder: UntypedFormBuilder) {

    fireRest.getAllCategories().subscribe((cd: any) => {
      // // console.log(cd);
      if (cd.length > 0) {
        this.catData = cd;
      }

      // // console.log(cd);
    });

    this.addCatForm = this.formBuilder.group({
      name: [''],
      isActive: true
    });


  }

  showAddChildForm(cat) {
    // console.log(cat);
    this.currentCat = cat;
  }

  add_category() {
    const payload = {
      "name": this.addCatForm.value['name'],
      "enabled": this.addCatForm.value['isActive'], "parentName": this.currentCat.name, "parentId": this.currentCat.id
    }
    // console.log(payload);
    this.fireRest.addCategory(payload).subscribe((data) => {
      if (data) {
        window.location.reload();
      }
    });
  }

  ngOnInit(): void {
  }

}
