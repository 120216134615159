import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FirebaseRestService } from 'src/app/services/firebase-rest.service';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RestService } from 'src/app/services/rest.service';
import { GetChildernPipe } from 'src/app/pipes/get-childern.pipe';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TitleCasePipe } from '@angular/common';
import * as _ from 'lodash';

export interface Addon {
  adnname: string;
  addedPrice: number;
  foodType?: string;
  snbyId?: string;
}

@Component({
  selector: 'app-de-verify-products',
  templateUrl: './de-verify-products.component.html',
  styleUrls: ['./de-verify-products.component.css']
})
export class DeVerifyProductsComponent implements OnInit {

  editProductForm: UntypedFormGroup;
  l1categories: any = [];
  l2categories: any = [];
  l3categories: any = [];

  l1selected: string = "";
  l2selected: string = "";
  l3selected: string = "";
  formdetails: any = [];
  breadCrumb = "";
  parentId = 0;
  formParentName = "";
  @Input() inventoryId = "";
  @Output() changed: any = new EventEmitter<any>(false);
  closeResult = '';
  currentImage = '';
  currentImageIndex = -1;
  deliveryEligible = false;
  catPathFull: any = [];
  catPathNames: any = [];
  priceChangesEvery = "0";
  allCategories: any = [];

  productDetails: any = {};

  // codeReader=new BrowserMultiFormatReader();

  public captures: Array<any>;
  public capturesForUpload: Array<any>;
  categoryId: any = "";
  shopDetails: any = {};
  dxComment = "";
  comment = "";
  lastPriceUpdate: any = {};
  embeeded = true;

  variants: any[] = [];
  addons: any[] = []
  customization: any = {};
  currentAddonDetails: any = {};
  showAdnForm = false;
  vshowAdnForm = false;
  remoteAdonItems = [];
  filteredremoteAdonItems = [];

  addonGroupDetails: any = {};

  remoteAdonGroups = [];
  filteredremoteAdonGroups = [];


  addVrnts: boolean = false;
  addAdns: boolean = false;

  allowVariants = false;
  allowAddons = false;
  allowAddonsBasedOnVariants = false;


  isFoodItem: boolean = false;
  foodTypeField: any = {};

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new UntypedFormControl();
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];

  showDeclineConfirm = false;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private rest: RestService, private route: ActivatedRoute, private router: Router, private formBuilder: UntypedFormBuilder, private modalService: NgbModal, public _snackBar: MatSnackBar, public fireRest: FirebaseRestService, private getChildern: GetChildernPipe, private titlecasePipe: TitleCasePipe) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));

  }

  ngOnInit() {
    if (this.inventoryId == "") {
      this.embeeded = false;
      this.route.params.subscribe(params => {
        if (params.productId && params.productId.length > 0) {
          this.inventoryId = params.productId;
          this.fetch(this.inventoryId);
        } else {
          this.fetch();
        }

      });
    } else {
      this.fetch(this.inventoryId);
    }
    // this.getProductCloud();
  }




  private fetch(invId?: string) {
    // console.log(invId);

    this.captures = [];
    this.capturesForUpload = [];
    this.l1categories = [];
    this.l2categories = [];
    this.l3categories = [];

    this.l1selected = "";
    this.l2selected = "";
    this.l3selected = "";
    this.formdetails = [];
    this.breadCrumb = "";
    this.parentId = 0;
    this.formParentName = "";
    this.inventoryId = (invId && invId.length > 0) ? invId : "";
    this.closeResult = '';
    this.currentImage = '';
    this.currentImageIndex = -1;
    this.deliveryEligible = false;
    this.catPathFull = [];
    this.catPathNames = [];
    this.priceChangesEvery = "0";
    this.categoryId = "";
    this.shopDetails = {};
    this.dxComment = "";
    this.comment = "";


    this.variants = [];
    this.addons = []
    this.customization = {};
    this.currentAddonDetails = {};
    this.showAdnForm = false;
    this.vshowAdnForm = false;
    this.remoteAdonItems = [];
    this.filteredremoteAdonItems = [];

    this.addonGroupDetails = {};

    this.remoteAdonGroups = [];
    this.filteredremoteAdonGroups = [];


    this.addVrnts = false;
    this.addAdns = false;

    this.allowVariants = false;
    this.allowAddons = false;
    this.allowAddonsBasedOnVariants = false;
    this.isFoodItem = false;
    this.foodTypeField = {};

    this.getProduct((invId && invId.length > 0) ? invId : null);
    this.editProductForm = this.formBuilder.group({});
  }

  // public capture(webcamImage: WebcamImage) {
  //   if (this.captures.length < 5) {
  //     this.captures.push(webcamImage.imageAsDataUrl);
  //     this.capturesForUpload.push(webcamImage.imageAsBase64);
  //     // // console.log(this.canvas.nativeElement.toDataURL("image/png").replace(/^data:image.+;base64,/, ''));
  //   } else {
  //     alert('only 5 photos allowed');
  //   }
  // }

  openPhoto(content, capture, index) {
    // this.codeReader.decodeFromImageUrl(capture).then((data)=>{
    //   // console.log(data);
    // }).catch((err)=>{
    //   // console.log(err);
    // });
    this.currentImage = capture;
    this.currentImageIndex = index;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.deleteCapture(result);
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  deleteCapture(id) {
    this.captures.splice(id, 1);
    this.capturesForUpload.splice(id, 1);
  }


  onSelect(e) {
    // console.log(e.addedFiles);
    const reader = new FileReader();
    reader.onload = (event) => {
      // console.log(event);
      this.captures.push(event.target.result);
    }
    reader.readAsDataURL(e.addedFiles[0]);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.captures, event.previousIndex, event.currentIndex);
  }


  addedFiles(e) {
    // console.log(e);
  }
  getProduct(invId?: string) {
    // console.log(invId);
    this.fireRest.getAllCategories().subscribe((allCat) => {
      if (allCat.length > 0) {
        // console.log(allCat);
        this.allCategories = allCat;
        this.fireRest.getSNBDEUnverifiedProduct((invId && invId.length > 0) ? invId : null).subscribe((product: any) => {
          if (Object.keys(product).length > 0) {
            this.productDetails = product;
            this.categoryId = this.productDetails.get('categoryId');
            if (this.productDetails.get('metaData')) {
              this.deliveryEligible = this.productDetails.get('metaData').deliveryEligible;
              this.priceChangesEvery = this.productDetails.get('metaData').priceChangesEvery;
            }

            // console.log(this.productDetails.data());
            this.lastPriceUpdate = this.productDetails.get('lastPriceUpdate');
            let cpf = this.productDetails.get('catPathFull').reverse();
            this.tags = this.productDetails.get('Tags') ? this.productDetails.get('Tags') : []
            this.shopDetails = this.productDetails.get('shopDetails');
            this.dxComment = this.productDetails.get('DXDeclineComment') ? this.productDetails.get('DXDeclineComment') : "";
            this.comment = this.productDetails.get('DEComment') ? this.productDetails.get('DEComment') : "";


            //setting variations

            if (this.productDetails.get('hasVariants') == 1) {
              // this.allowVariants = true;
              this.addVrnts = true;
            } else if (this.productDetails.get('allowAddons') == 1) {
              // this.allowAddons = true;
              this.addAdns = true;
            }




            if (this.productDetails.get('variations') && this.productDetails.get('variations').length > 0) {
              const v = { groupName: this.productDetails.get('variations')[0].groupname, items: [] };
              for (const variant of this.productDetails.get('variations')) {
                const varItem = {
                  vrntname: variant.name,
                  price: variant.price,
                  discount: variant.discount ? variant.discount : 0,
                  hasAddons: variant.variationallowaddon,
                  snbyId: variant.snbyId,
                  addongroups: []
                };

                for (const adon of variant.addon) {
                  const addonDetails = {
                    groupName: "",
                    minSelection: adon.addon_item_selection_min,
                    maxSelection: adon.addon_item_selection_max,
                    snbyId: adon.snbyId,
                    addons: []
                  };
                  // const addonGroupDetail = {};
                  // addonGroupDetail[adon.snbyId] = {}
                  this.addonGroupDetails[adon.snbyId] = {};

                  varItem.addongroups.push(addonDetails)
                }
                v.items.push(varItem)
              }
              this.variants.push(v);
              if (this.remoteAdonItems.length == 0) {
                this.getRemoteAddonItems();
              }
            } else if (this.productDetails.get('addons') && this.productDetails.get('addons').length > 0) {
              for (const adon of this.productDetails.get('addons')) {
                const addonDetails = {
                  groupName: "",
                  minSelection: adon.addon_item_selection_min,
                  maxSelection: adon.addon_item_selection_max,
                  snbyId: adon.snbyId,
                  addons: []
                };
                // const addonGroupDetail = {};
                // addonGroupDetail[adon.snbyId] = {}
                this.addonGroupDetails[adon.snbyId] = {};

                this.addons.push(addonDetails)
              }
              if (this.remoteAdonItems.length == 0) {
                this.getRemoteAddonItems();
              }
            }


            if (Object.keys(this.addonGroupDetails).length > 0) {
              // console.log(this.addonGroupDetails);
              this.fireRest.getAddonGroupsById(this.addonGroupDetails).subscribe((data) => {
                // console.log(data);
                if (Object.keys(data).length > 0) {
                  // console.log(this.variants);

                  if (this.variants.length > 0) {

                    this.variants.forEach((v) => {

                      v.items.forEach(item => {

                        item.addongroups.forEach(ag => {
                          const agdetails = data[ag.snbyId];
                          ag.groupName = agdetails['addongroup_name'];
                          // console.log(agdetails);

                          agdetails.addongroupitems.forEach((agd) => {
                            ag.addons.push({
                              adnname: agd.addonitem_name,
                              addedPrice: agd.addonitem_price,
                              foodType: agd.foodType,
                              snbyId: agd.snbyId
                            })
                          })
                        });

                      });



                    })



                  } else if (this.addons.length > 0) {
                    this.addons.forEach(ag => {
                      const agdetails = data[ag.snbyId];
                      ag.groupName = agdetails['addongroup_name'];
                      console.log(agdetails);

                      agdetails.addongroupitems.forEach((agd) => {
                        ag.addons.push({
                          adnname: agd.addonitem_name,
                          addedPrice: agd.addonitem_price,
                          foodType: agd.foodType,
                          snbyId: agd.snbyId
                        })
                      })
                    });
                  }
                }


              })
            }

            //end of setting customization






            // // console.log(cpf);
            for (let i = 0; i < cpf.length; i++) {
              // // console.log(cpf[i]);
              if (i == 1) {
                this.getCategoriesByParent(cpf[i].parentId, "l1", cpf[i].id);
              }
              if (i == 2) {
                this.getCategoriesByParent(cpf[i].parentId, "l2", cpf[i].id);
              }
              if (i == 3) {
                this.getCategoriesByParent(cpf[i].parentId, "l3", cpf[i].id);
              }
              if (i == cpf.length - 1) {
                this.getFormDetailsByCategory(cpf[i].id, this.productDetails.get('details'));
              }
            }

            this.inventoryId = this.productDetails.id;

            this.fireRest.getProductImagesAsBaseBlob(this.productDetails.id).subscribe((imgList: any[]) => {
              // this.captures=imgList;
              console.log(imgList);
              let iList = [];
              imgList.forEach((img) => {
                var reader = new FileReader();
                reader.readAsDataURL(img);
                reader.onloadend = (event) => {
                  // // console.log(event);
                  var base64data = reader.result;
                  iList.push(base64data);
                }

              })
              this.captures = iList;

            });



            // this.getFormDetailsByCategory(this.productDetails.get('categoryId'), this.productDetails.get('details'));

          }
        });
      }
    }
    );
  }

  getCategoriesByParent(parent, level: String, selected?: string) {
    console.log(parent);

    this.formdetails = [];
    if (parent != "") {
      let data = this.getChildern.transform(this.allCategories, parent, 'id');
      if (level == "l1") {
        this.l1categories = data;
        if (selected && selected.length > 0) {
          this.l1selected = selected;
        }
        this.l2selected = "";
        this.l3selected = "";
        this.l2categories = [];
        this.l3categories = [];
      } else if (level == "l2") {
        this.l2categories = data;
        if (selected && selected.length > 0) {
          this.l2selected = selected;
        }
        this.l3selected = "";
        this.l3categories = [];
      } else if (level == "l3") {
        if (data && data.length > 0) {
          this.l3categories = data;
          if (selected && selected.length > 0) {
            this.l3selected = selected;
          }
        } else {
          this.l3selected = "";
          this.l3categories = [];
          this.getFormDetailsByCategory(parent, this.productDetails.get('details'));
        }
      } else if (level == "l4") {
        this.getFormDetailsByCategory(parent, this.productDetails.get('details'));
      }
    }

    console.log(level);

    console.log(this.l3categories);

  }


  getFormDetailsByCategory(id, productDetails) {
    //*_*_*_*need to change this to getbycatid
    // // console.log(id);
    // // console.log(productDetails);
    this.formdetails = [];
    this.fireRest.getCategoryById(id).subscribe((data) => {
      if (Object.keys(data).length > 0) {
        this.allTags = data.get('tags');
      }

    })
    this.fireRest.getFormByCategoryId(id).subscribe((fieldData) => {
      // // console.log(fieldData);
      if (Object.keys(fieldData).length > 0) {

        this.categoryId = id;

        // fieldData = fieldData.filter(item => (exclude.indexOf(item.docId) === -1));
        for (const c in this.editProductForm.controls) {
          this.editProductForm.removeControl(c)
        }
        this.fireRest.getIal().subscribe((allIALS) => {
          if (allIALS.length > 0) {
            for (let f in fieldData) {
              // f.docId
              // // console.log(fieldData[f]);
              // // console.log(allIALS);
              const curIAL = allIALS.find(ele => ele.ialId == fieldData[f].docId);
              // // console.log(curIAL);
              fieldData[f].cl = curIAL.cl;
              fieldData[f].l = curIAL.l;
              fieldData[f].d = curIAL.d;
              fieldData[f].v = productDetails[f];
              if (curIAL.dm) {
                fieldData[f].dm = curIAL.dm;
              }
              fieldData[f].rfd = curIAL.rfd ? true : false;

              if (curIAL.ref_values) {
                fieldData[f].ref_values = curIAL.ref_values;
              }

              this.editProductForm.addControl(f, new UntypedFormControl(productDetails[f]));
              this.formdetails.push(fieldData[f])

            }
            this.foodTypeField = this.formdetails.find(ele => ele.docId == "LCcTnuJznJxAgqQBaVPw")
            if (this.foodTypeField && Object.keys(this.foodTypeField).length > 0) {
              this.isFoodItem = true;
            }
            this.formdetails = this.formdetails.sort((a, b) => a.order - b.order);
          }

        });
      }
    });
    // this.formParentName = name;
  }


  addAddon(index: number, ca: any) {
    // console.log(index);
    // console.log(ca);

    let adon: Addon = { adnname: ca.adnname, addedPrice: ca.addedPrice };

    if (this.isFoodItem) {
      adon.foodType = ca.foodType;
    }
    if (ca.snbyId) {
      adon.snbyId = ca.snbyId;
    }
    if (typeof (index) != "undefined" && this.addons[index]) {
      this.addons[index].addons.push(adon)
    } else {
      const v = { groupName: "", maxSelection: 1, minSelection: 0, addons: [adon] };
      this.addons.push(v);
    }
    this.showAdnForm = false;
    this.currentAddonDetails = {};
    this.filteredremoteAdonItems = [];
  }

  addAddonGroup() {

    if (this.remoteAdonItems.length == 0) {
      this.getRemoteAddonItems();
    }
    const v = { groupName: "", maxSelection: 1, minSelection: 0, addons: [] };
    this.addons.push(v);

  }



  private getRemoteAddonItems() {
    this.fireRest.getProductAddonItems().subscribe((adnData) => {
      if (adnData.success == true && adnData.data && !adnData.data.empty) {
        this.remoteAdonItems = adnData.data.docs.map(ele => this._map(ele.data()));
        this.remoteAdonItems = _.uniqBy(this.remoteAdonItems, function (e) {
          return e.adnname + e.addedPrice;
        });
        // console.log(this.remoteAdonItems);
      }
    });
  }



  adndrop(event: CdkDragDrop<Addon[]>, index) {
    moveItemInArray(this.addons[index].addons, event.previousIndex, event.currentIndex);
  }

  vadndrop(event: CdkDragDrop<Addon[]>, vid, itemId, index) {
    moveItemInArray(this.variants[vid].items[itemId].addongroups[index].addons, event.previousIndex, event.currentIndex);
  }

  filterRemoteAddons(e) {
    // console.log(e);
    if (this.currentAddonDetails.adnname && this.currentAddonDetails.adnname.length > 0) { this.filteredremoteAdonItems = this.remoteAdonItems.filter(ele => ele.adnname.toLowerCase().includes(this.currentAddonDetails.adnname.toLowerCase())) }
    else {
      this.filteredremoteAdonItems = [];
    }
  }


  private _map(adon: any): any {
    const finalValue: any = {
      adnname: adon.addonitem_name,
      addedPrice: adon.addonitem_price,
      snbyId: adon.snbyId,
      addonitemid: adon.addonitemid
    }

    if (adon.foodType) {
      finalValue['foodType'] = adon.foodType;
    }

    return finalValue;
  }








  addVariant(index?: number) {
    // console.log(index);

    if (typeof (index) != "undefined" && this.variants[index]) {
      this.variants[index].items.push({ vrntname: "", price: "", discount: "", hasAddons: false, addongroups: [] })
    } else {
      const v = { groupName: "Variations", items: [{ vrntname: "", price: "", discount: "", hasAddons: false, addongroups: [] }] };
      this.variants.push(v);
    }
  }

  addAddonToVariant(vid, itemId, aId, ca) {

    let adon: Addon = { adnname: ca.adnname, addedPrice: ca.addedPrice };

    if (this.isFoodItem) {
      adon.foodType = ca.foodType;
    }
    if (ca.snbyId) {
      adon.snbyId = ca.snbyId;
    }

    if (typeof (aId) != "undefined" && this.variants[vid].items[itemId].addongroups[aId]) {
      this.variants[vid].items[itemId].addongroups[aId].addons.push(adon)
    } else {
      this.variants[vid].items[itemId].addongroups.push({ groupName: "", maxSelection: 1, minSelection: 0, addons: [adon] })
    }

    this.vshowAdnForm = false;
    this.currentAddonDetails = {};
    this.filteredremoteAdonItems = [];

  }

  addAddonGroupToVariant(vid, itemId) {

    if (this.remoteAdonItems.length == 0) {
      this.getRemoteAddonItems();
    }
    this.variants[vid].items[itemId].addongroups.push({ groupName: "", maxSelection: 1, minSelection: 0, addons: [] })

  }

  removeGroup(index) {
    this.variants.splice(index, 1);
  }

  removeItem(index1, index2) {
    if (index2 == 0) {
      this.removeGroup(index1)
    } else {
      this.variants[index1].items.splice(index2, 1);
    }
  }


  validateCustomization() {
    let isValid = true;

    if (this.addVrnts) {
      this.addons = [];
      this.addAdns = false;
    } else {
      this.variants = [];
    }

    if (this.addVrnts) {
      for (let v of this.variants) {
        if (v.items.length < 2) {
          isValid = false;
        } else if (v.groupName == "") {
          isValid = false;
        } else {
          this.allowVariants = true;
          for (let item of v.items) {
            if (item.vrntname == "" || item.price == null) {
              isValid = false;
            } else {
              if (item.addongroups.length > 0) {
                this.allowAddonsBasedOnVariants = true;
                for (let ag of item.addongroups) {
                  if (ag.groupName == "") {
                    isValid = false;
                  } else if (ag.addons.length < 1 || ag.addons.filter(ele => ele.adnname == "" || ele.addedPrice == null || (this.isFoodItem && ele.foodType == "")).length > 0) {
                    isValid = false;
                  }
                }
              } else {
                this.allowAddonsBasedOnVariants = false;
              }
            }
          }

        }
      }
    }
    if (this.addAdns) {
      if (this.addons.length < 1) {
        isValid = false;
      } else {
        if (this.addons.length > 0) {
          this.allowAddons = true;
          for (let ag of this.addons) {
            if (ag.groupName == "") {
              isValid = false;
            } else if (ag.addons.length < 1 || ag.addons.filter(ele => ele.adnname == "" || ele.addedPrice == null || (this.isFoodItem && ele.foodType == "")).length > 0) {
              isValid = false;
            }
          }
        } else {
          this.allowAddons = false;
        }
      }
    }

    if (this.allowAddonsBasedOnVariants) {
      this.allowAddons = true;
    }

    return isValid;
  }


  searchGoogle(keyword) {
    if (keyword.length > 3)
      window.open('https://www.google.com/search?tbm=isch&q=' + keyword, '_blank');
    // this.iframeUrl = 'https://www.google.com/search?q=' + keyword;
  }

  call(link) {
    window.open(link, '_self');
  }




  onSubmit(formData, goNext: boolean = true, decline: boolean = false) {
    // console.log(this.categoryId);
    // console.log(this.captures);

    // console.log(this.tags);
    // this.fireRest.uploadProductImages(this.inventoryId, this.captures).subscribe((upload) => {
    //   // // console.log(upload);
    //   if (upload.status == "done") {
    //     this.router.navigate(['/shop/product-details/' + this.inventoryId]);
    //   }
    // })
    // return;
    this.editProductForm.updateValueAndValidity();
    console.log(this.editProductForm.value);

    if (this.editProductForm.valid) {

      if (this.validateCustomization()) {
        console.log("valid customization");

        this.customization['allowAddons'] = this.allowAddons;
        this.customization['allowAddonsBasedOnVariants'] = this.allowAddonsBasedOnVariants;
        this.customization['hasVariants'] = this.allowVariants;
        this.customization['addons'] = this.addons;
        this.customization['variants'] = this.variants;
      }

      const metaData: any = {};
      metaData['deliveryEligible'] = this.deliveryEligible;
      metaData['priceChangesEvery'] = this.priceChangesEvery;
      this.rest.deUpdateProduct(formData, this.categoryId, metaData, this.captures.length, this.comment, decline, this.tags, this.customization, this.inventoryId).subscribe((data) => {
        if (data.status == "success") {
          // console.log(data);
          this.fireRest.uploadProductImages(this.inventoryId, this.captures).subscribe((upload) => {
            // // console.log(upload);
            if (upload.status == "done") {
              if (!this.embeeded) {
                if (goNext) {
                  this.fetch();
                } else {
                  this.router.navigate(['/']);
                }
              } else {
                this.changed.emit(true);
              }

              // this.router.navigate(['/shop/product-details/' + data.resp.productId]);
            }
          })


        }
      })
    } else {
      this._snackBar.open("Please Fille the required fields", "Okay", {
        duration: 2000,
      });


    }


  }


  deleteProduct(id, goNext: boolean = true) {

    this.rest.deleteProduct(id).subscribe((resp) => {
      if (resp.status == "success") {
        if (!this.embeeded) {
          if (goNext) {
            this.fetch();
          } else {
            this.router.navigate(['/']);
          }
        } else {
          this.changed.emit(true);
        }

        // this.router.navigate(['/shop/product-details/' + data.resp.productId]);
      }
    })
  }


  //code for autofill chips

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      this.tags.push(this.titlecasePipe.transform(value.trim()));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

}
