import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FirebaseRestService } from 'src/app/services/firebase-rest.service';
import { RestService } from 'src/app/services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';
import * as _ from 'lodash';

export interface Addon {
  adnname: string;
  addedPrice: number;
  foodType?: string;
  snbyId?: string;
}

@Component({
  selector: 'app-expert-verify-products',
  templateUrl: './expert-verify-products.component.html',
  styleUrls: ['./expert-verify-products.component.css']
})
export class ExpertVerifyProductsComponent implements OnInit {

  formdetails: any = [];
  breadCrumb = "";
  @Input() inventoryId = "";
  @Output() changed: any = new EventEmitter<any>(false);

  closeResult = '';
  currentImage = '';
  currentImageIndex = -1;
  captures: Array<any>;
  captureThumbs: Array<any>;
  declined = false;
  comment = "";
  decomment = "";
  pData: any = {};
  catPath = "";
  shopDetails: any = {};
  iframeUrl = "";

  embeeded = true;



  variants: any[] = [];
  addons: any[] = []
  customization: any = {};
  currentAddonDetails: any = {};
  showAdnForm = false;
  vshowAdnForm = false;
  remoteAdonItems = [];
  filteredremoteAdonItems = [];

  addonGroupDetails: any = {};

  remoteAdonGroups = [];
  filteredremoteAdonGroups = [];


  addVrnts: boolean = false;
  addAdns: boolean = false;

  allowVariants = false;
  allowAddons = false;
  allowAddonsBasedOnVariants = false;

  isFoodItem: boolean = false;
  foodTypeField: any = {};


  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new UntypedFormControl();
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  constructor(public sanitizer: DomSanitizer, public rest: RestService, private route: ActivatedRoute, private router: Router, private modalService: NgbModal, public fireRest: FirebaseRestService, private titlecasePipe: TitleCasePipe) {

    this.captures = [];
    this.captureThumbs = [];

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));
  }

  ngOnInit() {
    if (this.inventoryId == "") {
      this.embeeded = false;
      this.route.params.subscribe(params => {
        if (params.productId && params.productId.length > 0) {
          this.inventoryId = params.productId;
          this.getProductCloud(this.inventoryId);
        } else {
          this.getProductCloud();
        }

      });
    } else {
      this.getProductCloud(this.inventoryId);
    }
  }

  openPhoto(content, capture, index) {
    if (this.captures.length > 0 && this.captures[index]) {
      this.currentImage = this.captures[index];
      this.currentImageIndex = index;
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        // this.deleteCapture(result);
      }, (reason) => {
        this.closeResult = `Dismissed `;
      });
    }

  }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }




  getProductCloud(invId?: string) {

    this.fireRest.getSNBEXPERTUnverifiedProduct((invId && invId.length > 0) ? invId : null).subscribe((product) => {
      if (Object.keys(product).length > 0) {
        // console.log(product.data());
        const p = product;
        this.inventoryId = p.id;
        this.pData = p.data();
        this.comment = p.get('DXDeclineComment');
        this.decomment = p.get('DEComment');
        this.tags = p.get('Tags') ? p.get('Tags') : [];
        this.fireRest.getProductImagesStatic(p.id).subscribe((imgList: any[]) => {
          // console.log(imgList);
          this.captures = imgList;
          this.captureThumbs = imgList;
        });

        this.fireRest.getCategoryById(this.pData.categoryId).subscribe((data) => {
          if (Object.keys(data).length > 0) {
            this.allTags = data.get('tags');
          }

        })

        // console.log(this.pData);


        this.catPath = p.data().catPathNames.reverse().join(' / ');
        this.shopDetails = p.data().shopDetails;
        // console.log(this.shopDetails);

        if (p.get('hasVariants') == 1) {
          // this.allowVariants = true;
          this.addVrnts = true;
        } else if (p.get('allowAddons') == 1) {
          // this.allowAddons = true;
          this.addAdns = true;
        }




        if (p.get('variations') && p.get('variations').length > 0) {
          const v = { groupName: p.get('variations')[0].groupname, items: [] };
          for (const variant of p.get('variations')) {
            const varItem = {
              vrntname: variant.name,
              price: variant.price,
              hasAddons: variant.variationallowaddon,
              snbyId: variant.snbyId,
              addongroups: []
            };

            for (const adon of variant.addon) {
              const addonDetails = {
                groupName: "",
                minSelection: adon.addon_item_selection_min,
                maxSelection: adon.addon_item_selection_max,
                snbyId: adon.snbyId,
                addons: []
              };
              // const addonGroupDetail = {};
              // addonGroupDetail[adon.snbyId] = {}
              this.addonGroupDetails[adon.snbyId] = {};

              varItem.addongroups.push(addonDetails)
            }
            v.items.push(varItem)
          }
          this.variants.push(v);
          if (this.remoteAdonItems.length == 0) {
            this.getRemoteAddonItems();
          }
        } else if (p.get('addons') && p.get('addons').length > 0) {
          for (const adon of p.get('addons')) {
            const addonDetails = {
              groupName: "",
              minSelection: adon.addon_item_selection_min,
              maxSelection: adon.addon_item_selection_max,
              snbyId: adon.snbyId,
              addons: []
            };
            // const addonGroupDetail = {};
            // addonGroupDetail[adon.snbyId] = {}
            this.addonGroupDetails[adon.snbyId] = {};

            this.addons.push(addonDetails)
          }
          if (this.remoteAdonItems.length == 0) {
            this.getRemoteAddonItems();
          }
        }


        if (Object.keys(this.addonGroupDetails).length > 0) {
          // console.log(this.addonGroupDetails);
          this.fireRest.getAddonGroupsById(this.addonGroupDetails).subscribe((data) => {
            // console.log(data);
            if (Object.keys(data).length > 0) {
              // console.log(this.variants);

              if (this.variants.length > 0) {

                this.variants.forEach((v) => {

                  v.items.forEach(item => {

                    item.addongroups.forEach(ag => {
                      const agdetails = data[ag.snbyId];
                      ag.groupName = agdetails['addongroup_name'];
                      // console.log(agdetails);

                      agdetails.addongroupitems.forEach((agd) => {
                        ag.addons.push({
                          adnname: agd.addonitem_name,
                          addedPrice: agd.addonitem_price,
                          foodType: agd.foodType,
                          snbyId: agd.snbyId
                        })
                      })
                    });

                  });



                })



              } else if (this.addons.length > 0) {
                this.addons.forEach(ag => {
                  const agdetails = data[ag.snbyId];
                  ag.groupName = agdetails['addongroup_name'];
                  console.log(agdetails);

                  agdetails.addongroupitems.forEach((agd) => {
                    ag.addons.push({
                      adnname: agd.addonitem_name,
                      addedPrice: agd.addonitem_price,
                      foodType: agd.foodType,
                      snbyId: agd.snbyId
                    })
                  })
                });
              }
            }


          })
        }

        this.formdetails = p.data()._clientView;

        this.foodTypeField = this.formdetails.find(ele => ele.docId == "LCcTnuJznJxAgqQBaVPw")
        if (this.foodTypeField && Object.keys(this.foodTypeField).length > 0) {
          this.isFoodItem = true;
        }

        this.formdetails = this.formdetails.sort((a, b) => a.o - b.o);
      }
    });
  }

  searchGoogle(keyword) {
    const sq = keyword + "";
    if (sq.length > 5)
      window.open('https://www.google.com/search?q=' + sq, '_blank');
    // this.iframeUrl = 'https://www.google.com/search?q=' + keyword;
  }

  call(link) {
    window.open(link, '_self');
  }

  private getRemoteAddonItems() {
    this.fireRest.getProductAddonItems().subscribe((adnData) => {
      if (adnData.success == true && adnData.data && !adnData.data.empty) {
        this.remoteAdonItems = adnData.data.docs.map(ele => this._map(ele.data()));
        this.remoteAdonItems = _.uniqBy(this.remoteAdonItems, function (e) {
          return e.adnname + e.addedPrice;
        });
        // console.log(this.remoteAdonItems);
      }
    });
  }

  private _map(adon: any): any {
    const finalValue: any = {
      adnname: adon.addonitem_name,
      addedPrice: adon.addonitem_price,
      snbyId: adon.snbyId,
      addonitemid: adon.addonitemid
    }

    if (adon.foodType) {
      finalValue['foodType'] = adon.foodType;
    }

    return finalValue;
  }


  submitResponse(approve: boolean) {
    this.rest.saveDXResponse(this.inventoryId, approve, this.comment, this.tags).subscribe((data) => {
      if (data.status === "success") {
        if (!this.embeeded) {

          this.router.navigate(['/']);

        } else {
          this.changed.emit(true);
        }
      } else if (data.status === "fail") {
        alert("error occured, Please contact developer support.")
      }

    })
  }

  //code for autofill chips

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      this.tags.push(this.titlecasePipe.transform(value.trim()));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  getObjlength(obj) {
    return Object.keys(obj).length;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

}
