<h1 mat-dialog-title>select Rider</h1>
<div mat-dialog-content>

    <mat-radio-group style="width: fit-content;" aria-labelledby="rider-list-group" class="rider-list-group"
        [(ngModel)]="selectedDP">
        <mat-radio-button *ngFor="let r of data.ars" [value]="r.id" class="my-2">
            <div>{{r.n}}</div>
            <div>({{r.sd}} km from shop)</div>
        </mat-radio-button>
    </mat-radio-group>

    <button mat-raised-button color="primary" [mat-dialog-close]="{data:data,rider:selectedDP}"> Assign </button>
</div>
<!-- <div mat-dialog-actions> -->
<!-- <button mat-button (click)="onNoClick()">Cancel</button> -->

<!-- </div> -->