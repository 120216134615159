import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { RestService } from '../services/rest.service';
import { daysOfWeek, hourOfAfternoon, hourOfDay, hourOfEvening, hourOfMorning, hourOfNight } from '../_models/models';

@Component({
  selector: 'app-edit-shop-details',
  templateUrl: './edit-shop-details.component.html',
  styleUrls: ['./edit-shop-details.component.css']
})
export class EditShopDetailsComponent implements OnInit {

  logo: any;
  rotation: number = 0;
  showRotater: boolean = false;
  shopCats: any = [];
  shopCat = "";
  ownerId = "";
  shopId = "";
  shopData: any = {};
  hasLogo: boolean = false;


  updateTimeButton = true;
  daysOfWeek = daysOfWeek
  hourOfDay = hourOfDay
  hourOfMorning = hourOfMorning
  hourOfAfternoon = hourOfAfternoon
  hourOfEvening = hourOfEvening
  hourOfNight = hourOfNight
  shopOnline = true;
  tcount = 2;
  timingSet = Array(this.tcount).fill(0).map((x, i) => i);
  // twoShifts: boolean = false;

  noOTPRequired: boolean = false;
  hidePhone: boolean = false;
  eligibleForDelivery: number = 1;
  payoutType: number = 1;

  color: ThemePalette = 'primary';
  shopActive = true;
  comingSoon = false;



  constructor(private fireRest: FirebaseRestService, private route: ActivatedRoute, private router: Router, private rest: RestService, private _snackBar: MatSnackBar) {
    this.route.params.subscribe(params => {
      console.log(params);
      this.ownerId = params.ownerId;
      this.shopId = params.shopId;
      this.getShopDetails(this.shopId)
    })
    this.getShopCats();
  }

  ngOnInit(): void {
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    console.log(event);
    let e: any = {};
    e.target = {
      files: event.addedFiles
    }

    this.imageChangedEvent = e;
  }
  imageCropped(event: ImageCroppedEvent) {

    this.croppedImage = event.base64;
  }
  imageLoaded(image?: HTMLImageElement) {
    // console.log("loaded");
    this.showRotater = true
    // show cropper
  }
  cropperReady() {
    console.log("cropper ready");

    // cropper ready
  }
  loadImageFailed() {
    console.log("load fail");

    // show message
  }

  private getShopCats() {
    this.fireRest.getShopCategories().subscribe((data) => {
      this.shopCats = data.docs;
      // console.log(data.docs);

    });
  }


  private getShopDetails(shopId: string) {
    this.fireRest.getShopByShopId(shopId).subscribe((data) => {
      if (!data.empty) {

        this.shopData = data.docs[0].data()
        this.hasLogo = (this.shopData.hasLogo == true || this.shopData.hasLogo == false) ? this.shopData.hasLogo : false;

        // console.log(this.shopData);

        this.shopOnline = this.shopData.status == 2 ? true : false;

        const timings = this.shopData.hours;

        this.noOTPRequired = (this.shopData.noOTPRequired == true || this.shopData.noOTPRequired == false) ? this.shopData.noOTPRequired : false;
        this.hidePhone = (this.shopData.hidePhone == true || this.shopData.hidePhone == false) ? this.shopData.hidePhone : false;
        this.eligibleForDelivery = this.shopData.eligibleForDelivery ? this.shopData.eligibleForDelivery : 1;
        this.payoutType = this.shopData.payoutType ? this.shopData.payoutType : 1;
        // console.log(shopdata.docs[0].get('shopActive') != true && shopdata.docs[0].get('shopActive') != false);
        this.shopActive = this.shopData.shopActive != true && this.shopData.shopActive != false ? true : this.shopData.shopActive;
        this.comingSoon = this.shopData.comingSoon != true && this.shopData.comingSoon != false ? false : this.shopData.comingSoon;
        if (timings && timings.length > 0) {
          // this.start1 = timings.openTime;
          // this.end1 = timings.closeTime;
          // this.start2 = timings.openTime2;
          // this.end2 = timings.closeTime2;
          // console.log(timings);

          this.daysOfWeek = timings;

          for (let d of this.daysOfWeek) {
            console.log(d);

          }
        }



      }

    })
  }

  saveShop() {
    if (this.shopData.shopCategory.length > 0 && this.shopId.length > 0 && this.ownerId.length > 0) {

      if (this.hasLogo == false && this.croppedImage.length > 0) {
        this.hasLogo = true;
      }


      this.fireRest.editShopDetails(this.shopId, this.ownerId, this.shopData, this.croppedImage, this.hasLogo).subscribe((data) => {
        console.log(data);
        this.router.navigate(['/manage-shops'])

      })
    }
  }


  updateShopTimings() {

    this.rest.updateShopTimings(this.shopId, this.ownerId, this.daysOfWeek, this.shopActive, this.comingSoon, this.noOTPRequired, this.hidePhone, this.eligibleForDelivery, this.payoutType).subscribe((data) => {
      if (data.status === "success") {
        this._snackBar.open("Timings/Settings Updated", "Okay", { duration: 2000 })
      }
    })

  }



}
