<button mat-raised-button class="ml-2" printSectionId="printIt" ngxPrint>
    <mat-icon>print</mat-icon>Print
</button>

<div style="padding: 3rem!important; width: 100%; text-align:center" id="printIt">
    <img style="width:70%;display: block;margin:auto" src="./assets/logo.png">
    <div style="font: 400 30px/35px Montserrat,Helvetica Neue,sans-serif;
    letter-spacing: normal;
    margin: 32px 0px 24px 0px;text-align:center;">
        Open <strong>shopnearby.me</strong> or <strong>snby.me</strong> and scan the QR code below to order our products
        online
    </div>


    <div style="text-align:center;">
        <div style="    width: 396px;
        margin: auto;
        background: #eeeeeeff;
        padding: 18px;
        border-radius: 36px;">
            <qrcode [elementType]="'img'" [qrdata]="decodedlink" [colorDark]="'#000000ff'" [colorLight]="'#eeeeeeff'"
                [width]="360" [errorCorrectionLevel]="'M'">
            </qrcode>
        </div>
    </div>





    <div style="font: 400 27px/33px Montserrat,Helvetica Neue,sans-serif;
    letter-spacing: normal;
    margin: 16px 0px 16px 0px;text-align:center;">
        {{shopData.shopname}}
    </div>
    <div style="font: 400 20px/32px Montserrat,Helvetica Neue,sans-serif;
    letter-spacing: normal;
    margin: 16px 0px;text-align:center;">
        {{shopData.address}}
    </div>
</div>