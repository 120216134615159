import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from '../services/sidenav.service';
import { AuthService } from '../auth/auth.service';
import { Role } from '../_models/role.enum';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  userLoggedIn = false;
  userRole = 0;

  isAdmin = false;
  isDE = false;
  isDX = false;
  isDM = false;
  isCS = false;


  constructor(private authService: AuthService, public router: Router, private sidenav: SidenavService) {
    this.authService.loginStatus.subscribe((data) => {
      if (data) {
        this.userLoggedIn = true;


        this.userRole = this.authService.userRole;
        switch (this.userRole) {
          case Role.Admin:
            this.isAdmin = true;
            break;
          case Role.DataEntry:
            this.isDE = true;
            break;
          case Role.DataExpert:
            this.isDX = true;
            break;
          case Role.DeliveryManager:
            this.isDM = true;
            break;
          case Role.CustomerSupport:
            this.isCS = true;
            break;
          default:
            this.isAdmin = false;
            this.isDE = false;
            this.isDX = false;
            this.isDM = false;
            this.isCS = false;
            break;
        }
      } else {

        this.userLoggedIn = false;
        this.userRole = 0;
        this.isAdmin = false;
        this.isDE = false;
        this.isDX = false;
        this.isDM = false;
        this.isCS = false;
      }
    });
  }

  ngOnInit(): void {

  }

  checkRole(roles: number[] = []) {

    return roles.includes(this.userRole);
  }



  goto_dashboard() {
    this.router.navigate(['/']);
    this.sidenav.close();
  }

  add_inv_field() {
    this.router.navigate(['/add-inventory-field']);
    this.sidenav.close();
  }
  edit_inv_field() {
    this.router.navigate(['/edit-inventory-field']);
    this.sidenav.close();
  }
  add_category() {
    this.router.navigate(['/add-category']);
    this.sidenav.close();
  }
  edit_category() {
    this.router.navigate(['/edit-category']);
    this.sidenav.close();
  }
  create_form() {
    this.router.navigate(['/create-form']);
    this.sidenav.close();
  }
  ref_products() {
    this.router.navigate(['/ref-products']);
    this.sidenav.close();
  }

  manage_order() {
    this.router.navigate(['/manage-orders']);
    this.sidenav.close();
  }

  reports() {
    this.router.navigate(['/reports']);
    this.sidenav.close();
  }
  manage_search() {
    this.router.navigate(['/manage-search']);
    this.sidenav.close();
  }
  cart_data() {
    this.router.navigate(['/cart-data']);
    this.sidenav.close();
  }

  manage_shop() {
    this.router.navigate(['/manage-shops']);
    this.sidenav.close();
  }

  manage_users() {
    this.router.navigate(['/manage-users']);
    this.sidenav.close();
  }

  login() {
    this.router.navigate(['/login']);
    this.sidenav.close();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
    this.sidenav.close();
  }




}
