import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import 'firebase/firestore';
import { Data } from '../data'
import { IAL } from '../ial'
import { FirebaseRestService } from '../services/firebase-rest.service';

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.css']
})
export class CreateFormComponent implements OnInit {
  catData: any = [];
  ial: any = [];
  cpath: any = [];
  currentCategory: any;
  currentCategoryId: string;
  catSelected = false;
  currentFormFields: any;
  parentFormFields: any;
  constructor(public firestore: AngularFirestore, private fireRest: FirebaseRestService) {


    this.doAction()


  }

  doAction() {
    let rcdata = this.firestore.collection('all_categories').doc('all').get();
    rcdata.subscribe((cd: any) => {
      this.catData = cd.data().categories;
      // // console.log(cd);
    });

    let rial = this.firestore.collection('ial').valueChanges({ idField: 'docId' });
    rial.subscribe((iald: any) => {
      this.ial = iald;
      // console.log(iald);
    });

  }

  getCategoryPath(name: String) {

    let cdata = this.firestore.collection('categories', ref => (ref.where('n', '==', name))).valueChanges({ idField: 'docId' });
    cdata.subscribe((d: any) => {
      this.cpath.push(d[0]);

      // console.log(d);
      if (d[0].p.indexOf('Root') == -1) {
        this.getCategoryPath(d[0].p);
      } else {
        for (let c of this.cpath) {
          if (c.form && c.form.length > 0) {
            for (let f of c.form) {
              for (let f2 of this.ial) {
                if (f2.docId == f.docId) {
                  f2.disabled = true;
                  f2.checked = true;
                  f2.order = f.order;
                  f2.required = f.required;
                }
              }
            }
          }
        }
        // this.ial=this.ial.sort((a, b) => a.checked - b.checked)
        // console.log(this.ial);
      }
    })
  }

  getCategory(id: string) {

    let cdata = this.fireRest.getAllCategories();
    cdata.subscribe((d: any) => {
      // console.log(d);
      this.currentCategory = d.find(element => element.id === id);
      this.currentCategoryId = id;
      // // console.log(this.merge_array(this.ial,this.currentCategory.form) );
      if (this.currentCategory.p.indexOf('Root') == -1) {
        this.getCategoryPath(this.currentCategory.p);
      }
      if (this.currentCategory.form && this.currentCategory.form.length > 0) {
        for (let f of this.currentCategory.form) {
          for (let f2 of this.ial) {
            if (f2.docId == f.docId) {
              f2.checked = true;
              f2.required = f.required;
              f2.order = f.order;

            }
          }
        }
      }
      this.catSelected = true;
    })
  }


  ngOnInit(): void {

    // // console.log(data);
    // this.catData = data;
    // // console.log(this.getChildern.transform(this.catData,'Products'));


  }

  getForm(event) {
    this.cpath = [];
    // // console.log(event);
    this.currentCategoryId = event;
    this.fireRest.getFormByCategoryId(event).subscribe((fData) => {
      if (fData && Object.keys(fData).length > 0) {
        // console.log(fData);
        // // console.log(this.ial);
        for (let f2 of this.ial) {
          // if (f2.docId == f.docId) {
          if (fData[f2.docId]) {
            f2.checked = true;
            f2.required = fData[f2.docId].required;
            f2.order = fData[f2.docId].order;

            if (this.currentCategoryId !== fData[f2.docId].catId) {
              f2.disabled = true;
            }
          }


          // }
        }
      }
      this.catSelected = true;

    });
  }



  submitForm() {
    // // console.log(this.ial.filter(item=>(item.checked)));

    let f: any = [];

    let selected = this.ial.filter(item => (item.checked && !item.disabled));
    // // console.log(selected);

    for (let v of selected) {
      let x: any = {};
      x.docId = v.docId;
      if (v.required) {
        x.required = v.required;
      } else {
        x.required = false;
      }
      if (v.order > 0) {
        x.order = v.order;
      } else {
        x.order = 1;
      }
      f.push(x);
    }


    let data = { form: f }

    //     // console.log(data);
    // return;
    this.firestore.collection('categories').doc(this.currentCategoryId).set(data, { merge: true }).then(function (docRef) {
      // // console.log("Document written with ID: ", docRef);

    })

  }

}
