import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseRestService } from '../services/firebase-rest.service';

import * as plusCode from 'open-location-code-typescript'
import { RestService } from '../services/rest.service';



@Component({
  selector: 'app-dp-finder',
  templateUrl: './dp-finder.component.html',
  styleUrls: ['./dp-finder.component.css']
})
export class DpFinderComponent implements OnInit {

  orderId;
  anim = "DROP";
  map;
  shopPlusCodes: string[];
  shopPlusCodeAreas: string[];

  plusCodeChars = [
    '2', '3', '4', '5', '6', '7', '8', '9', 'C', 'F', 'G', 'H', 'J', 'M', 'P', 'Q', 'R', 'V', 'W', 'X'
  ]

  constructor(private route: ActivatedRoute, private fireRest: FirebaseRestService, private rest: RestService) {
    this.route.params.subscribe((params) => {
      this.orderId = params.orderId;
      this.fireRest.getOrderById(this.orderId).subscribe((orderData) => {
        if (orderData.exists) {
          // console.log(orderData.data());
          this.shopPlusCodes = [];
          this.shopPlusCodeAreas = [];
          orderData.get('shops').forEach(shop => {
            this.shopPlusCodes.push(shop.shopDetails.plusCode)
            this.shopPlusCodeAreas.push(shop.shopDetails.plusCodeArea)
            // console.log(this.getNeighbours(shop.shopDetails.plusCode));

          });

          // console.log(this.shopPlusCodes);

          //check if any rider in the shop area
          this.fireRest.getRiders(6, this.getNeighbours(this.shopPlusCodeAreas[0])).subscribe((data) => {
            // console.log(data); return;

            if (data.length === 0) {
              //if no riders found above check if any rider in the shop neighbour area
              this.fireRest.getRiders(6, this.getNeighbours(this.shopPlusCodeAreas[0])).subscribe((ndata) => {
                if (ndata.length === 0) {
                  this.filterRiders(ndata, this.shopPlusCodes[0], orderData.get('shops')[0]);
                }

              })
            } else {
              this.filterRiders(data, this.shopPlusCodes[0], orderData.get('shops')[0]);
            }

          })


        }

      })
    })
  }

  ngOnInit(): void {
  }


  filterRiders(riderData, pca, shopData) {
    // console.log(riderData.docs);
    this.map = new google.maps.Map(
      document.getElementById("gmap") as HTMLElement,
      {
        zoom: 13,
        center: this.getlatLngForPC(pca),
        draggable: true,
        streetViewControl: false,
        mapTypeControl: false,
        scrollwheel: false
      }
    );

    this.addMarkerToMap(this.getlatLngForPC(pca), this.map, "s", shopData);
    const pcaArr = riderData;

    // console.log(pcaArr[0].data());


    riderData.forEach(rider => {
      // pcaArr.push(rider)
      this.addMarkerToMap(this.getlatLngForPC(rider['pc10']), this.map, "d", rider);
    });


    let selectedRider
    let i = 1.5;
    while (i < 20 && !selectedRider) {

      const neighbours = this.getNeighbours(pca, i);
      let ra = pcaArr.filter(ele => neighbours.includes(ele['pc10']))
      // console.log(pcaArr[0]);

      // console.log(neighbours);


      if (ra.length > 0) {

        selectedRider = ra[0];
      }

      i = i + 1;
    }
    console.log(i);

    console.log(selectedRider);
    if (!selectedRider) {
      const newPCA = pca.split('+')[0] + "+";
      i = 1.5;
      while (i < 10 && !selectedRider) {

        const neighbours = this.getNeighbours(newPCA, i);
        let ra = pcaArr.filter(ele => neighbours.includes(ele['pc8']))
        // console.log(pcaArr);

        // console.log(neighbours);


        if (ra.length > 0) {

          selectedRider = ra[0];
        }

        i = i + 1;
      }

    }
    console.log(i);
    console.log(selectedRider);

    if (!selectedRider) {
      const newPCA = pca.slice(0, -5) + "00+";
      i = 1.5;
      while (i < 10 && !selectedRider) {
        // console.log(newPCA);

        const neighbours = this.getNeighbours(newPCA, i);
        let ra = pcaArr.filter(ele => neighbours.includes(ele['pc6']))
        // console.log(pcaArr);

        // console.log(neighbours);


        if (ra.length > 0) {

          selectedRider = ra[0];
        }

        i = i + 1;
      }

    }
    console.log(i);
    console.log(selectedRider);


  }



  getNeighbours(code, factor = 1.5) {

    const area = plusCode.default.decode(code);
    const originalLat = area.latitudeCenter;
    const originalLng = area.longitudeCenter;
    const height = area.latitudeHi - area.latitudeLo;
    const width = area.longitudeHi - area.longitudeLo;
    const neighbours: any = [];
    // The < check multiples height by 1.5 to avoid Javascript exactness issues with
    // floating point numbers.
    for (let lat = originalLat - height; lat < originalLat + height * factor; lat += height) {
      for (let lng = originalLng - width; lng < originalLng + width * factor; lng += width) {
        const code = plusCode.default.encode(lat, lng, area.codeLength)
        neighbours.push(code);
      }
    }
    return neighbours;
  }

  getlatLngForPC(code) {
    const area = plusCode.default.decode(code);
    return { lat: area.latitudeCenter, lng: area.longitudeCenter }

  }

  addMarkerToMap(position, map, label, entityData) {

    const marker = new google.maps.Marker({
      position: position,
      map: map,
      clickable: true,
      icon: {
        url: "assets/" + label + ".png"
      },
    });

    if (label === 'd') {
      // console.log(entityData);

      const infoWindow = new google.maps.InfoWindow({
        content: "Name: " + entityData.name + "<br>Phone: <a href='tel:" + entityData.phone + "' >" + entityData.phone + "</a>"
      })
      infoWindow.open(map, marker);
    }

    marker.addListener("click", () => {

      if (label === 's') {
        const infoWindow = new google.maps.InfoWindow({
          content: "Name: " + entityData.shopDetails.shopname
        })
        infoWindow.open(map, marker);
        console.log(entityData);

      } else {
        this.handleMarkerClick(entityData, map, marker);
      }


    });
  }

  handleMarkerClick(data, map, marker) {
    // console.log(data);

    const infoWindow = new google.maps.InfoWindow({
      content: "Name: " + data.name + "<br>Phone: <a href='tel:" + data.phone + "' >" + data.phone + "</a>"
    })
    infoWindow.open(map, marker);



    // if (confirm("assign?")) {
    //   console.log(data);
    //   this.rest.assignRider(this.orderId, data.id).subscribe((data) => {
    //     console.log(data);

    //   })

    // }

  }

}
