import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { I } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { RestService } from '../services/rest.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-search-management',
  templateUrl: './search-management.component.html',
  styleUrls: ['./search-management.component.css']
})
export class SearchManagementComponent implements OnInit {
  s: any = [];
  rotation: number = 0;
  showRotater: boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  selectedKey: any = { k: "", st: "", t: "", l: 1, c: "", sl: [] };

  retrievedData: any = []
  skSub: Subscription;
  a: any = [];
  constructor(private fireRest: FirebaseRestService, private rest: RestService) {
    this.skSub = this.fireRest.getAllSearchKeys().subscribe((data) => {
      console.log(data);
      if (data.length > 0) {
        this.retrievedData = _.sortBy(data, 'r');
      }

    })
  }

  ngOnInit(): void {
  }

  resetKeys() {
    // this.skSub.unsubscribe();
    // this.skSub = this.fireRest.getAllSearchKeys().subscribe((data) => {
    //   console.log(data);
    //   if (data.length > 0) {
    //     this.a = data;
    //     this.retrievedData = data.map(ele => ele);
    //   }

    // })

    this.a = this.retrievedData.filter(ele => (ele.id != this.selectedKey.id) && (!this.selectedKey.sl.map(sl => sl.id).includes(ele.id)))
    // console.log(this.a);


    // console.log(this.retrievedData);


  }



  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  submitSK() {
    console.log(this.selectedKey);
    // console.log(this.croppedImage);

    this.rest.addSearchKey(this.selectedKey, this.croppedImage).subscribe((data) => {
      console.log(data);

    })
  }



  fileChangeEvent(event: any): void {
    console.log(event);
    let e: any = {};
    e.target = {
      files: event.addedFiles
    }

    this.imageChangedEvent = e;
  }
  imageCropped(event: ImageCroppedEvent) {

    this.croppedImage = event.base64;
  }
  imageLoaded(image?: HTMLImageElement) {
    // console.log("loaded");
    this.showRotater = true
    // show cropper
  }
  cropperReady() {
    console.log("cropper ready");

    // cropper ready
  }
  loadImageFailed() {
    console.log("load fail");

    // show message
  }

}
