<div>
    <div class="row">
        <div class="col-sm-6">
            <section class="example-section">
                <span class="example-list-section">
                    <mat-checkbox class="example-margin" [checked]="allChecked" [indeterminate]="someComplete()"
                        (change)="setAll($event.checked)">
                        {{catFilter.name}}&nbsp;&nbsp;(&nbsp;{{catFilter.count}}&nbsp;)
                    </mat-checkbox>
                </span>
                <span class="example-list-section">
                    <ul>
                        <li *ngFor="let subcat of catFilter.subcats">
                            <mat-checkbox [(ngModel)]="subcat.checked" [color]="subcat.color"
                                (ngModelChange)="updateallChecked()">
                                {{subcat.name}}&nbsp;&nbsp;(&nbsp;{{subcat.count}}&nbsp;)
                            </mat-checkbox>
                        </li>
                    </ul>
                </span>
            </section>
        </div>
        <div class="col-sm-6">
            <section class="example-section">
                <span class="example-list-section">
                    <mat-checkbox class="example-margin" [checked]="allStatusChecked"
                        [indeterminate]="someComplete('status')" (change)="setAll($event.checked,'status')">
                        {{statusFilter.name}}&nbsp;&nbsp;(&nbsp;{{statusFilter.count}}&nbsp;)
                    </mat-checkbox>
                </span>
                <span class="example-list-section">
                    <ul>
                        <li *ngFor="let substat of statusFilter.substatus">
                            <mat-checkbox [(ngModel)]="substat.checked" [color]="substat.color"
                                (ngModelChange)="updateallChecked('status')">
                                {{substat.name}}&nbsp;&nbsp;(&nbsp;{{substat.count}}&nbsp;)
                            </mat-checkbox>
                        </li>
                    </ul>
                </span>
            </section>

            <section class="example-section">
                <span class="example-list-section">
                    <mat-checkbox class="example-margin" [checked]="allICChecked" [indeterminate]="someComplete('ic')"
                        (change)="setAll($event.checked,'ic')">
                        {{icFilter.name}}&nbsp;&nbsp;(&nbsp;{{icFilter.count}}&nbsp;)
                    </mat-checkbox>
                </span>
                <span class="example-list-section">
                    <ul>
                        <li *ngFor="let subcount of icFilter.subcounts">
                            <mat-checkbox [(ngModel)]="subcount.checked" [color]="subcount.color"
                                (ngModelChange)="updateallChecked('ic')">
                                {{subcount.name}}&nbsp;&nbsp;(&nbsp;{{subcount.count}}&nbsp;)
                            </mat-checkbox>
                        </li>
                    </ul>
                </span>
            </section>
        </div>
    </div>

    <div class="row">
        <div class="col-auto">
            <button color="accent" type="button" mat-raised-button (click)="applyFilters()"><i
                    class="fas fa-filter"></i>&nbsp;<span>Apply
                    FIlters</span></button>
        </div>
        <div class="col-auto">
            <button color="accent" type="button" mat-raised-button (click)="applyFilters(true)">
                <span class="fa-stack">
                    <i style="font-size: 0.6rem;" class="fa fa-filter fa-stack-1x"></i>
                    <i style="font-size: 1.2rem;" class="fa fa-ban fa-stack-1x"></i>
                </span>&nbsp;<span>Clear
                    FIlters</span></button>
        </div>
    </div>

</div>


<mat-accordion multi>

    <div *ngFor="let p of productListHome let i=index;" role="group" class="mx-auto mt-1  btn-group w-100 d-flex row">
        <mat-expansion-panel class="w-100 {{getProductStatusColor(p.status)}}" [expanded]="step === i"
            (opened)="setStep(i)">
            <mat-expansion-panel-header class="h-auto py-2">
                <img src="https://firebasestorage.googleapis.com/v0/b/shopnearby-301e7-product-image-bucket/o/reference_product_images%2F{{p.productId}}%2F0.jpg?alt=media"
                    alt="no-image" width="40" />
                <h3 class="mat-subheading-2">{{p.Name}}</h3>


                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Status: </span>
                <strong>{{getStatusText(p.status)}}</strong>
            </mat-expansion-panel-header>
            <app-ref-product-edit [refProductId]="p.productId" (changed)="handleChange($event)"></app-ref-product-edit>



        </mat-expansion-panel>
    </div>
</mat-accordion>
<mat-toolbar color="white">
    <button mat-raised-button *ngIf="pageNumber==loadedPageNumber&&productListHome.length<totalCount" type="button"
        class="w-auto mx-auto" (click)="applyFilters(false,true)">
        Load More
    </button>
</mat-toolbar>