import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, tap, delay } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../services/data.service'
import { Role } from '../_models/role.enum';
import * as firebase from 'firebase/compat/app';
import 'firebase/auth'


const restUrl = `https://shopnearby.me/smarqo/api/rest.php?service=`;
const clientRestUrl = `https://shopnearby.me/smarqo/api/client_rest.php?service=`;
const authRestUrl = `https://asia-south1-shopnearby-301e7.cloudfunctions.net/app/`;//old one `https://asia-east2-shopnearby-auth.cloudfunctions.net/app/`;

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  currentUser: any;
  loginStatus = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, public dataService: DataService) {
    firebase.default.auth().onAuthStateChanged((data) => {
      // // console.log(data);
      if (data != null && data.isAnonymous == false) {
        // // console.log('registered user');
        this.setLogin(data);

        data.getIdTokenResult().then((idTokenResult) => {
          // Confirm the user is an Admin.
          // console.log(idTokenResult);
          if (idTokenResult.claims.role == 'admin') {
            this.setUserRole(Role.Admin);
            this.loginStatus.next(true);
          } else if (idTokenResult.claims.role == 'dataentry') {
            this.setUserRole(Role.DataEntry);
            this.loginStatus.next(true);
          } else if (idTokenResult.claims.role == 'dataexpert') {
            this.setUserRole(Role.DataExpert);
            this.loginStatus.next(true);
          } else if (idTokenResult.claims.role == 'deliverymanager') {
            this.setUserRole(Role.DeliveryManager);
            this.loginStatus.next(true);
          } else if (idTokenResult.claims.role == 'customersupport') {
            this.setUserRole(Role.CustomerSupport);
            this.loginStatus.next(true);
          } else {
            // firebase.auth().signOut();
          }

        })
          .catch((error) => {
            // console.log(error);
          });
      } else if (data != null && data.isAnonymous == true) {
        // console.log('anonymous user');
        // console.log(data);

        this.loginStatus.next(false);
        this.setLoggedIn(false);
      } else {
        // // console.log('nothing user');
        // firebase.auth().signInAnonymously().then((aldata)=>{
        //   // // console.log(data);
        // }).catch((err)=>{
        //   // console.log(err);
        // });
        this.loginStatus.next(false);
        this.setLoggedIn(false);

      }
    })



  }


  private loggedInStatus = false;//JSON.parse(localStorage.getItem('loggedIn')||'false');
  private userType = -1;
  // user={'username':'kurmarao','password':'5544'};
  // store the URL so we can redirect after logging in
  redirectUrl: string;

  public setLogin(data) {
    this.currentUser = data;
    if (this.currentUser) {
      this.setLoggedIn(true);
      // this.dataService.LoggedInUserPhone = data.phoneNumber.substring(3);
      // this.dataService.LoggedInUserUID = data.uid;
    }
  }

  nologin(): Observable<boolean> {
    return of(true).pipe(
      delay(1000),
      tap(val => this.setLoggedIn(true))
    );
  }

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
    // localStorage.setItem('loggedIn','true');
  }

  setUserRole(t: number) {
    this.userType = t;
  }

  get isLoggedIn() {
    return this.loggedInStatus;//JSON.parse(localStorage.getItem('loggedIn')||this.loggedInStatus.toString());
  }

  get userRole() {
    return this.userType;
  }



  cloudLogin(body) {
    if (this.currentUser != null) {
      this.logout();
    }
    return this.http.post<any>(authRestUrl + 'login', body)

  }


  setClaims(body) {
    return this.http.post<any>(authRestUrl + 'set_claims', body)
  }



  logout(): void {
    firebase.default.auth().signOut();
    this.setLoggedIn(false);
    this.loginStatus.next(false);

    // // console.log(this.currentUser);
    this.clearSessionVars();
  }

  private clearSessionVars() {
    sessionStorage.setItem('current_url', '');
    sessionStorage.removeItem('current_url');
    this.currentUser = null;

    localStorage.clear();

  }
}