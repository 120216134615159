<div class="container-fluid p-0">

    <div *ngFor="let ml of productListFiltered" class="mt-2">
        {{ml.show}}
        <ng-container>

            <mat-card class="productHead" style="background-color: #eaeaea;color: #000000;">
                <span class="mat-title">{{ml.l|titlecase}}
                    ({{ml.c}})</span>
            </mat-card>
            <div class="row mx-1">

                <div *ngFor="let n of ml.items" style="min-height: 133px;"
                    class="w-100 d-flex p-1 col-xs-12 col-sm-6 col-md-6 col-lg-4">
                    <ng-container>

                        <app-product-view [productData]="n" [selected]="selections[n.productId]"
                            (selection)="handleSelection($event)" class="w-100">
                        </app-product-view>&nbsp;

                    </ng-container>
                </div>

            </div>
        </ng-container>
    </div>



</div>