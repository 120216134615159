import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service'
import { DataService } from './data.service'
import { map, catchError, tap } from 'rxjs/operators';
import { Role } from '../_models/role.enum';
import * as firebase from 'firebase/compat/app';
import 'firebase/auth'
import { RefProductStatus } from '../_models/models';

// const endpoint = 'https://shopnearby.me/smarqo/api/rest.php?service=';
const apiKey = "AIzaSyDhvyEAvrBaa0gr9ArRYAhL7kNMYO_OX90";
const geolocationendpoint = 'https://www.googleapis.com/geolocation/v1/geolocate?key=' + apiKey;
const geocodingendpoint = 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBNVFckTcPOc8IXbQlKZHN7Hmf0XWRDheY';
const authRestUrl = `https://asia-south1-shopnearby-301e7.cloudfunctions.net/app/`
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

const httpFormOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) }

const httpSearchOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/javascript' }) }

@Injectable()
export class RestService {



  constructor(private http: HttpClient, public authService: AuthService, public dataService: DataService) {
    if (!this.authService.isLoggedIn) {
      return;
    }
  }


  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getGeoLocation(): Observable<any> {


    return this.http.post<any>(geolocationendpoint, httpSearchOptions).pipe(
      map(this.extractData));

  }


  getGeoCode(components): Observable<any> {


    return this.http.post<any>(authRestUrl + "geo_locate", components).pipe(
      map(this.extractData));

  }

  getServiceRequests(serviceId): Observable<any> {
    if (this.authService.currentUser != null && (this.authService.userRole == Role.Service || this.authService.userRole == Role.AffliatedService)) {
      const body = {
        serviceId: serviceId,
        uid: this.authService.currentUser.uid
      }

      return this.http.post<any>(authRestUrl + "check_service_requests", body).pipe(
        map(this.extractData));
    } else {
      return null;
    }

  }

  getPOSMenu(apiKey: string, apiSecret: string, accessToken: string, url: string, restID): Observable<any> {
    if (this.authService.currentUser != null) {
      const body = {
        "app_key": apiKey,
        "app_secret": apiSecret,
        "access_token": accessToken,
        "restID": restID
      }

      return this.http.post<any>(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }).pipe(
        map(this.extractData));
    } else {
      return null;
    }

  }

  getSearchResults(keyword, location): Observable<any> {
    const body = {
      keyword: keyword,
      location: location
    }

    return this.http.post<any>(authRestUrl + "search", body).pipe(
      map(this.extractData));


  }


  getProductReferences(keyword): Observable<any> {
    const body = {
      keyword: keyword
    }

    return this.http.post<any>(authRestUrl + "ref_product_search", body).pipe(
      map(this.extractData));


  }

  lockDEVerification(productId): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};
        if (productId && productId.length > 0) {
          body = {
            productId: productId,
            idToken: token
          }
        }

        const call = this.http.post<any>(authRestUrl + "lock_de_verification", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }

  lockDXVerification(productId): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};
        if (productId && productId.length > 0) {
          body = {
            productId: productId,
            idToken: token
          }
        }

        const call = this.http.post<any>(authRestUrl + "lock_dx_verification", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }

  saveDXResponse(productId, approved, comment, tags): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};
        if (productId && productId.length > 0) {
          body = {
            productId: productId,
            idToken: token,
            approved: approved,
            comment: comment,
            tags: tags
          }
        }

        const call = this.http.post<any>(authRestUrl + "save_dx_response", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }


  deleteProduct(productId: string): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};
        if (productId && productId.length > 0) {
          body = {
            productId: productId,
            idToken: token
          }
        }

        const call = this.http.post<any>(authRestUrl + "delete_product", body).pipe(
          map(this.extractData));
        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }


  deUpdateProduct(productData: any, categoryId: string, metaData, imageCount, comment: string, decline: boolean, tags: string[], customization: any, productId?: string): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};
        if (productId && productId.length > 0) {
          body = {
            productData: productData,
            categoryId: categoryId,
            productId: productId,
            metaData: metaData,
            imgCount: imageCount,
            comment: comment,
            declined: decline,
            idToken: token,
            tags: tags,
            customization: customization
          }
        } else {
          body = {
            productData: productData,
            categoryId: categoryId,
            metaData: metaData,
            imgCount: imageCount,
            idToken: token,
            tags: tags,
            customization: customization
          }
        }

        const call = this.http.post<any>(authRestUrl + "de_update_product", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }


  updateRefProduct(productData: any, categoryId: string, metaData, imageCount, productId: string, status: RefProductStatus, tags: string[]): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};
        if (productId && productId.length > 0) {
          body = {
            productData: productData,
            categoryId: categoryId,
            productId: productId,
            metaData: metaData,
            imgCount: imageCount,
            idToken: token,
            status: status,
            tags: tags
          }
        }

        const call = this.http.post<any>(authRestUrl + "de_update_ref_product", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }

  getSearchResultsByShop(areaFilter?: string[], catFilter?: string[], statusFilter?: string[], shopFilter?: string[], icFilter?: string[], pageNumber?: number): Observable<any> {

    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {

        const filters: any = [];

        if (catFilter && catFilter.length > 0) {
          filters.push(catFilter);
        }
        if (statusFilter && statusFilter.length > 0) {
          filters.push(statusFilter);
        }

        if (shopFilter && shopFilter.length > 0) {
          filters.push(shopFilter);
        }
        if (areaFilter && areaFilter.length > 0) {
          filters.push(areaFilter);
        }
        if (icFilter && icFilter.length > 0) {
          filters.push(icFilter);
        }

        // console.log(filters);

        const body = {
          filters: filters,
          idToken: token,
          page: (pageNumber && pageNumber > 0) ? pageNumber : 1
        }
        const call = this.http.post<any>(authRestUrl + "get_list_for_de_verify", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }

  getSearchResultsByRegion(location: any, keyword: string, catFilter?: string[], shopFilter?: string[], pageNumber?: number): Observable<any> {

    const resSubject = new BehaviorSubject<any>({ status: "pending" });

    const filters: any = [];

    if (catFilter && catFilter.length > 0) {
      filters.push(catFilter);
    }

    if (shopFilter && shopFilter.length > 0) {
      filters.push(shopFilter);
    }

    // console.log(filters);

    const body = {
      filters: filters,
      location: location,
      keyword: keyword,
      pageLength: 1000,
      page: (pageNumber && pageNumber > 0) ? pageNumber : 1
    }
    const call = this.http.post<any>(authRestUrl + "get_list_for_region", body).pipe(
      map(this.extractData));

    call.subscribe((data) => {
      resSubject.next(data);
    })

    return resSubject;
  }


  updateShopMenu(data): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};

        body = {
          idToken: token,
          menuData: data
        }


        const call = this.http.post<any>(authRestUrl + "update_shop_menu", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }


  updateShopComissions(comData, priceData): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};

        body = {
          idToken: token,
          comissionData: comData,
          priceData: priceData
        }


        const call = this.http.post<any>(authRestUrl + "update_shop_comissions", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }

  approveShopComissions(comData, ownerId, shopId, allowWalletRewards): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};

        body = {
          idToken: token,
          comissionData: comData,
          ownerId: ownerId,
          shopId: shopId,
          awr: allowWalletRewards
        }


        const call = this.http.post<any>(authRestUrl + "approve_shop_comissions", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }

  updateShoptaxes(data): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};

        body = {
          idToken: token,
          taxData: data
        }


        const call = this.http.post<any>(authRestUrl + "update_shop_taxes", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }


  updateShopTimings(shopId: string, ownerId: string, days: any, shopActive: boolean, comingSoon: boolean, noOTPRequired: boolean, hidePhone: boolean, eligibleForDelivery: number, payoutType: number): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        const body: any = {
          shopId: shopId,
          ownerId: ownerId,
          days: days,
          shopActive: shopActive,
          comingSoon: comingSoon,
          noOTPRequired: noOTPRequired,
          hidePhone: hidePhone,
          eligibleForDelivery: eligibleForDelivery,
          payoutType: payoutType,
          idToken: token
        }

        const call = this.http.post<any>(authRestUrl + "admin_update_shop_timings", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }



  getRefProductList(catFilter?: string[], statusFilter?: string[], icFilter?: string[], pageNumber?: number): Observable<any> {

    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {

        const filters: any = [];

        if (catFilter && catFilter.length > 0) {
          filters.push(catFilter);
        }
        if (statusFilter && statusFilter.length > 0) {
          filters.push(statusFilter);
        }
        if (icFilter && icFilter.length > 0) {
          filters.push(icFilter);
        }

        // console.log(filters);

        const body = {
          filters: filters,
          idToken: token,
          page: (pageNumber && pageNumber > 0) ? pageNumber : 1
        }
        const call = this.http.post<any>(authRestUrl + "get_ref_product_list", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }

  addRefProduct(productData: any, categoryId: string, metaData, imageCount, productId?: string): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};
        if (productId && productId.length > 0) {
          body = {
            productData: productData,
            categoryId: categoryId,
            productId: productId,
            metaData: metaData,
            imgCount: imageCount,
            idToken: token
          }
        } else {
          body = {
            productData: productData,
            categoryId: categoryId,
            metaData: metaData,
            imgCount: imageCount,
            idToken: token
          }
        }

        const call = this.http.post<any>(authRestUrl + "add_ref_product", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }


  addRefProductBatch(productBatchData: any, ss, tags): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};

        body = {
          productBatchData: productBatchData,
          suggestedShops: ss ? ss : [],
          tags: tags ? tags : [],
          idToken: token
        }


        const call = this.http.post<any>(authRestUrl + "add_ref_product_batch", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }

  getRecentOrders(): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};

        body = {
          idToken: token
        }


        const call = this.http.post<any>(authRestUrl + "get_recent_orders", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }


  addPOSProduct(product): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};

        body = {
          idToken: token,
          productData: product
        }


        const call = this.http.post<any>(authRestUrl + "add_pos_product", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }

  assignRider(orderId: string, riderId: string): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {
          orderId: orderId,
          riderId: riderId,
          idToken: token
        }


        const call = this.http.post<any>(authRestUrl + "assign_rider", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }


  callFromExotel(toNumber, fromNumber): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};

        body = {
          idToken: token,
          toNumber: toNumber,
          fromNumber: fromNumber
        }


        const call = this.http.post<any>(authRestUrl + "call_from_exotel", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }

  getUserToken(userId): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {};

        body = {
          idToken: token,
          userId: userId
        }


        const call = this.http.post<any>(authRestUrl + "getToken", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }

  addSearchKey(data: any, icon: string): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {
          data: data,
          idToken: token
        }

        if (icon.length > 0) {
          body['icon'] = icon;
        }

        const call = this.http.post<any>(authRestUrl + "add_search_key", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }


  assignRiderNew(dpid: string, srId: string, forAll: boolean): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {
          idToken: token,
          sRID: srId,
          dPID: dpid,
          forAll: forAll
        }

        const call = this.http.post<any>(authRestUrl + "assign_dp", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }

  sendNotification(userUIDs: string[], title: string, message: string, payload: any): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (this.authService.currentUser != null) {
      firebase.default.auth().currentUser.getIdToken().then((token) => {
        let body: any = {
          idToken: token,
          userUIDs: userUIDs,
          title: title,
          message: message,
          payload: { data: payload }
        }
        console.log(body);

        const call = this.http.post<any>(authRestUrl + "send_notification", body).pipe(
          map(this.extractData));

        call.subscribe((data) => {
          resSubject.next(data);
        })
      })
    } else {
      resSubject.next({ status: "error" })
    }
    return resSubject;
  }


  getShopProducts(shopId: string, searchKey: string, refresh: boolean = false): Observable<any> {
    const resSubject = new BehaviorSubject<any>({ status: "pending" });
    if (!this.dataService.shopProducts[shopId]) {
      if (this.authService.currentUser != null) {
        firebase.default.auth().currentUser.getIdToken().then((token) => {
          let body: any = {};

          body = {
            "keyword": searchKey,

            "hideInactiveShops": true,
            "pageLength": 500,
            "filters": ["shopId:" + shopId]
          }


          const call = this.http.post<any>(authRestUrl + "get_list_for_shop", body).pipe(
            map(this.extractData));

          call.subscribe((data) => {
            this.dataService.shopProducts[shopId] = data;
            resSubject.next(data);
          })
        })
      } else {
        resSubject.next({ status: "error" })
      }
    } else {
      resSubject.next(this.dataService.shopProducts[shopId]);
    }
    return resSubject;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // // console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}