<mat-toolbar color="white">
    <mat-toolbar-row>
        <button mat-raised-button color="primary" #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="{name: 'PhFTzbx3NzM0ySBopFJj'}">Select Category</button>
        <span class="fill-remaining-space"></span>
        

    </mat-toolbar-row>

</mat-toolbar>
<mat-menu #appMenu="matMenu">
    <ng-template matMenuContent let-name="name">
        <button *ngFor="let n of catData | getChildern:name:'id'" mat-menu-item [matMenuTriggerFor]="subMenu"
            [matMenuTriggerData]="{name2: n.id}"
            (click)="showAddChildForm(n);menuTrigger.closeMenu();">{{n.name}}</button>
    </ng-template>
</mat-menu>

<mat-menu #subMenu="matMenu">
    <ng-template matMenuContent let-name2="name2">
        <button *ngFor="let n of catData | getChildern:name2:'id'" mat-menu-item [matMenuTriggerFor]="subsubMenu"
            [matMenuTriggerData]="{name3: n.id}"
            (click)="showAddChildForm(n);menuTrigger.closeMenu();">{{n.name}}</button>
    </ng-template>
</mat-menu>

<mat-menu #subsubMenu="matMenu">
    <ng-template matMenuContent let-name3="name3">
        <button *ngFor="let n of catData | getChildern:name3:'id'" mat-menu-item [matMenuTriggerFor]="subsubsubMenu"
        [matMenuTriggerData]="{name4: n.id}"
            (click)="showAddChildForm(n);menuTrigger.closeMenu();">{{n.name}}</button>
    </ng-template>
</mat-menu>
<mat-menu #subsubsubMenu="matMenu">
    <ng-template matMenuContent let-name4="name4">
        <button *ngFor="let n of catData | getChildern:name4:'id'" mat-menu-item
            (click)="showAddChildForm(n);menuTrigger.closeMenu();">{{n.name}}</button>
    </ng-template>
</mat-menu>



<form *ngIf="currentCat.name" [formGroup]="addCatForm" (ngSubmit)="add_category()">


    <div>Parent:{{currentCat.name}}</div>
    <mat-form-field>
        <input  formControlName="name" matInput placeholder="Category Name">
    </mat-form-field>
    <br>
    <mat-checkbox formControlName="isActive">Enabled</mat-checkbox>
    <br>
    <button mat-raised-button type="submit">Add Category</button>
</form>