<mat-form-field class="example-chip-list">
    <mat-label>Menu Labels</mat-label>
    <mat-chip-list #chipList aria-label="Menu">
        <mat-chip *ngFor="let menulabel of menuLabels;" [selectable]="selectable" [removable]="menulabel.name!='Others'"
            (removed)="remove(menulabel)">
            {{menulabel.name}}
            <mat-icon matChipRemove *ngIf="menulabel.name!='Others'">cancel</mat-icon>
        </mat-chip>
        <input placeholder="New menu label..." class="w-100" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
</mat-form-field>
<div class="w-25">
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop2($event)">
        <div class="example-box" *ngFor="let menulabel of menuLabels" cdkDrag>{{menulabel.name}}</div>
    </div>
</div>
<button mat-raised-button (click)="submitMenu()">Submit</button>


<div cdkDropListGroup>
    <div class="example-container" *ngFor="let ml of menuLabels">
        <h2>{{ml.name}}</h2>

        <div cdkDropList [cdkDropListData]="menuListProducts[ml.name]" class="example-list"
            (cdkDropListDropped)="drop($event)">
            <div class="example-box" *ngFor="let item of menuListProducts[ml.name]" cdkDrag>{{item.Name}}</div>
        </div>
    </div>
</div>