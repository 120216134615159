<h1>Welcome!</h1>
<div *ngIf="showProducts">
    <mat-radio-group aria-label="Select a Region" (change)="getCoordsFromGoogle($event)">
        <mat-radio-button *ngFor="let c of city_list" [checked]="regionSelected.city==c.city" [value]="c">
            {{c.city}}
        </mat-radio-button>
    </mat-radio-group>


    <div>
        <mat-accordion>

            <div class="row">
                <div class="col-sm-auto">
                    <section class="example-section">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{catFilter.name}}&nbsp;&nbsp;(&nbsp;{{catFilter.count}}&nbsp;)
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="exp-body-scroll">
                                <span class="example-list-section">
                                    <mat-checkbox class="example-margin" [checked]="allChecked"
                                        [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                        {{catFilter.name}}&nbsp;&nbsp;(&nbsp;{{catFilter.count}}&nbsp;)
                                    </mat-checkbox>
                                </span>
                                <span class="example-list-section">
                                    <ul>
                                        <li *ngFor="let subcat of catFilter.subcats">
                                            <mat-checkbox [(ngModel)]="subcat.checked" [color]="subcat.color"
                                                (ngModelChange)="updateallChecked()">
                                                {{subcat.name}}&nbsp;&nbsp;(&nbsp;{{subcat.count}}&nbsp;)
                                            </mat-checkbox>
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </mat-expansion-panel>


                    </section>
                    <!-- </div>
            <div class="col-sm-6"> -->
                    <section class="example-section">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{statusFilter.name}}&nbsp;&nbsp;(&nbsp;{{statusFilter.count}}&nbsp;)
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="exp-body-scroll">

                                <span class="example-list-section">
                                    <mat-checkbox class="example-margin" [checked]="allStatusChecked"
                                        [indeterminate]="someComplete('status')"
                                        (change)="setAll($event.checked,'status')">
                                        {{statusFilter.name}}&nbsp;&nbsp;(&nbsp;{{statusFilter.count}}&nbsp;)
                                    </mat-checkbox>
                                </span>
                                <span class="example-list-section">
                                    <ul>
                                        <li *ngFor="let substat of statusFilter.substatus">
                                            <mat-checkbox [(ngModel)]="substat.checked" [color]="substat.color"
                                                (ngModelChange)="updateallChecked('status')">
                                                {{substat.name}}&nbsp;&nbsp;(&nbsp;{{substat.count}}&nbsp;)
                                            </mat-checkbox>
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </mat-expansion-panel>


                    </section>

                    <section class="example-section">

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{shopFilter.name}}&nbsp;&nbsp;(&nbsp;{{shopFilter.count}}&nbsp;)
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="exp-body-scroll">

                                <span class="example-list-section">
                                    <mat-checkbox class="example-margin" [checked]="allShopsChecked"
                                        [indeterminate]="someComplete('shop')" (change)="setAll($event.checked,'shop')">
                                        {{shopFilter.name}}&nbsp;&nbsp;(&nbsp;{{shopFilter.count}}&nbsp;)
                                    </mat-checkbox>
                                </span>
                                <span class="example-list-section">
                                    <ul>
                                        <li *ngFor="let subshop of shopFilter.subshops">
                                            <mat-checkbox [(ngModel)]="subshop.checked" [color]="subshop.color"
                                                (ngModelChange)="updateallChecked('shop')">
                                                {{subshop.name}}&nbsp;&nbsp;(&nbsp;{{subshop.count}}&nbsp;)
                                            </mat-checkbox>
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </mat-expansion-panel>


                    </section>

                    <section class="example-section">

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{icFilter.name}}&nbsp;&nbsp;(&nbsp;{{icFilter.count}}&nbsp;)
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="exp-body-scroll">

                                <span class="example-list-section">
                                    <mat-checkbox class="example-margin" [checked]="allICChecked"
                                        [indeterminate]="someComplete('ic')" (change)="setAll($event.checked,'ic')">
                                        {{icFilter.name}}&nbsp;&nbsp;(&nbsp;{{icFilter.count}}&nbsp;)
                                    </mat-checkbox>
                                </span>
                                <span class="example-list-section">
                                    <ul>
                                        <li *ngFor="let subcount of icFilter.subcounts">
                                            <mat-checkbox [(ngModel)]="subcount.checked" [color]="subcount.color"
                                                (ngModelChange)="updateallChecked('ic')">
                                                {{subcount.name}}&nbsp;&nbsp;(&nbsp;{{subcount.count}}&nbsp;)
                                            </mat-checkbox>
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </mat-expansion-panel>

                    </section>
                    <section class="example-section">

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{tagFilter.name}}&nbsp;&nbsp;(&nbsp;{{tagFilter.count}}&nbsp;)
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="exp-body-scroll">

                                <span class="example-list-section">
                                    <mat-checkbox class="example-margin" [checked]="allTagsChecked"
                                        [indeterminate]="someComplete('tag')" (change)="setAll($event.checked,'tag')">
                                        {{tagFilter.name}}&nbsp;&nbsp;(&nbsp;{{tagFilter.count}}&nbsp;)
                                    </mat-checkbox>
                                </span>
                                <span class="example-list-section">
                                    <ul>
                                        <li *ngFor="let subtag of tagFilter.subtags">
                                            <mat-checkbox [(ngModel)]="subtag.checked" [color]="subtag.color"
                                                (ngModelChange)="updateallChecked('tag')">
                                                {{subtag.name}}&nbsp;&nbsp;(&nbsp;{{subtag.count}}&nbsp;)
                                            </mat-checkbox>
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </mat-expansion-panel>

                    </section>
                </div>
            </div>

            <div class="row">
                <div class="col-auto">
                    <button color="accent" type="button" mat-raised-button (click)="applyFilters()"><i
                            class="fas fa-filter"></i>&nbsp;<span>Apply
                            FIlters</span></button>
                </div>
                <div class="col-auto">
                    <button color="accent" type="button" mat-raised-button (click)="applyFilters(true)">
                        <span class="fa-stack">
                            <i style="font-size: 0.6rem;" class="fa fa-filter fa-stack-1x"></i>
                            <i style="font-size: 1.2rem;" class="fa fa-ban fa-stack-1x"></i>
                        </span>&nbsp;<span>Clear
                            FIlters</span></button>
                </div>
            </div>
        </mat-accordion>
    </div>


    <mat-accordion multi>
        <div *ngFor="let p of productListHome let i=index;" role="group"
            class="mx-auto mt-1  btn-group w-100 d-flex row">
            <mat-expansion-panel class="w-100 {{getProductStatusColor(p.status)}}" [expanded]="step === i"
                (opened)="setStep(i)">
                <mat-expansion-panel-header class="h-auto py-2">
                    <img *ngIf="p.imgCount&&p.imgCount>0"
                        src="https://firebasestorage.googleapis.com/v0/b/shopnearby-301e7-product-image-bucket/o/product_images%2F{{p.productId}}%2F0.jpg?alt=media"
                        alt="no-image" width="40" />
                    <h3 class="mat-subheading-2">{{p.Name}}</h3>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Status: </span>
                    <strong>{{getStatusText(p.status)}}</strong>
                </mat-expansion-panel-header>

                <mat-tab-group>
                    <mat-tab *ngIf="loggedIn&&expertrole&&step===i" label="DX Verify">
                        <app-expert-verify-products [inventoryId]="p.productId" (changed)="handleChange($event)">
                        </app-expert-verify-products>
                    </mat-tab>
                    <mat-tab *ngIf="loggedIn&&datarole&&step===i" label="DE Verify">
                        <app-de-verify-products [inventoryId]="p.productId" (changed)="handleChange($event)">
                        </app-de-verify-products>
                    </mat-tab>
                </mat-tab-group>

                <!-- <mat-action-row>
            <button *ngIf="loggedIn&&datarole" color="primary" type="button" mat-raised-button
                (click)="gotoDEVerify(p.productId)"><i class="fa fa-external-link" aria-hidden="true"></i>&nbsp;<span>DE
                    Verify</span></button>
            <button *ngIf="loggedIn&&expertrole" color="primary" type="button" (click)="gotoExpertVerify(p.productId)"
                mat-raised-button>
                <i class="fa fa-edit" style="font-size:18px;color:black;"></i>&nbsp;<span>DX Verify</span>
            </button>

        </mat-action-row> -->
            </mat-expansion-panel>
        </div>
    </mat-accordion>
    <mat-toolbar color="white">
        <button mat-raised-button *ngIf="pageNumber==loadedPageNumber&&productListHome.length<totalCount" type="button"
            class="w-auto mx-auto" (click)="applyFilters(false,true)">
            Load More
        </button>
    </mat-toolbar>
</div>