import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseRestService } from '../services/firebase-rest.service';

@Component({
  selector: 'app-print-qr',
  templateUrl: './print-qr.component.html',
  styleUrls: ['./print-qr.component.css']
})
export class PrintQrComponent implements OnInit {
  shopId: string = "";
  shopData: any = {};
  decodedlink: string = "";
  constructor(private route: ActivatedRoute, private fireRest: FirebaseRestService) {
    this.route.params.subscribe(params => {
      if (params.shopId && params.shopId.length > 0) {
        this.shopId = params.shopId;
        this.decodedlink = "https://shopnearby.me/#/shop-products/" + this.shopId;

        this.fetch(this.shopId);
      }

    });
  }

  ngOnInit(): void {
  }

  fetch(shopId) {
    this.fireRest.getShopByShopId(shopId).subscribe((data) => {
      console.log(data);
      this.shopData = data.docs[0].data();
    })
  }

}
