import { Component, OnInit } from '@angular/core';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { Router } from '@angular/router';
import { RestService } from '../services/rest.service';
import { DataService } from '../services/data.service';
import * as _ from 'lodash';
import * as plusCode from 'open-location-code-typescript'
import { starter_cities, starter_cities_with_others } from '../_models/cities-states';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-shop-management',
  templateUrl: './shop-management.component.html',
  styleUrls: ['./shop-management.component.css']
})
export class ShopManagementComponent implements OnInit {

  readonly locStgName = 'location'

  shopsData: any;
  map: google.maps.Map<HTMLElement>;
  shopMarkers: any = {};
  regionSelected: any = {};
  city_list: any[] = starter_cities_with_others;

  constructor(private fireRest: FirebaseRestService, private router: Router, private rest: RestService, private dataService: DataService, private clipboard: Clipboard) {

    if (localStorage.getItem('selected' + this.locStgName)) {
      this.regionSelected = JSON.parse(localStorage.getItem('selected' + this.locStgName));
    }


  }

  ngOnInit(): void {
    // this.regionSelected = this.city_list.find(ele => ele.city == "Chirala")
    this.fireRest.getAllShops().subscribe((data) => {
      this.shopsData = _.orderBy(data.map(ele => ele.data()), ['shopCategory', 'shopname'], ['desc', 'asc'])
      console.log(this.shopsData);

      const gshops = this.shopsData.filter(ele => (ele.address_city == "Guntur"));
      // gshops.forEach(element => console.log(element));
      console.log(gshops);

      // this.map = new google.maps.Map(
      //   document.getElementById("smgmap") as HTMLElement,
      //   {
      //     zoom: 10,
      //     center: this.getlatLngForPC("7M72VC00+"),
      //     draggable: true,
      //     streetViewControl: false,
      //     mapTypeControl: false,
      //     scrollwheel: false
      //   }
      // );

      // for (const s of this.shopsData) {
      //   this.addMarkerToMap({ lat: s.location.Latitude, lng: s.location.Longitude }, this.map, "s", s);
      // }


    })
  }

  printShopQr(id) {
    this.router.navigate(['print-qr/' + id])
  }

  editShop(ownerId, id) {
    this.router.navigate(['edit-shop/' + ownerId + '/' + id])
  }

  editShopMenu(ownerId, id) {
    this.router.navigate(['edit-shop-menu/' + ownerId + '/' + id])
  }

  getToken(ownerId) {
    this.rest.getUserToken(ownerId).subscribe((data) => {
      // console.log(data);
      if (data.status === "success") {
        this.clipboard.copy("https://shopnearby.me/#/token-login/" + data.role + "/" + data.token)
      }
    })
  }

  managePrices(ownerId, id) {
    this.router.navigate(['price-management/' + ownerId + '/' + id])
  }

  manageTaxes(ownerId, id) {
    this.router.navigate(['tax-management/' + ownerId + '/' + id])
  }

  linkPos(ownerId, id) {
    this.router.navigate(['link-pos/' + ownerId + '/' + id])
  }

  refresh(ownerId, id) {
    this.fireRest.refreshShopProducts(ownerId, id)
  }

  downloadMenu(ownerId, id, onlyPrice = false) {

    const cFilter: any = [];
    const sFilter: any = [];

    if (id && id.length > 0) {
      sFilter.push('shopId:' + id);
    }
    this.rest.getSearchResultsByRegion({}, "", cFilter, sFilter).subscribe((productData) => {
      console.log(productData);
      if (productData.status === "success") {
        const sheetData: any = [];

        let columns: any = [];
        if (productData.data.hits && productData.data.hits.length > 0) {
          for (const hit of productData.data.hits) {
            // console.log(hit);
            const row: any = {};
            columns = [];
            if (!onlyPrice) {
              for (const v of hit._clientView) {
                row[v.l] = v.v ? this.format(v.v + "") : "";
                columns.push({ lable: v.l, order: v.o });
              }
            } else {
              row["Name"] = hit.Name;
              row["Price"] = hit._clientView.find(ele => ele.l === "Price (MRP)") ? hit._clientView.find(ele => ele.l === "Price (MRP)").v : 0;
              row["Discount"] = hit._clientView.find(ele => ele.l === "Discount amount") ? hit._clientView.find(ele => ele.l === "Discount amount").v : 0;
            }
            sheetData.push(row)
          }
          // console.log(sheetData);
          // console.log(columnNames);
          let columnNames: string[];
          if (!onlyPrice) {
            columnNames = _.orderBy(columns, ['order']).map(ele => ele.lable);
          } else {
            columnNames = ["Name", "Price", "Discount"];
          }

          // return;

          const sheetName = onlyPrice ? productData.data.hits[0].shopName + " Prices" : productData.data.hits[0].shopName;

          this.dataService.downloadFile(sheetData, sheetName, columnNames);
        }

      }


    });
  }
  getlatLngForPC(code) {
    const area = plusCode.default.decode(code);
    return { lat: area.latitudeCenter, lng: area.longitudeCenter }

  }

  addMarkerToMap(position, map, label, entityData) {
    // console.log(entityData);

    if (!this.shopMarkers[entityData['id']]) {
      this.shopMarkers[entityData['id']] = new google.maps.Marker({
        position: position,
        map: map,
        clickable: true,
        icon: {
          url: "assets/" + label + ".png"
        },
      });
    } else {
      this.shopMarkers[entityData['id']].setPosition(position)
    }


    this.shopMarkers[entityData['id']].addListener("click", () => {

      if (label === 's') {
        const infoWindow = new google.maps.InfoWindow({
          content: "Name: " + entityData.shopname
        })
        infoWindow.open(map, this.shopMarkers[entityData['id']]);
        // console.log(entityData);

      }


    });



    // console.log(this.riderMarkers);


  }

  setCity(e) {
    console.log(e);
    this.regionSelected = e.value;
    if (this.regionSelected.city != "Others")
      localStorage.setItem('selected' + this.locStgName, JSON.stringify(this.regionSelected));
  }

  format(comp) {
    return comp;//.replace(/,/g, '|')
  }

}
