import { Component, OnInit } from '@angular/core';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';


@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {

  catData: any = [];
  currentCat: any = {};
  currentCatIndex: number = -1;
  editCatForm: UntypedFormGroup;
  delHidden = true;
  files: File[] = [];

  imageChangedEvent: any = '';
  croppedImage: any = '';
  showRotater: boolean = false;
  rotation: number = 0;



  constructor(private fireRest: FirebaseRestService, private formBuilder: UntypedFormBuilder) {

    fireRest.getAllCategories().subscribe((cd: any) => {
      this.catData = cd;
      // // console.log(cd);
    });




  }




  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  fileChangeEvent(event: any): void {
    console.log(event);
    let e: any = {};
    e.target = {
      files: event.addedFiles
    }

    this.imageChangedEvent = e;
  }
  imageCropped(event: ImageCroppedEvent) {

    this.croppedImage = event.base64;
  }
  imageLoaded(image?: HTMLImageElement) {
    // console.log("loaded");
    this.showRotater = true
    // show cropper
  }
  cropperReady() {
    console.log("cropper ready");

    // cropper ready
  }
  loadImageFailed() {
    console.log("load fail");

    // show message
  }

  toTitleCase(str) {
    return str.split(/\s+/).map(s => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()).join(" ");
  }

  showAddChildForm(cat) {
    this.files = [];
    // console.log(cat);
    //[this.toTitleCase(cat.name)],
    this.editCatForm = this.formBuilder.group({
      name: [this.toTitleCase(cat.name)],
      parent: [cat.parentId],
      isActive: cat.enabled === "1" ? true : false
    });
    this.currentCat = cat;
    this.currentCatIndex = this.catData.findIndex(element => element.id === this.currentCat.id)

  }



  update_category() {



    const payload = {
      "name": this.editCatForm.value['name'],
      "enabled": this.editCatForm.value['isActive'],
      "parentId": this.editCatForm.value['parent'],
      "parentName": this.getNameById(this.editCatForm.value['parent'])
    }
    // // console.log(payload);
    this.fireRest.editCategory(payload, this.currentCat.id, this.currentCatIndex, this.croppedImage).subscribe((data: any) => {
      if (data.length > 0) {
        // // console.log(data);
        this.catData = data;
        // this.showAddChildForm(data.filter(item => (item.parentId === undefined))[1]);
      }
    });
    // // console.log(this.catData.filter(item => (item.parentId === undefined)));
    // this.showAddChildForm(this.catData.filter(item => (item.parentId === undefined))[0]);
  }

  delete_category() {

    this.fireRest.deleteCategory(this.currentCat.id, this.currentCatIndex).subscribe((data: any) => {
      if (data.length > 0) {
        // console.log(data);
        this.catData = data;
        this.delHidden = true;
      }
    });
  }

  ngOnInit(): void {
  }

  getIdByName(name) {
    return this.catData.filter(item => (item.name === name))[0].id
  }

  getNameById(Id) {
    // // console.log(Id);
    // // console.log(this.catData);
    return this.catData.filter(item => (item.id === Id))[0].name
  }
}
