import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { SidenavService } from '../services/sidenav.service';
import { Role } from '../_models/role.enum';
import { ThemePalette } from '@angular/material/core';
import { RestService } from '../services/rest.service';
import { DataService } from '../services/data.service';
import { FirebaseRestService } from '../services/firebase-rest.service';
import { ProductStatus } from '../_models/models';
import { MatAccordion } from '@angular/material/expansion';
import { BehaviorSubject } from 'rxjs';
import { starter_cities } from '../_models/cities-states';


export interface CatFilter {
  name: string;
  id?: string;
  count: number;
  checked: boolean;
  color: ThemePalette;
  subcats?: CatFilter[];
}

export interface TagFilter {
  name: string;
  tagName?: string;
  count: number;
  checked: boolean;
  color: ThemePalette;
  subtags?: TagFilter[];
}

export interface ShopFilter {
  name: string;
  id?: string;
  count: number;
  checked: boolean;
  color: ThemePalette;
  subshops?: ShopFilter[];
}

export interface StatusFilter {
  name: string;
  status?: number;
  count: number;
  checked: boolean;
  color: ThemePalette;
  substatus?: StatusFilter[];
}

export interface ICFilter {
  name: string;
  iCount?: number;
  count: number;
  checked: boolean;
  color: ThemePalette;
  subcounts?: ICFilter[];
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  loggedIn = true;
  datarole;
  expertrole;
  productListHome: any = [];
  catFilter: CatFilter = {
    name: 'All Categories',
    count: 0,
    checked: true,
    color: 'primary',
    subcats: []
  };

  tagFilter: TagFilter = {
    name: 'All Tags',
    count: 0,
    checked: true,
    color: 'primary',
    subtags: []
  };

  shopFilter: ShopFilter = {
    name: 'All Shops',
    count: 0,
    checked: true,
    color: 'primary',
    subshops: []
  };

  statusFilter: StatusFilter = {
    name: 'All Statuses',
    count: 0,
    checked: true,
    color: 'primary',
    substatus: []
  };
  icFilter: ICFilter = {
    name: 'All Image Counts',
    count: 0,
    checked: true,
    color: 'primary',
    subcounts: []
  };

  pageNumber = 1;
  loadedPageNumber = 1;
  totalCount = 0;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  readonly locStgName = 'location'
  userLat: number;
  userLng: number;
  locationName = "";
  locationObject: { name: any; lat: any; lng: any; };
  locationSet = false;
  locationSub = new BehaviorSubject<boolean>(false);
  plusCodeFilters: string[] = [];
  regionSelected: any = {};

  city_list: any[] = starter_cities;

  userRole = 0;
  showProducts = false;

  constructor(private authService: AuthService, public router: Router, private rest: RestService, public dataService: DataService, private fireRest: FirebaseRestService) {

    if (localStorage.getItem('selected' + this.locStgName)) {
      this.regionSelected = JSON.parse(localStorage.getItem('selected' + this.locStgName));
    }
    // console.log(this.regionSelected);

    this.authService.loginStatus.subscribe((data) => {

      this.datarole = (this.authService.userRole == Role.Admin || this.authService.userRole == Role.DataEntry);
      this.expertrole = (this.authService.userRole == Role.Admin || this.authService.userRole == Role.DataExpert);
      // console.log(this.datarole);
      if (data) {
        this.loggedIn = true;
        this.userRole = this.authService.userRole;




        if (this.checkRole([Role.Admin, Role.DataEntry, Role.DataExpert])) {
          this.showProducts = true;
          this.getCoordsFromGoogle();
          this.locationSub.subscribe((isSet) => {
            if (isSet) {
              const pca = this.dataService.getPlusCodeArea(this.userLat, this.userLng);

              const nighbours: string[] = this.dataService.getNeighbours(pca);
              this.plusCodeFilters = []
              nighbours.forEach((pc) => {
                this.plusCodeFilters.push('plusCodeArea:' + pc)
              })

              // console.log(this.plusCodeFilters);

              this.getProducts();

            }
          })


        }




      } else {
        this.loggedIn = false;
        this.userRole = 0;
      }
    })




  }

  ngOnInit(): void {

  }

  checkRole(roles: number[] = []) {
    // console.log(this.userRole);

    return roles.includes(this.userRole);
  }


  gotoDEVerify(id?: string) {
    if (id && id.length > 0) {
      this.router.navigate(['/de-verify/' + id])
    } else {
      this.router.navigate(['/de-verify'])
    }

  }

  gotoExpertVerify(id?: string) {
    if (id && id.length > 0) {
      this.router.navigate(['/expert-verify/' + id])
    } else {
      this.router.navigate(['/expert-verify'])
    }
  }

  allChecked: boolean = true;
  allTagsChecked: boolean = true;
  allStatusChecked: boolean = true;
  allShopsChecked: boolean = true;
  allICChecked: boolean = true;

  step = 0;
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  updateallChecked(type?: string) {
    if (type === "status") {
      this.allStatusChecked = this.statusFilter.substatus != null && this.statusFilter.substatus.every(t => t.checked);
    } else if (type === "shop") {
      this.allShopsChecked = this.shopFilter.subshops != null && this.shopFilter.subshops.every(t => t.checked);
    } else if (type === "ic") {
      this.allICChecked = this.icFilter.subcounts != null && this.icFilter.subcounts.every(t => t.checked);
    } else if (type === "tag") {
      this.allTagsChecked = this.tagFilter.subtags != null && this.tagFilter.subtags.every(t => t.checked);
    } else {
      this.allChecked = this.catFilter.subcats != null && this.catFilter.subcats.every(t => t.checked);
    }

    // this.applyFilters();

  }

  getProductStatusColor(status): string {
    switch (status) {
      case ProductStatus.Active: {
        return '';
      }
      case ProductStatus.PendingSNBVerification: {
        return 'bg-info';
      }
      case ProductStatus.SNBDEBeingVerified, ProductStatus.SNBDXBeingVerified, ProductStatus.PendingSNBExpertVerification, ProductStatus.SNBExpertDeclined: {
        return 'bg-primary';
      }
    }

  }

  applyFilters(clear?: boolean, nextPage?: boolean) {
    const cFilter: any = [];
    const sFilter: any = [];
    const shopFilter: any = [];
    const icFilter: any = [];

    if (!clear) {
      for (const cf in this.catFilter.subcats) {
        if (this.catFilter.subcats[cf].checked)
          cFilter.push('categoryId:' + this.catFilter.subcats[cf].id);
      }
      for (const tf in this.tagFilter.subtags) {
        if (this.tagFilter.subtags[tf].checked)
          cFilter.push('tags:' + this.tagFilter.subtags[tf].tagName);
      }
      for (const sf in this.statusFilter.substatus) {
        if (this.statusFilter.substatus[sf].checked)
          sFilter.push('status:' + this.statusFilter.substatus[sf].status);
      }
      for (const shopf in this.shopFilter.subshops) {
        if (this.shopFilter.subshops[shopf].checked)
          shopFilter.push('shopId:' + this.shopFilter.subshops[shopf].id);
      }
      for (const icf in this.icFilter.subcounts) {
        if (this.icFilter.subcounts[icf].checked)
          icFilter.push('imgCount:' + this.icFilter.subcounts[icf].iCount);
      }
    }

    if (nextPage) {
      this.pageNumber = this.pageNumber + 1
    } else {
      this.pageNumber = 1;
    }
    const currPage = this.pageNumber;


    this.rest.getSearchResultsByShop(this.plusCodeFilters, cFilter.length > 0 ? cFilter : null, sFilter.length > 0 ? sFilter : null, shopFilter.length > 0 ? shopFilter : null, icFilter.length > 0 ? icFilter : null, currPage).subscribe((productData) => {
      console.log(productData);
      if (productData.status === "success") {
        if (nextPage) {
          for (const hit of productData.data.hits) {
            this.dataService.productListResults.hits.push(hit);
          }
          this.dataService.productListResults.page = productData.data.page;
          this.setFilters(this.dataService.productListResults);
        } else {
          this.setFilters(productData.data);
        }
      }
    });
  }

  someComplete(type?: string): boolean {
    if (type === "status") {
      if (this.statusFilter.substatus == null) {
        return false;
      }
      return this.statusFilter.substatus.filter(t => t.checked).length > 0 && !this.allStatusChecked;
    } else if (type === "ic") {
      if (this.icFilter.subcounts == null) {
        return false;
      }
      return this.icFilter.subcounts.filter(t => t.checked).length > 0 && !this.allICChecked;
    } else if (type === "shop") {
      if (this.shopFilter.subshops == null) {
        return false;
      }
      return this.shopFilter.subshops.filter(t => t.checked).length > 0 && !this.allShopsChecked;
    } else if (type === "tag") {
      if (this.tagFilter.subtags == null) {
        return false;
      }
      return this.tagFilter.subtags.filter(t => t.checked).length > 0 && !this.allTagsChecked;
    } else {
      if (this.catFilter.subcats == null) {
        return false;
      }
      return this.catFilter.subcats.filter(t => t.checked).length > 0 && !this.allChecked;
    }

  }

  setAll(completed: boolean, type?: string) {
    if (type === "status") {
      this.allStatusChecked = completed;
      if (this.statusFilter.substatus == null) {
        return;
      }
      this.statusFilter.substatus.forEach(t => t.checked = completed);
    } else if (type === "ic") {
      this.allICChecked = completed;
      if (this.icFilter.subcounts == null) {
        return;
      }
      this.icFilter.subcounts.forEach(t => t.checked = completed);
    } else if (type === "shop") {
      this.allShopsChecked = completed;
      if (this.shopFilter.subshops == null) {
        return;
      }
      this.shopFilter.subshops.forEach(t => t.checked = completed);
    } else if (type === "tag") {
      this.allTagsChecked = completed;
      if (this.tagFilter.subtags == null) {
        return;
      }
      this.tagFilter.subtags.forEach(t => t.checked = completed);
    } else {
      this.allChecked = completed;
      if (this.catFilter.subcats == null) {
        return;
      }
      this.catFilter.subcats.forEach(t => t.checked = completed);
    }

  }

  getProducts() {
    // this.dataService.productListHome = [];
    if (Object.keys(this.dataService.productListResults).length == 0) {
      this.rest.getSearchResultsByShop(this.plusCodeFilters).subscribe((productData) => {
        // console.log(productData);
        if (productData.status === 'success') {
          this.setFilters(productData.data);
        }
      })
    } else {
      this.setFilters(this.dataService.productListResults);
    }
  }

  private setFilters(productData: any) {
    this.dataService.productListResults = productData;
    const catFacets = this.dataService.productListResults.facets.categoryId;
    const tagFacets = this.dataService.productListResults.facets.tags;
    const shopFacets = this.dataService.productListResults.facets.shopId;
    const statusFacets = this.dataService.productListResults.facets.status;
    const icFacets = this.dataService.productListResults.facets.imgCount;
    this.totalCount = this.dataService.productListResults.nbHits;
    this.loadedPageNumber = this.dataService.productListResults.page + 1;
    this.catFilter.subcats = [];
    this.tagFilter.subtags = [];
    this.shopFilter.subshops = [];
    this.statusFilter.substatus = [];
    this.icFilter.subcounts = [];
    this.productListHome = this.dataService.productListResults.hits;


    if (catFacets && Object.keys(catFacets).length > 0) {
      this.catFilter.count = this.totalCount;

      this.fireRest.getAllCategories().subscribe((catData) => {
        if (catData.length > 0) {
          for (const cf in catFacets) {
            const catName = catData.find(ele => ele.id == cf).name;
            this.catFilter.subcats.push({ name: catName, id: cf, count: catFacets[cf], checked: true, color: "primary" });
          }
        }
      });
    }

    if (tagFacets && Object.keys(tagFacets).length > 0) {
      this.tagFilter.count = this.totalCount;

      for (const tf in tagFacets) {
        this.tagFilter.subtags.push({ name: tf, tagName: tf, count: tagFacets[tf], checked: true, color: "primary" });
      }

    }

    if (shopFacets && Object.keys(shopFacets).length > 0) {
      this.shopFilter.count = this.totalCount;

      this.fireRest.getAllShops().subscribe((shopData) => {
        if (shopData.length > 0) {
          for (const shopf in shopFacets) {
            const cshop = shopData.find(ele => (ele.get('id') === shopf))
            this.shopFilter.subshops.push({ name: cshop && cshop.get('shopname') ? cshop.get('shopname') : "shop on hold", id: shopf, count: shopFacets[shopf], checked: true, color: "primary" });
          }
        }
      })
    }


    if (icFacets && Object.keys(icFacets).length > 0) {
      this.icFilter.count = this.totalCount;
      for (const icf in icFacets) {
        let countText = "NA";
        switch (parseInt(icf)) {
          case 0: {
            countText = "No Image";
            break;
          }
          case 1: {
            countText = "1 Image";
            break;
          }
          case 2: {
            countText = "2 Images";
            break;
          }
          case 3: {
            countText = "3 Images";
            break;
          }
          case 4: {
            countText = "4 Images";
            break;
          }
          case 5: {
            countText = "5 Images";
            break;
          }
        }
        this.icFilter.subcounts.push({ name: countText, iCount: parseInt(icf), count: icFacets[icf], checked: true, color: "primary" });
      }
    }

    if (statusFacets && Object.keys(statusFacets).length > 0) {
      this.statusFilter.count = this.totalCount;
      for (const sf in statusFacets) {
        let statusText = this.dataService.getStatusText(sf)
        this.statusFilter.substatus.push({ name: statusText, status: parseInt(sf), count: statusFacets[sf], checked: true, color: "primary" });
      }
    }
  }

  getStatusText(s) {
    return this.dataService.getStatusText(s);
  }

  getCoordsFromGoogle(e?) {
    // console.log(e);

    // if (this.locationForm.valid) {
    // const address = this.locationForm.value;

    if (e && e.value) {
      this.regionSelected = e.value;
    }
    if (this.regionSelected.city && this.regionSelected.city.length > 0) {
      this.dataService.productListResults = {}
      localStorage.setItem('selected' + this.locStgName, JSON.stringify(this.regionSelected));

    }

    const c = this.regionSelected.city ? this.regionSelected.city : "Chirala";
    const s = this.regionSelected.state ? this.regionSelected.state : "Andhra Pradesh";

    const addrStr = "address=" +
      this.format(c
        + " " + s + " IN")

    //console.log(addrStr);

    if (this.regionSelected.lat && this.regionSelected.lng) {
      this.userLat = this.regionSelected.lat;
      this.userLng = this.regionSelected.lng;
      // this.locationName = loc.formatted_address;
      this.locationObject = {
        name: this.locationName,
        lat: this.userLat,
        lng: this.userLng
      };
      localStorage.setItem(this.locStgName, JSON.stringify(this.locationObject));
      this.locationSet = true;
      this.locationSub.next(this.locationSet);
    } else {
      this.rest.getGeoCode(addrStr).subscribe((data) => {
        console.log(data);

        const locations = data;
        let loc = locations.results[0];
        console.log(this.userLat = loc.geometry['location']);

        this.userLat = loc.geometry['location'].lat;
        this.userLng = loc.geometry['location'].lng;
        this.locationName = loc.formatted_address;
        this.locationObject = {
          name: this.locationName,
          lat: this.userLat,
          lng: this.userLng
        };
        localStorage.setItem(this.locStgName, JSON.stringify(this.locationObject));
        this.locationSet = true;
        this.locationSub.next(this.locationSet);

      });
    }

  }

  format(comp) {
    return encodeURIComponent(comp.replace(/,/g, ' ').replace(/\./g, ' ').replace(/-/g, ' '))
  }

  loadMore() {
    // if (this.dataService.shopPLLastItem) {
    //   this.fireRest.getUserProductsIncr(this.pageCount, this.dataService.shopPLLastItem).subscribe((data: any) => {
    //     if (data.length > 0) {
    //       // console.log(data);
    //       this.dataService.shopPLLastItem = data[data.length - 1];
    //       this.dataService.pLastLoadedTime = new Date();
    //       for (const p of data) {
    //         this.dataService.productListHome[p.id] = { id: p.id, data: p.data() }
    //       }
    //       // this.dataService.productListHome = this.dataService.productListHome.concat(data);
    //     }

    //   })
    // }
  }

  handleChange(e) {
    console.log(e);
    if (e) {
      // this.accordion.closeAll();
      this.nextStep();
    }
  }

}
